import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as Data } from '../../assets/img/data.svg';
import { ReactComponent as Clock } from '../../assets/img/clock.svg';
import { ReactComponent as Organizer } from '../../assets/img/organizer.svg';
import { ReactComponent as TournType } from '../../assets/img/kimono.svg';

import { TextField, Tooltip } from '@material-ui/core';

import { serverDefaultPath, DEFAULT_TOURNAMENT } from '../../helpers/constants';
import { organizerName } from '../../helpers/selectors';

import EmptyState from '../EmptyState/EmptyState';

import { useStyles } from './Styles';

const TournamentsList = (props) => {
  const {
    pastTournaments,
    upcomingTournaments,
    loading,
    nextTourn,
    prevTourn,
    currentTournamnets,
    countCards,
    currentDate,
    viewportWidth,
    handleTxtChange,
    getTournByPin,
    pin,
    role
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const currentRole = localStorage.getItem('role');

  const moveTo = () =>
    navigate('/events/add-event', {
      state: { prevUrl: location.pathname }
    });

  const moveToEvent = (item) => {
    navigate(`/events/event/${item.id}`, {
      state: { prevUrl: location.pathname }
    });
  };

  const go2QuickRegistration = (item) => {
    localStorage.setItem('invitation_token', item.token);
    localStorage.setItem('invitation_tournId', item.id);

    navigate(`/register_to_tournament`, {
      state: { prevUrl: location.pathname }
    });

    // When multiple tabs are open, to refresh all tabs
    localStorage.setItem(
      'navigated_2_register_2_tournament',
      `navigate_${Math.random()}`
    );
  };

  const got2Participants = (item) => {
    navigate(`/events/participants?tournament_id=${item?.id}`, {
      state: { prevUrl: location.pathname }
    });
  };

  const smallScreen = viewportWidth <= 450;
  
  const generateLabelWithValue = (tournInfo, classes) => (
    <span className={clsx(classes.flex, tournInfo.className, classes.wrap, classes.marginLeft)}>
      <span className={clsx(classes.marginRight, classes.statisticsLable, classes.bold)}>
        {tournInfo.label}:
      </span>
      {tournInfo.value}
    </span>
  );

  const calculateRemainingTime = (item, currentDate, t, classes) => {
    const startDay = new Date(`${item.start_date}T${item.start_time}:00`);
    const newDate = startDay - currentDate;
    const remainingTime = Math.max(newDate, 0);
    const seconds = Math.floor(remainingTime / 1000);
    const sec = seconds % 60;
    const min = Math.floor(seconds / 60) % 60;
    const hour = Math.floor(seconds / 3600) % 24;
    const day = Math.floor(seconds / (3600 * 24));
    const times = [
      {
        val: day < 10 ? `0${day}` : day,
        label: day === 1 ? t('day') : t('days'),
        className: classes.day
      },
      {
        val: hour < 10 ? `0${hour}` : hour,
        label: hour === 1 ? t('hour') : t('hours'),
        className: classes.hour
      },
      {
        val: min < 10 ? `0${min}` : min,
        label: min === 1 ? t('minute') : t('minutes'),
        className: classes.min,
        valClassName: classes.orderFirst
      },
      {
        val: sec < 10 ? `0${sec}` : sec,
        label: sec === 1 ? t('second') : t('seconds'),
        className: classes.sec,
        valClassName: classes.orderFirst
      }
    ];

    return times.map((digit, idxDigit) => (
      <span key={idxDigit} className={clsx(classes.flex, classes.column, classes.timerDigits, digit.className)}>
        <span className={digit.valClassName}>{digit.val.toString().padStart(2, '0')}</span>
        <span>{digit.label}</span>
      </span>
    ));
  };

  const data = [
    {
      value: [
        ...(role === 'coach' ? [{ isInfoCard: true }] : []), 
        ...upcomingTournaments
      ],
      label: t('upcomingEvents'),
      children: (item) => {
        if (new Date() >= new Date(`${item.start_date}T${item.start_time}:00`)) {
          return (
            <span className={classes.auto}>
              {finishedTournContent(item).map((tournInfo, idxTournInfo) =>
                <span key={idxTournInfo}>
                  {generateLabelWithValue(tournInfo, classes)}
                </span>
              )}
            </span>
          );
        } else {
          return (
            <span
              className={clsx(
                classes.flex, 
                classes.column, 
                classes.txtAlignCenter, 
                classes.verticallyCenter, 
                classes.clockWrapper, 
                classes.fullWidth, 
                classes.auto
              )}
            >
              <span className={clsx(classes.flex, classes.wrapper)}>
                <Clock className={classes.clockSvg} />
                {calculateRemainingTime(item, new Date(), t, classes)}
              </span>
            </span>
          );
        }
      }
    },
    ...pastTournaments.map((finishedTourn) => ({
      value: finishedTourn.data,
      label: [t('pastEvents'), finishedTourn.year].join(' '),
      children: (item) => (
        <span className={classes.auto}>
          {finishedTournContent(item).map((tournInfo, idxTournInfo) =>
            <span key={idxTournInfo}>
              {generateLabelWithValue(tournInfo, classes)}
            </span>
          )}
        </span>
      )
    }))
  ];

  const arrows = [
    { className: classes.prevArrow, onClick: prevTourn },
    { className: classes.nextArrow, onClick: nextTourn }
  ];

  const tournContent = (item) => {
    const organizer = {
      isNotOwner: role === 'master' && !+item.is_master,
      className: classes.icColumn,
      icon: <Organizer className={classes.icon} />,
      label: t('organizer'),
      value: [
        organizerName(item, true)?.firstN,
        organizerName(item, true)?.lastN
      ].join(' ')
    };

    const type = {
      className: classes.icColumn,
      icon: <TournType className={classes.icon} />,
      label: t('eventType'),
      value:
        +item.type === 3
          ? t('trainingSchool')
          : [+item.type === 1 ? t('local') : t('international')].join(' ')
    };
    const address = (className) => ({
      className,
      icon: <LocationOnIcon className={classes.icon} />,
      label: t('address'),
      value: item.address
    });
    return [
      [
        {
          className: classes.icColumn,
          icon: <Data className={classes.icon} />,
          label: t('startDay'),
          value: item?.start_date?.split('-').reverse().join('.')
        },
        +item.finished
          ? organizer
          : {
              className: classes.icColumn,
              icon: <Data className={classes.icon} />,
              label: t('registrationDate'),
              value: item?.registration_date?.split('-').reverse().join('.')
            }
      ],
      [
        +item.finished ? type : organizer,
        !+item.finished ? type : address(classes.icColumn)
      ],
      !+item.finished && [address()]
    ];
  };
  const finishedTournContent = (item) => [
    {
      label: t('countries'),
      value: item.countries_count,
      className: classes.marginBottom
    },
    {
      label: t('coaches'),
      value: item.coaches_count,
      className: classes.marginBottom
    },
    {
      label: t('categories'),
      value: item.categories_count,
      className: classes.marginBottom
    },
    {
      label: t('athletes'),
      value: item.participants_count,
      className: classes.marginBottom
    },
    {
      label: t('participants'),
      value: item.participation_count,
      className: classes.marginBottom
    },
    ...(item.judges_count !== null
      ? [
          {
            label: t('judges'),
            value: item.judges_count
          }
        ]
      : [])
  ];

  return (
    <>
      {data.map(({ value, label, children }, idx) => {
        const tournLen = value.length;
        const shouldDisableArrow = (eventIdx, arrowIdx) =>
          (arrowIdx === 0 && currentTournamnets[eventIdx] === 0) ||
          (arrowIdx === 1 &&
            (currentTournamnets[eventIdx] === tournLen - 1 ||
              currentTournamnets[eventIdx] + countCards >= tournLen));

        return (
          <span
            key={idx}
            className={clsx(classes.flex, classes.fullWidth, classes.column)}>
            <span
              className={clsx(
                classes.flex,
                classes.bold,
                classes.padding5,
                classes.fullWidth,
                classes.tournamentTypeTitle
              )}>
              {label}
              {smallScreen && (
                <span className={clsx(classes.marginLeftCenter, classes.flex)}>
                  {idx === 0 && role === 'coach'
                    ? currentTournamnets[idx]
                    : currentTournamnets[idx] + 1}{' '}
                  of {idx === 0 && role === 'coach' ? tournLen - 1 : tournLen}
                </span>
              )}
            </span>
            {!loading ? (
              tournLen > 0 ? (
                <span
                  id="slider"
                  className={clsx(
                    classes.flex,
                    classes.wrapper,
                    classes.verticallyCenter
                  )}>
                  {arrows.map((it, arrowIdx) => (
                    <ArrowBackIcon
                      key={arrowIdx}
                      className={clsx(
                        classes.arrow,
                        it.className,
                        shouldDisableArrow(idx, arrowIdx)
                          ? classes.disableIcon
                          : classes.activeArrow
                      )}
                      onClick={
                        shouldDisableArrow(idx, arrowIdx)
                          ? undefined
                          : () => it.onClick(idx)
                      }
                    />
                  ))}
                  <span
                    className={clsx(classes.flex, classes.cardsWrapperOnly)}>
                    <span
                      className={classes.flex}
                      style={{
                        transform: `translateX(-${
                          currentTournamnets[idx] * (100 / tournLen)
                        }%)`
                      }}>
                      {Array.isArray(value) &&
                        value.map((tournament, idxTourn) => (
                          <span
                            key={idxTourn}
                            className={clsx(
                              classes.flex,
                              classes.paper,
                              role === 'master' &&
                                !+tournament?.is_master &&
                                classes.noOwnerBorder,
                              classes.column
                            )}>
                            {!tournament?.isInfoCard ? (
                              <>
                                <span
                                  className={clsx(
                                    classes.flex,
                                    classes.marginBottom
                                  )}>
                                  <img
                                    alt={t('poster')}
                                    onClick={() =>
                                      role === 'coach'
                                        ? go2QuickRegistration(tournament)
                                        : role === 'recorder'
                                        ? got2Participants(tournament)
                                        : moveToEvent(tournament)
                                    }
                                    className={classes.img}
                                    src={
                                      tournament.poster
                                        ? serverDefaultPath + tournament.poster
                                        : DEFAULT_TOURNAMENT
                                    }
                                  />
                                  {children(tournament)}
                                </span>
                                <span
                                  className={clsx(
                                    classes.bold,
                                    classes.marginBottom,
                                    classes.hiddenHeadText
                                  )}>
                                  {tournament.tournament_name}
                                </span>
                                {tournContent(tournament).map(
                                  (rowDetails, idxRowDetails) =>
                                    rowDetails && (
                                      <span
                                        className={clsx(
                                          classes.marginBottom,
                                          classes.flex
                                        )}
                                        key={idxRowDetails}>
                                        {rowDetails.map(
                                          (tournInfo, idxTournInfo) => (
                                            <span
                                              className={clsx(
                                                classes.flex,
                                                tournInfo.className
                                              )}
                                              key={idxTournInfo}>
                                              <span className={classes.flex}>
                                                <span
                                                  className={classes.relative}>
                                                  {tournInfo.icon}
                                                  {tournInfo.isNotOwner && (
                                                    <span
                                                      className={
                                                        classes.noOwnerInnerIc
                                                      }>
                                                      !
                                                    </span>
                                                  )}
                                                </span>

                                                <span
                                                  className={clsx(
                                                    classes.flex,
                                                    classes.column,
                                                    classes.icTxt
                                                  )}>
                                                  <span
                                                    className={clsx(
                                                      classes.flex,
                                                      classes.bold
                                                    )}>
                                                    {tournInfo.label}
                                                  </span>
                                                  <span
                                                    className={clsx(
                                                      classes.flex,
                                                      classes.breakWord
                                                    )}>
                                                    {tournInfo.value}
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          )
                                        )}
                                      </span>
                                    )
                                )}
                              </>
                            ) : (
                              idx === 0 &&
                              role === 'coach' && (
                                <>
                                  <EmptyState
                                    setNewComponent={
                                      <>
                                        <span
                                          className={
                                            classes.coachRecorderUseQuickRegLinkInfo
                                          }>
                                          {t(
                                            'coachRecorderUseQuickRegLinkInfo'
                                          )}
                                        </span>
                                        <span
                                          className={clsx(
                                            classes.flex,
                                            classes.marginTop05
                                          )}>
                                          <TextField
                                            className={classes.txtField}
                                            label={t('pin')}
                                            name="pin"
                                            value={pin || ''}
                                            onChange={handleTxtChange}
                                            variant="outlined"
                                          />
                                          <Tooltip
                                            arrow
                                            title={t('link2QuickRegistration')}>
                                            <ArrowBackIcon
                                              onClick={() =>
                                                pin
                                                  ? getTournByPin((val) =>
                                                      go2QuickRegistration(val)
                                                    )
                                                  : undefined
                                              }
                                              className={clsx(
                                                classes.iconBtn,
                                                classes.marginLeft20,
                                                !pin && classes.noEvent
                                              )}
                                            />
                                          </Tooltip>
                                        </span>
                                      </>
                                    }
                                  />
                                </>
                              )
                            )}
                          </span>
                        ))}
                    </span>
                  </span>
                </span>
              ) : (
                <span
                  className={clsx(
                    classes.flex,
                    classes.wrapper,
                    classes.verticallyCenter
                  )}>
                  <span
                    className={clsx(classes.flex, classes.cardsWrapperOnly)}>
                    <span className={classes.paper}>
                      <EmptyState
                        specialTxt={
                          currentRole === 'recorder' && t('recorderNoEvents')
                        }
                        purpose={t('letsDoIt')}
                        onClick={
                          currentRole !== 'coach' &&
                          currentRole !== 'recorder' &&
                          moveTo
                        }
                      />
                    </span>
                  </span>
                </span>
              )
            ) : (
              <span
                className={clsx(
                  classes.flex,
                  classes.spinner,
                  classes.verticallyCenter
                )}>
                <CircularProgress />
              </span>
            )}
          </span>
        );
      })}
    </>
  );
};

export default TournamentsList;
