export const styles = () => ({
  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  fullWidth: {
    width: '100%'
  },

  maxColumnWidth: {
    maxWidth: '200px'
  },

  centerVertically: {
    alignItems: 'center'
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  filter: {
    width: '25em'
  },

  disabled: {
    opacity: 0.5
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content',
      marginRight: '0.5rem'
    }
  },

  docImg: {
    width: '5em',
    height: '5em',
    objectFit: 'contain',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '0.5rem'
  },

  downloadIc: {
    width: '1.5em',
    height: '1.5em',
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D'
  },

  left: {
    marginLeft: 'auto'
  },

  ic: {
    marginRight: '0.5rem',
    marginLeft: '0 !important',
    width: '2.2rem',
    height: '2.2rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    '@media (min-width:901px)': {
      marginLeft: '.65em'
    }
  },

  onHoverIc: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    },
    '&:active': {
      opacity: 0.5
    }
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  triangle: {
    position: 'relative',
    width: 0,
    height: 0,
    borderTop: '25px solid transparent',
    borderRight: '35px solid rgb(60,93,150)',
    borderBottom: '25px solid transparent',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  triangleTeamR: {
    bottom: '2.2rem',
    left: '1.5rem'
  },

  triangleTeamL: {
    bottom: '2.2rem',
    right: '5.2rem'
  },

  teamNum: {
    top: ' 1.7rem',
    position: 'absolute',
    color: '#fff',
    right: '0.3rem'
  },

  schemeWrapperOuter: {
    overflowX: 'auto'
  },

  schemeWrapperInner: {
    minWidth: '1036px'
  },

  pointer: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  triangleBorder: {
    width: 0,
    height: 0,
    position: 'absolute',
    borderTop: '20px solid transparent',
    borderRight: '30px solid #fff',
    borderBottom: '20px solid transparent',
    top: '-1.25rem',
    left: '0.2rem'
  },

  slidderName: {
    width: '3em',
    fontSize: '2.5rem',
    fontWeight: 700,
    padding: '0.2rem 1rem'
  },

  auto: {
    margin: 'auto'
  },

  slidderLabel: {
    margin: 'auto',
    fontSize: '1.5rem',
    fontWeight: 700
  },

  slidderLabelTeam: {
    color: '#fff',
    bottom: '3rem',
    right: '1.01rem',
    position: 'absolute',
    width: '4em',
    textAlign: 'center'
  },

  relative: {
    position: 'relative'
  },

  leftMainJ: {
    left: '-10.6rem',
    top: '0.5rem',
    '@media (max-width:900px)': {
      left: '-4rem',
      top: '2.7rem'
    }
  },

  topLeftMainJudge: {
    top: '-2rem',
    left: '-6rem'
  },

  topRightMainJudge: {
    top: '-2rem'
  },

  rightMainJudge: {
    right: '-10.6rem',
    top: '0.5rem',
    '@media (max-width:900px)': {
      right: '-4rem',
      top: '2.7rem'
    }
  },

  wrapperTopMainJudges: {
    height: '10em',
    alignItems: 'center'
  },

  topMainJudges: {
    background: '#fff',
    border: '2px solid rgb(60,93,150)',
    fontSize: '1.5rem',
    borderRadius: '1rem',
    padding: '0.2rem 1rem',
    width: '3em',
    margin: '0.5rem 0.5rem'
  },

  squareWrapper: {
    height: '25em',
    '@media (max-width:900px)': {
      marginTop: '3rem'
    }
  },

  fontSize1And2: {
    fontSize: '1.2rem'
  },

  squareJudges: {
    border: '2px solid rgb(60,93,150)',
    background: '#fff',
    fontSize: '1.5rem',
    borderRadius: '50%',
    padding: '.5rem',
    textAlign: 'center',
    width: '2em',
    height: '2em',
    position: 'absolute'
  },

  warningTxt: {
    marginBottom: '1rem',
    color: '#ff0000',
    fontSize: '.8rem'
  },

  squareJudgeR: {
    bottom: '4.9rem',
    left: '9rem'
  },

  squareJudgeRName: {
    bottom: '2.5rem',
    left: '5rem'
  },

  squareJudgeTopLeftU: {
    left: '-5rem',
    bottom: '9rem'
  },

  squareJudgeTopRighttU: {
    right: '-5rem',
    bottom: '9rem'
  },

  squareJudgeBottomRightU: {
    bottom: 0,
    right: '-5rem'
  },

  squareJudgeBottomLeftU: {
    bottom: 0,
    left: '-5rem'
  },

  squareJudgeTopLeftUName: {
    left: '-16.2rem',
    bottom: '9.3rem',
    '@media (max-width:900px)': {
      left: '-11.2rem',
      bottom: '12.2rem'
    }
  },

  squareJudgeTopRighttUName: {
    right: '-16.2rem',
    bottom: '9.3rem',
    '@media (max-width:900px)': {
      right: '-11.2rem',
      bottom: '12.2rem'
    }
  },

  squareJudgeBottomRighttUName: {
    right: '-16.2rem',
    bottom: 0,
    '@media (max-width:900px)': {
      right: '-11.2rem',
      bottom: '3.2rem'
    }
  },

  squareJudgeBottomLefttUName: {
    left: '-16.2rem',
    bottom: 0,
    '@media (max-width:900px)': {
      left: '-11.2rem',
      bottom: '3.2rem'
    }
  },

  squareJudgeBTopLeft: {
    top: '0.1rem',
    left: '0.5rem'
  },

  squareJudgeBTopRight: {
    top: '0.1rem',
    right: '0.5rem'
  },

  squareJudgeBBottomRight: {
    bottom: '0.1rem',
    right: '0.5rem'
  },

  squareJudgeBBottomLeft: {
    bottom: '0.1rem',
    left: '0.5rem'
  },

  squareJudgeBTopLeftName: {
    bottom: '21.1rem',
    left: '-8rem',
    '@media (max-width:900px)': {
      left: '-4.5rem'
    }
  },

  squareJudgeBTopRightName: {
    bottom: '21.1rem',
    right: '-8rem',
    '@media (max-width:900px)': {
      right: '-4.5rem'
    }
  },

  squareJudgeBBottomRightName: {
    top: '21.1rem',
    right: '-8rem',
    '@media (max-width:900px)': {
      right: '-4.5rem'
    }
  },

  squareJudgeBBottomLeftName: {
    top: '21.1rem',
    left: '-8rem',
    '@media (max-width:900px)': {
      left: '-4.5rem'
    }
  },

  square: {
    width: '21em',
    height: '21em',
    margin: 'auto'
  },

  absolute: {
    position: 'absolute'
  },

  teamWrapper: {
    top: '7rem',
    right: '6.5rem'
  },

  placeholderName: {
    textAlign: 'center',
    backgroundColor: 'rgba(68,114,196,0.9)',
    border: '2px solid rgb(60,93,150)',
    color: '#fff',
    borderRadius: '0.5rem',
    padding: '0.5rem 0.2rem',
    width: '11em',
    height: '2.3em',
    position: 'absolute'
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },

  check: {
    display: 'inline-block',
    transform: 'rotate(45deg)',
    height: '24px',
    width: '12px',
    borderBottom: '7px solid #78b13f',
    borderRight: '7px solid #78b13f'
  },

  checkPTop: {
    position: 'absolute',
    top: '1.7rem',
    right: '0.6rem'
  },

  checkPBottom: {
    position: 'absolute',
    top: '1.4rem',
    right: 0
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  switchWrapper: {
    minWidth: 'fit-content',
    padding: '0.5rem 0 0.5rem 1rem'
  },

  tatamiDetails: {
    flexDirection: 'column',
    padding: '0.2rem',
    textTransform: 'lowercase'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  tatamiWrapper: {
    borderRadius: '4px',
    width: '12em',
    border: '1px solid #BFBFBF',
    height: '7em',
    margin: '0 1rem'
  }
});

export const getTatamiTheme = (bg) => ({
  background: bg,
  color: '#fff'
});
