import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import clsx from 'clsx';

import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import { ReactComponent as CopyIcon } from 'assets/img/copy-regular.svg';
import { ReactComponent as TransferIcon } from 'assets/img/paste-regular.svg';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  TextField,
  Tooltip
} from '@material-ui/core';

import {
  TEST_ID_COPY,
  NUMERO_SIGN,
  NOT_SET,
  serverDefaultPath,
  DEFAULT_AVATAR
} from '../../helpers/constants';
import {
  convertMs,
  selectedValue,
  finishedTournament,
  DDMMYYYY
} from '../../helpers/selectors';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UsersToolbar from './Toolbar/Toolbar';
import List from '../CategoriesList/List';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

import { useStyles } from './StylesTable';

const GlobalTable = (props) => {
  const {
    header,
    body,
    actionIcons,
    onClick,
    id,
    data,
    selected,
    noEvent,
    order,
    rowOrder,
    subTableActionIcons,
    onClickSubtableRow,
    mainTableRowColor,
    onClickCopyIndicatorBody,
    onClickPasteIndicatorHeader,
    copyPasteData,
    collapsed,
    collapseTableHeader,
    collapseTableBody,
    style,
    search,
    clearSearch,
    textChange,
    showButton,
    filter,
    pressEnter,
    paginationComponent,
    shouldAllowEditing,
    role,
    openEmptyForm,
    shouldHideHeader,
    className,
    shouldHideTableToolbar,
    condensedTableData,
    onSort,
    sortDirection,
    showMainTbClassNameIssue,
    classNameCellWidth,
    classNameHideTableSpecificWidth,
    classNameHideCondensedTableSpecificWidth,
    //categories list
    tournamentApplications,
    listCollapsed,
    toggleCollpasedList,
    processing,
    openModal,
    shouldShowNoncollapsibleList,
    shouldDisableEditing,
    collapsedCondensedTableData,
    condensedCardWidth,
    categoryTypes,
    //coach role, assign participant to tourn
    assignParticipantTeams,
    assignParticipantCategories,
    expandTableRow,
    tournamentIdParams,
    qdanList,
    settedActionIconsHead, //use this when the <th> of the action icon column is set to a specific value
    toolbarIndividualContent,
    shouldHideSearch,
    onSortCollapsibleTable,
    selectedSubTable,
    showParticipantForm,
    maxColumnWidth,
    componentBefore, //used to show a children before search
    unorderedList,
    subTableDataKey, //this option is used for collapsed tables to show subtable data
    reportCheckboxes, //on Reports page, display chechboxes
    areaOfInput,
    indexReference,
    inputSwitch,
    closeIcon,
    actionIconsList,
    addNewReference,
    editableTableCell,
    onTableCellCheckbox,
    onEditableTableCell, //edit cell directly inside table
    createNewRow
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const listComponent = (item) => (
    <List
      collapsed={listCollapsed}
      {...{ tournamentApplications }}
      user={item}
      {...{ processing }}
      itemToggle={toggleCollpasedList}
      tournamentId={tournamentIdParams}
      {...{ openModal }}
      {...{ shouldShowNoncollapsibleList }}
      listIconsClasses={[
        classes.icon,
        classes.iconWrapper,
        classes.marginLeft0dot5
      ]}
      {...{ onClick }}
      {...{ shouldDisableEditing }}
      {...{ actionIconsList }}
    />
  );

  const actionIconsComponent = (item, idx, className) => (
    <span
      className={clsx(
        classes.flex,
        classes.ationIconsLeft,
        className,
        classes.fitContent
      )}
      onClick={(evt) => evt.stopPropagation()}>
      {actionIcons(
        item,
        idx,
        classes.icon,
        classes.iconWrapper,
        classes.icMarginLeft,
        classes.marginBottom05
      )}
    </span>
  );

  const editableTableCellComponent = (item, arg) => {
    const focusedInput = item['participant_id'] === editableTableCell[arg];

    return (
      <TextField
        style={{ width: '80px' }}
        value={
          (!focusedInput ? item[arg] : editableTableCell?.values?.[arg]) || ''
        }
        name={arg}
        onChange={(evt) => {
          onEditableTableCell(evt, item, arg);
        }}
        onBlur={(evt) => {
          onEditableTableCell(evt, item, arg, true);
        }}
        error={focusedInput && Boolean(editableTableCell?.errors?.[arg])}
        helperText={focusedInput && editableTableCell?.errors?.[arg]}
        fullWidth
        onKeyUp={(evt) => {
          if (evt.key === 'Enter') {
            onEditableTableCell(evt, item, arg, true);
            evt.target.blur();
          }
        }}
        variant="outlined"
      />
    );
  };

  const cardTxtComponent = (item, params, className) => (
    <span className={clsx(classes.flex, classes.column, className)}>
      {params.map((key, index) => {
        if (key) {
          let param = key.name;
          let value =
            item[key.key] !== NOT_SET &&
            item[key.key] &&
            item[key.key] !== 'null'
              ? item[key.key]
              : '-';

          if (key.key === 'main_time_ms') {
            value = convertMs(item[key.key], true);
          } else if (key.key === 'weight_to') {
            value = item[key.key] ? `${parse('&#177;')}${item[key.key]}` : '-';
          } else if (key.dropdown) {
            value = (
              <>
                {item[key.dropdown.key]}
                {item[key.dropdown.key] !== '0' && (
                  <ArrowDropDownIcon
                    className={
                      (
                        Array.isArray(collapsed)
                          ? collapsed.find((it) => +it?.id === +item?.[id])?.id
                          : collapsed[item[id]]
                      )
                        ? classes.arrowDropUp
                        : undefined
                    }
                  />
                )}
              </>
            );
            param = key.dropdown.name;
          } else if (
            editableTableCell &&
            Object.entries(editableTableCell)?.some(([editCellKey]) =>
              editCellKey?.includes(key.key)
            )
          ) {
            value = editableTableCellComponent(item, key.key);
          } else if (key.key === 'next_qdan_id') {
            let myQdan;
            if (item.school_info) {
              myQdan = selectedValue(
                qdanList,
                'id',
                `${item.school_info[key.key]}`
              );
            } else if (item.next_qdan_id) {
              myQdan = selectedValue(qdanList, 'id', `${item[key.key]}`);
            }

            value = myQdan && myQdan.name;
          } else if (key.key === 'find_qdan') {
            let qdan = selectedValue(qdanList, 'id', `${item.qdan}`);

            value = qdan && qdan.name;
          } else if (typeof key === 'object' && key?.isCheckbox) {
            value = (
              <CheckboxBtn
                checked={!!+item[key.key]}
                onChange={(evt) => onTableCellCheckbox(evt, item, key.key)}
              />
            );
          } else if (typeof key.key === 'object') {
            value = [item[key.key.firstName], item[key.key.lastName]].join(' ');
          } else if (key.key === 'pay_type') {
            value =
              +item.pay_type === 1 ? t('monthlyPayment') : t('annualPayment');
          } else if (
            (key.key === 'Fist' && item.FistSecondAttempt) ||
            (key.key === 'RibLeg' && item.RibLegSecondAttempt) ||
            (key.key === 'Elbow' && item.ElbowSecondAttempt) ||
            (key.key === 'EdgeOfThePalm' && item.EdgeOfThePalmSecondAttempt)
          ) {
            value = (
              <>
                {item[key.key]}{' '}
                <span className={classes.redCheckmark}>
                  {parse('&#10003;')}
                </span>
              </>
            );
          } else if (key.defaultN === 'category_type_name') {
            value = categoryTypes
              ?.find((it) => +it.id === +item?.[key.paramN])
              ?.name?.toUpperCase();
          } else if (key.key === 'status') {
            value = t(
              +item?.status === 1
                ? 'declared'
                : +item?.status === 2
                ? 'accepted'
                : '-'
            );
          } else if (key.key === 'logName') {
            value = item;
          } else if (key.key === 'status_recorder') {
            value = t(item?.status === 'active' ? 'active' : 'inactive');
          } else if (key.key === 'status_name') {
            value = t(item?.status_name === 'active' ? 'active' : 'inactive');
          } else if (key.key === 'positionName') {
            value = item.positionName
              ? [`(${item.positionShort})`, item.positionName].join(' ')
              : '-';
          } else if (key.key === 'registrar_role') {
            value = item.role === 'operator' ? t('operator') : t('registrar');
          } else if (key.key === 'tournament_type') {
            value =
              +item?.type === 1
                ? t('local')
                : +item?.type === 2
                ? t('international')
                : t('trainingSchool');
          } else if (key.key === 'registration_active') {
            value = finishedTournament(item)
              ? t('eventClosed')
              : !+item?.registration_active
              ? t('registrationClosed')
              : t('registrationOpened');
          } else if (key.key === 'registered') {
            value = !!+item?.registered ? t('yes') : t('no');
          }

          return (
            <span
              className={clsx(
                classes.txt,
                classes.ellipsis,
                key && key.dropdown && [classes.flex, classes.verticallyCenter]
              )}
              key={index}
              onClick={(evt) => {
                evt.stopPropagation();

                if (key && key.dropdown) {
                  expandTableRow(evt, item);
                }

                if (
                  editableTableCell &&
                  Object.keys(editableTableCell).includes(key.key)
                ) {
                  onEditableTableCell(evt, item, key.key, true, true);
                }
              }}>
              <span className={clsx(classes.bold, classes.marginRight)}>
                {param}:
              </span>
              {value}
            </span>
          );
        }
        return true;
      })}
    </span>
  );

  const columnDataNamePhoto = (item, key) => (
    <span className={clsx(classes.flex, classes.fullWidth)}>
      {key.photo && (
        <img
          className={clsx(classes.flex, classes.img)}
          src={
            item[key.photo]
              ? serverDefaultPath + item[key.photo]
              : DEFAULT_AVATAR
          }
          alt="pic"
        />
      )}
      <span
        className={clsx(
          classes.flex,
          classes.column,
          classes.center,
          classes.marginLeft,
          key.photo && classes.columnTxtMinusImgWidth
        )}>
        <span className={classes.ellipsis}>{item[key.firstName]}</span>
        <span className={classes.ellipsis}>{item[key.lastName]}</span>
        <span className={classes.ellipsis}>{item[key.patronymic]}</span>
      </span>
    </span>
  );

  const setCondensedCardMainName = (values, cardDataKeys) => {
    return (
      cardDataKeys.mainName &&
      (typeof cardDataKeys.mainName !== 'object' ? (
        values[cardDataKeys.mainName]
      ) : values[cardDataKeys.mainName.firstName] ? (
        [
          values[cardDataKeys.mainName.firstName],
          values[cardDataKeys.mainName.lastName],
          values[cardDataKeys.mainName.patronymic]
        ].join(' ')
      ) : values[cardDataKeys.mainName.categoryNamePlus] ? (
        <span className={clsx(classes.flex, classes.ellipsis, classes.column)}>
          <span>
            {values[cardDataKeys.mainName.categoryName]}
            {role === 'administrator' && ` (${values.category_id})`}
          </span>
          <span>
            {values[cardDataKeys.mainName.categoryNamePlus]}
            {role === 'administrator' && ` (${values.category_id_plus})`}
          </span>
        </span>
      ) : (
        values[cardDataKeys.mainName.categoryName]
      ))
    );
  };

  const CollapseTable = ({ item, itemIdx }) => {
    const findRowInArray = collapsed.find((it) => +it?.id === +item?.[id]);
    const inCollapsed = findRowInArray && findRowInArray.id;
    const subTableData = findRowInArray && findRowInArray[subTableDataKey];
    const collapseTableHeaderCopy =
      typeof collapseTableHeader === 'function'
        ? collapseTableHeader(item)
        : collapseTableHeader;

    return (
      <TableRow>
        <TableCell
          classes={{
            root: classes.cell
          }}
          className={classes.noPadding}
          colSpan={collapseTableHeaderCopy?.length}>
          <Collapse
            className={classes.collaps}
            in={Boolean(inCollapsed)}
            timeout="auto"
            unmountOnExit>
            {subTableData && subTableData.length > 0 && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {collapseTableHeaderCopy.map(
                      (collapseHeaderItem, collapseHeaderIdx) => {
                        let headerVal = collapseHeaderItem.label;

                        if (collapseHeaderItem.isDrag) {
                          headerVal = [
                            {
                              icon: CopyIcon,
                              title: t('copy'),
                              className: classes.marginRight,
                              id: TEST_ID_COPY
                            },
                            { icon: TransferIcon, title: t('transfer') }
                          ].map((it, itIdx) => (
                            <Fragment key={itIdx}>
                              <Tooltip title={it.title}>
                                <it.icon
                                  className={clsx(
                                    classes.copyPaste,
                                    it.className
                                  )}
                                  onClick={() =>
                                    onClickPasteIndicatorHeader &&
                                    onClickPasteIndicatorHeader(item, it.id)
                                  }
                                />
                              </Tooltip>
                            </Fragment>
                          ));
                        }

                        const content = findRowInArray.sortDirection ? (
                          <span className={classes.flex}>
                            {headerVal}

                            {collapseHeaderItem.num && (
                              <ArrowDropDownIcon
                                className={
                                  findRowInArray.sortDirection[1] ===
                                  collapseHeaderIdx
                                    ? findRowInArray.sortDirection[0] === 1
                                      ? classes.arrowDropUp
                                      : undefined
                                    : collapseHeaderItem.num
                                    ? classes.opacity
                                    : undefined
                                }
                              />
                            )}
                          </span>
                        ) : (
                          collapseHeaderItem.label
                        );

                        return (
                          <TableCell
                            className={clsx(
                              (collapseHeaderItem.num ||
                                collapseHeaderItem.isDrag) &&
                                classes.row,
                              collapseHeaderItem.isDrag &&
                                copyPasteData?.paste?.id === item?.id &&
                                classes.selected
                            )}
                            onClick={() => {
                              if (collapseHeaderItem.num) {
                                onSortCollapsibleTable(
                                  item[id],
                                  collapseHeaderItem.num,
                                  collapseHeaderIdx
                                );
                              }
                            }}
                            classes={{ root: classes.cell }}
                            key={collapseHeaderIdx}>
                            {content}
                          </TableCell>
                        );
                      }
                    )}
                    {subTableActionIcons && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subTableData.map((collapseBodyItem, collapseBodyIdx) => {
                    const findCopyPasteItem = copyPasteData?.copy
                      ?.find((it) => +item.id === +it.category_id)
                      ?.participants?.find(
                        (it) =>
                          +it.participant_id ===
                          +collapseBodyItem.participant_id
                      );

                    return (
                      <TableRow
                        onClick={
                          shouldAllowEditing &&
                          shouldAllowEditing(item) &&
                          onClickSubtableRow
                            ? (evt) => {
                                onClickSubtableRow(
                                  evt,
                                  collapseBodyItem,
                                  collapseBodyIdx,
                                  item,
                                  itemIdx
                                );
                              }
                            : undefined
                        }
                        key={collapseBodyIdx}
                        className={classes.row}
                        classes={{ selected: classes.selected }}
                        selected={
                          selectedSubTable && selectedSubTable(collapseBodyItem)
                        }>
                        {collapseTableBody.map((el) => {
                          let collapsedCellValue = collapseBodyItem[el]
                            ? collapseBodyItem[el]
                            : '-';

                          if (el === 'category_name') {
                            collapsedCellValue =
                              collapseBodyItem.category_name_plus ? (
                                <span className={classes.flex}>
                                  <span
                                    className={clsx(
                                      classes.flex,
                                      classes.column
                                    )}>
                                    <span>
                                      1. {collapseBodyItem[el]}
                                      {role === 'administrator' &&
                                        ` (${collapseBodyItem.category_id})`}
                                    </span>
                                    <span className={classes.ellipsis}>
                                      2. {collapseBodyItem.category_name_plus}
                                      {role === 'administrator' &&
                                        ` (${collapseBodyItem.category_id_plus})`}
                                    </span>
                                  </span>
                                </span>
                              ) : (
                                collapseBodyItem[el]
                              );
                          } else if (el?.photo || el?.noPhoto) {
                            collapsedCellValue = columnDataNamePhoto(
                              collapseBodyItem,
                              el
                            );
                          } else if (
                            typeof el === 'object' &&
                            collapseBodyItem[el.firstName]
                          ) {
                            collapsedCellValue = [
                              collapseBodyItem[el.firstName],
                              collapseBodyItem[el.lastName]
                            ].join(' ');
                          } else if (typeof el === 'object' && el.isDrag) {
                            collapsedCellValue = (
                              <Tooltip title={t('select')}>
                                <CopyIcon />
                              </Tooltip>
                            );
                          } else if (Array.isArray(collapseBodyItem[el])) {
                            collapsedCellValue =
                              listComponent(collapseBodyItem);
                          } else if (el === 'next_qdan_id') {
                            const myQdan = selectedValue(
                              qdanList,
                              'id',
                              `${collapseBodyItem[el]}`
                            );
                            collapsedCellValue = myQdan && myQdan.name;
                          } else if (el === 'find_qdan') {
                            let qdan = selectedValue(
                              qdanList,
                              'id',
                              `${collapseBodyItem.qdan}`
                            );
                            collapsedCellValue = qdan && qdan.name;
                          } else if (el === 'weight_to') {
                            collapsedCellValue = collapseBodyItem[el]
                              ? `${parse('&#177;')}${collapseBodyItem[el]}`
                              : '-';
                          } else if (el === 'main_time_ms') {
                            collapsedCellValue = convertMs(
                              collapseBodyItem[el],
                              true
                            );
                          } else if (el === 'tournament_type') {
                            collapsedCellValue =
                              +collapseBodyItem?.type === 1
                                ? t('local')
                                : +collapseBodyItem?.type === 2
                                ? t('international')
                                : t('trainingSchool');
                          } else if (el?.defaultN === 'category_type_name') {
                            collapsedCellValue = categoryTypes
                              ?.find(
                                (it) =>
                                  +it.id === +collapseBodyItem?.[el?.paramN]
                              )
                              ?.name?.toUpperCase();
                          } else if (el === 'registered') {
                            collapsedCellValue = !!+collapseBodyItem?.registered
                              ? t('yes')
                              : t('no');
                          }

                          return (
                            <Fragment key={el.isDrag ? el.param : el}>
                              <TableCell
                                className={clsx(
                                  el.isDrag && classes.row,
                                  +findCopyPasteItem?.participant_id &&
                                    classes.selected
                                )}
                                onClick={
                                  el.isDrag
                                    ? (evt) => {
                                        evt.stopPropagation();

                                        onClickCopyIndicatorBody &&
                                          onClickCopyIndicatorBody(
                                            evt,
                                            collapseBodyItem,
                                            item
                                          );
                                      }
                                    : undefined
                                }
                                classes={{ root: classes.cell }}
                                key={el}>
                                {collapsedCellValue}
                              </TableCell>
                            </Fragment>
                          );
                        })}
                        {subTableActionIcons && (
                          <TableCell classes={{ root: classes.cell }}>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.left,
                                className,
                                classes.fitContent
                              )}
                              onClick={(evt) => evt.stopPropagation()}>
                              {subTableActionIcons(
                                collapseBodyItem,
                                collapseBodyIdx,
                                classes.icon,
                                classes.iconWrapper,
                                classes.icMarginLeft,
                                classes.marginBottom05,
                                item
                              )}
                            </span>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };
  const CollapsedCondensedView = ({ item }) => {
    const findRowInArray = collapsed.find((it) => +it?.id === +item?.[id]);
    const inCollapsed = findRowInArray && findRowInArray.id;
    const subTableData = findRowInArray && findRowInArray[subTableDataKey];

    return (
      <Collapse
        className={classes.collaps}
        in={Boolean(inCollapsed)}
        timeout="auto"
        unmountOnExit>
        {subTableData &&
          subTableData.length > 0 &&
          subTableData.map((key, index) => {
            const findCopyPasteItem = copyPasteData?.copy
              ?.find((it) => +item.id === +it.category_id)
              ?.participants?.find(
                (it) => +it.participant_id === +key.participant_id
              );

            const collapsedCondensedTableDataCopy =
              typeof collapsedCondensedTableData === 'function'
                ? collapsedCondensedTableData(item)
                : collapsedCondensedTableData;

            return (
              <Fragment key={index}>
                <span
                  className={clsx(
                    classes.flex,
                    collapsedCondensedTableDataCopy?.mainName?.isDrag && [
                      classes.verticallyCenter
                    ],
                    classes.fullWidth,
                    classes.cardTitle
                  )}>
                  {collapsedCondensedTableDataCopy?.mainName?.photo && (
                    <img
                      className={clsx(classes.img, classes.alignSelfStart)}
                      src={
                        key[collapsedCondensedTableDataCopy?.mainName?.photo]
                          ? serverDefaultPath +
                            key[
                              collapsedCondensedTableDataCopy?.mainName?.photo
                            ]
                          : DEFAULT_AVATAR
                      }
                      alt="pic"
                    />
                  )}
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      collapsedCondensedTableDataCopy?.mainName?.photo &&
                        classes.paddingLeft10,
                      classes.fullWidth,
                      condensedCardWidth
                    )}>
                    <span
                      className={clsx(
                        collapsedCondensedTableDataCopy?.mainName?.isDrag && [
                          classes.flex,
                          classes.verticallyCenter
                        ],
                        classes.fullWidth,
                        classes.cardTitle
                      )}>
                      {setCondensedCardMainName(
                        key,
                        collapsedCondensedTableDataCopy
                      )}
                      {collapsedCondensedTableDataCopy?.mainName?.isDrag &&
                        !shouldDisableEditing && (
                          <span
                            onClick={(evt) => {
                              evt.stopPropagation();
                              onClickCopyIndicatorBody(evt, key, item);
                            }}
                            className={clsx(
                              +findCopyPasteItem?.participant_id &&
                                classes.selected,
                              classes.flex,
                              classes.iconWrapper,
                              classes.row,
                              classes.verticallyCenter,
                              classes.center,
                              classes.ationIconsLeft
                            )}>
                            <CopyIcon />
                          </span>
                        )}
                    </span>
                    <span
                      className={clsx(
                        classes.flex,
                        classes.marginTop10,
                        classes.cardTxt
                      )}>
                      {cardTxtComponent(
                        key,
                        collapsedCondensedTableDataCopy?.info
                      )}
                      {collapsedCondensedTableDataCopy.list &&
                        !unorderedList && (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.column,
                              classes.left,
                              classes.fullWidth
                            )}>
                            {listComponent(key)}
                          </span>
                        )}
                      {subTableActionIcons && (
                        <span
                          className={clsx(
                            classes.flex,
                            classes.left,
                            className,
                            classes.fitContent
                          )}
                          onClick={(evt) => evt.stopPropagation()}>
                          {subTableActionIcons(
                            key,
                            index,
                            classes.icon,
                            classes.iconWrapper,
                            classes.icMarginLeft,
                            classes.marginBottom05,
                            item
                          )}
                        </span>
                      )}
                    </span>
                  </span>
                </span>
              </Fragment>
            );
          })}
      </Collapse>
    );
  };

  return (
    <PerfectScrollbar
      className={clsx(className, classes.showScroll, classes.paper)}
      {...{ style }}>
      {!shouldHideTableToolbar && (
        <UsersToolbar
          {...{ shouldHideSearch }}
          {...{ search }}
          {...{ clearSearch }}
          {...{ textChange }}
          onClick={openEmptyForm}
          {...{ showButton }}
          {...{ filter }}
          {...{ pressEnter }}
          {...{ role }}
          {...{ componentBefore }}
          content={toolbarIndividualContent}
          showParticipantForm={showParticipantForm}
          {...{ closeIcon }}
        />
      )}
      <Table
        className={clsx(
          noEvent && classes.noEvent,
          classNameHideTableSpecificWidth
            ? classNameHideTableSpecificWidth
            : classes.hideTable
        )}>
        {!shouldHideHeader && (
          <TableHead>
            <TableRow>
              {order && <TableCell>{NUMERO_SIGN}</TableCell>}
              {header.map((item, idx) => {
                const content = sortDirection ? (
                  <span className={clsx(classes.flex)}>
                    {item.label ? item.label : item.icon}
                    {item.num && (
                      <ArrowDropDownIcon
                        className={
                          sortDirection[1] === idx
                            ? sortDirection[0] === 1
                              ? classes.arrowDropUp
                              : undefined
                            : item.num
                            ? classes.opacity
                            : undefined
                        }
                      />
                    )}
                  </span>
                ) : item.label ? (
                  item.label
                ) : (
                  <Tooltip arrow title={item?.tooltipLabel}>
                    {item.icon}
                  </Tooltip>
                );
                return (
                  <TableCell
                    className={clsx(item.num && classes.row)}
                    onClick={() => item.num && onSort(item.num, idx)}
                    key={idx}>
                    {role === 'administrator' ? (
                      <div className={classes.resizeColumn}>{content}</div>
                    ) : (
                      content
                    )}
                  </TableCell>
                );
              })}
              {actionIcons && !settedActionIconsHead && <TableCell />}
            </TableRow>
          </TableHead>
        )}
        {Array.isArray(body) && body.length > 0 ? (
          <TableBody>
            {body.map((item, idx) => {
              const tableBodyKeys =
                typeof data === 'function' ? data(item) : data;

              return (
                <Fragment key={idx}>
                  {addNewReference &&
                    idx === 0 &&
                    createNewRow(indexReference, tableBodyKeys)}
                  <TableRow
                    onClick={
                      shouldAllowEditing && shouldAllowEditing(item)
                        ? (evt) => onClick(evt, item, idx)
                        : undefined
                    }
                    className={clsx(
                      shouldAllowEditing && shouldAllowEditing(item)
                        ? classes.row
                        : undefined,
                      mainTableRowColor
                    )}
                    classes={{ selected: classes.selected }}
                    selected={selected && selected(item)}>
                    {order && <TableCell>{idx + 1}</TableCell>}
                    {tableBodyKeys.map((key, index) => {
                      let param =
                        item[key] !== NOT_SET &&
                        item[key] &&
                        item[key] !== 'null'
                          ? item[key]
                          : '-';

                      if (key?.defaultN === 'category_type_name') {
                        param = categoryTypes
                          ?.find((it) => +it.id === +item?.[key?.paramN])
                          ?.name?.toUpperCase();
                      } else if (key === 'category_name') {
                        param = item.category_name_plus ? (
                          <span
                            className={clsx(classes.flex, classes.fullWidth)}>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.column,
                                classes.fullWidth
                              )}>
                              <span>
                                1. {item[key]}
                                {role === 'administrator' &&
                                  ` (${item.category_id})`}
                              </span>
                              <span>
                                2. {item.category_name_plus}
                                {role === 'administrator' &&
                                  ` (${item.category_id_plus})`}
                              </span>
                            </span>
                          </span>
                        ) : (
                          item[key]
                        );
                      } else if (
                        key === 'FistSecondAttempt' ||
                        key === 'RibLegSecondAttempt' ||
                        key === 'EdgeOfThePalmSecondAttempt' ||
                        key === 'ElbowSecondAttempt'
                      ) {
                        param = (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.borderBlackLeft,
                              classes.verticallyCenter
                            )}>
                            {item[key] ? (
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.redCheckmark
                                )}>
                                {parse('&#10003;')}
                              </span>
                            ) : (
                              '-'
                            )}
                          </span>
                        );
                      } else if (
                        key === 'Fist' ||
                        key === 'RibLeg' ||
                        key === 'Elbow' ||
                        key === 'EdgeOfThePalm'
                      ) {
                        param = (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.borderBlackRight
                            )}>
                            <span
                              className={clsx(
                                classes.tameshiwariInnerValue,
                                (key === 'Fist' && item.FistSecondAttempt) ||
                                  (key === 'RibLeg' &&
                                    item.RibLegSecondAttempt) ||
                                  (key === 'Elbow' &&
                                    item.ElbowSecondAttempt) ||
                                  (key === 'EdgeOfThePalm' &&
                                    item.EdgeOfThePalmSecondAttempt)
                                  ? classes.redBorder
                                  : classes.blackBorder
                              )}>
                              {item[key] || '-'}
                            </span>
                          </span>
                        );
                      } else if (key === 'list' && !unorderedList) {
                        param = listComponent(item);
                      } else if (key === 'judge_unordered_list') {
                        param =
                          unorderedList && item?.tatamis?.length > 0
                            ? unorderedList(item, 'tatamis')
                            : '-';
                      } else if (key === 'judge_unordered_list_2') {
                        param =
                          unorderedList && item?.teams?.length > 0
                            ? unorderedList(item, 'teams')
                            : '-';
                      } else if (key === 'judge_unordered_list_0') {
                        param =
                          unorderedList && item?.positionName
                            ? unorderedList(item, 'positionName')
                            : '-';
                      } else if (key === 'next_qdan_id') {
                        let myQdan;
                        if (item.school_info) {
                          myQdan = selectedValue(
                            qdanList,
                            'id',
                            `${item.school_info[key]}`
                          );
                        } else if (item[key]) {
                          myQdan = selectedValue(
                            qdanList,
                            'id',
                            `${item[key]}`
                          );
                        }

                        param = myQdan?.name;
                      } else if (
                        (key === 'uploading_date' ||
                          key === 'start_date' ||
                          key === 'registration_date' ||
                          key === 'update_date') &&
                        item[key]
                      )
                        param = DDMMYYYY(item[key]);
                      else if (key?.dropdown) {
                        param =
                          item[key.dropdown] === '0' ? (
                            item[key.dropdown]
                          ) : (
                            <span
                              onClick={(evt) => {
                                evt.stopPropagation();
                                expandTableRow(evt, item);
                              }}
                              className={clsx(classes.flex, classes.dropdown)}>
                              {item[key.dropdown]}
                              <ArrowDropDownIcon
                                className={
                                  (
                                    Array.isArray(collapsed)
                                      ? collapsed.find(
                                          (it) => +it?.id === +item?.[id]
                                        )?.id
                                      : collapsed[item[id]]
                                  )
                                    ? classes.arrowDropUp
                                    : undefined
                                }
                              />
                            </span>
                          );
                      } else if (typeof key === 'object' && key?.isCheckbox) {
                        param = (
                          <CheckboxBtn
                            checked={!!+item[key.param]}
                            onChange={(evt) =>
                              onTableCellCheckbox(evt, item, key.param)
                            }
                          />
                        );
                      } else if (key?.photo || key?.noPhoto) {
                        param = columnDataNamePhoto(item, key);
                      } else if (key === 'pay_type') {
                        param =
                          +item.pay_type === 1
                            ? t('monthlyPayment')
                            : t('annualPayment');
                      } else if (key === 'deleted') {
                        param =
                          item.deleted === '1' ? (
                            <CheckBox />
                          ) : item.deleted === '0' ? (
                            <CheckBoxOutlineBlank />
                          ) : null;
                      } else if (
                        //winners: used to display team members in an individual row
                        item.team &&
                        (key === 'participant_name' ||
                          key === 'participant_coach')
                      ) {
                        param = item[key].split(', ').map((it, teamIdx) => (
                          <span
                            className={classes.flex}
                            key={`${it}_${teamIdx}`}>
                            {it}
                          </span>
                        ));
                      } else if (key === 'status') {
                        param = t(
                          +item?.status === 1
                            ? 'declared'
                            : +item?.status === 2
                            ? 'accepted'
                            : '-'
                        );
                      } else if (key === 'status_recorder') {
                        param = t(
                          item?.status === 'active' ? 'active' : 'inactive'
                        );
                      } else if (key === 'status_name') {
                        param = t(
                          item?.status_name === 'active' ? 'active' : 'inactive'
                        );
                      } else if (key === 'positionName') {
                        param = item.positionName
                          ? [`(${item.positionShort})`, item.positionName].join(
                              ' '
                            )
                          : '-';
                      } else if (key === 'registrar_role') {
                        param =
                          item.role === 'operator'
                            ? t('operator')
                            : t('registrar');
                      } else if (key === 'registration_active') {
                        param = finishedTournament(item)
                          ? t('eventClosed')
                          : !+item?.registration_active
                          ? t('registrationClosed')
                          : t('registrationOpened');
                      } else if (key === 'tournament_type') {
                        param =
                          +item?.type === 1
                            ? t('local')
                            : +item?.type === 2
                            ? t('international')
                            : t('trainingSchool');
                      } else if (key === 'Idx') {
                        param = idx + 1;
                      } else if (
                        key === 'settingsRep' ||
                        key === 'settingsPage'
                      ) {
                        param = '';
                      } else if (key === 'registered') {
                        param = !!+item?.registered ? t('yes') : t('no');
                      } else if (key === 'logName') {
                        param = item;
                      } else if (
                        editableTableCell &&
                        Object.entries(editableTableCell)?.some(
                          ([editCellKey]) => editCellKey?.includes(key)
                        )
                      ) {
                        param = editableTableCellComponent(item, key);
                      } else if (key === 'history') {
                        param = item.history === null ? t('no') : t('yes');
                      }

                      const bodyColumn = () => {
                        return (
                          <>
                            {!header?.[index]?.noTooltip ? (
                              <Tooltip title={param}>
                                <span>
                                  {inputSwitch
                                    ? areaOfInput(
                                        indexReference,
                                        key,
                                        item.id,
                                        param
                                      )
                                    : param}
                                </span>
                              </Tooltip>
                            ) : (
                              param
                            )}
                            {item.checkboxes &&
                              key === 'settingsRep' &&
                              reportCheckboxes(item, key)}
                            {item.checkboxes &&
                              key === 'settingsPage' &&
                              reportCheckboxes(item, key)}
                          </>
                        );
                      };

                      return (
                        <Fragment key={index}>
                          {key && (
                            <TableCell
                              onClick={(evt) => {
                                if (
                                  editableTableCell &&
                                  Object.keys(editableTableCell).includes(key)
                                ) {
                                  evt.stopPropagation();
                                  onEditableTableCell(
                                    evt,
                                    item,
                                    key,
                                    true,
                                    true
                                  );
                                }
                              }}
                              classes={{
                                root: clsx(
                                  (key === 'FistSecondAttempt' ||
                                    key === 'RibLegSecondAttempt' ||
                                    key === 'EdgeOfThePalmSecondAttempt' ||
                                    key === 'ElbowSecondAttempt') &&
                                    classes.paddingLeft0,
                                  (key === 'Fist' ||
                                    key === 'RibLeg' ||
                                    key === 'Elbow' ||
                                    key === 'EdgeOfThePalm') &&
                                    classes.paddingRight0
                                )
                              }}
                              className={clsx(
                                key && key.photo && classes.column,
                                index === 0 && classNameCellWidth,
                                classes.ellipsis,
                                showMainTbClassNameIssue &&
                                  showMainTbClassNameIssue(item, key) &&
                                  classes.issue,
                                maxColumnWidth || classes.maxWidth,
                                (key === 'club' || key === 'region') &&
                                  classes.preLine
                              )}>
                              {!isNaN(rowOrder) && index === 0 ? (
                                <span
                                  className={clsx(
                                    classes.flex,
                                    classes.relative,
                                    classes.verticallyCenter
                                  )}>
                                  <span
                                    className={clsx(
                                      classes.flex,
                                      classes.center,
                                      classes.orderRowNum
                                    )}>
                                    {rowOrder + idx + 1}
                                  </span>
                                  {bodyColumn()}
                                </span>
                              ) : (
                                bodyColumn()
                              )}
                            </TableCell>
                          )}
                        </Fragment>
                      );
                    })}
                    {assignParticipantCategories && (
                      <TableCell>{assignParticipantCategories(item)}</TableCell>
                    )}
                    {assignParticipantTeams && (
                      <TableCell>{assignParticipantTeams(item)}</TableCell>
                    )}
                    {actionIcons && (
                      <TableCell>{actionIconsComponent(item, idx)}</TableCell>
                    )}
                  </TableRow>
                  {collapsed && Array.isArray(collapsed) && (
                    <CollapseTable {...{ item }} itemIdx={idx} />
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            {addNewReference && createNewRow(indexReference, data)}
            <TableRow>
              <TableCell className={classes.textAlign} colSpan={header?.length}>
                {t('noResults')}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <div
        className={
          classNameHideCondensedTableSpecificWidth
            ? classNameHideCondensedTableSpecificWidth
            : classes.hideCondensedTable
        }>
        {Array.isArray(body) && body.length > 0 ? (
          body.map((item, idx) => {
            const tableDataValues =
              typeof condensedTableData === 'function'
                ? condensedTableData(item)
                : condensedTableData;
            const collapsedCondensedTableDataCopy =
              typeof collapsedCondensedTableData === 'function'
                ? collapsedCondensedTableData(item)
                : collapsedCondensedTableData;

            return (
              <div
                key={idx}
                className={clsx(
                  classes.paper,
                  classes.padding,
                  classes.flex,
                  shouldAllowEditing && shouldAllowEditing(item) && classes.row,
                  selected && selected(item) && classes.selected
                )}
                onClick={
                  shouldAllowEditing && shouldAllowEditing(item)
                    ? (evt) => onClick(evt, item, idx)
                    : undefined
                }>
                {tableDataValues.photo && (
                  <img
                    className={classes.img}
                    src={
                      item[tableDataValues.photo]
                        ? serverDefaultPath + item[tableDataValues.photo]
                        : DEFAULT_AVATAR
                    }
                    alt="pic"
                  />
                )}
                <span
                  className={clsx(
                    classes.flex,
                    classes.column,
                    classes.paddingLeft10,
                    classes.fullWidth,
                    condensedCardWidth
                  )}>
                  <span
                    className={clsx(
                      collapsedCondensedTableDataCopy?.mainName?.isDrag && [
                        classes.flex,
                        classes.verticallyCenter
                      ],
                      classes.fullWidth,
                      classes.cardTitle,
                      mainTableRowColor,
                      showMainTbClassNameIssue &&
                        showMainTbClassNameIssue(
                          item,
                          condensedTableData.mainName
                        ) &&
                        classes.issue
                    )}>
                    {setCondensedCardMainName(item, tableDataValues)}
                    {collapsedCondensedTableDataCopy?.mainName?.isDrag &&
                      !shouldDisableEditing &&
                      [
                        {
                          icon: <CopyIcon />,
                          className: classes.ationIconsLeft,
                          id: TEST_ID_COPY
                        },
                        { icon: <TransferIcon /> }
                      ].map((it, itIdx) => (
                        <Fragment key={itIdx}>
                          <span
                            onClick={(evt) => {
                              evt.stopPropagation();
                              onClickPasteIndicatorHeader(item, it.id);
                            }}
                            className={clsx(
                              classes.flex,
                              classes.iconWrapper,
                              classes.row,
                              classes.verticallyCenter,
                              classes.center,
                              it.className
                            )}>
                            {it.icon}
                          </span>
                        </Fragment>
                      ))}
                  </span>
                  {tableDataValues.list ? (
                    <span
                      className={clsx(
                        classes.flex,
                        classes.marginTop10,
                        classes.cardTxt,
                        classes.smallViewportWrap
                      )}>
                      {cardTxtComponent(item, tableDataValues.info)}
                      <span
                        className={clsx(
                          classes.flex,
                          classes.column,
                          classes.left,
                          classes.fullWidth
                        )}>
                        {!unorderedList && listComponent(item)}
                        {unorderedList && (
                          <>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.marginBottom05,
                                classes.column,
                                classes.marginLeft
                              )}>
                              {item?.positionName && (
                                <>
                                  {t('positions')}{' '}
                                  {unorderedList(item, 'positionName')}
                                </>
                              )}
                            </span>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.marginBottom05,
                                classes.column,
                                classes.marginLeft
                              )}>
                              {item?.tatamis?.length > 0 && (
                                <>
                                  {t('tatamis')}{' '}
                                  {unorderedList(item, 'tatamis')}
                                </>
                              )}
                            </span>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.column,
                                classes.marginLeft
                              )}>
                              {item?.teams?.length > 0 && (
                                <>
                                  {t('teams')} {unorderedList(item, 'teams')}
                                </>
                              )}
                            </span>
                          </>
                        )}
                      </span>
                    </span>
                  ) : (
                    <>
                      {cardTxtComponent(
                        item,
                        tableDataValues.info,
                        mainTableRowColor
                      )}
                      {assignParticipantCategories &&
                        assignParticipantCategories(item) && (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.verticallyCenter
                            )}>
                            <span className={classes.marginRight}>
                              {t('categories')}:
                            </span>
                            {assignParticipantCategories(item)}
                          </span>
                        )}
                      {assignParticipantTeams &&
                        assignParticipantTeams(item) && (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.verticallyCenter
                            )}>
                            <span className={classes.marginRight}>
                              {t('teams')}:
                            </span>
                            {assignParticipantTeams(item)}
                          </span>
                        )}
                    </>
                  )}
                  {collapsed && Array.isArray(collapsed) && (
                    <CollapsedCondensedView {...{ item }} />
                  )}
                  {item.checkboxes && reportCheckboxes(item, 'settingsRep')}
                  {item.checkboxes && reportCheckboxes(item, 'settingsPage')}
                </span>
                {actionIcons &&
                  actionIconsComponent(item, idx, classes.smallViewportColumn)}
              </div>
            );
          })
        ) : (
          <span
            className={clsx(
              classes.flex,
              classes.center,
              classes.condensedViewNoResults
            )}>
            {t('noResults')}
          </span>
        )}
      </div>
      {paginationComponent}
    </PerfectScrollbar>
  );
};
export default GlobalTable;
