import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  navLink: {
    color: theme.palette.primary.contrastText
  },
  activeLink: {
    opacity: 0.5
  },
  item: {
    paddingBottom: '1.5em',
    fontSize: '1.2rem',
    '&:hover': {
      opacity: 0.3
    },
    '@media (max-width:900px)': {
      paddingBottom: '1em',
      fontSize: '1rem'
    }
  },
  icon: {
    paddingRight: '.8em',
    '& > svg': {
      width: '2.2rem'
    }
  },
  active: {
    '& $icon, & $item': {
      opacity: 0.5
    }
  },
  materialUiIcons: {
    '& .MuiSvgIcon-root': {
      width: '2.2rem',
      height: '2.2rem'
    }
  }
}));
