const styles = () => ({
  flex: {
    display: 'flex'
  },

  maxWidth: {
    maxWidth: '10em'
  },

  centerVertically: {
    alignItems: 'center'
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content',
      marginLeft: '0.5rem'
    }
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  switchWrapper: {
    minWidth: 'fit-content',
    padding: '0.5rem 0 0.5rem 1rem'
  },

  condensedCardWidth: {
    width: 'calc(100% - 6.5em - 1.5rem)'
  }
});

export default styles;
