import { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import GroupIcon from '@material-ui/icons/Group';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Collapse } from '@material-ui/core';

import { TEST_ID_COPY } from '../../helpers/constants';

import SearchInput from '../SearchInput/SearchInput';
import Radio from '../RadioBtn/RadioBtn';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import Button from '../Buttons/ActionButtons';
import Table from '../Table/Table';

import { useStyles } from './Styles';

const DistributeParticipantsByCategoriesComponentComponent = (props) => {
  const {
    filteredData,
    onSearch,
    onClearSearch,
    searchBar,
    onExpandRow,
    shouldDisableEditing,
    onRadioBtnSelection,
    onSelectPartCheckbox,
    selectedParticiantsCategory,
    collapsed,
    onSaveDistribution,
    onPanelSelection,
    tournamentData,
    activeFiltersPerPanel
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const FIRST_NAME = !+tournamentData?.last_name_first
    ? 'participant_first_name'
    : 'participant_last_name';
  const LAST_NAME = !+tournamentData?.last_name_first
    ? 'participant_last_name'
    : 'participant_first_name';

  const TABLE_BODY = [
    { firstName: FIRST_NAME, lastName: LAST_NAME, noPhoto: true },
    'age',
    'gender',
    'weight',
    'qdan_name'
  ];

  const CONDENSED_TABLE_DATA = {
    mainName: { firstName: FIRST_NAME, lastName: LAST_NAME },
    info: [
      { key: 'age', name: t('age') },
      { key: 'gender', name: t('gender') },
      { key: 'weight', name: t('weight') },
      { key: 'qdan_name', name: t('kyuDan') }
    ]
  };

  return (
    <div className={clsx(classes.flex, classes.centerHorizontally)}>
      <div className={clsx(classes.flex, classes.column)}>
        <SearchInput
          className={classes.search}
          onChange={onSearch}
          clearSearch={onClearSearch}
          value={searchBar}
          isSearchInactive={!searchBar}
        />
        <div className={classes.flex}>
          {[1, 2].map((panel, panelIdx) => {
            const param_agr = `panel_${panelIdx}`;
            const filtersByPanel = activeFiltersPerPanel[param_agr];

            return (
              <span className={clsx(classes.flex, classes.column)} key={panel}>
                <span
                  className={clsx(
                    classes.flex,
                    panelIdx === 0
                      ? classes.filterWrapperPanel0
                      : classes.filterWrapperPanel1,
                    classes.fontSize08,
                    classes.fullWidth,
                    classes.centerVertically,
                    classes.marginBottom05
                  )}>
                  <Button
                    specificallyStyledBtn={clsx(
                      classes.filterBtn,
                      filtersByPanel && classes.activeBtn
                    )}
                    onClick={() => onPanelSelection(panelIdx)}
                    label={t('filter')}
                  />

                  <span className={clsx(classes.flex, classes.filterSummary)}>
                    {Array.isArray(filtersByPanel) &&
                      filtersByPanel.map((it, filteredByPanelIdx) => (
                        <span
                          className={classes.marginRight05}
                          key={filteredByPanelIdx}>
                          {`${it.name}${
                            filteredByPanelIdx !== filtersByPanel.length - 1
                              ? ','
                              : ''
                          }`}
                        </span>
                      ))}
                  </span>
                </span>

                <span
                  className={clsx(
                    classes.flex,
                    classes.fullHeight,
                    classes.minHeight
                  )}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      classes.categoriesPanel,
                      panelIdx === 1 &&
                        shouldDisableEditing &&
                        classes.marginLeft2
                    )}>
                    {filteredData[param_agr]?.map((category, categoryIdx) => {
                      const findCategoryIdx =
                        selectedParticiantsCategory?.oldData?.findIndex(
                          (it) => +it.category_id === +category.id
                        );
                      const findCategoryParts =
                        selectedParticiantsCategory.oldData &&
                        selectedParticiantsCategory.oldData[findCategoryIdx] &&
                        selectedParticiantsCategory.oldData[findCategoryIdx]
                          .participants;

                      return (
                        <Fragment key={categoryIdx}>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.marginBottom05,
                              classes.centerVertically
                            )}>
                            {panelIdx === 1 && (
                              <Radio
                                value={
                                  selectedParticiantsCategory.newCategory || ''
                                }
                                item={`${category.id}`}
                                onClick={(evt) =>
                                  onRadioBtnSelection(evt, category)
                                }
                              />
                            )}
                            <span
                              className={clsx(
                                classes.flex,
                                classes.btnHover,
                                classes.fullHeight,
                                classes.width30
                              )}
                              onClick={(evt) =>
                                onExpandRow(evt, panelIdx, category)
                              }>
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.categoryItem,
                                  classes.fullWidth,
                                  classes.centerVertically,
                                  classes.padding05
                                )}>
                                <span>{category.name}</span>
                                <span
                                  className={clsx(
                                    classes.flex,
                                    classes.alignLeft
                                  )}>
                                  <span
                                    className={clsx(
                                      classes.flex,
                                      classes.marginLeft02
                                    )}>
                                    {category.participants_count}
                                  </span>

                                  <GroupIcon className={classes.marginLeft02} />
                                </span>
                              </span>
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.dropdownIc,
                                  classes.padding05,
                                  classes.centerVertically
                                )}>
                                <ArrowDropDownIcon
                                  className={
                                    collapsed?.[param_agr]?.[category.id]
                                      ?.length > 0
                                      ? classes.rotate180
                                      : undefined
                                  }
                                />
                              </span>
                            </span>
                          </span>
                          <Collapse
                            className={classes.collaps}
                            in={
                              collapsed?.[param_agr]?.[category.id]?.length > 0
                            }
                            timeout="auto"
                            unmountOnExit>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.column,
                                classes.marginBottom05,
                                panelIdx === 0
                                  ? classes.marginLeft1
                                  : classes.marginLeft4
                              )}>
                              <Table
                                shouldDisableEditing
                                condensedTableData={CONDENSED_TABLE_DATA}
                                shouldHideHeader={true}
                                shouldHideTableToolbar={true}
                                body={collapsed?.[param_agr]?.[category.id]}
                                data={TABLE_BODY}
                                colSpan={8}
                                actionIcons={
                                  panelIdx === 0 && !shouldDisableEditing
                                    ? (part, idx, className, iconWrapper) =>
                                        panelIdx === 0 && (
                                          <CheckboxBtn
                                            classControlLabel={clsx(
                                              classes.alignLeft,
                                              classes.marginRight0
                                            )}
                                            className={clsx(
                                              classes.padding0,
                                              classes.marginLeft1
                                            )}
                                            checked={
                                              Array.isArray(findCategoryParts)
                                                ? findCategoryParts.some(
                                                    (it) =>
                                                      it.participant_id ===
                                                      part.participant_id
                                                  )
                                                : false
                                            }
                                            onChange={() =>
                                              onSelectPartCheckbox(
                                                part,
                                                category
                                              )
                                            }
                                          />
                                        )
                                    : false
                                }
                              />
                            </span>
                          </Collapse>
                        </Fragment>
                      );
                    })}
                  </span>
                  {panelIdx === 0 && !shouldDisableEditing && (
                    <span>
                      <span
                        id="TEST_ID_TRANSFER"
                        onClick={onSaveDistribution}
                        className={clsx(
                          classes.flex,
                          classes.actionBtn,
                          classes.centerHorizontally,
                          classes.btnHover,
                          classes.marginBottom05
                        )}>
                        <span
                          className={clsx(
                            classes.flex,
                            classes.centerVertically,
                            classes.column,
                            classes.rotate270,
                            classes.noEvent
                          )}>
                          <span>{t('transfer')}</span>
                          <span className={classes.rotate90}>&#187;</span>
                        </span>
                      </span>
                      <span
                        id={TEST_ID_COPY}
                        onClick={onSaveDistribution}
                        className={clsx(
                          classes.flex,
                          classes.actionBtn,
                          classes.centerHorizontally,
                          classes.btnHover
                        )}>
                        <span
                          className={clsx(
                            classes.flex,
                            classes.centerVertically,
                            classes.column,
                            classes.rotate270,
                            classes.noEvent
                          )}>
                          <span>{t('copy')}</span>
                          <span className={classes.rotate90}>&#8250;</span>
                        </span>
                      </span>
                    </span>
                  )}
                </span>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DistributeParticipantsByCategoriesComponentComponent;
