import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  droppable: {
    '@media (min-width:901px)': {
      width: '30em'
    }
  },

  flex: {
    display: 'flex'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  justifyContent: {
    justifyContent: 'space-between'
  },

  subText: {
    marginTop: '0.2rem'
  },

  subTextDigit: {
    padding: '0 0.2rem',
    alignSelf: 'center'
  },

  wordBreak: {
    wordBreak: 'break-word'
  },

  extraTime: {
    padding: '0 0.2rem'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  column: {
    flexDirection: 'column'
  },

  subWrapper: {
    flexDirection: 'column',
    padding: '0.2rem',
    textTransform: 'lowercase'
  },

  tatamiIcons: {
    width: 'auto'
  },

  marginBottom0And5: {
    marginBottom: '0.5rem'
  },

  tab: {
    width: '12em',
    height: '7em',
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    border: '1px solid #BFBFBF',
    marginBottom: '0.5rem',
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5
    }
  },

  seletedTab: {
    border: '.8rem solid #D0D1D3'
  },

  padding05: {
    padding: '0.5rem'
  },

  search: {
    width: 'calc(100% - 1rem)'
  },

  flipCard: {
    position: 'relative',
    height: '5.2em',
    backgroundColor: 'transparent',
    perspective: '1000px',
    transformStyle: 'preserve-3d',
    transition: 'all 0.8s ease'
  },

  cardIsFliped: {
    transform: 'rotateY(180deg)'
  },

  front: {
    background: 'rgb(244,246,248)'
  },

  back: {
    background: 'rgb(244,246,248)',
    transform: 'rotateY(180deg)'
  },
  
  side: {
    width: 'calc(100% - 1rem)',
    height: 'calc(100% - 1rem)',
    position: 'absolute',
    backfaceVisibility: 'hidden',
    [`-webkit-backface-visibility`]: 'hidden'
  },

  textContainer: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },

  flipIc: {
    width: '2em',
    height: '2em',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.6'
    }
  },

  txtInput: {
    maxWidth: '7em',
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  blockWrapper: {
    border: '1px solid #BFBFBF',
    borderLeft: 'none',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '5em'
  },

  ellipsis: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  blockName: {
    transform: 'rotate(-90deg)'
  },

  blockTxt: {
    textAlign: 'center',
    maxHeight: '5em',
    padding: '0.2rem'
  },

  marginRight: {
    marginRight: '1rem'
  },

  textAlign: {
    textAlign: 'center'
  }
});

export const getTatamiTabStyle = (bg) => ({
  background: bg,
  color: bg ? '#fff' : '#000'
});

export const getListStyle = (isDraggingOver, numOfTatamis) => ({
  background: isDraggingOver && '#d9ddf2',
  padding: '1rem',
  border: '2px solid #d1d6dd',
  maxHeight: `${numOfTatamis > 3 ? numOfTatamis * 7.4 : 20}em`, //7.4 includes tab height + marginBttom + border
  overflow: 'auto'
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  padding: '0.5rem',
  marginBottom: '0.5rem',
  // change background colour if dragging
  background: isDragging && '#6877ca',
  border: '2px solid #4d4d4d',
  // needed styles to apply on draggables
  ...draggableStyle
});
