export default {
  exit: 'Выход',
  lang: 'Язык',
  english: 'Английский',
  russian: 'Pусский',
  romanian: 'Румынский',
  german: 'Немецкий',
  templates: 'Шаблоны',
  template: 'Шаблон',
  subject: 'Тема',
  here: 'aici',
  apply: 'Применить',
  proceed: 'Перейти',
  picture: 'Картинку',
  photo: 'Фото',
  accountCreated: 'Аккаунт создан!',
  accountFailToCreate: 'Не удалось активировать аккаунт!',
  fail2AddUser:
    'Не удалось сохранить изменения. Пользователь с именем "{{name}}" уже существует в системе.',
  navigateTo: 'Перейдите к',
  min: 'мин',
  sec: 'сек',
  kg: 'кг',
  cm: 'см',
  minute: 'Минута',
  minutes: 'Минут',
  second: 'Секунда',
  seconds: 'Секунд',
  hour: 'Час',
  hours: 'Часов',
  days: 'Дней',
  day: 'День',
  tournamentDay: 'День турнира',
  years: 'лет',
  errorBoundary: 'О нет! Что-то пошло не так',
  logout: 'Выход',
  firstname: 'Имя',
  lastname: 'Фамилия',
  firstNameNational: 'Имя латиница',
  lastNameNational: 'Фамилия латиница',
  yourFirstName: 'Ваше Имя',
  yourLastName: 'Ваша Фамилия',
  coachFirstName: 'Имя Тренера',
  coachLastName: 'Фамилия Тренера',
  patronymic: 'Отчество',
  tournament: 'Турнир',
  logIn: 'Войти',
  activateAccount: 'Активировать аккаунт',
  loginIssue: 'Не удалось войти в систему?',
  username: 'Имя пользователя',
  password: 'Пароль',
  passwordChangedSuccessfully: 'Пароль успешно изменён!',
  emailSent: 'Вам отправлено письмо для восстановления пароля на адрес',
  followInstructions: 'Следуйте инструкциям в письме.',
  categories: 'Категории',
  categoriesForm: 'Категорий',
  deleteCategoryErrorsBcOfTourn: `Эту категорию нельзя удалить, так как она используется в следующих турнирах: {{name}}`,
  deleteGroupCategoryErrorsBcOfCategories: `Эту группу нельзя удалить, так как в ней есть категории`,
  participantsNum: 'Кол. Участников',
  participants: 'Участники',
  participant: 'Участника',
  athletes: 'Спортсмены',
  athlete: 'Спортсмена',
  athleteForm: 'Спортсменов',
  myTeam: 'Моя команда',
  myFederation: 'Моя Федерация',
  cluborFederationName: 'Клуб или Федерация',
  users: 'Пользователи',
  logs: 'Логи',
  demoUsers: 'Демо-пользователи',
  expandList: 'Развернуть список',
  total: 'Всего',
  sum: 'Оценка',
  upcoming: 'Предстоящие',
  finished: 'Прошедшие',
  upcomingEvents: 'Предстоящие мероприятия',
  pastEvents: 'Прошедшие мероприятия',
  eventStatus: 'Статус мероприятия',
  active: 'Активные',
  inactive: 'Не активные',
  archive: 'Архив',
  showRed: 'Показать красные',
  appointed: 'назначенных',
  filled: 'наполненных',
  human: 'человек',
  redCategories: 'Красные категории',
  addRecord: 'Добавить {{name}}',
  boys: 'Мальчики',
  girls: 'Девочки',
  men: 'Мужчины',
  women: 'Женщины',
  femaleGender: 'жен пол',
  maleGender: 'муж пол',
  moreApps: 'Загрузить Приложения',
  forDesktop: 'для Рабочего стола',
  forMobile: 'для Мобильного',
  downloadTournamentManagerInfo:
    'Наше приложение разработано, чтобы помочь вам провести свой турнир. Чтобы быть готовым организовать состязание, скачайте его сейчас! ',
  downloadCasterInfo:
    'Специальное приложение, которое эмулирует работу табло под управлением ОС Windows. Хорошая альтернатива Raspberry Pi. Используйте это приложение если у вас нет мини-компьтера Raspberry Pi.',
  downloadCastManager:
    'Приложение для настройки табло и управления взяимосвязью оператора татами и изображения на экране. С этим приложением настройка проще и удобнее!',
  downloadMobileInfo:
    'Теперь доступно скачивание нашего мобильного приложения. Проще, удобнее и быстрее! Загрузите приложение на свой телефон, чтобы быть в курсе последних событий.',
  downloadDiploma:
    'Это программа для печати и создания наградных дипломов, сертификатов и грамот. Если у вас на турнире есть принтер - отправляйте дипломы на печать сразу из программы, как только завершаются финальные поединки. Используйте это приложение для награждения финалистов на турнире!',
  availableFor: 'Доступно для ',
  versionLTS: 'Рекомендуется для большинства пользователей',
  versionNew: 'Свежая версия',
  current: 'Текущий',
  certificates: 'Сертификаты',
  certificate: 'Сертификат',
  document: 'Документ',
  noAttachmentsMsg: 'Похоже, нет вложений для загрузки',
  noCertificates: 'В настоящее время у вас нет сертификатов.',
  certificateNameLimitExceed:
    'Название документа не может превышать 128 символов.',
  uploadCertificates: 'Загрузить Сертификаты',
  importAttachment: 'Импортировать документ',
  attachment: 'Вложение',
  attachments: 'Вложения',
  documents: 'Документы',
  documentsCount: 'Всего документов',
  diplomas: 'Дипломы',
  regulations: 'Регламент',
  attachmentType: 'Тип документа',
  nameDocument: 'Наименование документа',
  acceptRequest: 'Принять запрос',
  rejectRequest: 'Отклонить запрос',
  uploadDate: 'Дата загрузки',
  judgeWithSuchPositionExists:
    'На Должность "{{position}}" уже назначен Судья "{{name1}}". Вы хотите заменить его на Судью "{{name2}}"?',
  judicialQualifications: 'Документы подтверждающие судейскую квалификацию',
  failed2Load: 'Не удалось загрузить файл. Нет прав или файл поврежден.',
  import: 'Импортировать',
  informativeUploadImageMsg:
    'Для успешного импорта вложений используйте .png, .jpg, .jpeg.',
  dragMsg: 'Перетащите документ сюда, чтобы загрузить или нажмите',
  rejectedFileError:
    'Это вложение не в нужном формате. Пожалуйста, загрузите вложение в правильном формате.',
  browse: 'Просмотр',
  isDragRejectMsg:
    'Этот файл не может быть прикреплен, потому что его формат не поддерживается',
  certificateNameLabel: 'Название сертификата',
  fileTooLargeMsg:
    'Это вложение превышает 20МБ. Пожалуйста, загрузите вложение меньшего размера.',
  imgTooBig:
    'Размер прикрепленного файла превышает допустимый предел, 3840x3840',
  maxChars: 'Максимум {{max}} символов',
  minChars: 'Минимум {{num}} символов',
  maxCharsForm: 'Максимум {{max}} символа',
  dropFile: 'Броси файл, чтобы прикрепить',
  remove: 'Удалить',
  insufficientData: 'Недостаточно данных',
  successfullyAdded: 'Успешно добавлено',
  resolveAllInputs: 'Разрешить все поля',
  successfullyUpdated: 'Обновление завершено успешно.',
  failed2Distribute: 'Однако распределение участников: {{name}} не удалось.',
  search: 'Поиск',
  deleteEventGridMsg: 'Вы уверены, что хотите удалить турнирную сетку?',
  deleteEventMsg: 'Вы уверены, что хотите удалить это мероприятие?',
  deleteParticipantMsg: 'Вы уверены, что хотите удалить этого участника?',
  deleteAthleteMsg: 'Вы уверены, что хотите удалить этого спортсмена?',
  deleteCategoryMsg: 'Вы уверены, что хотите удалить этy категорию?',
  deleteCategoryGroupMsg:
    'Вы уверены, что хотите удалить эту группу категорий?',
  deleteUserMsg: 'Вы уверены, что хотите удалить этого пользователя?',
  deleteCertificateMsg: 'Вы уверены, что хотите удалить этот сертификат?',
  deleteDocumenMsg: 'Вы уверены, что хотите удалить документ?',
  deleteJudgeMsg: 'Вы уверены, что хотите удалить этого судью?',
  deleteMsg: 'Вы уверены, что хотите удалить это?',
  deleteRecord: 'Удалить {{name}}',
  deleteParticipantWithCategoriesMsg:
    'Чтобы успешно удалить этого участника, сначала отмените назначение участника из всех категорий, сняв флажок.',
  deleteCategory: 'Удалить Kатегорию',
  deleteCategoryGroup: 'Удалить группу категорий',
  letsDoIt: 'Давай сделаем это!',
  deleteUser: 'Удалить Пользователя',
  useLatinChars: 'Использовать латинские символы',
  useCyrillicChars: 'Используйте кириллические символы',
  id: 'ИД',
  ikoCard: 'И.K.O Карта',
  kyuDan: 'Kю/Дaн',
  name: 'Имя',
  denomination: 'Наименование Мероприятия',
  age: 'Возраст',
  country: 'Страна',
  gender: 'Пол',
  units: 'Единицы',
  weight: 'Вес',
  weightFromTo: 'Вес от / до',
  weightInfoMark:
    'Две зеркальные категории будут созданы на основе указанного значения. Одна категория - для веса меньше введенного значения, вторая - для веса больше этого.',
  category: 'Категория',
  coach: 'Тренер',
  coaches: 'Тренеры',
  coachForm: 'Тренеров',
  totalRegistrars: 'Регистраторов',
  countries: 'Страны',
  countriesForm: 'Стран',
  type: 'Тип',
  date: 'Дата',
  eventStartDate: 'Дата начала мероприятия',
  startDay: 'День начала',
  eventStart: 'Начало мероприятия',
  sportEvent: 'Спортивное мероприятие',
  training: 'Обучение',
  nextKyuDan: 'Следующий Kю/Дан',
  exams: 'Экзамены',
  time: 'Время',
  preliminaryRounds: 'Предварительные раунды',
  semifinalAndFinals: 'Полуфиналы и финалы',
  mainAndExtraTime: 'Основное и дополнительное время',
  city: 'Город',
  address: 'Адрес',
  secretary: 'Главный Секретарь',
  mainJudge: 'Главный судья',
  allTournaments: 'Все турниры',
  allParticipants: 'Все участники',
  from: 'От',
  to: 'До',
  birthday: 'Дата рождения',
  newerDate: 'Пожалуйста, введите более новую дату',
  club: 'Клуб',

  region: 'Регион',
  role: 'Роль',
  upload: 'Загрузить',
  description: 'Описание',
  website: 'Веб-сайт',
  eventName: 'Название мероприятия',
  eventType: 'Тип мероприятия',
  submit: 'Отправить',
  more: 'Больше',
  height: 'Рост',
  history: 'История Судейства за последний 1 год',
  bestResults: 'Лучшие результаты за последние 3 года',
  selectCoachFirst: 'Выберите тренера',
  phone: 'Телефон',
  noTournaments: 'Турниров нет',
  registrationDate: 'Дата закрытия регистрации',
  local: 'Локальный Турнир',
  international: 'Интернациональный Турнир',
  trainingSchool: 'Тренировочные Сборы',
  cancel: 'Отмена',
  error: 'Возникла ошибка',
  notFound: 'Не найдено',
  appNotFound: 'Приложение не найдено',
  deletedApp: 'Приложение удалено',
  noRights: 'Недостаточно прав',
  fillInputs: 'Заполните поля',
  incompleteData: 'Неполные данные',
  deletedSuccessfully: 'Успешно удаленно',
  less: 'Меньше',
  close: 'Закрыть',
  required: 'Обязательно',
  updateFailed: 'Обновление не удалось завершить',
  cannotSaveChanges:
    'Ваши изменения не могут быть сохранены. Свяжитесь со службой поддержки.',
  invalidEmail: 'Неправильный Email',
  requiredPasswordLength:
    'Ваш пароль должен содержать не менее 4 и не более 16 символов',
  passwordsDoNotMatch: 'Пароли не совпадают',
  emailAlreadyUsed: 'Email уже используется',
  serverError: 'Сервер не отвечает. Проверьте ваше соединение',
  wrongCurrentPasswordMsg: 'Неверный пароль. Пожалуйста, попробуйте еще раз.',
  updatedPasswordMsg: 'Пароль обновлен',
  registerNewUserMsg:
    'Письмо с подтверждением было выслано вам на почту нажмите на ссылку чтобы подтвердить свой Email',
  editParticipantInformationMsg:
    'для редактирования сведений об участнике щелкните по нему в таблице',
  typeEmails: 'Введите адрес электронной почты',
  invalidEmailMsg:
    'У вас недействительный адрес электронной почты. Отредактируйте электронное письмо, чтобы отправить приглашение.',
  signUp: 'Создать учётную запись',
  forgotPassword: 'Забыли пароль?',
  changePassword: 'Поменять Пароль',
  chooseNewPassword: 'Введите новый пароль',
  submitChange: 'Поменять',
  alreadyHaveAnAccount: 'Профиль уже существует',
  confirmPassword: 'Потвердить пароль',
  send: 'Отправить',
  resetPasswordMsg:
    'Пожалуйста, введите адрес электронной почты, который вы использовали при регистрации. Затем мы отправим вам электронное письмо для сброса пароля.',
  emailVerficationFailedMsg:
    'Количество попыток восстановления пароля было превышено. Вам необходимо повторить попытку через 24 часа или обратиться к администрации',
  emailVerficationNoUserMsg:
    'Подтверждение по электронной почте не было отправлено. Повторите попытку позже.',
  requestSentToYourEmail: 'Запрос отправлен на ваш Email',
  goBack: 'Вернуться',
  notSentTo:
    'К сожалению, Ваше приглашение не может быть доставлено одному или нескольким получателям:',
  invalidPassword: 'Неправильный пароль',
  wrongEmail: 'Веденный вами электронный адрес не связан ни с одним аккаунтом.',
  noSymbols: 'Символы запрещены',
  lettersRequired: 'Буквы обязательны',
  digitsOnly: 'Разрешены только цифры',
  updateRecord: 'Обновить {{name}}',
  registered: 'Регистрация',
  registeredParticipation: 'Прошли регистрацию',
  notRegistered: 'Не зарегистрировались',
  admisDocs: 'Допуск Документы',
  admisWeighs: 'Допуск Взвешивание',
  admisEquipment: 'Допуск Экипировка',
  admisMedic: 'Допуск Медик',
  admins: 'Администраторы',
  masters: 'Мастеры',
  registerers: 'Регистраторы',
  coachesTotal: 'Всего тренеров',
  pageDoesNotExists: 'Страница не существует',
  insertPinFirst: 'Введите код турнира',
  pin: 'Введите ПИН код',
  selectParticipants: 'Выберите участников',
  getTournament: 'Получить мероприятие',
  attach: 'Добавить на Мероприятие',
  events: 'Мероприятия',
  event: 'Мероприятие',
  eventStatistics: 'Статистика Мероприятия',
  select: 'Выбрать',
  participantRequestSent: 'Ваша заявка отправлена',
  participationApproved: 'Участие подтверждено',
  participationDisapproved: 'Участие отклонено',
  approveRegistration: 'Утвердить Участие',
  participantsTotal: 'Участников',
  requests: 'Заявок',
  accesptedRequests: 'принято заявок',
  saveList: 'Скачать список',
  approve: 'Утвердить',
  male: 'Мужчины',
  female: 'Женщины',
  unisex: 'Унисекс',
  additionToCategoryFailed:
    'Произошло как минимум одно неудачное добавление из-за превышения срока регистрации или количества участников.',
  failToRemoveParticipant:
    'Не удалось удалить участника, он привязан к турниру',
  participantsRemainingMsg:
    'Успешно обновлено. Категории, содержащие участников, останутся отмеченными.',
  noAttachedTatamisMsg:
    'Чтобы назначить категории на турнир, вам может понадобиться хотя бы один татами',
  assignCategoriesToTournament: 'Добавить категории на турнир',
  kids: 'Дети',
  juniors: 'Юниоры',
  cadets: 'Кадеты',
  adults: 'Взрослые',
  veterans: 'Ветераны',
  eventCode: 'Код мероприятия',
  organizer: 'Организаторы Мероприятия',
  tournOrganizer: 'Организатор',
  addNew: 'Добавить',
  addAthlete: 'Добавить спортсмена',
  addCategory: 'Добавить Категорию',
  updateCategory: 'Обновить Категорию',
  all: 'Всё',
  allPersons: 'Все',
  edit: 'Редактировать',
  tournamentReference: 'Справка по турниру.',
  reportWinners: 'Список Победителей Турнира по Категориям.',
  winners: 'Победители',
  reportCoachClub: 'Общий Список Участников Турнира в разрезе Тренер/Клуб.',
  reportListOfCategoties:
    'Сводный Список Участников Турнира в разрезе Категорий.',
  reportParticipants: 'Формирование Регистрационных номеров Участников.',
  reportRegistration: 'Список Участников Турнира прошедших Регистрацию.',
  reportRunners: 'Формирование Бегунков для Регистрации на Турнир.',
  reportRatting:
    'Категория - Кумите, Рейтинг спортсменов по результатам поединков.',
  reportCategories:
    'Общий Список Категорий Турнира с настройками и кол-вом участников',
  reportCategoryType: 'Турнирная таблица в категории {{name}}.',
  reports: 'Отчеты',
  tournamentResults:
    'Командный зачёт по итогам турнира. Золото, серебро и бронза.',
  reportApplicants: 'Список Заявителей на участие в мероприятии.',
  reportCategoryDistribution: 'Распределение категорий по татами.',
  addCoach: 'Добавить Регистратора',
  schemeJudges: 'Схема расстановки Судей',
  closeScheme: 'Закрыть схему',
  judgeNoTatami:
    'Чтобы получить доступ к этой функции, вам сначала нужно добавить Судей и хотя бы одно татами на турнир',
  judges: 'Судьи',
  approveJudges: 'Утвердить Судей',
  addJudge: 'Добавить Судью',
  updateJudge: 'Обновить Судью',
  positions: 'Должности',
  removePosition: 'Убрать должность',
  experience: 'Стаж',
  ikoQual: 'IKO Квалификация',
  rfQual: 'Категория',
  tatami: 'Татами',
  tatamis: 'Татами',
  broadcastLink: 'Ссылка на трансляцию',
  videoLinks: 'Видео ссылки',
  blocksCount: 'Кол-во блоков',
  block: 'Блок {{count}}',
  tatamiBlock: 'Блок',
  addDeleteBlock: 'Добавить/Удалить Блок',
  blockName: 'Название Блока',
  tatamiCategories: 'Татами/Категории',
  currentPassword: 'Текущий Пароль',
  newPassword: 'Новый Пароль',
  invalidCharacters: 'Неправильные символы',
  cannotHaveMoreThan32Chars: 'Не может содержать больше чем 32 символов',
  actionFailed: 'Не удалось завершить действие',
  invalidValue: 'Неправильное значение',
  invalidDate: 'Недействительная дата',
  invalidTime: 'Неверное время',
  futureDate: 'Дата должна быть не раннее текущей и регистрационных дат',
  registrationDateError:
    'Дата должна совпадать или быть раннее даты начала мероприятия',
  minimumAllowableWeight: 'Вес не может быть меньше 10',
  noCategories: 'Категории отсутствуют',
  approvedParticipants: 'Принятые участники',
  unapprovedParticipants: 'Непринятые участники',
  participantKicked: 'Участник снят',
  newParticipant: 'Новый участник',
  save: 'Сохранить',
  Cancel: 'Отменить',
  closeDiscard: 'Подтвердить',
  cancelUnsavedChangessMsg:
    'При закрытии этого окна все несохраненные изменения будут отменены.',
  discardChanges: 'Отменить изменения?',
  noOptionsText: 'Нет вариантов',
  openText: 'Oткрыть',
  clearText: 'Удалить',
  noResults: 'Нет результатов',
  noRecords: 'В настоящее время нет записей.',
  recorderNoEvents:
    'В настоящее время у Вас нет доступа ни к одному мероприятию',
  createRecordsMsg: 'Однако вы можете их создать!',
  profile: 'Мой профиль',
  userDetails: 'Данные пользователя',
  invite: 'Пригласить',
  invitationsSent: 'Приглашения были отправлены',
  defaultInvitationTxt: 'Основной текст приглашения',
  invitationTxtRu: 'Текст приглашения на русском языке',
  invitationTxtRo: 'Текст приглашения на румынском языке',
  link2QuickRegistration: 'Ссылка на электронную регистрацию.',
  teamRepresentative: 'Представитель команды',
  sendTo: 'Кому',
  previewTooltip: 'Предварительный просмотр',
  preview: 'Предварительный просмотр письма',
  tooManyRequests: 'Ваша квота исчерпана',
  restore: 'Восстановить',
  assignParticipant: 'Распределите участников по категориям',
  assignParticipantToTraining: 'Назначьте участников на обучение',
  assignYourself: 'назначь себя',
  enableBronzeFight: 'Поединок за 3-е место',
  numberOfPlaces: 'Призовые места',
  numberOfPlacesHelperTxt:
    'Правила Absolute European Championship (8 призовых мест)',
  removeParticipantCateory: 'Удалить категорию участникa',
  removeParticipantCateoryMsg:
    'Вы действительно хотите удалить участника из этой категории {{name}}?',
  editParticipantCategory: 'Изменить категорию участника',
  tournamentIsOverMsg: 'Присоединение участников невозможно, турнир окончен.',
  assignParticipantError: 'Не удалось назначить участника',
  threeParticipantsTeamKata: 'У Вас уже есть 6 участников в данной группе',
  generateBracket: 'Показать турнирную таблицу',
  bracketInformation: 'Турнирная таблица',
  noBracketMsg: 'Информация о турнирной сетке отсутствует',
  number: 'Номер',
  tournSettings: 'Настройки мероприятия',
  kumiteSettings: 'Настройки Кумитэ',
  generalSettings: 'Общие настройки',
  date2CalculateNumberOfYears: 'Дата расчёта к-ва полных лет',
  permissionToDownload: 'У кого должно быть разрешение на скачивание?',
  permissions: 'Разрешение',
  registrar: 'Регистратор',
  operator: 'Оператор',
  user: 'Пользователя',
  status: 'Статус',
  parameter: 'Параметр',
  value: 'Значение',
  coachesOnly: 'Только тренеры',
  masterOnly: 'Только я',
  noPartNumber: 'Отключить номера участников',
  participantNumbers: 'Номера участников',
  noCountry: 'Скрыть Страну при регистрации',
  kataFinal: 'Финал участников КАТА',
  publishTourn: 'Oпубликовать мероприятие',
  privatizeTourn: 'Приватизировать турнир',
  eventClosed: 'Мероприятие закрыто',
  registrationOpened: 'Регистрация открыта',
  registrationClosed: 'Регистрация закрыта',
  deleteEventGrid: 'Удалить турнирную сетку',
  closeTournament: 'Вы уверены, что хотите закрыть мероприятие?',
  closeTournamentMsg:
    'После закрытия мероприятия вы не сможете вносить какие-либо изменения.',
  userExists: 'Аккаунт с этим адресом электронной почты уже существует',
  doesNotExist: 'не существует',
  team: 'Команда',
  teams: 'Команды',
  judgeTeams: 'Бригада',
  judgeTeam: 'Бригада',
  deleteJudgeTeam: 'Удалить Бригаду',
  removeParticipantFromTourn: 'Снять участника с турнира',
  removeParticipantTournMsg:
    'Вы уверены, что хотите снять участника с турнира?',
  reassign: 'Переназначить',
  assign: 'Назначить',
  resetAll: 'Сбросить',
  removeParticipantTourn: 'Снять участника с турнира',
  generate: 'Генерировать',
  pendingApproval: 'В ожидании утверждения',
  rank: 'Место',
  selectCertificate: 'Выберите Cертификат',
  selectCoaches:
    'Выберите тренеров, которым хотите отправить электронное письмо',
  valueExists: 'Значение уже существует',
  emptyState: 'Здесь пусто!',
  participantList: 'Сформировать заявку',
  categoriesList: 'Список категорий',
  filter: 'Фильтр',
  export: 'Экспорт',
  participantRegistration: 'Регистрация участников',
  runners: 'Бегунки',
  rating: 'Рейтинг',
  categoriesCount: 'Количество категорий',
  participantsCount: 'количество участников',

  //Quick registration to tourn
  coachRecorderUseQuickRegLinkInfo:
    'Для регистрации участников на мероприятие вы должны получить уникальную ссылку-приглашение от организатора. Используйте ссылку!',
  registerToTourn: 'Регистрация на мероприятие',
  sendRequest: 'Отправить заявку',
  downloads: 'загрузки',
  download: 'Скачать',
  otherVersions: 'Другие версии',
  dojoInfo: 'Информация о Dojo',
  tournamentInfo: 'Информация о турнирах',
  myFN: 'Мое имя',
  myLN: 'Моя фамилия',
  myEmail: 'Мой электронный адрес',
  email: 'Электронный адрес',
  myCoach: 'Мой тренер',
  myCountry: 'Моя страна',
  myCity: 'Мой город',
  myRegion: 'Мой регион',
  tournDoc: 'документы мероприятия',
  doc: 'Документы',
  branchChief: 'Branch chief',
  nameBranchChief: 'ФИО Брэнч-Чифа',
  summary: 'Сводка',
  failedToSendRequest: 'Запрос не может быть отправлен',
  failedToSendRequestMsg:
    'Для того, чтобы подать заявку, вам нужно иметь хотя бы одного участника хотя бы в одной категории',
  copyTxtMsg:
    'Если необходимо отредактировать данную заявку используйте ссылку ниже:',
  openRegistration:
    'Регистрация участников открыта. Последний день регистрации {{date}}',
  closedRegistration: `Регистрация участников закрыта. Последний день регистрации был {{date}}`,
  openJudgeRegistration:
    'Регистрация судей открыта. Последний день регистрации {{date}}',
  closedJudgeRegistration:
    'Регистрация судей закрыта. Последний день регистрации был {{date}}',
  openRegistrationBtn: 'Открыть турнирную регистрацию',
  closeRegistrationBtn: 'Закрыть турнирную регистрацию',
  disableRegistration: 'Отменить регистрацию',
  register: 'Зарегистрировать',

  //tour guide
  endTour: 'Завершить тур',
  closeTour: 'Закрыть тур',
  registerDojo: 'Зарегистрируйте свое dojo, заполнив и отправив текущую форму',
  addPartStep: 'Добавить свою команду в категории турнира',
  registerTeamStep: 'Зарегистрируйте свою команду на турнир',

  useLogo: 'Дает вам возможность использовать {{name}} позже',
  logo: 'Логотип',
  stamp: 'Печать',
  signature: 'Подпись',
  bronzeSkirmish: 'Поединок за бронзу',
  round: 'Раунд',
  final: 'Финал',
  score: 'Счет',
  sort: 'Сортировать',
  descending: 'По убыванию',
  ascending: 'По возрастанию',
  useImgLater: 'Дает вам возможность использовать {{name}} позже',
  systemCategories: 'Системные категории',
  customCategories: 'Мои категории',
  payments: 'Платежи',
  payment: 'Оплата',
  monthlyPayment: 'Месячный',
  annualPayment: 'Годовой',
  circleRound: 'Круговой раунд',
  tameshiwari: 'Тамeшивари',
  kataByFlags: 'Ката по Флажкам',
  fist: 'Кулак (Seiken)',
  secondTry: 'вторая попытка',
  footRib: 'Ребро стопы (Sokuto)',
  elbow: 'Локоть (Enpi)',
  edgeOfPalm: 'Ребро ладони (Shuto)',
  outcome: 'Итог',
  lastNameFirst: 'Фамилия, затем имя',
  indicateKataNames: 'Задайте названия ката-дисциплин',
  kataDiscipline: 'ката-дисциплин',
  red: 'Красный',
  blueSky: 'Синий',
  yellow: 'Желтый',
  cyan: 'Циановый',
  violet: 'Сиреневый',
  plum: 'Сливовый',
  green: 'Зеленый',
  white: 'Белый',
  blue: 'Cиний',
  transparent: 'Отключить',
  color: 'Цвет',
  colorsHelperTxt: 'Выберите цвет, который вам нравится',
  scoreboardColor: 'Цвет табло',
  roundKataFlags: 'Раунд {{count}}',
  kumite: 'Кумите',
  kata: 'Ката',
  teamKata: 'Ката Группа',
  pointKumite: 'Кумите по Очкам',
  autoSelectionByAgeSex: 'Автоматический подбор по возрасту и полу/весу',
  noReport: 'На данный момент такого отчета не существует.',
  successRegistration: 'Вы зарегестрированы! Теперь можете войти в систему.',
  alternative: 'Альтернативное',
  nameMinusCategories: 'Название минус категории',
  namePlusCategories: 'Название плюс категории',
  assignCategories: 'Назначить категории',
  assignTatami: 'Назначить татами',
  distributeCategories: 'Распределить категории между татами',
  distributeParticipantsByCategories:
    'Распределить участников между категориями',
  distributeParticipantsByCategoriesError:
    'Убедитесь, что вы выбрали участника и категорию',
  transfer: 'Перенос',
  copy: 'Копирование',
  assigned: 'Назначенные',
  unassigned: 'НЕ назначенные',
  specifyHeight: 'Указывать Рост при регистрации',
  specifyCoach2: 'Указывать второго тренера',
  male0To11: 'Мальчики',
  female0To11: 'Девочки',
  male11To15: 'Юноши',
  female11To15: 'Девушки',
  male16To17: 'Юниоры',
  female16To17: 'Юниорки',
  male18plus: 'Мужчины',
  female18plus: 'Женщины',
  male35plus: 'Ветераны',
  female35plus: 'Женщины',
  nameRegistrar: 'Имя Регистратора',
  infRegistrar: 'Информация о Регистраторе',
  nameCategories: 'Наименование Категории',
  nameCategoriesForm: 'Название Категории',
  inanimateName: 'Название',
  groupAddUpdate: 'Группу',
  absoluteCategory: 'Абсолютная Категория',
  categoryGroup: 'Группу Категорий',
  owner: 'Сделано',
  nameEvent: 'Наименование Мероприятия',
  requestCall: 'Заказать Звонок',
  home: 'Главная',
  aboutUs: 'О нас',
  products: 'Продукты',
  contacts: 'Контакты',
  myAccount: 'Мой аккаунт',
  createAccount: 'Создать аккаунт',
  softwareForProfessional: 'Программное обеспечение для',
  managementSportingEvents: 'профессионального проведения турнира',
  fast: 'БЫСТРО',
  comfortable: 'УДОБНО',
  safe: 'НАДЁЖНО',
  durationDays: 'Количество дней',
  contactUs: 'Связаться с нами',
  showAll: 'Все',
  showNotAccepted: 'Не утверждённые',
  provideThisAction: 'Вы уверены, что хотите выполнить это действие?',
  yes: 'Да',
  no: 'Нет',
  participantUpColor: 'Цвет полоски участника вверху',
  participantDownColor: 'Цвет полоски участника внизу',
  leaderboardSettings: 'Настройки турнирной таблицы',
  district: 'Регион',
  startDateAndTime: 'Дата и время начала',
  venueAndDatesOfTheEvent: 'Место и сроки проведения мероприятия',
  placeAndDateOfTheCommission:
    'Место и дата проведения комиссии по допуску участников',
  applicationsForRegistrationAcceptedUntil:
    'Заявки на регистрацию участников принимаются до',
  applicationsForRefereeingAreAcceptedUntil:
    'Заявки на судейство принимаются до',
  organisingСommittee: 'Организационный Комитет',
  chiefJudge: 'Главный судья соревнований',
  chiefCompetitionSecretary: 'Главный секретарь соревнований',
  chairman: 'Председатель оргкомитета',
  organizingCommitteeAddress: 'Адрес оргкомитета',
  theCommission: 'Комиссия по допуску участников',
  commissionStartDate: 'Дата начала работы комиссии',
  code: 'Код',
  administrator: 'Администратор',
  master: 'Мастер',
  queryType1: 'возвращает и пользователей, и их организаторов',
  queryType2: 'возвращает только организаторов',
  queryType3: 'возвращает только пользователей организатора',
  recorder: 'Регистратор',
  regions: 'Регионы',
  cities: 'Города',
  report: 'Отчет',
  achievements: 'Достижения',
  registrationOfJudges: 'Регистрация судей на турнир',
  declared: 'заявлен',
  accepted: 'принят',
  submitApplication: 'Подать заявку',
  deleteApplication: 'Удалить заявку',
  titleForReportsPortrait: 'Заголовок для Отчетов (книжная)',
  titleForReportsLandscape: 'Заголовок для Отчетов (альбомная)',
  titleDescriptionPortrait:
    'Дает вам возможность использовать заголовок для отчетов в книжном формате',
  titleDescriptionLandscape:
    'Дает вам возможность использовать заголовок для отчетов в альбомном формате',
  MFMF: 'МЖМЖ',
  FMFM: 'ЖМЖМ',
  FFMM: 'ЖЖММ',
  MMFF: 'ММЖЖ',
  toggleReportHead: 'Отключить заголовки для отчетов',
  recommendSize:
    'Рекомендованное разрешение картинки в пикселях: {{size}} (Ширина x Высота)',
  sportsCategory: 'Спорт-разряд',
  indicateSportsRank: 'Указывать Спорт-разряд при Регистрации',
  reference: 'Справочник',
  qualJudgesRF: 'Квалификация судей Российской Федерации',
  refereePositions: 'Судейские Должности',
  categoryTypes: 'Типы категорий',
  shortName: 'Аббревиатура',
  boyGirl: 'Мужчины, Женщины, Мужчины, Женщины',
  girlBoy: 'Женщины, Мужчины, Женщины, Мужчины',
  boyBoy: 'Мужчины, Мужчины, Женщины, Женщины',
  girlGirl: 'Женщины, Женщины, Мужчины, Мужчины',
  groupBy: 'Группировать по: ',
  portraitPageOrientation: 'Книжная ориентация',
  titleReport: 'Название Oтчета',
  reportSettings: 'Настройки Oтчета',
  pageSettings: 'Настройки Страницы',
  run: 'Выполнить',
  turnOn: 'Включить: ',
  listOfJudges: 'Список судей по татами и бригадам',
  compositionOfTheJury: 'Состав Судейской коллегии',
  judgeSignature: 'Подпись главного судьи',
  secretarySignature: 'Подпись главного секретаря',
  stampOnReports: 'Поставить печать на отчеты',
  signatureOnReports: 'Поставить подпись на отчеты',
  judgesForm: 'Судей',
  useQRcode: 'Использовать QR-код при регистрации',
  registration: 'Настройки Регистрации',
  showIkoField: 'Указывать IKO при регистрации',
  showQdanField: 'Указывать Kю/Дaн при регистрации',
  showRegionField: 'Указывать Регион при регистрации',
  showClubField: 'Указывать Клуб при регистрации',
  showCityField: 'Указывать Город при регистрации',
  showMiddleName: 'Указывать Отчество при регистрации',
  preTournamentRegistration: 'Проводить регистрацию перед турниром',
  importSettingsFromOtherTournament: 'Импортировать настройки',
  AreYouSureYouWantToImportSettings:
    'Вы уверены, что хотите импортировать настройки из турнира {{from}} в турнир {{to}}?',
  info: 'Информация',
  docIsBeingWritten: 'Документация для данной страницы в процессе написания...',
  useAlternativeCategoryName: 'Использовать альтернативное название категории',
  eventInfo: 'Информация о мероприятии',
  registrarInfo: 'Информация о регистраторе',
  enterValue: 'Введите значение',
  selectValue: 'Выберите значение',
  declaredWeight: 'Заявленный Вес',
  sendingNotifications: 'Отправлять оповещения',
  switchToParticipantMode: 'Переключиться в режим участников',
  switchToCategoryMode: 'Переключить в режим категорий',
  participantMode: 'Режим участников',
  categoryMode: 'Режим категорий',
  deleteTatamiMsg: 'Вы уверены, что хотите удалить этот Татами?',
  categoriesWillBeMoved:
    'Все категории будут пересены на ближайший доступный татами.',
  lastTatamiCannotBeRemoved: 'Последний татами удалить нельзя!',
  tournamentMustHaveOneTatami: 'На турнире должно быть хотя бы одно татами.',
  showRetired: 'Показать выбывших',
  listOfWinnersByCategory: 'Список победителей по категориям',
  awardProcedure: 'Порядок награждения',
  registrarsCount: 'Количество регистраторов',
  сreationDate: 'Дата создания',
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
  generateEmailToTournamentRegistrar:
    'После выполнении этого действия организатору будет отправлено электронное письмо со списком участников',
  showDeleted: 'Показать удалённых',
  assignParticipantTooltip: 'Распределить участников по категориям',
  assignToJudging: 'Назначить на судейство',
  removeFromJudging: 'Снять с судейства',
  help: 'Помощь'
};
