import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
    flex: {
        display: 'flex',
    },
    formTitle: {
        borderBottom: '1px solid rgba(0, 29, 61, 0.4)',
        color: theme.palette.primary.main,
        fontWeight: 500,
        paddingBottom: 0,
        alignItems: 'center',
    },
    closeIcon: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        color: '#fff',
        height: '1.5em',
        width: '1.5em',
        '&:hover': {
            opacity: .6,
        },
    },
    formTitleTxt: {
        fontSize: '28px',
        padding: '10px'
    },
    formTitleTxtException: {
        padding: '5px 0',
        fontSize: '22px',
    },
    icon: {
        cursor: 'pointer'
    },
    fullWidth: {
        width: '100%'
    },
    link: {
        marginLeft: '5px',
        color: '#2C7CB5',
        '&:hover': {
            color: '#86BBE0',
        },
    },
}))