const styles = () => ({
  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content',
      marginLeft: '0.5rem'
    }
  },

  maxWidth: {
    maxWidth: '10em'
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  switchWrapper: {
    minWidth: 'fit-content',
    padding: '0.5rem 0 0.5rem 1rem'
  }
});

export default styles;
