import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import { useStyles } from './StylesRadioBtn';

const RadioBtn = (props) => {
  const {
    options,
    onClick,
    value,
    label,
    item,
    className,
    radioClass,
    tooltipTitleParam
  } = props;

  const classes = useStyles();

  const intermidiateRadioBtn = (value, label) => (
    <FormControlLabel
      classes={{
        root: className
      }}
      key={value}
      {...{ value }}
      {...{ label }}
      control={
        <Radio
          classes={{
            root: radioClass
          }}
          color="primary"
        />
      }
    />
  );
  const finalRadioBtn = (value, label, title) =>
    title ? (
      <Tooltip key={value} {...{ title }}>
        {intermidiateRadioBtn(value, label)}
      </Tooltip>
    ) : (
      intermidiateRadioBtn(value, label)
    );

  return (
    <RadioGroup {...{ value }} {...{ onClick }} className={classes.radioGroup}>
      {Array.isArray(options)
        ? options.map((it) =>
            finalRadioBtn(it[item], it[label], it?.[tooltipTitleParam])
          )
        : finalRadioBtn(item, label)}
    </RadioGroup>
  );
};
export default RadioBtn;
