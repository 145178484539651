import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { Tooltip, Switch, FormControlLabel } from '@material-ui/core';

import { ENABLED, serverDefaultPath } from '../../helpers/constants';
import {
  changeModal,
  fetchTournaments,
  changeHead,
  fetchTournRegistrar,
  textChangeHandler,
  changeDate,
  changeImage,
  onBlur,
  fetchReference,
  selectOption,
  fetchCountries,
  fetchQdan,
  validateForm,
  downloadUplaodedFile,
  downloadFile,
  fetchReferenceRegion,
  usersRegisterNewUser,
  fetchStaff,
  toggleUserStatus,
  pageChangeHandler,
  rowsPerPageHandler
} from '../../helpers/util';
import {
  finishedTournament,
  findSortDirection,
  compareValueForSorting
} from '../../helpers/selectors';

import SideModal from '../../components/Snackbar/SideModal';
import Table from '../../components/Table/Table';
import LoadingState from '../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import EmptyState from '../../components/EmptyState/EmptyState';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import CoachForm from 'components/QuickTournRegistration/CoachForm/CoachForm';
import Pagination from '../../components/TablePagination/TablePagination';
import Button from '../../components/Buttons/ActionButtons';
import Modal from '../../components/Modal/ModalNewDesign';

import styles from './Styles';
import QuestionMark from 'components/QuestionMark/QuestionMark';

const defaultForm = {
  first_name: '',
  last_name: '',
  email: ''
};

const TABLE_BODY_PARAMS = (value, tournamentData) => [
  {
    firstName: value?.firstName,
    lastName: value?.lastName,
    ...(!!+tournamentData?.show_reg_patronymic
      ? { patronymic: 'patronymic' }
      : {}),
    photo: 'photo'
  },
  'email',
  ...(!+tournamentData?.no_country ? ['country_iso3'] : []),
  ...(!!+tournamentData?.show_reg_region ? ['region'] : []),
  ...(!!+tournamentData?.show_reg_city ? ['city'] : []),
  ...(!!+tournamentData?.show_reg_club ? ['club'] : []),
  { dropdown: 'participants_count' }
];

class ApproveJudges extends Component {
  state = {
    tournamentId: this.props.match.params.tourId,
    open: false,
    showModal: false,
    modalInfo: '',
    success: false,
    filters: {
      searchBar: '',
      isSwitchSet: true
    },
    isLoading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    tournamentData: {},
    allRegistrar: [],
    modalData: {},
    errors: {},
    statistics: {},
    countries: [],
    collapsed: [],
    sortDirection: [],
    windowWidth: window.innerWidth,
    recordsCount: 0,
    rowsPerPage: 10,
    page: 1,
    showError: {},
    iframeURL: {}
  };

  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  changeHead = changeHead.bind(this);
  fetchTournRegistrar = fetchTournRegistrar.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  changeDate = changeDate.bind(this);
  changeImage = changeImage.bind(this);
  onBlur = onBlur.bind(this);
  fetchReference = fetchReference.bind(this);
  selectOption = selectOption.bind(this);
  fetchCountries = fetchCountries.bind(this);
  fetchQdan = fetchQdan.bind(this);
  validateForm = validateForm.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  fetchStaff = fetchStaff.bind(this);
  toggleUserStatus = toggleUserStatus.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing = finishedTournament(tournamentData);

      this.fetchData(() => {
        const tableBody = TABLE_BODY_PARAMS({}, tournamentData);
        const findIdx = tableBody.findIndex(
          (it) => it.dropdown === 'participants_count'
        );

        this.onSort(7, findIdx); // on initial mount, sort by participants_count DESC
      });

      this.fetchCountries();

      this.setState({ shouldDisableEditing });

      changeHead(tournamentData, t('approveJudges'));
    });

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter(
          (item) => item.name === 'judges_requests'
        );
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, sortDirection, shouldUpdateTable, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(() => {
        this.setState({ shouldUpdateTable: false });

        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('approveJudges'));

      this.setState({ langOnLoad: currentLang });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => this.setState({ windowWidth: window.innerWidth });

  fetchData = (cb) => {
    const { tournamentId, tournamentData } = this.state;

    this.fetchTournRegistrar(tournamentId, 2, (data) => {
      const recordsCount = data?.length;
      const totalAcceptedRequests = data?.filter((it) => !!+it.status)?.length;

      const statistics = {
        totalRequests: recordsCount,
        totalAcceptedRequests,
        totalJudges: tournamentData?.judges_count
      };

      this.setState(
        {
          statistics,
          recordsCount,
          allRegistrar: data,
          filteredRegistrar: data,
          isLoading: false
        },
        () => cb && cb()
      );
    });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  closeModalHandler = () => this.setState({ showModal: false });

  onToggleJudgeStatus = (evt, item) => {
    this.toggleUserStatus(
      evt,
      item.id,
      () => this.setState({ shouldUpdateTable: true }),
      2
    );
  };

  searchHandler = (evt) => {
    const { value } = evt.target;

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, searchBar: value },
        page: 1
      }),
      () => {
        this.onFilterRecords();
      }
    );
  };

  showModalForm = (evt, item, idx) => {
    evt.preventDefault();

    const id = item?.id;
    let state = {};

    if (item?.country_id) {
      this.fetchReferenceRegion(item?.country_id);
    }

    state = {
      open: true,
      selectedRow: id,
      errors: {}
    };

    if (id) {
      state = {
        ...state,
        modalData: {
          ...item,
          ...(item.photo
            ? { imagePreview: serverDefaultPath + item.photo }
            : {}),
          phone: item?.phone_number,
          currentRecordIdx: idx
        }
      };
    }

    this.setState(state);
  };

  hideModal = () => {
    this.setState({
      open: false,
      selectedRow: null,
      modalData: {},
      allRegions: []
    });
  };

  saveForm = (evt) => {
    const { tournamentData } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          {
            ...defaultForm,
            ...(!+tournamentData?.no_country ? { country_id: '' } : {})
          },
          form,
          'errors',
          () => {
            this.usersRegisterNewUser(evt, tournamentData?.id, form, () => {
              this.setState({ shouldUpdateTable: true });
              this.hideModal();
            });
          },
          () => {
            this.setState({
              showError: { email: true }
            });
          }
        );
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { tournamentData, collapsed } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => +it?.id === +item?.id
      );

      if (findIndex === -1) {
        this.fetchStaff(
          tournamentData?.id,
          item.id,
          (body) => {
            let rowSubTable = {
              id: item.id,
              records: body,
              sortDirection: []
            };

            if (body?.length !== item?.participants_count) {
              //This is done to update the drop-down list entries and the counter at the same time
              this.setState({ shouldUpdateTable: true });
            }

            this.setState((prevState) => ({
              collapsed: [...prevState.collapsed, rowSubTable]
            }));
          },
          null
        );
      } else {
        allCollapsedTables.splice(findIndex, 1);

        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onFilterRecords = () => {
    const { allRegistrar, filters } = this.state;
    const len = allRegistrar?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = allRegistrar[i];
      const bySearch = filters.searchBar
        ? [el.first_name, el.last_name].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const byAssigned = filters.isSwitchSet ? !+el?.status : true;

      if (bySearch && byAssigned) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }
    this.setState({ filteredRegistrar: newFilteredRecords }, () => {
      const { filteredRegistrar } = this.state;
      const len = filteredRegistrar?.length;

      this.setState({ recordsCount: len });
    });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { allRegistrar, sortDirection, tournamentData } = this.state;
    const clone = [...allRegistrar];

    let field;
    switch (sortField) {
      case 1:
        field = !+tournamentData?.last_name_first ? 'first_name' : 'last_name';
        break;
      case 2:
        field = 'email';
        break;
      case 3:
        field = 'country_iso3';
        break;
      case 4:
        field = 'region';
        break;
      case 5:
        field = 'city';
        break;
      case 6:
        field = 'club';
        break;
      case 7:
        field = 'participants_count';
        break;
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        allRegistrar: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  onSwitchToggle = () => {
    this.setState(
      (prevState) => ({
        page: 1,
        filters: {
          ...prevState.filters,
          isSwitchSet: !prevState.filters.isSwitchSet
        }
      }),
      () => this.onFilterRecords()
    );
  };

  render() {
    const {
      success,
      modalInfo,
      showModal,
      filters,
      shouldDisableEditing,
      isLoading,
      filteredRegistrar,
      tournamentData,
      modalData,
      errors,
      statistics,
      countries,
      allRegions,
      windowWidth,
      showError,
      selectedRow,
      sortDirection,
      open,
      collapsed,
      recordsCount,
      page,
      rowsPerPage,
      iframeURL
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;

    const FIRST_NAME = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';
    const LAST_NAME = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';
    const FIRST_NAME_JUDGE = !+tournamentData?.last_name_first
      ? 'firstName'
      : 'lastName';
    const LAST_NAME_JUDGE = !+tournamentData?.last_name_first
      ? 'lastName'
      : 'firstName';
    const openRegistration = tournamentData?.registration_active === ENABLED;

    const header = [
      { num: 1, label: t('nameRegistrar'), noTooltip: true },
      { num: 2, label: t('email') },
      ...(!+tournamentData?.no_country
        ? [{ num: 3, label: t('country') }]
        : []),
      ...(!!+tournamentData?.show_reg_region
        ? [{ num: 4, label: t('region') }]
        : []),
      ...(!!+tournamentData?.show_reg_city
        ? [{ num: 5, label: t('city') }]
        : []),
      ...(!!+tournamentData?.show_reg_club
        ? [{ num: 6, label: t('club') }]
        : []),
      { num: 7, label: t('judges') },
      { label: t('approve') }
    ];

    const tableBody = TABLE_BODY_PARAMS(
      { firstName: FIRST_NAME, lastName: LAST_NAME },
      tournamentData
    );

    const condensedTableData = {
      mainName: {
        firstName: FIRST_NAME,
        lastName: LAST_NAME,
        ...(!!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'patronymic' }
          : {})
      },
      photo: 'photo',
      info: [
        { key: 'email', name: t('email') },
        ...(!+tournamentData?.no_country
          ? [{ key: 'country_iso3', name: t('country') }]
          : []),
        { key: 'qual_iko_short', name: t('ikoQual') },
        { key: 'qual_rf_short', name: t('rfQual') },
        { key: 'experience', name: t('experience') },
        { dropdown: { key: 'participants_count', name: t('judges') } }
      ]
    };

    const collapseTableHeader = [
      { label: t('judges') },
      { label: t('gender') },
      { label: t('birthday') },
      { label: t('kyuDan') },
      ...(!+tournamentData?.no_country ? [{ label: t('country') }] : []),
      ...(!!+tournamentData?.show_reg_city ? [{ label: t('city') }] : []),
      ...(!!+tournamentData?.show_reg_region ? [{ label: t('region') }] : []),
      ...(!!+tournamentData?.show_reg_club ? [{ label: t('club') }] : []),
      { label: t('rfQual') },
      { label: t('experience') }
    ];

    const collapseTableBody = [
      {
        firstName: FIRST_NAME_JUDGE,
        lastName: LAST_NAME_JUDGE,
        ...(!!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'patronymic' }
          : {}),
        noPhoto: true
      },
      'gender',
      'birthday',
      'qdan_name',
      ...(!+tournamentData?.no_country ? ['iso3'] : []),
      ...(!!+tournamentData?.show_reg_city ? ['city'] : []),
      ...(!!+tournamentData?.show_reg_region ? ['region'] : []),
      ...(!!+tournamentData?.show_reg_club ? ['club'] : []),
      'qualificationRfShort',
      'experience'
    ];

    const collapsedCondensedTableData = {
      mainName: {
        firstName: FIRST_NAME_JUDGE,
        lastName: LAST_NAME_JUDGE,
        ...(!!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'patronymic' }
          : {}),
        noPhoto: true
      },
      info: [
        { key: 'gender', name: t('gender') },
        { key: 'qdan_name', name: t('kyuDan') },
        ...(!+tournamentData?.no_country
          ? [{ key: 'iso3', name: t('country') }]
          : []),
        { key: 'qualificationRfShort', name: t('rfQual') },
        { key: 'experience', name: t('experience') }
      ]
    };

    const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_first_name'
      : 'coach_last_name';

    const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_last_name'
      : 'coach_first_name';

    const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachFirstName')
      : t('coachLastName');

    const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachLastName')
      : t('coachFirstName');

    const dojoInfo = [
      {
        name: FIRST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('firstname')
          : t('lastname'),
        required: true
      },
      {
        name: LAST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('lastname')
          : t('firstname'),
        required: true
      },
      ...(!!+tournamentData?.show_reg_patronymic
        ? [
            {
              name: 'patronymic',
              label: t('patronymic')
            }
          ]
        : []),
      {
        name: 'email',
        label: t('email'),
        shouldEditCoach: false,
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        name: 'branch_chief',
        label: t('nameBranchChief')
      },
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL
      },
      ...(!+tournamentData?.no_country
        ? [
            {
              country: true,
              isFilter: true
            }
          ]
        : []),
      ...(!!+tournamentData?.show_reg_region
        ? [
            allRegions?.length > 0
              ? {
                  region: true,
                  isFilter: true
                }
              : {
                  name: 'region',
                  label: t('region')
                }
          ]
        : []),
      ...(!!+tournamentData?.show_reg_city
        ? [
            {
              name: 'city',
              label: t('city')
            }
          ]
        : []),
      ...(!!+tournamentData?.show_reg_club
        ? [
            {
              name: 'club',
              label: t('cluborFederationName')
            }
          ]
        : []),
      {
        label: t('phone'),
        name: 'phone'
      },
      { label: t('website'), name: 'website' },
      { save: true }
    ];

    const pageHeaderStatistics = {
      info: [
        {
          label: [t('total'), t('requests').toLowerCase()].join(' '),
          name: statistics?.totalRequests
        },
        {
          label: [t('total'), t('accesptedRequests')].join(' '),
          name: statistics?.totalAcceptedRequests
        },
        {
          label: [t('total'), t('judgesForm').toLowerCase()].join(' '),
          name: statistics?.totalJudges
        }
      ]
    };

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {isLoading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              dialogTitle={modalData?.id ? t('infRegistrar') : t('addCoach')}
              onSwitchBetween={
                modalData?.id && {
                  prev: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx - 1;

                    if (nextItemIdx >= 0) {
                      const item = filteredRegistrar[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  },
                  next: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx + 1;
                    const len = filteredRegistrar?.length - 1;

                    if (len >= nextItemIdx) {
                      const item = filteredRegistrar[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  }
                }
              }
              dialogContent={
                <CoachForm
                  inputs={dojoInfo}
                  onBlur={this.onBlur}
                  {...{ showError }}
                  {...{ tournamentData }}
                  changeTxt={this.textChangeHandler}
                  param={'modalData'}
                  {...{ countries }}
                  values={modalData}
                  selectOption={this.selectOption}
                  {...{ errors }}
                  saveCoachData={this.saveForm}
                  changeImage={this.changeImage}
                  changeDate={(date) =>
                    this.changeDate(date, 'modalData', errors, true)
                  }
                  {...{ openRegistration }}
                  preventEvent={modalData.id}
                  fetchRegionBySelectedCountry={
                    this.fetchRegionBySelectedCountry
                  }
                  {...{ allRegions }}
                  isOldDesign
                />
              }
            />
            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics }}
              shouldShowBtn
            />
            <Table
              condensedCardWidth={classes.condensedCardWidth}
              toolbarIndividualContent={
                <>
                  <FormControlLabel
                    className={clsx(classes.switchWrapper, classes.switch)}
                    control={
                      <Switch
                        checked={filters.isSwitchSet}
                        onChange={(evt, checked) => {
                          this.onSwitchToggle(evt, checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={t('showNotAccepted')}
                  />
                  <Button
                    className={classes.exportBtn}
                    disabled
                    onClick={() =>
                      this.exportParticipationCandidatesReport(
                        tournamentData.id
                      )
                    }
                    label={t('saveList')}
                  />
                </>
              }
              maxColumnWidth={classes.maxWidth}
              {...{ condensedTableData }}
              {...{ collapsedCondensedTableData }}
              search={filters.searchBar}
              textChange={this.searchHandler}
              clearSearch={this.clearSearch}
              onSort={this.onSort}
              {...{ sortDirection }}
              shouldDisableEditing
              {...{ header }}
              body={filteredRegistrar.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )}
              data={tableBody}
              openEmptyForm={(evt) => this.showModalForm(evt)}
              onClick={(evt, item, idx) => this.showModalForm(evt, item, idx)}
              selected={(item) => selectedRow === item?.id}
              showButton={!shouldDisableEditing}
              {...{ collapsed }}
              {...{ collapseTableHeader }}
              {...{ collapseTableBody }}
              expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
              shouldAllowEditing={() => true}
              rowOrder={(page - 1) * rowsPerPage}
              subTableDataKey="records"
              id="id"
              actionIcons={(item, _, className, iconWrapper, marginLeft) => (
                <span className={clsx(classes.flex, classes.centerVertically)}>
                  <Tooltip
                    arrow
                    title={t(
                      +item?.status === 1 ? 'rejectRequest' : 'acceptRequest'
                    )}>
                    <span>
                      <CheckboxBtn
                        checked={+item?.status === 1}
                        onChange={(evt) => this.onToggleJudgeStatus(evt, item)}
                        disabled={shouldDisableEditing}
                      />
                    </span>
                  </Tooltip>
                </span>
              )}
              settedActionIconsHead
              paginationComponent={
                recordsCount > 0 && (
                  <Pagination
                    count={recordsCount}
                    onPageChange={this.pageChangeHandler}
                    onRowsPerPageChange={this.rowsPerPageHandler}
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              }
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(ApproveJudges)));
