import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import Invitation from '../../components/Invitation/Invitation';
import SideModal from '../../components/Snackbar/SideModal';
import {
  fetchTemplates,
  changeModal,
  fetchTournaments,
  updateTemplate,
  fetchCoaches,
  sendInvitation,
  getInvitationToken,
  changeHead,
  fetchReference
} from '../../helpers/util';
import {
  replaceTemplateFields,
  selectedValue,
  invitationTxtLang
} from '../../helpers/selectors';
import {
  REPLACE_ARRAY,
  LANG,
  ENV_URL,
  VALIDATE_SINGLE_EMAIL,
  VALIDATE_PAST_EMAILS,
  LINK_STYLE,
  KEEP_EDITING,
  CLOSE_DISCARD
} from '../../helpers/constants';
import { changeTitle } from '../../helpers/actions';
import LoadingState from '../../components/LoadingState/LoadingState';
import EmptyState from '../../components/EmptyState/EmptyState';
import QuestionMark from 'components/QuestionMark/QuestionMark';

const initialState = {
  checkedValues: [],
  indeterminate: false,
  checkAll: false,
  recipients: []
};
class InviteCoaches extends Component {
  state = {
    tournamentId: +this.props.match.params.tournamentId,
    templates: [],
    templateLang: '1',
    open: false,
    type: 1,
    ...initialState,
    showModal: false,
    coachesList: [],
    coachesEmails: [],
    selectedCheckboxes: [],
    selectedTemplate: {},
    tournamentData: {},
    errors: {},
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    iframeURL: {}
  };

  fetchTemplates = fetchTemplates.bind(this);
  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  updateTemplate = updateTemplate.bind(this);
  fetchCoaches = fetchCoaches.bind(this);
  sendInvitation = sendInvitation.bind(this);
  getInvitationToken = getInvitationToken.bind(this);
  changeHead = changeHead.bind(this);
  fetchReference = fetchReference.bind(this);

  componentDidMount() {
    const { tournamentId, type, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      this.fetchTemplates(type, () => {
        this.setState({ loading: false });
        this.getInvitationToken(tournamentId);
        this.fetchDefaultTemplate();
      });
      changeHead(this.state.tournamentData, t('invite'));
    });

    this.fetchCoaches(null, () => {
      const coachesEmails = this.state.coachesList.map((it) => it.email);
      this.setState({ coachesEmails });
    });

    this.fetchReference(4113, (data) => {
      const filteredData = data.filter(item => item.name === 'invite');
      this.setState({ iframeURL: filteredData[0] });
    }, null, langOnLoad);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCheckboxes, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      selectedCheckboxes &&
      selectedCheckboxes !== prevState.selectedCheckboxes
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('invite'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  fetchDefaultTemplate = (editTemplate, value) => {
    const { tournamentId } = this.state;

    const selectedTemplate = selectedValue(
      this.state.templates,
      LANG,
      this.state.templateLang
    );
    const replaceWithArray = [
      this.state.tournamentData && this.state.tournamentData.tournament_name,
      tournamentId,
      `<a ${LINK_STYLE} href=${ENV_URL}>${invitationTxtLang(
        this.state.templateLang
      )}</a>`,
      `<a ${LINK_STYLE} href=${ENV_URL}/register_to_tournament/{request_token}>${invitationTxtLang(
        this.state.templateLang
      )}</a>`
    ];
    const defaultTempate = editTemplate
      ? value
      : selectedTemplate && selectedTemplate.template;
    this.setState({
      templateId: selectedTemplate && selectedTemplate.id,
      templateTxt: defaultTempate,
      previewTemplate: replaceTemplateFields(
        defaultTempate,
        REPLACE_ARRAY,
        replaceWithArray
      )
    });
  };
  selectOption = (event, value) => {
    if (value !== null) {
      this.setState(
        { templateLang: value.lang, shouldEditTemplate: false },
        () => this.fetchDefaultTemplate()
      );
    }
  };
  editEmailReference = (event) => {
    const { name, value } = event.target;
    const { errors, recipients } = this.state;
    if (event.target.name === 'sendTo') {
      if (!value && recipients && recipients.length === 0) {
        errors.sendTo = this.props.t('required');
      } else {
        delete errors.sendTo;
      }
    }
    this.setState({ [name]: value, errors });
  };
  onKeyDown = (event) => {
    const { sendTo, checkedValues } = this.state;
    if (['Enter', 'Tab', ','].includes(event.key)) {
      event.preventDefault();
      this.addNewEmail();
    } else if (event.key === 'Backspace') {
      let emails = this.state.recipients;
      if (emails.length > 0 && !sendTo) {
        //remove the last chip from the ToBox input, uncheck the selected checkbox at the same time
        const selectedCheckboxes = checkedValues.includes(
          emails[emails.length - 1]
        )
          ? checkedValues.filter((it) => it !== emails[emails.length - 1])
          : checkedValues;
        emails.splice(-1, 1);
        this.setState({
          recipients: emails,
          checkedValues: selectedCheckboxes,
          indeterminate: selectedCheckboxes.length > 0
        });
      }
    }
  };
  addNewEmail = () => {
    const { coachesEmails, sendTo, checkedValues } = this.state;
    let enteredText = sendTo;
    let recipient = enteredText && enteredText.trim();
    if (recipient && this.isEmailValid(recipient)) {
      const checkedEmails =
        coachesEmails.includes(recipient) && !checkedValues.includes(recipient)
          ? [...checkedValues, recipient]
          : checkedValues;
      this.setState(
        {
          recipients: [...this.state.recipients, recipient],
          sendTo: '',
          checkedValues: checkedEmails,
          selectedCheckboxes: checkedEmails
        },
        () => this.isIndeterminate()
      );
    }
  };
  editTemplateBody = (evt) => {
    const { value } = evt.target;
    const { errors } = this.state;
    if (!value) {
      errors.templateTxt = this.props.t('required');
    } else {
      delete errors.templateTxt;
    }
    this.setState({ templateTxt: value, shouldEditTemplate: true, errors });
  };

  //if checkboxes selections was canceled, show previous selections
  showModal = () => {
    const { checkedValues } = this.state;
    this.setState(
      {
        open: true,
        selectedCheckboxes: checkedValues
      },
      () => this.isIndeterminate()
    );
  };
  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };
  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;
    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };
  hideSnackBar = () => this.setState({ showModal: false });
  pressItem = (evt) => this.setState({ pressedIcon: evt.currentTarget.id });
  selectAllEmails = (_, checked) => {
    const { coachesEmails } = this.state;
    const nextValue = checked ? coachesEmails : [];
    this.setState({
      isEditing: true,
      selectedCheckboxes: nextValue,
      indeterminate: false,
      checkAll: checked
    });
  };
  selectEmails = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedEmails = selectedCheckboxes.includes(key)
      ? selectedCheckboxes.filter((it) => it !== key)
      : [...selectedCheckboxes, key];
    this.setState(
      {
        selectedCheckboxes: checkedEmails,
        isEditing: true
      },
      () => this.isIndeterminate()
    );
  };
  isIndeterminate = () => {
    const { coachesEmails } = this.state;
    const valuesCount = this.state.selectedCheckboxes.length;
    const emailsCount = coachesEmails.length;
    const isIndeterminate = valuesCount > 0 && valuesCount < emailsCount;
    const isCheckedAll = valuesCount === emailsCount;
    this.setState({
      indeterminate: isIndeterminate,
      checkAll: isCheckedAll
    });
  };
  isEmailValid = (key) => {
    const { errors, sendTo } = this.state;
    const { t } = this.props;
    if (!sendTo) {
      errors.sendTo = t('required');
    } else if (!VALIDATE_SINGLE_EMAIL.test(key)) {
      errors.sendTo = t('invalidEmailMsg');
    } else if (this.state.recipients.includes(key)) {
      errors.sendTo = t('valueExists');
    } else {
      delete errors.sendTo;
    }
    this.setState({ errors });
    if (errors.sendTo) {
      return false;
    }
    return true;
  };
  deleteEmail = (key) => {
    const { recipients, checkedValues } = this.state;
    const emails = recipients.filter((email) => email !== key);
    const checkedEmails = checkedValues.includes(key)
      ? checkedValues.filter((it) => it !== key)
      : checkedValues;
    this.setState({
      recipients: emails,
      checkedValues: checkedEmails,
      selectedCheckboxes: checkedEmails,
      indeterminate: checkedEmails > 0
    });
  };
  pasteEmails = (evt) => {
    evt.preventDefault();
    const { recipients, coachesEmails, checkedValues } = this.state;
    let paste = evt.clipboardData.getData('text');
    let emails = paste.match(VALIDATE_PAST_EMAILS);
    if (emails) {
      let addEmail = emails.filter((key) => !recipients.includes(key));
      let selectEmails = [];
      for (let i = 0; i < emails.length; i++) {
        if (
          !checkedValues.includes(emails[i]) &&
          coachesEmails.includes(emails[i])
        ) {
          selectEmails.push(emails[i]);
        }
      }
      this.setState(
        {
          recipients: [...recipients, ...addEmail],
          checkedValues: [...checkedValues, ...selectEmails],
          selectedCheckboxes: [...checkedValues, ...selectEmails]
        },
        () => this.isIndeterminate()
      );
    }
  };
  applyEmails = () => {
    const { recipients, selectedCheckboxes, coachesEmails } = this.state;
    this.setState(
      {
        checkedValues: selectedCheckboxes
      },
      () => {
        const emails = this.state.checkedValues.filter(
          (email) => !recipients.includes(email)
        );
        let i = recipients.length;
        while (i--) {
          if (
            coachesEmails.includes(recipients[i]) &&
            !this.state.checkedValues.includes(recipients[i])
          ) {
            recipients.splice(i, 1);
          }
        }
        this.setState(
          {
            recipients: [...recipients, ...emails],
            isAttemptingToEditModalFields: false
          },
          () => {
            this.addNewEmail();
            this.hideModal();
          }
        );
      }
    );
  };

  validateForm = () => {
    const { recipients, errors, templateTxt } = this.state;
    const { t } = this.props;
    if (Array.isArray(recipients) && recipients.length === 0) {
      if (!errors.sendTo) errors.sendTo = t('required');
    }
    if (!templateTxt) errors.templateTxt = t('required');
    if (Object.keys(errors).length === 0) {
      this.sendInvitation(initialState);
    }
    this.setState({ errors });
  };

  render() {
    const {
      templates,
      templateLang,
      previewTemplate,
      open,
      templateTxt,
      showModal,
      success,
      modalInfo,
      recipients,
      subject,
      pressedIcon,
      coachesList,
      selectedCheckboxes,
      checkAll,
      indeterminate,
      sendTo,
      errors,
      isSending,
      shouldEditTemplate,
      invitationToken,
      tournamentData,
      loading,
      shouldShowDiscardChanges,
      iframeURL
    } = this.state;

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName}/>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <Invitation
            selectOption={this.selectOption}
            editTemplateBody={this.editTemplateBody}
            {...{ templates }}
            {...{ templateLang }}
            {...{ previewTemplate }}
            {...{ open }}
            showModal={this.showModal}
            hideModal={this.hideModal}
            {...{ templateTxt }}
            updateTemplate={this.updateTemplate}
            editEmailReference={this.editEmailReference}
            {...{ recipients }}
            {...{ subject }}
            {...{ pressedIcon }}
            pressItem={this.pressItem}
            {...{ coachesList }}
            selectEmails={this.selectEmails}
            sendInvitation={this.validateForm}
            {...{ selectedCheckboxes }}
            applyEmails={this.applyEmails}
            selectAllEmails={this.selectAllEmails}
            {...{ checkAll }}
            {...{ indeterminate }}
            {...{ sendTo }}
            deleteEmail={this.deleteEmail}
            onKeyDown={this.onKeyDown}
            fetchDefaultTemplate={() =>
              this.fetchDefaultTemplate(shouldEditTemplate, templateTxt)
            }
            addNewEmail={this.addNewEmail}
            {...{ errors }}
            {...{ isSending }}
            pasteEmails={this.pasteEmails}
            {...{ invitationToken }}
            {...{ tournamentData }}
            discardOrKeepEditing={this.discardOrKeepEditing}
            {...{ shouldShowDiscardChanges }}
          />
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(InviteCoaches));
