import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  paper: {
    backgroundColor: '#fff',
    padding: '0.5rem'
  },

  gridForm: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: '0.5rem'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '0.5rem'
    }
  },

  formAvatarWrapper: {
    '@media (min-width:1024px)': {
      gridRow: 'span 12'
    },
    '@media (max-width:1023px)': {
      gridRow: 'span 3'
    }
  },

  formAvatarInnerWrapper: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridRowGap: '1.5rem'
    }
  },

  formAvatar: {
    '@media (min-width:1024px)': {
      width: '16em',
      height: '18.5em'
    },
    '@media (max-width:1023px)': {
      height: '8.5em',
      width: '7em',
      justifySelf: 'center'
    },
    '@media (max-width:767px)': {
      marginBottom: '1rem'
    }
  },

  formSave: {
    height: '3.35em',
    width: '100%'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  pairInputs: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 2',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.5rem'
    },
    '@media (max-width:1023px)': {
      display: 'contents'
    }
  },

  historyInput: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  assignedCategoriesWrapper: {
    display: 'grid',
    marginBottom: '1rem',
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  fullWidth: {
    width: '100%'
  },

  icon: {
    width: '2.2rem',
    height: '2.2rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    padding: '5px',
    borderRadius: '4px',
    color: '#fff'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  listWrapper: {
    display: 'grid',
    gridRowGap: '0.5rem'
  },

  listRecord: {
    border: '1px solid #000',
    padding: '1rem',
    borderRadius: '0.2rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1.3rem'
  },

  issue: {
    color: '#ff0000'
  },

  marginRight: {
    marginRight: '5px'
  },

  labelCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0
    }
  },

  checkboxPaddingLeft: {
    '&.MuiCheckbox-root': {
      paddingLeft: 0
    }
  },

  labelCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0
    }
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  maxHeightTxtInput: {
    height: '5em'
  },

  maxHeightTxtInputMultipleline: {
    minHeight: '5em'
  },

  ellipsis: {
    '& .MuiInputLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 1.5rem)'
    },
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },

  saveBtn: {
    '@media (min-width:1024px)': {
      gridColumnStart: '4'
    },
    '@media (max-width:676px)': {
      marginTop: '1rem'
    }
  },

  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused, &.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 1)'
      },
    },
  },

  buttonsBorder: {
    border: '1px solid #98A5BA',
    borderRadius: '0.2rem'
  }
}));

export const bgColor = (isOldDesign) => ({
  backgroundColor: `${!isOldDesign ? '#2D63EE' : '#001D3D'}`
});
