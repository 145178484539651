const styles = () => ({
  checkboxIcon: {
    padding: 0,
    marginLeft: '.5rem'
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    padding: '1em'
  },
  flex: {
    display: 'flex'
  },
  column: {
    flexDirection: 'column'
  },
  cardImgPortrait: {
    width: '35em',
    height: '5.5em',
    '@media (max-width:1300px)': {
      width: '5em'
    }
  },
  cardImgLandscape: {
    width: '51em',
    height: '5.5em',
    '@media (max-width:1300px)': {
      width: '5em'
    }
  },
  h1: {
    fontSize: '1.5rem',
    fontWeight: 500,
    paddingBottom: '.5em',
    wordBreak: 'break-word'
  },
  justifyContent: {
    justifyContent: 'space-between'
  },
  marginBottom20: {
    marginBottom: '20px'
  },
  btn: {
    width: '15em'
  },
  justifyEnd: {
    marginTop: '20px',
    justifyContent: 'end'
  },
  opacity: {
    opacity: '0.5'
  },
  fontSize12: {
    fontSize: '12px'
  },

  unsetWhiteSpace: {
    whiteSpace: 'unset'
  },

  sizeForSignature: {
    height: '5.5em',
    width: '5em',
    marginLeft: '20px'
  },

  inlineContainer: {
    marginTop: '15px',
    display: 'inline-flex',
    width: '49.5%'
  },

  marginRight: {
    marginRight: '1%'
  },

  spinner: {
    color: '#fff',
    height: '1em'
  },

  spinnerBtn: {
    width: '2.5em',
    height: '2.5em'
  },

  inlineBlock: {
    display: 'inline-block'
  },

  marginTop15: {
    marginTop: '15px'
  }
});

export default styles;
