import { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { withRouter } from '../../components/withRouter';

import {
  PARTICIPANT_FORM,
  ID,
  COACH_ID,
  ERRORS,
  COACH_FORM,
  ENABLED,
  CLOSE_DISCARD,
  KEEP_EDITING,
  ALL_SPORT_RANK
} from '../../helpers/constants';
import {
  fetchCountries,
  selectTournament,
  selectOption,
  textChangeHandler,
  fetchCategories,
  updateParticipant,
  addParticipant,
  changeModal,
  changeImage,
  changeDate,
  fetchUsers,
  validateForm,
  applyCategories,
  selectCheckbox,
  addNewCategory,
  selectMultipleCategories,
  selectMultipleCategoryType,
  selectMultipleTeams,
  removeCategory,
  validateMultipleCategoriesAssignment,
  fetchAllCategoryTypes,
  fetchReferenceRegion,
  fetchTournamnetInformation,
  fetchReference
} from '../../helpers/util';
import {
  categoryTypesPresentOnTournament,
  DDMMYYYY,
  selectedValue
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import SideModal from '../../components/Snackbar/SideModal';
import Filter from '../../components/Filter/Filter';
import Modal from '../../components/Modal/ModalNewDesign';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import AssignCategories from '../../components/QuickTournRegistration/AssignCategories';
import ParticipantForm from '../../components/QuickTournRegistration/ParticipantFormNewDesign/ParticipantForm';
import LoadingState from '../../components/LoadingState/LoadingState';
import Button from '../../components/Buttons/ActionButtons';
import Places2Win from 'components/Places2Win/Places2Win';

import styles from './Styles';
const TOURNAMENT_INFORMATION = 'TOURNAMENT_INFORMATION';
const defaultErrors = {
  first_name: '',
  last_name: '',
  age: '',
  gender: '',
  weight: '',
  birthday: ''
};
const initialStateCategory = {
  type: '',
  id: '',
  team_id: '',
  filteredCategories: []
};

class AddParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coachesList: [...(props?.state?.coachesList ?? [])],
      participantForm: { ...(props?.state?.modalData ?? {}) },
      personalAssignedCategories: props.state
        ? props.state.personalAssignedCategories
        : [],
      countries: [],
      allRegions: [],
      qdanList: [...props.state.qdanList],
      categoryTypes: [],
      categories: [],
      filteredCategories: [],
      errors: {},
      isFilterChecked: true,
      //text validation for city/club
      showModal: false,
      windowWidth: window.innerWidth,
      success: false,
      open: false,
      isLoading: true,
      selectedCategories: [initialStateCategory],
      selectedCategoriesErrors: [],
      isSending: false,
      all_sport_rank: []
    };

    this.tournamentIdParam = new URLSearchParams(window.location.search).get(
      'tournament_id'
    );
    this.userId = localStorage.getItem('user_id');

    this.fetchCountries = fetchCountries.bind(this);
    this.selectTournament = selectTournament.bind(this);
    this.selectOption = selectOption.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.fetchCategories = fetchCategories.bind(this);
    this.updateParticipant = updateParticipant.bind(this);
    this.addParticipant = addParticipant.bind(this);
    this.changeModal = changeModal.bind(this);
    this.changeImage = changeImage.bind(this);
    this.changeDate = changeDate.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.validateForm = validateForm.bind(this);
    this.applyCategories = applyCategories.bind(this);
    this.selectCheckbox = selectCheckbox.bind(this);
    this.addNewCategory = addNewCategory.bind(this);
    this.selectMultipleCategories = selectMultipleCategories.bind(this);
    this.selectMultipleCategoryType = selectMultipleCategoryType.bind(this);
    this.selectMultipleTeams = selectMultipleTeams.bind(this);
    this.removeCategory = removeCategory.bind(this);
    this.validateMultipleCategoriesAssignment =
      validateMultipleCategoriesAssignment.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.fetchReferenceRegion = fetchReferenceRegion.bind(this);
    this.fetchTournamnetInformation = fetchTournamnetInformation.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { selectedCategories, isFilterChecked, isEditing } = this.state;
    const { state } = this.props;

    if (
      isEditing &&
      ((selectedCategories &&
        selectedCategories !== prevState.selectedCategories) ||
        isFilterChecked !== prevState.isFilterChecked)
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (
      state?.selectedRow &&
      state?.selectedRow !== prevProps.state?.selectedRow
    ) {
      this.setState({
        participantForm: state?.modalData,
        personalAssignedCategories: state?.personalAssignedCategories
      });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => this.setState({ windowWidth: window.innerWidth });

  componentDidMount() {
    const { state } = this.props;
    const { participantForm } = this.state;

    this.setState({ isLoading: true });
    this.fetchCountries();
    this.fetchReference(ALL_SPORT_RANK, (data) =>
      this.setState({ all_sport_rank: data })
    );

    if (!state.participantId) {
      //use the coach's login data to automatically fill in the new participant form
      this.assignYourself((user) => {
        this.fetchReferenceRegion(user?.country_id);
      });
    } else {
      this.fetchReferenceRegion(participantForm?.participant_country_id);
    }

    this.setState({ isLoading: false });
  }

  coachChangeHandler = (e, cb) => {
    const { coachesList } = this.state;
    const { isCoachItem } = this.props;

    if (coachesList && coachesList.length > 0) {
      const coach = coachesList.find(
        (coach) => coach?.[isCoachItem ? 'coach_id' : 'id'] === e
      );

      if (coach) {
        cb && cb(coach);
        this.setState({ coachForm: coach });
        this.autoFillCoachData(coach);
      }
    }
  };

  autoFillCoachData = (coach) => {
    this.setState((prevState) => ({
      participantForm: {
        ...prevState.participantForm,
        club: coach.club ?? coach.coach_club,
        region: coach.region ?? coach.coach_region,
        country_id: coach.country_id,
        coach_first_name: coach.coach_first_name ?? coach.c_first_name,
        coach_last_name: coach.coach_last_name ?? coach.c_last_name,
        coach_id: coach.id ?? coach?.coach_id,
        city: coach.city ?? coach.coach_city
      }
    }));
  };

  assignYourself = (cb) => {
    const { errors } = this.state;

    this.setState((prevState) => ({
      participantForm: {
        ...prevState.participantForm,
        coach_id: this.userId
      }
    }));

    delete errors.coach_id;
    this.coachChangeHandler(this.userId, cb);
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  saveParticipant = (evt) => {
    const {
      participantForm,
      coachForm,
      personalAssignedCategories,
      isSending
    } = this.state;
    const { state, triggerTableUpdate } = this.props;
    const { selectedTournamentInfo } = participantForm;
    const { role } = this.context.authState;
    const assignCategories =
      personalAssignedCategories &&
      personalAssignedCategories.length > 0 &&
      personalAssignedCategories.map((key) => {
        return { id: key.id, ...(key.team_id ? { team_id: key.team_id } : {}) };
      });
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        participantForm: {
          ...prevState.participantForm,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.participantForm;

        this.validateForm(
          evt,
          role !== 'coach'
            ? {
                ...defaultErrors,
                ...(role !== 'recorder' &&
                selectedTournamentInfo &&
                +selectedTournamentInfo.type === 2
                  ? { first_name_national: '', last_name_national: '' }
                  : {}),
                ...(!!+selectedTournamentInfo?.height_needed
                  ? { height: '' }
                  : {}),
                ...(role !== 'recorder' &&
                !!+selectedTournamentInfo?.show_reg_patronymic
                  ? { patronymic: '' }
                  : {}),
                coach_id: ''
              }
            : defaultErrors,
          form,
          ERRORS,
          () => {
            if (form.id) {
              this.updateParticipant(
                evt,
                form,
                triggerTableUpdate && triggerTableUpdate(true),
                selectedTournamentInfo,
                assignCategories,
                null,
                role
              );
            } else
              !isSending &&
                this.addParticipant(
                  evt,
                  form,
                  coachForm,
                  ERRORS,
                  PARTICIPANT_FORM,
                  () => {
                    state && triggerTableUpdate();
                    this.setState({ personalAssignedCategories: [] });
                  },
                  selectedTournamentInfo,
                  assignCategories,
                  null,
                  selectedTournamentInfo
                );
          }
        );
      }
    );
  };

  showModal = () => {
    const {
      participantForm,
      personalAssignedCategories,
      shouldSwitchTournament
    } = this.state;
    const { selectedTournamentInfo } = participantForm;
    const { category_types } = selectedTournamentInfo;

    this.setState({ open: true }, () => {
      this.fetchCategories(
        () => {
          let selectedCategories = [];
          if (!shouldSwitchTournament) {
            this.fetchAllCategoryTypes((allSystemTypes) => {
              const categoryTypes = categoryTypesPresentOnTournament(
                allSystemTypes || [],
                category_types
              );

              this.setState({ categoryTypes });
            });
          }
          selectedCategories =
            Array.isArray(personalAssignedCategories) &&
            personalAssignedCategories.map((it) => {
              return {
                ...it,
                filteredCategories:
                  Array.isArray(this.state.filteredCategories) &&
                  (!it.type
                    ? this.state.filteredCategories
                    : this.state.filteredCategories.filter(
                        (cat) => `${cat.category_type_id}` === `${it.type}`
                      ))
              };
            });
          this.setState({
            shouldSwitchTournament: false,
            categoriesFitsParticipant: this.state.filteredCategories,
            selectedCategories:
              selectedCategories.length > 0
                ? selectedCategories
                : [
                    {
                      ...initialStateCategory,
                      filteredCategories: this.state.filteredCategories
                    }
                  ]
          });
        },
        selectedTournamentInfo.id,
        null,
        null,
        null,
        this.state.isFilterChecked,
        { ...participantForm }
      );
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        isFilterChecked: true,
        shouldShowDiscardChanges: false,
        isEditing: false,
        selectedCategoriesErrors: []
      });
    }
  };
  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;
    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  fetchRegionBySelectedCountry = (key) => {
    const { participantForm } = this.state;
    let cpy = { ...participantForm };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ participantForm: cpy });
  };

  onRemoveCategoriesInForm = (item) => {
    const { personalAssignedCategories } = this.state;
    let copy = [...personalAssignedCategories];

    copy = copy?.filter((it) => it !== item);

    this.setState({ personalAssignedCategories: copy });
  };

  showTournamentInformation = () => {
    const { participantForm } = this.state;
    let cpy = { ...participantForm };

    this.fetchTournamnetInformation(cpy, (data) => {
      cpy.subModalData = data;
      this.setState({
        open: true,
        participantForm: cpy,
        pressedBtn: TOURNAMENT_INFORMATION
      });
    });
  };

  hideTournamentInformation = () => {
    const { participantForm } = this.state;
    let cpy = { ...participantForm };
    cpy.subModalData = null;

    this.setState({
      participantForm: cpy,
      pressedBtn: false,
      open: false
    });
  };

  render() {
    const {
      coachesList,
      categoryTypes,
      participantForm,
      showModal,
      modalInfo,
      success,
      windowWidth,
      errors,
      isFilterChecked,
      countries,
      allRegions,
      qdanList,
      open,
      selectedCategories,
      personalAssignedCategories,
      isLoading,
      pressedBtn,
      shouldShowDiscardChanges,
      selectedCategoriesErrors,
      all_sport_rank
    } = this.state;
    const { selectCheckboxSchool, t, isCoachOptional, isCoachItem, classes } =
      this.props;
    const { role } = this.context.authState;
    const { selectedTournamentInfo } = participantForm;
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    const MODAL_FIRST_NAME = (item) =>
      this.tournamentIdParam
        ? !+selectedTournamentInfo?.last_name_first
          ? item.participant_first_name
          : item.participant_last_name
        : !isRuLang
        ? item.first_name
        : item.last_name;
    const MODAL_LAST_NAME = (item) =>
      this.tournamentIdParam
        ? !+selectedTournamentInfo?.last_name_first
          ? item.participant_last_name
          : item.participant_first_name
        : !isRuLang
        ? item.last_name
        : item.first_name;

    const PART_FIRST_NAME_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'first_name'
        : 'last_name';

    const PART_LAST_NAME_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'last_name'
        : 'first_name';

    const PART_FIRST_NAME_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('firstname')
        : t('lastname');

    const PART_LAST_NAME_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('lastname')
        : t('firstname');

    const PART_FIRST_NAME_NATIONAL_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'first_name_national'
        : 'last_name_national';

    const PART_LAST_NAME_NATIONAL_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'last_name_national'
        : 'first_name_national';

    const PART_FIRST_NAME_NATIONAL_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('firstNameNational')
        : t('lastNameNational');

    const PART_LAST_NAME_NATIONAL_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('lastNameNational')
        : t('firstNameNational');

    let onClick, dialogTitle, dialogContent, buttonPurpose, dialo;

    if (pressedBtn !== TOURNAMENT_INFORMATION) {
      onClick = () =>
        this.validateMultipleCategoriesAssignment(() =>
          this.applyCategories(participantForm)
        );
      dialogTitle = t('assignParticipant');
      dialogContent = (
        <>
          <CheckboxBtn
            checked={isFilterChecked}
            onChange={(evt) =>
              this.selectCheckbox(
                evt,
                participantForm,
                selectedTournamentInfo.id,
                true
              )
            }
            label={t('autoSelectionByAgeSex')}
          />
          <AssignCategories
            {...{ categoryTypes }}
            {...{ selectedCategories }}
            {...{ selectedCategoriesErrors }}
            addNewCategory={() => this.addNewCategory(initialStateCategory)}
            removeCategory={this.removeCategory}
            selectCategories={this.selectMultipleCategories}
            selectCategoryType={this.selectMultipleCategoryType}
            selectTeam={this.selectMultipleTeams}
            tournamentIdParam={this.tournamentIdParam}
          />
        </>
      );
      buttonPurpose = t('apply');
    } else {
      dialogTitle = t('tournamentInfo');
      dialogContent =
        participantForm?.subModalData?.length > 0 ? (
          participantForm?.subModalData?.map((tourn, tournIdx) => {
            return (
              <div
                key={tournIdx}
                style={{
                  display: 'grid',
                  marginBottom: '1rem',
                  border: '1px solid rgba(0, 0, 0, 0.1)'
                }}>
                <span
                  style={{
                    display: 'flex',
                    background: 'rgba(0, 0, 0, 0.1)',
                    padding: '0.5rem'
                  }}>
                  {tourn.tournament_name} {DDMMYYYY(tourn.tournament_date)}
                </span>
                <div
                  style={{
                    display: 'grid',
                    gridRowGap: '0.5rem',
                    padding: '0.5rem'
                  }}>
                  {tourn?.categories?.map((cat, catIdx) => {
                    return (
                      <div style={{ display: 'flex' }} key={catIdx}>
                        {catIdx + 1}. {cat.category_name}
                        {cat.place && (
                          <Places2Win item={cat} paramName="place" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {t('noTournaments')}
          </span>
        );
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{ dialogTitle, dialogContent, onClick, buttonPurpose }}
          subHeader={
            participantForm && (
              <span className={clsx(classes.flex, classes.column)}>
                <>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.marginBottom05,
                      classes.marginTop1
                    )}>
                    {[
                      MODAL_FIRST_NAME(participantForm),
                      MODAL_LAST_NAME(participantForm),
                      [
                        participantForm.age,
                        participantForm.gender,
                        participantForm.weight &&
                          `${participantForm.weight}${t('kg')}`,
                        (!!+selectedTournamentInfo?.height_needed ||
                          !this.tournamentIdParams) &&
                          participantForm.height &&
                          `${participantForm.height}${t('cm')}`,
                        (!!+selectedTournamentInfo?.show_reg_qdan ||
                          !this.tournamentIdParams) &&
                          participantForm.qdan_name &&
                          participantForm.qdan_name
                      ]
                        .filter(Boolean)
                        .join(', ')
                    ].join(' ')}
                  </span>
                  {t('coach')}:{' '}
                  {this.tournamentIdParam
                    ? [
                        participantForm.coach_first_name,
                        participantForm.coach_last_name
                      ].join(' ')
                    : participantForm.coach_name}
                </>
              </span>
            )
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
          isOldDesign
        />

        {isLoading ? (
          <LoadingState />
        ) : (
          <ParticipantForm
            {...{ countries }}
            {...{ selectCheckboxSchool }}
            openAssignFormInsideParticipantForm={this.showModal}
            {...{ all_sport_rank }}
            changeTxt={this.textChangeHandler}
            values={participantForm}
            selectOption={this.selectOption}
            changeImage={this.changeImage}
            {...{ errors }}
            param={PARTICIPANT_FORM}
            {...{ qdanList }}
            changeDate={(date) =>
              this.changeDate(
                date,
                PARTICIPANT_FORM,
                errors,
                null,
                this.tournamentIdParam &&
                  (selectedTournamentInfo?.calc_age_date ??
                    selectedTournamentInfo?.start_date)
              )
            }
            saveParticipant={this.saveParticipant}
            isFormWidthSet={windowWidth}
            topComponent={
              <>
                {(this.tournamentIdParam ||
                  isCoachItem ||
                  role === 'administrator') && (
                  <Filter
                    className={classes.maxHeight}
                    options={coachesList}
                    onChange={(evt, val) =>
                      this.selectOption(
                        evt,
                        val,
                        PARTICIPANT_FORM,
                        COACH_ID,
                        isCoachItem ? COACH_ID : ID,
                        errors,
                        (currentVal) => {
                          this.coachChangeHandler(currentVal);
                        }
                      )
                    }
                    value={
                      selectedValue(
                        coachesList,
                        isCoachItem ? COACH_ID : ID,
                        participantForm.coach_id
                      ) || ''
                    }
                    fullWidth
                    label={t('registrar')}
                    variant="outlined"
                    item={
                      isCoachItem
                        ? isCoachItem
                        : {
                            firstName:
                              !this.tournamentIdParam &&
                              role === 'administrator'
                                ? 'c_first_name'
                                : 'first_name',
                            lastName:
                              !this.tournamentIdParam &&
                              role === 'administrator'
                                ? 'c_last_name'
                                : 'last_name'
                          }
                    }
                    optional={isCoachOptional ? isCoachOptional : 'email'}
                    error={errors.coach_id}
                    helperText={errors.coach_id}
                    disabled={!!+participantForm?.participant_id}
                    freeSolo={!!+participantForm?.participant_id}
                    multiline
                  />
                )}
                {(!!+participantForm?.participant_id ||
                  !!+participantForm?.id) && (
                  <Button
                    label={t('tournamentInfo')}
                    onClick={this.showTournamentInformation}
                    className={clsx(classes.formSave)}
                  />
                )}
              </>
            }
            showModal={this.showModal}
            openRegistration={
              !this.tournamentIdParam ||
              (selectedTournamentInfo &&
                selectedTournamentInfo.finished !== ENABLED)
            }
            personalAssignedCategories={
              this.tournamentIdParam && personalAssignedCategories
            }
            tournamentData={selectedTournamentInfo}
            fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
            {...{ allRegions }}
            tournamentIdParams={this.tournamentIdParam}
            partFirstLastNameOrder={{
              firstNameVal: PART_FIRST_NAME_VAL,
              lastNameVal: PART_LAST_NAME_VAL,
              firstNameLabel: PART_FIRST_NAME_LABEL,
              lastNameLabel: PART_LAST_NAME_LABEL,
              firstNameNationalVal: PART_FIRST_NAME_NATIONAL_VAL,
              lastNameNationalVal: PART_LAST_NAME_NATIONAL_VAL,
              firstNameNationalLabel: PART_FIRST_NAME_NATIONAL_LABEL,
              lastNameNationalLabel: PART_LAST_NAME_NATIONAL_LABEL
            }}
            onRemoveCategoriesInForm={this.onRemoveCategoriesInForm}
            isOldDesign
          />
        )}
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(AddParticipant))
);
