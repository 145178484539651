import { Fragment, Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';

import LoadingState from '../../components/LoadingState/LoadingState';
import DroppableContent from '../../components/DroppableContent/DroppableContent';
import SideModal from '../../components/Snackbar/SideModal';
import EmptyState from '../../components/EmptyState/EmptyState';
import ActionButton from '../../components/Buttons/ActionButtons';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Modal from '../../components/Modal/Modal';

import {
  fetchTatmisCategories,
  reassignCategories,
  changeModal,
  generateCategoriesOnTatami,
  getTatamisTheme,
  downloadFile,
  exportTournamentTatamiReport
} from '../../helpers/util';
import {
  categoryTypesPresentOnTournament,
  isSmallScreen
} from '../../helpers/selectors';
import { FILTERS_CHECKBOXES } from '../../helpers/constants';

import { styles } from './StylesDragDrop';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
//moves an item from one list to another list
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class DragDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentData: props.tournamentData || {},
      categoryTypes: categoryTypesPresentOnTournament(
        props?.categoryTypes ?? [],
        props?.tournamentData?.category_types ?? []
      ),
      loading: true,
      showModal: false,
      selectedCheckboxes: [],
      droppables: [],
      filteredDroppables: [],
      tatamisTheme: [],
      searchBar: '',
      openModal: false,
      windowWidth: window.innerWidth,
      openModalId: '',
      selCheckbox: ''
    };

    this.fetchTatmisCategories = fetchTatmisCategories.bind(this);
    this.reassignCategories = reassignCategories.bind(this);
    this.changeModal = changeModal.bind(this);
    this.generateCategoriesOnTatami = generateCategoriesOnTatami.bind(this);
    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.exportTournamentTatamiReport = exportTournamentTatamiReport.bind(this);
    this.downloadFile = downloadFile.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoryTypes, tournamentData } = this.props;

    if (prevProps?.categoryTypes !== categoryTypes) {
      const tournamentCat = categoryTypesPresentOnTournament(
        categoryTypes,
        tournamentData?.category_types
      );
      this.setState({ categoryTypes: tournamentCat });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidMount() {
    const { tournamentData } = this.state;
    const { id } = tournamentData;
    const { onGetPageHeaderStatistics } = this.props;

    onGetPageHeaderStatistics();
    this.fetchTatmisCategories(id, (categories) => {
      this.getTatamisTheme();
      this.fetchData(categories);
    });
  }

  onResize = () => this.setState({ windowWidth: window.innerWidth });

  fetchData = (reassignedCategories, cb) => {
    let droppables = {};
    const len = reassignedCategories.length;

    for (let i = 0; i < len; i++) {
      const item = reassignedCategories[i];
      //Matches the IDs of the droppable container to the names of the source arrays stored in the state.

      droppables[`block_${item.block}`] = droppables[`block_${item?.block}`]
        ? [
            ...droppables[`block_${item.block}`],
            {
              [`droppable_${item.tatami_id}`]: item.categories,
              tatami_name: item.tatami_name,
              tatami_id: item.tatami_id,
              theme_id: item.theme_id,
              block_name: item.block_name,
              block_id: item.block
            }
          ]
        : [
            {
              [`droppable_${item.tatami_id}`]: item.categories,
              tatami_name: item.tatami_name,
              tatami_id: item.tatami_id,
              theme_id: item.theme_id,
              block_name: item.block_name,
              block_id: item.block
            }
          ];
    }

    //sort data ASC
    const sortedBlocksASC = Object.entries(droppables).sort((a, b) => {
      if (!a[0].slice(-1)) {
        return 1;
      }
      if (!b[0].slice(-1)) {
        return -1;
      }
      //sort numbers
      return +a[0].slice(-1) - +b[0].slice(-1);
    });

    droppables = sortedBlocksASC.map((it) => it[1]);

    const activeTab = droppables[0]?.[0] && droppables[0][0].tatami_id;

    this.setState(
      {
        filteredDroppables: droppables,
        reassignedCategories,
        activeTab,
        droppables,
        numOfTatamis: len //use this for maxHeight
      },
      () => cb && cb()
    );
  };

  resetCategories = () => {
    const { tournamentData } = this.state;
    this.fetchTatmisCategories(tournamentData.id, (categories) => {
      this.setState({ selectedCheckboxes: [] });
      this.fetchData(categories);
    });
    this.handleCloseModal();
  };

  generateCategoriesAndCloseModal = (evt, id) => {
    this.generateCategoriesOnTatami(evt, id);
    this.handleCloseModal();
  };

  onChangeTab = (tab) => {
    this.setState({ activeTab: tab.tatami_id, selCheckbox: '' });
  };

  findBlock = (data, droppableId) => {
    //find array(tatami block) in array of arrays
    const blocksLen = data.length;

    for (let i = 0; i < blocksLen; i++) {
      const block = data[i];
      const len = block.length;

      for (let j = 0; j < len; j++) {
        const items = data[i][j];

        if (items[[droppableId]]) {
          return i;
        }
      }
    }
  };

  onDragEnd = (result) => {
    const { droppables, filteredDroppables, activeTab } = this.state;
    const { source, destination } = result;

    let droppabesleCopy = [...droppables];
    let filteredDroppablesCopy = [...filteredDroppables];
    const droppableId = `droppable_${activeTab}`;
    const findBlockIdx = this.findBlock(filteredDroppablesCopy, droppableId);
    const droppableIdx = filteredDroppablesCopy[findBlockIdx].findIndex(
      (it) => it[droppableId]
    );

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const reorderedList = reorder(
        droppables[findBlockIdx][droppableIdx][droppableId],
        source.index,
        destination.index
      );

      const reorderedListFiltered = reorder(
        filteredDroppables[findBlockIdx][droppableIdx][droppableId],
        source.index,
        destination.index
      );

      filteredDroppablesCopy[findBlockIdx][droppableIdx][droppableId] = [
        ...reorderedListFiltered
      ];
      droppabesleCopy[findBlockIdx][droppableIdx][droppableId] = [
        ...reorderedList
      ];
    } else {
      //dragging a category to the tab that is active - dropableTabActive
      if (destination.droppableId === droppableId) return;
      else {
        //destination droppable index
        const destinationFindBlockIdx = this.findBlock(
          filteredDroppablesCopy,
          destination.droppableId
        );
        const destinsationDroppableIdx = filteredDroppablesCopy[
          destinationFindBlockIdx
        ].findIndex((it) => it[destination.droppableId]);
        const result = move(
          filteredDroppablesCopy[findBlockIdx][droppableIdx][droppableId],
          filteredDroppablesCopy[destinationFindBlockIdx][
            destinsationDroppableIdx
          ][destination.droppableId],
          source,
          destination
        );

        // remove from droppables moved categories based on sourceFrom/ destinationTo
        const movedItem =
          filteredDroppablesCopy[findBlockIdx][droppableIdx][droppableId][
            source.index
          ];
        const findIdx = droppables[findBlockIdx][droppableIdx][
          droppableId
        ].findIndex((it) => +it.category_id === +movedItem.category_id);

        droppabesleCopy[findBlockIdx][droppableIdx][droppableId].splice(
          findIdx,
          1
        );
        droppabesleCopy[destinationFindBlockIdx][destinsationDroppableIdx][
          destination.droppableId
        ].unshift(movedItem);

        filteredDroppablesCopy[findBlockIdx][droppableIdx][droppableId] =
          result.droppable_active;
        filteredDroppablesCopy[destinationFindBlockIdx][
          destinsationDroppableIdx
        ][destination.droppableId] = result[destination.droppableId];
      }
    }

    this.setState({
      droppables: droppabesleCopy,
      filteredDroppables: filteredDroppablesCopy
    });
  };

  hideSnackBar = () => this.setState({ showModal: false });

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      const blockLen = this.state.droppables.length;
      let allBlocks = [];

      if (checkedValues.length > 0) {
        let genderArray = [];
        let ageArray = [];
        let typeArray = [];

        checkedValues.map((it) => {
          if (it.gender) genderArray = [...genderArray, it.gender];
          if (it.age) ageArray = [...ageArray, it.age];
          if (it.type) typeArray = [...typeArray, it.id];
          return true;
        });

        for (let y = 0; y < blockLen; y++) {
          const len = this.state.droppables[y].length;
          let filteredCategoriesPerTatami = [];

          for (let i = 0; i < len; i++) {
            let categories = [];
            const droppableId = `droppable_${this.state.droppables[y][i].tatami_id}`;
            const categoriesPerTatamiLen =
              this.state.droppables[y][i][droppableId].length;

            for (let j = 0; j < categoriesPerTatamiLen; j++) {
              const category = this.state.droppables[y][i][droppableId][j];

              const filterByGenderList =
                genderArray.length > 0
                  ? genderArray.some((el) => category.category_gender === el)
                  : category;

              const filterByAgeList =
                ageArray.length > 0
                  ? ageArray.some((el) =>
                      category.category_age_to
                        ? +category.category_age_from >= el[0] &&
                          +category.category_age_to <= el[1]
                        : +category.category_age_from >= el[0] &&
                          +category.category_age_from <= el[1]
                    )
                  : category;

              const filterByTypeList =
                typeArray.length > 0
                  ? typeArray.some((el) => +category.category_type === +el)
                  : category;

              if (filterByGenderList && filterByAgeList && filterByTypeList) {
                categories = [...categories, category];
              }
            }

            filteredCategoriesPerTatami = [
              ...filteredCategoriesPerTatami,
              { ...this.state.droppables[y][i], [droppableId]: categories }
            ];
          }

          allBlocks = [...allBlocks, filteredCategoriesPerTatami];
        }
      } else {
        allBlocks = [...this.state.droppables];
      }

      allBlocks = this.onTxtSearch(this.state.searchBar, allBlocks);

      this.setState({ filteredDroppables: allBlocks });
    });
  };

  onCategoryTatamiSort = () => {
    const { droppables, filteredDroppables, activeTab, selCheckbox } =
      this.state;
    const droppableId = `droppable_${activeTab}`;
    const findBlockIdx = this.findBlock(droppables, droppableId);
    const droppableIdx = droppables[findBlockIdx].findIndex(
      (it) => it[droppableId]
    );
    const genderOrder = { M: 0, F: 1 };

    const sortCategories_FMFM = (a, b, genderOrder) => {
      if (b.category_type !== a.category_type) {
        if (+a?.category_type === 2) {
          return -1;
        } else if (+b?.category_type === 2) {
          return 1;
        } else {
          return a.category_type - b.category_type;
        }
      } else if (a.category_age_from !== b.category_age_from) {
        return a.category_age_from - b.category_age_from;
      } else if (a.category_gender !== b.category_gender) {
        return genderOrder[b.category_gender] - genderOrder[a.category_gender];
      } else if (a.origin_category_order !== b.origin_category_order) {
        return sortByOriginCategoryOrder(a, b);
      }
    };

    const sortByOriginCategoryOrder = (a, b) => {
      if (a.origin_category_order > b.origin_category_order) {
        return 1;
      } else if (a.origin_category_order < b.origin_category_order) {
        return -1;
      }
    };

    const sortCategories_MFMF_FMFM = (a, b, genderOrder, type) => {
      if (b.category_type !== a.category_type) {
        if (+a?.category_type === 2) {
          return -1;
        } else if (+b?.category_type === 2) {
          return 1;
        } else {
          return a.category_type - b.category_type;
        }
      } else if (a.category_age_from !== b.category_age_from) {
        return a.category_age_from - b.category_age_from;
      } else if (a.category_weight > b.category_weight) {
        return 1;
      } else if (a.category_weight < b.category_weight) {
        return -1;
      } else if (a.category_gender !== b.category_gender && type === 'MFMF') {
        return genderOrder[a.category_gender] - genderOrder[b.category_gender];
      } else if (a.category_gender !== b.category_gender && type === 'FMFM') {
        return genderOrder[b.category_gender] - genderOrder[a.category_gender];
      }
    };

    const customSort = (a, b, selCheckbox, genderOrder) => {
      switch (selCheckbox) {
        case 'MFMF':
          return sortCategories_MFMF_FMFM(a, b, genderOrder, 'MFMF');
        case 'MMFF':
          return sortByOriginCategoryOrder(a, b);
        case 'FFMM':
          return sortCategories_FMFM(a, b, genderOrder);
        case 'FMFM':
          return sortCategories_MFMF_FMFM(a, b, genderOrder, 'FMFM');
        default:
          return 0; // Default value if selCheckbox doesn't match any case
      }
    };

    const newOrder1 = droppables[findBlockIdx][droppableIdx][droppableId].sort(
      (a, b) => customSort(a, b, selCheckbox, genderOrder)
    );

    const newOrder2 = filteredDroppables[findBlockIdx][droppableIdx][
      droppableId
    ].sort((a, b) => customSort(a, b, selCheckbox, genderOrder));

    droppables[findBlockIdx][droppableIdx][droppableId] = newOrder1;
    filteredDroppables[findBlockIdx][droppableIdx][droppableId] = newOrder2;

    this.setState({
      droppables,
      filteredDroppables
    });

    this.handleCloseModal();
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value }, () => this.onSelectCheckbox({}));
  };

  onTxtSearch = (value, data) => {
    const blockLen = data.length;
    let allBlocks = [];

    for (let y = 0; y < blockLen; y++) {
      const len = data[y].length;
      let filteredCategoriesPerTatami = [];

      for (let i = 0; i < len; i++) {
        let categories = [];
        const droppableId = `droppable_${data[y][i].tatami_id}`;
        const categoriesPerTatamiLen = data[y][i][droppableId].length;

        for (let j = 0; j < categoriesPerTatamiLen; j++) {
          const category = data[y][i][droppableId][j];

          if (
            category.category_name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          ) {
            categories = [...categories, category];
          }
        }

        filteredCategoriesPerTatami = [
          ...filteredCategoriesPerTatami,
          { ...data[y][i], [droppableId]: categories }
        ];
      }

      allBlocks = [...allBlocks, filteredCategoriesPerTatami];
    }

    return allBlocks;
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () => this.onSelectCheckbox({}));
  };

  handleOpenModal = (id) => {
    this.setState({ openModal: true, openModalId: id });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, openModalId: null });
  };

  modalOnClickToggle = (openModalId, id) => {
    switch (openModalId) {
      case 'resetCategories':
        return this.resetCategories;
      case 'generateCategories':
        return (evt) => this.generateCategoriesAndCloseModal(evt, id);
      default:
        return null;
    }
  };

  selectCheckbox = (evt) => {
    const { value } = evt.target;

    this.setState({ selCheckbox: value }, () => this.onCategoryTatamiSort());
  };

  render() {
    const {
      droppables,
      loading,
      success,
      showModal,
      modalInfo,
      selectedCheckboxes,
      windowWidth,
      tournamentData,
      categoryTypes,
      activeTab,
      filteredDroppables,
      searchBar,
      numOfTatamis,
      tatamisTheme,
      openModal,
      openModalId,
      selCheckbox
    } = this.state;
    const { t, classes, shouldDisableEditing } = this.props;

    const buttons = [
      {
        label: t('resetAll'),
        onClick: () => this.handleOpenModal('resetCategories'),
        id: 'resetCategories'
      },
      {
        label: t('generate'),
        onClick: () => this.handleOpenModal('generateCategories'),
        id: 'generateCategories'
      },
      {
        onClick: (evt) =>
          this.reassignCategories(evt, tournamentData.id, droppables),
        isSaveBtn: true,
        label: t('save')
      },
      {
        label: t('report'),
        onClick: () => this.exportTournamentTatamiReport(tournamentData.id)
      }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : Array.isArray(filteredDroppables) &&
          filteredDroppables.length > 0 ? (
          <>
            {!shouldDisableEditing && (
              <div className={clsx(classes.flex, classes.buttonWrapper)}>
                {buttons.map(({ label, isSaveBtn, onClick }, index) => (
                  <Fragment key={index}>
                    <ActionButton
                      onClick={onClick}
                      className={classes.button}
                      label={label}
                      isSaveBtn={isSaveBtn}
                    />
                  </Fragment>
                ))}
              </div>
            )}
            <span
              className={clsx(
                classes.flex,
                classes.wrap,
                classes.marginTop1,
                !isSmallScreen(windowWidth) && classes.centerHorizontally
              )}>
              {FILTERS_CHECKBOXES(categoryTypes).map((group, groupIdx) => (
                <span
                  key={groupIdx}
                  className={clsx(
                    classes.flex,
                    classes.wrap,
                    classes.marginRight1
                  )}>
                  {group.map((item, idx) => (
                    <Fragment key={idx}>
                      <CheckboxBtn
                        formControlClassName={classes.paper}
                        classControlLabel={classes.checkboxItem}
                        className={classes.checkboxIcon}
                        checked={selectedCheckboxes.some(
                          (it) => it.id === item.id
                        )}
                        onChange={() => this.onSelectCheckbox(item)}
                        label={item.name}
                        labelPlacement="start"
                      />
                    </Fragment>
                  ))}
                </span>
              ))}
            </span>
            <Modal
              open={openModal}
              onClick={this.modalOnClickToggle(openModalId, tournamentData.id)}
              close={this.handleCloseModal}
              buttonPurpose={t('yes')}
              dialogContent={t('provideThisAction')}
              closeButtonlabel={t('no')}
            />
            <div className={clsx(classes.flex, classes.centerHorizontally)}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <DroppableContent
                  {...{ filteredDroppables }}
                  {...{ windowWidth, categoryTypes }}
                  onChangeTab={this.onChangeTab}
                  {...{ activeTab }}
                  onSearch={this.onSearch}
                  onClearSearch={this.onClearSearch}
                  {...{ searchBar }}
                  {...{ numOfTatamis }}
                  findBlock={this.findBlock}
                  {...{ tatamisTheme }}
                  selectCheckbox={this.selectCheckbox}
                  selCheckbox={selCheckbox}
                />
              </DragDropContext>
            </div>
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(DragDrop));
