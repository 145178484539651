import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  tatamiWrapper: {
    background: '#fff',
    padding: '0 0.5rem 0.5rem 0.5rem',
    marginTop: '0.5rem'
  },

  paper: {
    width: '12em',
    height: '4em',
    justifyContent: 'center',
    marginTop: '0.5rem',
    marginRight: '0.5rem',
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 10%), 0 1px 3px 0 rgb(63 63 68 / 20%)'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  tatami: {
    position: 'relative',
    borderRadius: '2rem',
    padding: '0.2rem',
    width: '4em',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 500
  },

  noEvent: {
    pointerEvents: 'none'
  },

  verticallyCentered: {
    alignItems: 'center'
  },

  left: {
    marginLeft: 'auto'
  },

  marginTop1and5: {
    marginTop: '1.5rem'
  },

  marginRight0And5: {
    marginRight: '0.5rem'
  },

  marginLeft0And5: {
    marginLeft: '0.5rem'
  },

  videoIc: {
    height: '0.7em',
    width: '0.7em',
    position: 'absolute',
    top: '0.6rem',
    left: '0.7rem'
  },

  btn: {
    width: '5.25em'
  },

  badge: {
    background: '#dc3545',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    padding: '0.35em 0.65em',
    fontSize: '.75em',
    fontWeight: 700,
    lineHeight: 1,
    color: '#fff',
    whiteSpace: 'nowrap',
    borderRadius: '50%',
    left: '100%',
    top: 0,
    zIndex: 1
  },

  relative: {
    position: 'relative'
  }
}));

export const tatamiStyle = (bg) => ({
  color: '#fff',
  background: bg || '#808080'
});
