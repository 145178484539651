import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  marginBottom40: {
    marginBottom: '40px'
  },
  goBackArrow: {
    width: 'fit-content'
  },
  heightFitContent: {
    height: 'fit-content'
  },
  left: {
    marginLeft: 'auto'
  },

  paper: {
    backgroundColor: '#fff',
    padding: '.65rem'
  },

  paddingRight: {
    paddingRight: '.65rem'
  },

  paddingLeft: {
    paddingLeft: '.65rem'
  },

  column: {
    flexDirection: 'column'
  },
  fullWidth: {
    width: '100%'
  },
  img: {
    objectFit: 'cover',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    height: '6em',
    borderRadius: '4px'
  },
  panelWithoutImgBtn: {
    width: 'calc(100% - 13em)'
  },
  marginLeftRight: {
    padding: '0 .5em'
  },
  paddingBottom: {
    paddingBottom: '.5em'
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  secondaryTxt: {
    paddingRight: '.2em',
    whiteSpace: 'break-spaces',
    color: '#92979F'
  },

  paperShadow: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    '@media (min-width:901px)': {
      borderRadius: '4px 0 0 4px'
    }
  },
  borderRightLargeViewport: {
    '@media (min-width:901px)': {
      borderRight: ' 1px solid rgba(0, 29, 61, 0.5)'
    }
  },
  textAlign: {
    textAlign: 'center'
  }
}));

export const useStylesPageHeader = makeStyles((theme) => ({
  grid: (props) => {
    return {
      display: 'grid',
      '@media (min-width:1024px)': {
        gridTemplateColumns: '' + props?.map(() => '1fr').join(' ') + '',
        ...(props?.length > 1 ? { gridColumnGap: '1rem' } : {})
      },
      '@media (max-width:1023px)': {
        gridRowGap: '1rem'
      }
    };
  }
}));
