import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import parse from 'html-react-parser';

import { ReactComponent as ExitIc } from 'assets/img/exit.svg';
import { ReactComponent as QuestionMarkIc } from 'assets/img/question_mark.svg';

import { PRODUCT_LANG, LANG, LABEL } from 'helpers/constants';
import { selectedValue } from 'helpers/selectors';
import { onLogout } from 'helpers/util';

import Filter from 'components/Filter/Filter';
import { AuthContext } from 'AuthContext';

import { useStyles } from './Styles';
import QuestionMarkNew from 'components/QuestionMarkNewDesign/QuestionMarkNew';
import { ReactComponent as Exit } from 'assets/img/new_close_modal.svg';
import { Tooltip } from '@material-ui/core';

const TopbarP2 = (props) => {
  const { showExitBtn, breadcrumbs } = props;

  const classes = useStyles();
  let navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const lang = localStorage.getItem('i18nextLng');

  const [nav, setNav] = useState(false);

  const switchLang = (evt, item) => {
    i18n.changeLanguage(item.lang);
  };
  const handleResize = () => {
    setViewportWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="top">
      {breadcrumbs.map((breadcrumb, breadcrumbIdx) => {
        return (
          <span key={breadcrumbIdx} className={classes.breadcrumbsDefault}>
            <Link
              to={breadcrumb.href}
              className={clsx(
                location.pathname === breadcrumb.href
                  ? classes.breadcrumbsActive
                  : classes.breadcrumbsDefault
              )}>
              {breadcrumb.label}
            </Link>
            {breadcrumbIdx !== breadcrumbs.length - 1 && (
              <span className={classes.marginRL0Dot5}>{parse(`&#62;`)}</span>
            )}
          </span>
        );
      })}
      <div
        className={clsx(classes.flex, classes.fullWidth, classes.marginTB1LR0)}>
        <div className={classes.flex}>
          {showExitBtn && (
            <Tooltip arrow title={t('exit')}>
              <div>
                <ExitIc
                  className={clsx(
                    classes.flex,
                    classes.radiusIc,
                    classes.onHover,
                    classes.marginRight1,
                    classes.leftAuto
                  )}
                  onClick={() => {
                    onLogout(authContext, () =>
                      navigate('/sign-in', {
                        state: { prevUrl: location.pathname }
                      })
                    );
                  }}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <Filter
          className={clsx(classes.marginRight1, classes.leftAuto, classes.filterInput)}
          classes={{
            inputRoot: clsx(classes.z10),
            hasPopupIcon: classes.dropdownIcon
          }}
          shouldLookLikeBtn={true}
          options={PRODUCT_LANG}
          onChange={switchLang}
          item={LABEL}
          inputProps={{
            disableUnderline: true,
            readOnly: true,
            className: classes.langText
          }}
          value={
            selectedValue(
              PRODUCT_LANG,
              LANG,
              localStorage.getItem('i18nextLng') || 'en'
            ) || ''
          }
          freeSolo={true}
          langListbox={true}
        />
        <Tooltip arrow title={t('help')}>
          <div>
            <QuestionMarkNew className={clsx(classes.radiusIc)}/>
          </div>
        </Tooltip>
        <Tooltip arrow title={t('exit')}>
          <div className={clsx(classes.marginLeft1)}>
            <Exit
              className={clsx(
                classes.flex,
                classes.radiusIc,
                classes.onHover,
                classes.buttonSize
              )}
              onClick={() => {
                onLogout(authContext, () =>
                  navigate('/sign-in', {
                    state: { prevUrl: location.pathname }
                  })
                );
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default TopbarP2;
