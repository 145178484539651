import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { MAX_FILE_SIZE } from '../../../helpers/constants';
import Alert from '../../Alerts/Alert';
import useStyles from './StylesDropzone';

const Dropzone = (props) => {
  const { onDrop, fileData, isCertificateRelated, removeFile, preview } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    accept: isCertificateRelated && 'image/jpg, image/jpeg, image/png',
    minSize: 0,
    MAX_FILE_SIZE,
    onDrop,
    multiple: false
  });
  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_FILE_SIZE;
  const alertMsg = (message) => (
    <span className={clsx(classes.flex, classes.alertWrapper)}>
      <Alert className={classes.alertBanner} {...{ message }} />
    </span>
  );

  return (
    <>
      {rejectedFiles.length > 0 &&
        !isFileTooLarge &&
        alertMsg(t('rejectedFileError'))}
      {isFileTooLarge && alertMsg(t('fileTooLargeMsg'))}
      <div
        className={clsx(
          classes.flex,
          classes.container,
          classes.centerHorizontally,
          isDragReject && classes.rejectFile
        )}
        {...getRootProps()}>
        <input {...getInputProps()} />
        {fileData.files.map(
          (file) =>
            !isDragReject && (
              <Fragment key={file.name}>
                <span className={classes.ellipsis}>{preview(file)}</span>
                <div
                  className={clsx(classes.removeButton, classes.textLink)}
                  onClick={(evt) => evt.stopPropagation()}>
                  <span onClick={() => removeFile(file)}>{t('remove')}</span>
                </div>
              </Fragment>
            )
        )}
        {!isDragActive && fileData.files.length === 0 && (
          <span className={clsx(classes.informativeMsg)}>
            {t('dragMsg')}
            <span className={clsx(classes.textLink, classes.browseText)}>
              {t('import')}
            </span>
          </span>
        )}
        {isDragReject && <p>{t('isDragRejectMsg')}</p>}
        {isDragActive && !isDragReject && fileData.files.length === 0 && (
          <p>{t('dropFile')}</p>
        )}
      </div>
    </>
  );
};

export default Dropzone;
