/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { List, ListItem } from '@material-ui/core';
import { useStyles } from './StylesSidebarNav';

const SidebarNav = ({ pages, children, onClose }) => {
  const classes = useStyles();

  return (
    <List>
      {pages.map(({ title, href, icon, id }) => (
        <NavLink
          end
          onClick={() => {
            onClose();
            document.querySelector('#top') &&
              window.scroll({
                top: document.querySelector('#top').offsetTop,
                behavior: 'smooth'
              });
          }}
          to={href}
          key={id}
          className={({ isActive }) =>
            clsx(classes.navLink, isActive && classes.activeLink)
          }>
          <ListItem className={classes.item} disableGutters id={id}>
            <div className={clsx(classes.materialUiIcons, classes.icon)}>
              {icon}
            </div>
            {title}
          </ListItem>
        </NavLink>
      ))}
      {children}
    </List>
  );
};

export default SidebarNav;
