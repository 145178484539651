import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    padding: '0 .5rem'
  },

  flex: {
    display: 'flex'
  },

  grid: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: '0.5rem'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '0.5rem'
    }
  },

  formSave: {
    height: '3.35em',
    '@media (min-width:1024px)': { width: 'calc((100%  - 1.5rem)/4)' },
    '@media (min-width:768px)': {
      gridColumn: '1 / -1'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      width: 'calc((100%  - .5rem)/2)'
    },
    '@media (max-width:767px)': {
      width: '100%'
    },
    display: 'flex',
    marginLeft: 'auto'
  },

  formAvatar: {
    '@media (min-width:1024px)': {
      gridRow: 'span 6',
      width: '16em',
      height: '18.5em'
    },
    '@media (max-width:1023px)': {
      gridRow: 'span 2',
      height: '8.5em',
      width: '7em',
      justifySelf: 'center'
    },
    '@media (max-width:767px)': {
      marginBottom: '1rem'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#ff0000'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  maxHeightTxtInput: {
    height: '5em'
  }
}));

export const bgColor = (isOldDesign) => ({
  backgroundColor: `${!isOldDesign ? '#2D63EE' : '#001D3D'}`
});
