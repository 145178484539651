import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { Divider, TextField } from '@material-ui/core';

import { ReactComponent as Bronze } from '../../assets/img/bronze.svg';

import {
  KEEP_EDITING,
  CLOSE_DISCARD,
  MODAL_EDIT_IC,
  UPDATE_CATEGORY,
  UPDATE_GROUP,
  DELETE_ICON
} from '../../helpers/constants';
import { convertMs, setCategoryName } from '../../helpers/selectors';
import {
  fetchCategoriesPerTourn,
  addCategoriesToTourn,
  updateTournamentCategoryTime,
  fetchKataNames,
  updateTournKataNames,
  updateTournamentCategoryKataFinal,
  exportTournamentCategories,
  downloadFile,
  textChangeHandler,
  updateCategoryGroup,
  updateCategory,
  changeModal
} from '../../helpers/util';

import CategoriesBlock from '../../components/AddTournamentCategories/AddTournamentCategories';
import Modal from '../../components/Modal/ModalNewDesign';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import AddCategory from '../../components/AddCategory/AddCategory';

import styles from './style';

const STATISTICS_DETAILS = (t, statistics) => ({
  info: [
    {
      label: [
        t('total'),
        t('categories').toLowerCase(),
        `(${t('appointed')})`
      ].join(' '),
      name: statistics.assignedCount
    },
    {
      label: [
        t('total'),
        t('categories').toLowerCase(),
        `(${t('filled')})`
      ].join(' '),
      name: statistics.filledCount
    },
    {
      label: [t('redCategories'), `(1 ${t('human').toLowerCase()})`].join(' '),
      name: statistics.issueCount
    }
  ]
});

const EXTRA_TIME = (t, item) => [
  {
    title: t('preliminaryRounds'),
    subtitle: t('mainAndExtraTime'),
    time: [
      {
        label: 'MT',
        value: item.main_time_ms && convertMs(item.main_time_ms, true),
        param: 'main_time_ms'
      },
      {
        label: 'ET',
        value: item.extra_time_ms && convertMs(item.extra_time_ms, true),
        param: 'extra_time_ms'
      }
    ]
  },
  {
    title: t('semifinalAndFinals'),
    subtitle: t('mainAndExtraTime'),
    time: [
      {
        label: 'FMT',
        value:
          item.final_main_time_ms && convertMs(item.final_main_time_ms, true),
        param: 'final_main_time_ms'
      },
      {
        label: 'FET',
        value:
          item.final_extra_time_ms && convertMs(item.final_extra_time_ms, true),
        param: 'final_extra_time_ms'
      }
    ]
  }
];

class TournamentAddCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categoryTypes: props?.categoryTypes ?? [],
      filteredCategories: [],
      kata_names: [],
      selectedCheckboxes: [],
      errors: {},
      role: localStorage.getItem('role'),
      langOnLoad: localStorage.getItem('i18nextLng'),
      isCategoryOwner: localStorage.getItem('user_id'),
      open: false,
      selectedKataNames: {},
      modalData: {},
      searchBar: '',
      isSending: false,
      loading: true
    };

    this.fetchCategoriesPerTourn = fetchCategoriesPerTourn.bind(this);
    this.addCategoriesToTourn = addCategoriesToTourn.bind(this);
    this.updateTournamentCategoryTime = updateTournamentCategoryTime.bind(this);
    this.fetchKataNames = fetchKataNames.bind(this);
    this.updateTournKataNames = updateTournKataNames.bind(this);
    this.updateTournamentCategoryKataFinal =
      updateTournamentCategoryKataFinal.bind(this);
    this.exportTournamentCategories = exportTournamentCategories.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.updateCategoryGroup = updateCategoryGroup.bind(this);
    this.updateCategory = updateCategory.bind(this);
    this.changeModal = changeModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEditing, selectedKataNames, langOnLoad, statistics } = this.state;
    const { categoryTypes, t, onGetPageHeaderStatistics } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      selectedKataNames.round_1 &&
      selectedKataNames !== prevState.selectedKataNames
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      onGetPageHeaderStatistics(STATISTICS_DETAILS(t, statistics));

      this.setState({ langOnLoad: currentLang });
    }

    if (prevProps?.categoryTypes !== categoryTypes) {
      this.setState({ categoryTypes: categoryTypes });
    }
  }

  componentDidMount() {
    this.fetchKataNames();
    this.fetchMainDataTournCategories(true);
  }

  selectKataHandler = (category) => {
    const { tournamentId } = this.props;

    if (category.tournament_presence !== 0) {
      category.tournament_presence = 0;
    } else {
      category.tournament_presence = +tournamentId;
    }

    this.forceUpdate();
  };

  fetchMainDataTournCategories = (isFilteringUnneeded, successMsg) => {
    const { tournamentId, onGetPageHeaderStatistics, t, tournamentData } =
      this.props;

    this.fetchCategoriesPerTourn(
      tournamentId,
      () => {
        const categories = this.state.categories;
        const len = categories.length;
        let newCategories = [];
        let filteredCategories = [];
        let countForStatistics = {
          assignedCount: 0,
          filledCount: 0,
          issueCount: 0
        };

        for (let i = 0; i < len; i++) {
          const category = categories[i];
          let presentCategory = {};
          const updateNewCateoryTime = (category) => {
            //used for weighted categories
            return {
              bronze_fight_enabled: category.bronze_fight_enabled,
              ...(!presentCategory.main_time_ms ||
              category.main_time_ms === presentCategory.main_time_ms
                ? {
                    main_time_ms: category.main_time_ms,
                    main_time_ms_in_sec: category.main_time_ms / 1000
                  }
                : {}),
              ...(!presentCategory.final_main_time_ms ||
              category.final_main_time_ms === presentCategory.final_main_time_ms
                ? {
                    final_main_time_ms: category.final_main_time_ms,
                    final_main_time_ms_in_sec:
                      category.final_main_time_ms / 1000
                  }
                : {}),
              ...(!presentCategory.extra_time_ms ||
              category.extra_time_ms === presentCategory.extra_time_ms
                ? {
                    extra_time_ms: category.extra_time_ms,
                    extra_time_ms_in_sec: category.extra_time_ms / 1000
                  }
                : {}),
              ...(!presentCategory.final_extra_time_ms ||
              category.final_extra_time_ms ===
                presentCategory.final_extra_time_ms
                ? {
                    final_extra_time_ms: category.final_extra_time_ms,
                    final_extra_time_ms_in_sec:
                      category.final_extra_time_ms / 1000
                  }
                : {})
            };
          };

          if (category.weight_f) {
            const weightFLen = category.weight_f.length;

            for (let j = 0; j < weightFLen; j++) {
              const weight_f = category.weight_f[j];

              if (!!+weight_f.tournament_presence) {
                presentCategory = updateNewCateoryTime(weight_f);
                countForStatistics.assignedCount += 1;

                if (!!+weight_f.participants_count) {
                  countForStatistics.filledCount += 1;
                }

                if (+weight_f.participants_count === 1) {
                  countForStatistics.issueCount += 1;
                }
              }
            }
          }

          if (category.weight_t) {
            const weightTLen = category.weight_t.length;

            for (let j = 0; j < weightTLen; j++) {
              const weight_t = category.weight_t[j];

              if (!!+weight_t.tournament_presence) {
                presentCategory = updateNewCateoryTime(weight_t);
                countForStatistics.assignedCount += 1;

                if (!!+weight_t.participants_count) {
                  countForStatistics.filledCount += 1;
                }

                if (+weight_t.participants_count === 1) {
                  countForStatistics.issueCount += 1;
                }
              }
            }
          }

          if (!!+category?.kata?.tournament_presence) {
            countForStatistics.assignedCount += 1;

            if (+category?.kata?.participants_count !== 0) {
              countForStatistics.filledCount += 1;
            }

            if (+category?.kata?.participants_count === 1) {
              countForStatistics.issueCount += 1;
            }
          }

          if (+category?.type === 5) {
            presentCategory = {
              bronze_fight_enabled: category?.kata?.bronze_fight_enabled
            };
          }

          newCategories = [
            ...newCategories,
            Object.keys(presentCategory).length !== 0
              ? { ...category, ...presentCategory }
              : category
          ];
        }

        this.setState({ statistics: countForStatistics }, () => {
          const { statistics } = this.state;
          onGetPageHeaderStatistics(STATISTICS_DETAILS(t, statistics));
        });

        if (isFilteringUnneeded) {
          filteredCategories = [...newCategories];

          this.setState({
            loading: false,
            filteredCategories,
            categories: newCategories
          });
        } else {
          //using setState here when adding new KUMITE will show input to set time
          this.setState({ loading: false, categories: newCategories }, () =>
            this.onSelectCheckbox({})
          );
        }
      },
      successMsg
    );
  };

  //will perform both the filter and search action at the same time, when selecting a filter or when searching
  onTxtSearch = (value, categories) => {
    let newCategories = [];
    const len = categories && categories.length;

    for (let i = 0; i < len; i++) {
      const item = categories[i];
      const blockName = setCategoryName(item);

      if (
        [blockName, item.g_name].some((it) =>
          it.toLowerCase().includes(value.toLowerCase().trim())
        )
      ) {
        newCategories = [...newCategories, item];
      }
    }

    return newCategories;
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value }, () => this.onSelectCheckbox({}));
  };

  selectCategoryHandler = (weight_t, weight_f, weightIndex) => {
    if (!!+weight_t?.[weightIndex]?.tournament_presence) {
      weight_t[weightIndex].tournament_presence = 0;

      if (weight_f) {
        weight_f[weightIndex].tournament_presence = 0;

        let num = weightIndex;

        while (num--) {
          if (+!!weight_t?.[num]?.tournament_presence) {
            weight_f[num].tournament_presence = 1;

            break;
          }
        }
      }
    } else {
      weight_t[weightIndex].tournament_presence = 1;
      weight_t[weightIndex].justSelected = true;

      if (weight_f) {
        weight_f[weightIndex].tournament_presence = 1;
      }
    }

    this.forceUpdate();
  };

  addCategorieshandler = () => {
    const { categories, filteredCategories } = this.state;
    const { tournamentId } = this.props;
    let categoriesIds = [];
    let copyCategories = [...categories];
    let copyFilteredCategories = [...filteredCategories];

    //take all our needed categories (from weight from, weight to and kata)
    for (const [index, category] of categories.entries()) {
      if (!!+category?.kata?.tournament_presence) {
        categoriesIds = [...categoriesIds, category.kata.category_id];
      }

      //check if weight from and weight to are not null
      if (category.weight_f) {
        let num = category.weight_f?.length;

        while (num--) {
          if (!!+category?.weight_f?.[num]?.tournament_presence) {
            categoriesIds = [
              ...categoriesIds,
              category?.weight_f?.[num].category_id
            ];

            break;
          }
        }

        let plusCategories = category.weight_f?.filter(
          (it) => !!+it?.tournament_presence
        );

        if (plusCategories.length > 0)
          categoriesIds = [
            ...categoriesIds,
            plusCategories[plusCategories.length - 1].category_id
          ];
      }

      if (category.weight_t) {
        for (let weight of category.weight_t) {
          if (!!+weight?.tournament_presence)
            categoriesIds = [...categoriesIds, weight.category_id];
        }
      }
    }

    const body = JSON.stringify({
      tournament_id: tournamentId,
      categories: categoriesIds
    });

    this.addCategoriesToTourn(body, (successMsg) =>
      this.fetchMainDataTournCategories(null, successMsg)
    );
  };

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes, isCategoryOwner, role } = this.state;
    const { tournamentData } = this.props;
    const currentOwner =
      role === 'administrator' ? tournamentData?.organizer_id : isCategoryOwner;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      let filteredCategories = [];

      if (checkedValues.length > 0) {
        let genderArray = [];
        let ageArray = [];
        let typeArray = [];
        let ownerList = [];
        let assignList = [];

        checkedValues.map((it) => {
          if (it.gender) genderArray = [...genderArray, it.gender];
          if (it.age) ageArray = [...ageArray, it.age];
          if (it.type) typeArray = [...typeArray, it.id];
          if (it.owner) ownerList = [...ownerList, it.owner];
          if (it.tournament_presence)
            assignList = [...assignList, it.tournament_presence];
          return true;
        });

        const categoriesLen =
          this.state.categories && this.state.categories.length;

        for (let i = 0; i < categoriesLen; i++) {
          const category = this.state.categories[i];

          const filterByAssignList =
            assignList.length > 0
              ? assignList.some((el) => {
                  if (category.kata) {
                    return +category.kata.tournament_presence === +el;
                  } else if (category.weight_t) {
                    if (+el === 0) {
                      return category.weight_t.every(
                        (kumite_cat) => +kumite_cat.tournament_presence === +el
                      );
                    } else if (+el === 1) {
                      return category.weight_t.some(
                        (kumite_cat) => +kumite_cat.tournament_presence === +el
                      );
                    }
                  } else {
                    return false;
                  }
                })
              : category;

          const filterByGenderList =
            genderArray.length > 0
              ? genderArray.some((el) => category.gender === el)
              : category;

          const filterByAgeList =
            ageArray.length > 0
              ? ageArray.some((el) =>
                  category.age_to
                    ? +category.age_from >= el[0] && +category.age_to <= el[1]
                    : +category.age_from >= el[0] && +category.age_from <= el[1]
                )
              : category;

          const filterByTypeList =
            typeArray.length > 0
              ? typeArray.some((el) => +category?.type === +el)
              : category;

          const filterByOwnerList =
            ownerList.length > 0
              ? ownerList.some(
                  (el) =>
                    (+el === 0 && category.master_id !== isCategoryOwner) ||
                    (+el === 1 && category.master_id === currentOwner)
                )
              : category;

          if (
            filterByGenderList &&
            filterByAgeList &&
            filterByTypeList &&
            filterByOwnerList &&
            filterByAssignList
          ) {
            filteredCategories = [...filteredCategories, category];
          }
        }
      } else filteredCategories = [...this.state.categories];

      filteredCategories = this.onTxtSearch(
        this.state.searchBar,
        filteredCategories
      );

      this.setState({
        filteredCategories,
        errors:
          {} /*discard errors when filtering or searching because we show the default value of the time input*/
      });
    });
  };

  onTimeEdit = (time, val, param) => {
    const { errors, modalData } = this.state;
    const { t } = this.props;
    let copyErrors = { ...errors };
    let copyModalData = { ...modalData };
    let newTime;
    let timeInSeconds = 0;

    if (time === null) {
      copyErrors[param] = t('required');
    } else if (isNaN(time)) {
      copyErrors[param] = t('invalidTime');
    } else {
      delete copyErrors[param];

      //use Hrs as min, min as sec
      newTime = (time.getHours() * 60 + time.getMinutes()) * 1000;
      timeInSeconds = time.getHours() * 60 + time.getMinutes();
    }

    copyModalData = {
      ...copyModalData,
      [param]: newTime,
      [`${param}_in_sec`]: timeInSeconds
    };

    this.setState({
      errors: copyErrors,
      modalData: copyModalData
    });
  };

  txtChange = (evt) => {
    const { modalData } = this.state;
    const { name, value } = evt.target;
    let copyModalData = { ...modalData };

    copyModalData.kata = {
      ...copyModalData.kata,
      [name]: value
    };

    this.setState({
      modalData: copyModalData
    });
  };

  setBronzeRound = (evt) => {
    const { modalData } = this.state;
    let copyModalData = { ...modalData };
    const { checked } = evt.target;

    copyModalData = { ...copyModalData, bronze_fight_enabled: checked };

    this.setState({ modalData: copyModalData });
  };

  showModal = (evt, item, currentPressedIcon) => {
    evt.preventDefault();

    const { categoryTypes } = this.state;
    const currentCategoryType = categoryTypes.find(
      (it) => +it?.id === +item?.type
    );
    let selectedKataNames = {};

    if (item?.kata) {
      selectedKataNames = {
        category_type: item.type,
        category_default_name: `${setCategoryName(item)} (${
          item.kata.category_name || ''
        })`,
        category_id: item.kata.category_id,
        round_1:
          (currentPressedIcon !== DELETE_ICON && item.kata.kara_round_1) ?? [],
        round_2:
          (currentPressedIcon !== DELETE_ICON && item.kata.kara_round_2) ?? [],
        ...(+item.type === 5 // is KATA BY FLAGS
          ? {
              round_3:
                (currentPressedIcon !== DELETE_ICON &&
                  item.kata.kata_round_3) ??
                []
            }
          : {})
      };
    }

    this.setState((prevState) => ({
      pressedIcon: currentPressedIcon,
      open: true,
      modalData: {
        ...prevState.modalData,
        ...item,
        group_category_name: item?.g_name,
        group_category_alt_name: item?.g_alt_name,
        group_category_age_from: item?.age_from,
        group_category_age_to: item?.age_to,
        group_category_gender: item?.gender,
        group_category_type: +item?.type,
        ...(!+item.group_id
          ? {
              category_id: currentCategoryType.weighted
                ? item?.weight_f?.[0]?.category_id
                : item?.kata?.category_id
            }
          : {}),
        category_type_name: currentCategoryType?.name,
        form_title_name: item?.g_name,
        selectedKataNames
      }
    }));
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        shouldShowDiscardChanges: false,
        modalData: {},
        errors: {},
        isEditing: false
      });
    }
  };

  onSelectKataNames = (kata, round) => {
    let copyModalData = { ...this.state.modalData };

    copyModalData.selectedKataNames[round] = copyModalData?.selectedKataNames[
      round
    ].some((it) => +it === +kata)
      ? copyModalData?.selectedKataNames[round].filter((it) => +it !== +kata)
      : [...copyModalData?.selectedKataNames[round], +kata];

    this.setState({ isEditing: true, modalData: copyModalData });
  };

  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;

    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () => this.onSelectCheckbox({}));
  };

  hideSnackbar = () => {
    this.setState({ showModal: false });
  };

  onSaveModalForm = (evt) => {
    const { modalData, isCategoryOwner } = this.state;
    const { tournamentId, tournamentData } = this.props;
    let categoriesList = [];
    let promises = [];

    if (modalData?.weight_t && modalData?.weight_f) {
      const weightFLen = modalData.weight_f?.length;
      const weightTLen = modalData.weight_t?.length;

      for (let i = 0; i < weightFLen; i++) {
        if (modalData.weight_f[i].tournament_presence) {
          categoriesList = [
            ...categoriesList,
            modalData.weight_f[i].category_id
          ];
        }
      }

      for (let i = 0; i < weightTLen; i++) {
        if (modalData.weight_t[i].tournament_presence) {
          categoriesList = [
            ...categoriesList,
            modalData.weight_t[i].category_id
          ];
        }
      }
    } else if (
      +modalData?.type === 5 &&
      !!+modalData?.kata?.tournament_presence
    ) {
      categoriesList = [modalData?.kata?.category_id];
    }

    if (
      (+modalData?.type === 5 && !!+modalData?.kata?.tournament_presence) ||
      (modalData?.weight_t && modalData?.weight_f)
    ) {
      promises.push(
        new Promise((resolve) =>
          this.updateTournamentCategoryTime(
            evt,
            tournamentId,
            categoriesList,
            modalData?.weight_t &&
              modalData?.weight_f && {
                main_time_ms: modalData.main_time_ms_in_sec,
                extra_time_ms: modalData.extra_time_ms_in_sec,
                final_main_time_ms: modalData.final_main_time_ms_in_sec,
                final_extra_time_ms: modalData.final_extra_time_ms_in_sec
              },
            true,
            modalData?.bronze_fight_enabled,
            () => resolve(true)
          )
        )
      );
    }
    if (modalData?.kata) {
      if (
        !!+modalData?.kata?.tournament_presence &&
        +modalData.type !== 4 &&
        +modalData.type !== 5
      ) {
        promises.push(
          new Promise((resolve) =>
            this.updateTournamentCategoryKataFinal(
              evt,
              tournamentId,
              modalData?.kata,
              () => resolve(true)
            )
          )
        );
      }

      if (!!+modalData?.kata?.tournament_presence && +modalData.type !== 4) {
        promises.push(
          new Promise((resolve) =>
            this.updateTournKataNames(
              evt,
              tournamentId,
              modalData.selectedKataNames,
              () => resolve(true)
            )
          )
        );
      }
    }

    if (
      modalData?.master_id === isCategoryOwner &&
      !!+tournamentData.use_alt_category_name
    ) {
      if (!!+modalData?.group_id) {
        promises.push(
          new Promise((resolve) =>
            this.updateCategoryGroup(evt, modalData, () => resolve(true))
          )
        );
      } else {
        promises.push(
          new Promise((resolve) =>
            this.updateCategory(evt, modalData, () => resolve(true))
          )
        );
      }
    }

    Promise.all(promises)
      .then(() => {
        this.triggerUpdate();
      })
      .catch((err) => console.error(err));
  };

  triggerUpdate = () => {
    this.fetchMainDataTournCategories();
    this.hideModal();
  };

  render() {
    const {
      filteredCategories,
      categoryTypes,
      selectedCheckboxes,
      errors,
      kata_names,
      open,
      shouldShowDiscardChanges,
      pressedIcon,
      searchBar,
      loading,
      isSending,
      success,
      showModal,
      isCategoryOwner,
      modalData,
      modalInfo
    } = this.state;

    const {
      t,
      classes,
      shouldDisableEditing,
      tournamentId,
      viewportWidth,
      tournamentData
    } = this.props;

    let onClick,
      dialogTitle,
      shouldShowTopBottomDivider,
      buttonPurpose,
      classNameBtn,
      dialogContent;

    if (pressedIcon === MODAL_EDIT_IC) {
      dialogTitle = t('generalSettings');
      dialogContent = (
        <div style={{ display: 'grid', gridRowGap: '1rem' }}>
          {modalData?.master_id === isCategoryOwner &&
            !!+tournamentData.use_alt_category_name && (
              <>
                <div>
                  <h5>
                    {[t('alternative'), `${t('nameCategoriesForm')}`].join(' ')}
                  </h5>
                  <Divider />
                </div>
                <AddCategory
                  classNamePaper={classes.classNamePaper}
                  textChange={this.textChangeHandler}
                  formParamName={'modalData'}
                  value={modalData}
                  {...{ categoryTypes }}
                  error={{}}
                  shouldShowForm={
                    !!+modalData?.group_id ? UPDATE_GROUP : UPDATE_CATEGORY
                  }
                  disabledFields={{
                    group_category_age_from: true,
                    group_category_age_to: true,
                    group_category_name: true,
                    gender: true,
                    type: true
                  }}
                  isUpdateForm={true}
                  shouldHideSaveFormBtn={() => true}
                />
              </>
            )}
          {modalData?.weight_t &&
            modalData?.weight_f &&
            EXTRA_TIME(t, modalData).map((timeElem, timeElemIdx) => (
              <Fragment key={timeElemIdx}>
                <div>
                  <span className={clsx(classes.flex)}>
                    <h5 style={{ paddingRight: '0.5rem' }}> {t('time')}:</h5>
                    {timeElem.title}
                  </span>
                  ({timeElem.subtitle})
                  <Divider />
                </div>
                <div>
                  {timeElem.time.map(({ label, value, param }, timeIdx) => (
                    <MuiPickersUtilsProvider key={timeIdx} utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        KeyboardButtonProps={{
                          disabled: true,
                          style: { display: 'none' }
                        }}
                        ampm={false}
                        onChange={(date, time) =>
                          this.onTimeEdit(date, time, param)
                        }
                        value={
                          (value &&
                            new Date(
                              null,
                              null,
                              null,
                              value.split(':')[0],
                              value.split(':')[1]
                            )) ||
                          ''
                        }
                        views="seconds"
                        classes={{ root: classes.timeInputWrapper }}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.time,
                          startAdornment: (
                            <span className={classes.marginRight0dot5}>
                              {label}:
                            </span>
                          )
                        }}
                        error={Boolean(errors[param])}
                        helperText={errors[param]}
                      />
                    </MuiPickersUtilsProvider>
                  ))}
                </div>
              </Fragment>
            ))}
          {((+modalData?.type === 5 &&
            !!+modalData?.kata?.tournament_presence) ||
            (modalData?.weight_t && modalData?.weight_f)) && (
            <>
              <div>
                <span className={clsx(classes.flex, classes.centerVertical)}>
                  <h5 className={classes.marginRight0dot5}>
                    {t('enableBronzeFight')}
                  </h5>
                  <Bronze />
                </span>
                <Divider />
              </div>
              <CheckboxBtn //set bronze round (Kumite)
                classControlLabel={classes.marginRight0}
                checked={!!+modalData.bronze_fight_enabled}
                onChange={this.setBronzeRound}
                label={t('enableBronzeFight')}
              />
            </>
          )}
          {!!+modalData?.kata?.tournament_presence &&
            +modalData.type !== 4 &&
            +modalData.type !== 5 && (
              <>
                <div>
                  <h5>{[t('round'), 'II'].join(' ')}</h5>
                  <Divider />
                </div>
                <TextField
                  value={modalData.kata.kata_final || ''}
                  name="kata_final"
                  className={clsx(
                    classes.txtInput,
                    shouldDisableEditing && classes.noEvent
                  )}
                  variant="outlined"
                  onChange={this.txtChange}
                />
              </>
            )}
          {!!+modalData?.kata?.tournament_presence && +modalData.type !== 4 && (
            <>
              <div>
                <h5>{t('indicateKataNames')}</h5>
                <Divider />
              </div>
              <span>
                {t('roundKataFlags', { count: 'I' })}{' '}
                {t('roundKataFlags', { count: 'II' })}{' '}
                {+modalData?.selectedKataNames?.category_type === 5
                  ? t('roundKataFlags', { count: 'III' })
                  : ''}
              </span>
              {pressedIcon !== DELETE_ICON &&
                kata_names.map((kata_name) => (
                  <span
                    className={clsx(classes.flex, classes.centerVertically)}
                    key={kata_name.id}>
                    {[
                      'round_1',
                      'round_2',
                      ...(+modalData?.selectedKataNames?.category_type === 5
                        ? ['round_3']
                        : [])
                    ].map((round) => (
                      <CheckboxBtn
                        key={`${round}_${kata_name.id}`}
                        checked={modalData?.selectedKataNames?.[round]?.some(
                          (el) => +el === +kata_name.id
                        )}
                        onChange={() =>
                          this.onSelectKataNames(kata_name.id, round)
                        }
                      />
                    ))}
                    {kata_name.name}
                  </span>
                ))}
            </>
          )}
        </div>
      );
      buttonPurpose = t('save');
      classNameBtn = Object.keys(errors)?.length > 0 && [
        classes.noEvent,
        classes.disabled
      ];
      shouldShowTopBottomDivider = true;
      onClick = (evt) => this.onSaveModalForm(evt);
    } else {
      onClick = (evt) =>
        this.updateTournKataNames(
          evt,
          tournamentId,
          selectedKataNames,
          this.triggerUpdate
        );
      dialogTitle = t('deleteRecord', { name: t('kataDiscipline') });
      buttonPurpose = t('remove');
      dialogContent = t('deleteMsg');
    }

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{
            onClick,
            dialogTitle,
            shouldShowTopBottomDivider,
            buttonPurpose,
            dialogContent,
            classNameBtn
          }}
          subHeader={
            <>
              {setCategoryName(modalData)}
              {modalData?.form_title_name && (
                <>
                  {'  '}({modalData?.form_title_name})
                </>
              )}
            </>
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
          isOldDesign
        />
        {loading ? (
          <LoadingState />
        ) : (
          <CategoriesBlock
            {...{ categoryTypes }}
            showModal={this.showModal}
            {...{ selectedCheckboxes }}
            onSelectCheckbox={this.onSelectCheckbox}
            categories={filteredCategories}
            {...{ tournamentId }}
            exportTournamentCategories={this.exportTournamentCategories}
            selectKata={this.selectKataHandler}
            selectCategory={this.selectCategoryHandler}
            addCategory={this.addCategorieshandler}
            {...{ isSending }}
            {...{ shouldDisableEditing }}
            {...{ viewportWidth }}
            {...{ kata_names }}
            onSearch={this.onSearch}
            onClearSearch={this.onClearSearch}
            {...{ searchBar }}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(TournamentAddCategories));
