const styles = () => ({
  marginLeft0: {
    marginLeft: 0
  },

  marginLeft065: {
    marginLeft: '0.65rem'
  },

  flex: {
    display: 'flex'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  icon: {
    width: '2.2rem',
    height: '2.2rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content'
    }
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  marginLeft1: {
    marginBottom: '-1rem'
  },

  column: {
    flexDirection: 'column'
  },

  marginRight0: {
    marginRight: 0
  },

  smallViewportColumn: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },

  checkboxSvg: {
    '& svg': {
      width: '1.9em !important',
      height: '1.9em !important'
    }
  },

  marginCheckbox: {
    marginTop: '-0.3rem',
    marginRight: '-0.3rem'
  },

  labelCheckbox: {
    marginBottom: '.1rem',
    alignItems: 'center'
  },

  fitContent: {
    minWidth: 'fit-content'
  },

  padding0: {
    padding: 0
  },

  checkboxPaddingLeft: {
    '&.MuiCheckbox-root': {
      alignSelf: 'end',
      padding: 0,
      paddingLeft: '0.5em',
      paddingBottom: '1px'
    }
  },

  styleFilter: {
    width: '11em',
    marginRight: '0.7rem',
    height: 'calc(100% + 0.4rem)',
    border: 'none',
    '& .MuiInput-underline:before': {
      height: '100%',
      borderBottom: 'none',
      borderRight: '1px solid rgba(63,63,68,0.15)'
    },
    '& .MuiFormControl-root, & .MuiInputBase-root': {
      height: '100%'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none'
    }
  },

  mainTableRowColor: {
    backgroundColor: 'rgba(0,29,61,0.2)'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  marginRight0dot5: {
    marginRight: '.5rem'
  },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },

  checkboxesWrapper: {
    marginLeft: '-0.3rem'
  },

  checkboxItem: {
    margin: '0.6rem 0.3rem',
    minWidth: '120px',
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  switchMode: {
    alignItems: 'center'
  },

  marginLeft05: {
    marginLeft: '0.5rem'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  }
});

export default styles;
