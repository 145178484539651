import { Fragment } from 'react';
import { ro, ru, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import Logo from '../../assets/img/club_logo.png';
import Signature from '../../assets/img/signature.jpg';
import Stamp from '../../assets/img/stamp.webp';

import {
  GENDER,
  ID,
  NAME,
  EMAIL,
  AVATAR,
  COUNTRY_ID
} from '../../helpers/constants';
import { selectedValue, countryLabel, roleName } from '../../helpers/selectors';

import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';
import { ReactComponent as DateIcon } from '../../assets/img/date.svg';
import UploadImage from '../UploadImage/UploadImage';
import { GlobalCss, useStyles } from './StylesAccountDetails';

const AccountDetails = (props) => {
  const {
    updateUser,
    values,
    countries,
    allRegions,
    selectOption,
    fetchRegionBySelectedCountry,
    handleChange,
    dateChange,
    selectedDate,
    role,
    errors,
    onChangeImg,
    showChangePassword,
    showModal
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const currentLang = localStorage.getItem('i18nextLng');
  const isRuLang = currentLang === 'ru';
  const FIRST_NAME = !isRuLang ? 'first_name' : 'last_name';
  const LAST_NAME = !isRuLang ? 'last_name' : 'first_name';

  const inputs = [
    [
      {
        label: !isRuLang ? t('firstname') : t('lastname'),
        name: FIRST_NAME,
        className: classes.marginBottom,
        error: Boolean(!isRuLang ? errors.first_name : errors.last_name),
        helperText: !isRuLang ? errors.first_name : errors.last_name,
        required: true
      }
    ],
    [
      {
        label: !isRuLang ? t('lastname') : t('firstname'),
        name: LAST_NAME,
        className: classes.marginBottom,
        error: Boolean(!isRuLang ? errors.last_name : errors.first_name),
        helperText: !isRuLang ? errors.last_name : errors.first_name,
        required: true
      }
    ],
    [
      {
        label: t('patronymic'),
        name: 'patronymic',
        className: classes.marginBottom
      }
    ],
    [
      {
        label: EMAIL,
        name: 'email',
        disabled: role !== 'administrator',
        className: classes.marginBottom,
        required: true
      }
    ],
    [
      {
        birthday: true,
        className: [classes.marginRight, classes.marginBottom]
      },
      { gender: true }
    ],
    [
      {
        label: t('nameBranchChief'),
        name: 'branch_chief',
        error: Boolean(errors.branch_chief),
        helperText: errors.branch_chief,
        required: true,
        className: [classes.marginRight, classes.marginBottom]
      }
    ],
    [
      {
        label: !isRuLang ? t('coachFirstName') : t('coachLastName'),
        name: !isRuLang ? 'coach_first_name' : 'coach_last_name',
        error: Boolean(
          errors?.[!isRuLang ? 'coach_first_name' : 'coach_last_name']
        ),
        helperText:
          errors?.[!isRuLang ? 'coach_first_name' : 'coach_last_name'],
        className: [classes.marginRight, classes.marginBottom]
      },
      {
        label: !isRuLang ? t('coachLastName') : t('coachFirstName'),
        name: !isRuLang ? 'coach_last_name' : 'coach_first_name',
        error: Boolean(
          errors?.[isRuLang ? 'coach_last_name' : 'coach_first_name']
        ),
        helperText: errors?.[isRuLang ? 'coach_last_name' : 'coach_first_name'],
        className: classes.marginBottom
      }
    ],
    [
      { country: true },
      allRegions?.length > 0
        ? { region: true, className: classes.marginLeft }
        : {
            label: t('district'),
            name: 'region',
            className: [classes.marginLeft, classes.marginBottom]
          },
      {
        label: t('city'),
        name: 'city',
        className: [classes.marginLeft, classes.marginBottom]
      }
    ],
    [
      {
        label: t('cluborFederationName'),
        name: 'club',
        className: classes.marginBottom
      }
    ],
    [
      {
        label: t('phone'),
        name: 'phone',
        error: Boolean(errors?.phone),
        helperText: errors?.phone,
        className: [classes.marginRight, classes.marginBottom]
      },
      { label: t('website'), name: 'website', className: classes.marginBottom }
    ]
  ];

  const cards_pofile = [
    {
      title: t('logo'),
      id: 'TEST_ID_UPLOAD_LOGO',
      description: t('useImgLater', { name: t('logo').toLowerCase() }),
      param: 'logo',
      src: Logo,
      preview: 'logoPreview'
    },
    {
      title: t('stamp'),
      id: 'TEST_ID_UPLOAD_STAMP',
      description: t('useImgLater', { name: t('stamp').toLowerCase() }),
      param: 'stamp',
      src: Stamp,
      preview: 'stampPreview'
    },
    {
      title: t('signature'),
      id: 'TEST_ID_UPLOAD_SIGNATURE',
      description: t('useImgLater', {
        name: t('signature').toLowerCase()
      }),
      param: 'signature',
      src: Signature,
      preview: 'signaturePreview'
    }
  ];

  const filterComponent = (
    options,
    label,
    param,
    name,
    item,
    required,
    cb,
    freeSolo,
    additionalItem,
    className
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      param,
      values[name],
      name === COUNTRY_ID
    );

    return (
      <Filter
        className={clsx(
          classes.inputHeight,
          classes.fullWidth,
          classes.marginBottom,
          classes.asterisk,
          classes.label,
          className
        )}
        classes={{ inputRoot: classes.outlined }}
        {...{ options }}
        onChange={(evt, val) =>
          selectOption(evt, val, 'userForm', name, param, errors, cb)
        }
        {...{ label }}
        value={currentVal || ''}
        {...{ item }}
        variant="outlined"
        {...{ required }}
        id={name}
      />
    );
  };

  return (
    <div className={clsx(classes.flex, classes.condensedView)}>
      <div className={clsx(classes.uploadWrapper, classes.column)}>
        <div className={clsx(classes.marginBottom, classes.paper)}>
          <span className={clsx(classes.flex)}>
            <span className={clsx(classes.flex, classes.column, classes.about)}>
              <span className={clsx(classes.flex, classes.h1)}>
                {[values[FIRST_NAME], values[LAST_NAME]].join(' ')}
              </span>
              <span className={classes.role}>{`${t('role')}: ${
                values.role === 'coach'
                  ? t('registrar').toLocaleLowerCase()
                  : roleName(values.role)
              }`}</span>
            </span>
            <UploadImage
              id="upload-file"
              className={classes.avatar}
              onChange={(evt) =>
                onChangeImg(evt, 'userForm', AVATAR, 'imagePreview')
              }
              img={values.imagePreview}
            />
          </span>
          {showChangePassword && (
            <>
              <hr className={classes.divider} />
              <Button
                className={classes.marginTop}
                onClick={showModal}
                label={t('changePassword')}
              />
            </>
          )}
        </div>
        {cards_pofile.map((card, cardIdx) => (
          <div
            key={cardIdx}
            className={clsx(classes.flex, classes.paper, card.className)}>
            <span
              className={clsx(classes.flex, classes.column, classes.cardTxt)}>
              <span className={clsx(classes.flex, classes.h1)}>
                {card.title}
              </span>
              <span>{card.description}</span>
            </span>
            <UploadImage
              id={card.id}
              className={classes.cardImg}
              onChange={(evt) =>
                onChangeImg(evt, 'userForm', card.param, card.preview)
              }
              img={values[card.preview] || card.src}
            />
          </div>
        ))}
      </div>
      <div className={clsx(classes.paper, classes.formWrapper)}>
        <GlobalCss />
        {inputs.map((item, idx) => (
          <span key={idx} className={clsx(classes.flex, classes.condensedView)}>
            {item.map((it, index) => (
              <Fragment key={index}>
                {!it.birthday && !it.gender && !it.country && !it.region && (
                  <TextField
                    className={clsx(
                      classes.inputHeight,
                      classes.fullWidth,
                      it.className,
                      classes.asterisk,
                      classes.outlinedTxtInput,
                      classes.label
                    )}
                    inputProps={
                      it.name === 'region' ? { autoComplete: 'off' } : {}
                    }
                    label={it.label}
                    name={it.name}
                    onChange={handleChange}
                    value={values[it.name] || ''}
                    variant="outlined"
                    disabled={it.disabled}
                    error={it.error}
                    helperText={it.helperText}
                    required={it.required}
                  />
                )}
                {it.birthday && (
                  <MuiPickersUtilsProvider
                    locale={[ru, ro, de].find((it) => it.code === currentLang)}
                    utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      cancelLabel={t('cancel')}
                      okLabel={t('apply')}
                      keyboardIcon={<DateIcon />}
                      className={clsx(
                        classes.inputHeight,
                        classes.fullWidth,
                        classes.outlinedTxtInput,
                        classes.label,
                        it.className
                      )}
                      inputVariant="outlined"
                      name="birthday"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t('birthday')}
                      value={selectedDate}
                      onChange={(date) => dateChange(date)}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </MuiPickersUtilsProvider>
                )}
                {it.gender &&
                  filterComponent(GENDER, t('gender'), NAME, 'gender', NAME)}
                {it.country &&
                  filterComponent(
                    countries,
                    t('country'),
                    ID,
                    'country_id',
                    {
                      firstName: 'iso3',
                      lastName: countryLabel(currentLang),
                      parathesis: true
                    },
                    true,
                    (val) =>
                      fetchRegionBySelectedCountry &&
                      fetchRegionBySelectedCountry(val)
                  )}
                {it.region &&
                  filterComponent(
                    allRegions,
                    t('district'),
                    NAME,
                    'region',
                    NAME,
                    null,
                    null,
                    true,
                    { name: values.region },
                    it.className
                  )}
              </Fragment>
            ))}
          </span>
        ))}
        {role !== 'operator' && role !== 'recorder' && (
          <Button
            label={t('save')}
            isSaveBtn={true}
            onClick={updateUser}
            className={clsx(classes.flex, classes.btn)}
          />
        )}
      </div>
    </div>
  );
};

export default AccountDetails;
