import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Dialog } from '@material-ui/core';

import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { ReactComponent as Exit } from 'assets/img/new_close_modal.svg';

import {
  KEEP_EDITING,
  CLOSE_DISCARD,
  CLOSE,
  SAVE
} from '../../helpers/constants';

import ButtonNewDesign from '../Buttons/ActionButtonsP2';
import ButtonOldDesign from '../Buttons/ActionButtons';

import { useStyles } from './StylesNewDesign';

const Modal = (props) => {
  const {
    open,
    close,
    children,
    buttonPurpose,
    onClick,
    dialogTitle,
    dialogContent,
    shouldShowDiscardChanges,
    discardOrKeepEditing,
    subHeader,
    setPaperWidth,
    isOldDesign,
    onSwitchBetween,
    classNameBtn,
    closeButton,
    closeButtonlabel
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const Button = isOldDesign ? ButtonOldDesign : ButtonNewDesign;

  const buttonComponent = (
    action,
    label,
    autoFocus,
    isSaveBtn,
    className,
    id,
    warning
  ) => (
    <Button
      {...{ id }}
      {...{ className }}
      {...{ isSaveBtn }}
      {...{ autoFocus }}
      onClick={action}
      {...{ label }}
      specificallyStyledBtn={warning}
    />
  );

  const discardBtns = [
    {
      id: KEEP_EDITING,
      label: t('Cancel'),
      onClick: discardOrKeepEditing,
      className: [classes.left, classes.marginRight1]
    },
    {
      id: CLOSE_DISCARD,
      label: t('closeDiscard'),
      onClick: discardOrKeepEditing,
      isSaveBtn: true
    }
  ];

  const buttonData = [
    onClick && {
      id: SAVE,
      label: buttonPurpose,
      onClick,
      isSaveBtn: true,
      className: [classes.btn, classes.margin1dot5, classNameBtn],
      autoFocus: true
    },
    closeButton && {
      id: CLOSE,
      label: closeButtonlabel ? closeButtonlabel : t('close'),
      onClick: closeButton,
      className: [classes.btn, classes.margin1dot5],
    }
  ];

  const modalContent = (
    <>
      <span className={clsx(classes.wrapper_header)}>
        <span className={clsx(classes.flex, classes.header)}>
          <h3 className={classes.marginRight1}>{dialogTitle}</h3>
          {onSwitchBetween && (
            <>
              <ArrowLeft
                onClick={onSwitchBetween.prev}
                className={clsx(
                  classes.onHover,
                  classes.toggleIcons,
                  classes.marginRight1,
                  classes.left
                )}
              />
              <ArrowRight
                onClick={onSwitchBetween.next}
                className={clsx(
                  classes.onHover,
                  classes.toggleIcons,
                  classes.marginRight2
                )}
              />
            </>
          )}
          <Exit
            className={clsx(classes.onHover, !onSwitchBetween && classes.left)}
            onClick={close}
          />
        </span>
        {subHeader && (
          <span className={clsx(classes.flex, classes.contentSubheader)}>
            {subHeader}
          </span>
        )}
      </span>
      <span className={classes.contentWrappper}>
        {dialogContent}
        {children}
      </span>
    </>
  );

  return (
    <Dialog
      {...{ open }}
      classes={{ paper: setPaperWidth ?? classes.maxWidth }}
      onClose={close}>
      {shouldShowDiscardChanges ? (
        <>
          <span className={clsx(classes.onHoldEditing, classes.paddingLR1dot5)}>
            {modalContent}
          </span>
          <span
            className={clsx(classes.discardWrapper, classes.wrapper_footer)}>
            <span className={clsx(classes.flex, classes.wrap)}>
              <span className={classes.discardChanges}>
                {t('discardChanges')}
              </span>
              {t('cancelUnsavedChangessMsg')}
            </span>
            <span className={clsx(classes.flex, classes.marginTop1)}>
              {discardBtns.map(
                (it, idx) =>
                  it && (
                    <Fragment key={idx}>
                      <Button
                        id={it.id}
                        isSaveBtn={it.isSaveBtn}
                        onClick={it.onClick}
                        className={clsx(classes.btn, it.className)}
                        label={it.label}
                      />
                    </Fragment>
                  )
              )}
            </span>
          </span>
        </>
      ) : (
        <span className={clsx(classes.flex, classes.column)}>
          {modalContent}
          {onClick && (
            <span className={clsx(classes.flex, classes.wrapper_footer)}>
              {buttonData.map(
                (it, idx) =>
                  it && (
                    <Fragment key={idx}>
                      <Button
                        id={it.id}
                        isSaveBtn={it.isSaveBtn}
                        onClick={it.onClick}
                        className={clsx(it.className)}
                        label={it.label}
                      />
                    </Fragment>
                  )
              )}
            </span>
          )}
        </span>
      )}
    </Dialog>
  );
};

export default Modal;
