import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import { Tooltip, Collapse, ListItem } from '@material-ui/core';

import { ReactComponent as DropdownUp } from 'assets/img/new_dropdown_grey.svg';
import { ReactComponent as PencilEdit } from 'assets/img/pencil_edit_grey.svg';
import { ReactComponent as InfoMark } from 'assets/img/info_mark.svg';
import { ReactComponent as Downloads } from 'assets/img/downloads_grey.svg';
import { ReactComponent as ArrowTop } from 'assets/img/arrow_top.svg';
import { ReactComponent as EditRecordGrey } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as DeleteGrey } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as AssignGrey } from 'assets/img/new_assign_grey.svg';
import { ReactComponent as UnselectedCheckboxGrey } from 'assets/img/new_unselected_checkbox_grey.svg';
import { ReactComponent as SelectedCheckboxGrey } from 'assets/img/new_selected_checkbox_grey.svg';
import { ReactComponent as InfoIcon } from 'assets/img/new_info-grey.svg';
import { ReactComponent as FilterIc } from 'assets/img/filter_sort.svg';
import { ReactComponent as FilterIcActive } from 'assets/img/filter_sort_active.svg';
import { ReactComponent as Clear } from 'assets/img/new_x_grey.svg';
import { ReactComponent as RestoreIc } from 'assets/img/restore-white.svg';

import {
  MODAL_EDIT_IC,
  ADD_CATEGORIES,
  EMAIL,
  serverDefaultPath,
  COACH_MODAL_EDIT_IC,
  TOURN_INFO_IC,
  MODAL_FILTER_IC,
  DELETE_ICON,
  TEST_ID_INFO_BTN,
  ENABLED,
  NEW_DEFAULT_AVATAR,
  SEND_FINAL_IC
} from '../../../helpers/constants';
import {
  organizerName,
  finishedRegistration
} from '../../../helpers/selectors';

import { SearchInput } from 'components';
import HeaderTournInfo from '../../HeaderTournInfo/HeaderTournInfoNewDesign';
import Button from 'components/Buttons/ActionButtonsP2';

import {
  useStyles,
  categoryTxt,
  useStylesActionComponent,
  useStylesJudges,
  useStylesPariticipants
} from './Styles';

const PARICIPANT_PARAMS = (tournamentData, t) => [
  { key: 'age', name: t('age') },
  { key: 'gender', name: t('gender') },
  { key: 'weight', name: t('weight') },
  ...(!!+tournamentData?.height_needed
    ? [{ key: 'height', name: t('height') }]
    : []),
  ...(!!+tournamentData.show_reg_qdan
    ? [{ key: 'qdan_name', name: t('kyuDan') }]
    : []),
  ...(!!+tournamentData?.sport_rank_needed
    ? [{ key: 'sport_rank_short', name: t('sportsCategory') }]
    : []),
  {
    list: true,
    key: 'categories_info',
    paramN: 'category_name',
    name: t('categories')
  },
  ...(!+tournamentData?.no_country
    ? [{ key: 'participant_country', name: t('country') }]
    : []),
  { key: 'coach_name', name: t('coach') }
];

const JUDGE_PARAMS = (tournamentData, t) => [
  { key: 'gender', name: t('gender') },
  ...(!!+tournamentData?.show_reg_qdan
    ? [{ key: 'qdan_name', name: t('kyuDan') }]
    : []),
  ...(!!+tournamentData?.show_reg_iko
    ? [{ key: 'qualificationIkoShort', name: t('ikoQual') }]
    : []),
  { key: 'experience', name: t('experience') },
  { key: 'qualificationRfShort', name: t('rfQual') },
  { key: 'positionName', name: t('positions') }
];

const DOWNLOAD_FILE = 'download_files';

const QuickTournRegistration = (props) => {
  const {
    savedCoachForm,
    invitationInfo,
    tournamentData,
    participants,
    showModal,
    searchBar,
    clearSearch,
    searchText,
    pressEnter,
    isLoading,
    exportParticipants,
    viewportWidth,
    onChangeInnerPage,
    innerPageIdx, //index of the page (Participants or Judges)
    allJudges,
    collapsed,
    subItemToggle,
    filters,
    restoreParticipant,
    onToggleJudgeStatus,
    directionFilters,
    sortLabelJudges,
    currentFilters,
    sortLabelParticipants
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const classes1 = useStylesPariticipants(PARICIPANT_PARAMS(tournamentData, t));
  const classes2 = useStylesJudges(JUDGE_PARAMS(tournamentData, t));

  const isMobile = viewportWidth < 768;
  const openRegistration = !!+tournamentData?.registration_active;
  const openParticipantRegistration =
    openRegistration &&
    !finishedRegistration(
      tournamentData?.registration_date,
      tournamentData?.finished
    );
  const openJudgeRegistration =
    openRegistration &&
    !finishedRegistration(
      tournamentData?.reg_judge_date,
      tournamentData?.finished
    );

  const actionHeaderParams = [
    ...(!!+tournamentData?.reg_judge_form
      ? [{ val: '. .', size: 'repeat(2, minmax(5em, 10em))' }]
      : []),
    ...((
      innerPageIdx === 0 ? openParticipantRegistration : openJudgeRegistration
    )
      ? [{ val: 'addNew', size: 'minmax(5em, 15em)' }]
      : []),
    { val: 'search', size: '1fr' },
    { val: 'export', size: 'minmax(5em, 15em)' },
    ...(innerPageIdx === 0 && openParticipantRegistration
      ? [{ val: 'submit', size: 'minmax(5em, 15em)' }]
      : [])
  ];

  const classes3 = useStylesActionComponent(actionHeaderParams);

  const FIRST_NAME_VAL = !+tournamentData?.last_name_first
    ? 'first_name'
    : 'last_name';

  const LAST_NAME_VAL = !+tournamentData?.last_name_first
    ? 'last_name'
    : 'first_name';

  const FIRST_NAME_JUDGE_TABLE_VAL = !+tournamentData?.last_name_first
    ? 'firstName'
    : 'lastName';

  const LAST_NAME__JUDGE_TABLE_VAL = !+tournamentData?.last_name_first
    ? 'lastName'
    : 'firstName';

  const summaryHeader = (tournType) => [
    ...(tournamentData
      ? [
          {
            title: t('eventInfo'),
            img: {
              src:
                tournamentData.poster &&
                serverDefaultPath + tournamentData.poster
            },
            button: [
              {
                className: true,
                ic: (
                  <span
                    id={TOURN_INFO_IC}
                    style={{ height: 'fit-content' }}
                    className={clsx(classes.flex, classes.onHover)}
                    onClick={(evt) => {
                      showModal(evt, null, null, null, null, TOURN_INFO_IC);
                    }}>
                    <Tooltip arrow title={t('eventInfo')}>
                      <span className={clsx(classes.flex)}>
                        <InfoMark
                          className={clsx(classes.radiusIc, classes.noEvent)}
                        />
                      </span>
                    </Tooltip>
                  </span>
                )
              },
              {
                className: true,
                ic: (
                  <span
                    id={DOWNLOAD_FILE}
                    style={{
                      height: 'fit-content'
                    }}
                    className={clsx(classes.flex, classes.onHover)}
                    onClick={(evt) => {
                      showModal(evt, null, null, null, null, DOWNLOAD_FILE);
                    }}>
                    <Tooltip
                      arrow
                      title={[t('download'), t('documents')].join(' ')}>
                      <span className={clsx(classes.flex)}>
                        <Downloads
                          className={clsx(classes.radiusIc, classes.noEvent)}
                          onClick={showModal}
                        />
                      </span>
                    </Tooltip>
                  </span>
                )
              }
            ],
            info: [
              {
                label: t('sportEvent'),
                name:
                  invitationInfo.tournament_name ||
                  tournamentData.tournament_name
              },
              {
                label: [t('tournOrganizer'), t('country')].join(' / '),
                name: [
                  [
                    organizerName(tournamentData)?.firstN,
                    organizerName(tournamentData)?.lastN
                  ].join(' '),
                  tournamentData.iso3
                ].join(' / ')
              },
              {
                label: t('eventStart'),
                name:
                  tournamentData.start_date &&
                  moment(tournamentData.start_date).format('DD.MM.YYYY')
              }
            ]
          }
        ]
      : []),
    {
      title: t('registrarInfo'),
      img: { src: savedCoachForm.imagePreview },
      button: [
        {
          ic: (
            <span
              id={COACH_MODAL_EDIT_IC}
              style={{ height: 'fit-content' }}
              className={clsx(classes.flex, classes.onHover)}
              onClick={(evt) => {
                showModal(evt, null, null, null, null, COACH_MODAL_EDIT_IC);
              }}>
              <Tooltip arrow title={t('edit')}>
                <span className={classes.flex}>
                  <PencilEdit
                    className={clsx(classes.radiusIc, classes.noEvent)}
                  />
                </span>
              </Tooltip>
            </span>
          )
        }
      ],
      info: [
        {
          label: t('teamRepresentative'),
          name:
            savedCoachForm?.[FIRST_NAME_VAL] +
            ' ' +
            savedCoachForm?.[LAST_NAME_VAL]
        },
        {
          label: [t('club'), t('country')].join(' / '),
          name: [savedCoachForm.club, savedCoachForm.iso3].join(' / ')
        },
        { label: EMAIL, name: savedCoachForm.email }
      ]
    }
  ];

  const recordDataParams = {
    mainName: {
      firstName:
        innerPageIdx === 0 ? FIRST_NAME_VAL : FIRST_NAME_JUDGE_TABLE_VAL,
      lastName: innerPageIdx === 0 ? LAST_NAME_VAL : LAST_NAME__JUDGE_TABLE_VAL,
      ...(tournamentData.show_reg_patronymic === ENABLED
        ? { patronymic: 'patronymic' }
        : {})
    },
    info: [
      ...(innerPageIdx === 0
        ? [...PARICIPANT_PARAMS(tournamentData, t)]
        : [...JUDGE_PARAMS(tournamentData, t)])
    ]
  };

  const categoryComponent = (
    parent,
    categoryVal,
    categoryIdx,
    paramN,
    className
  ) => (
    <span
      key={categoryIdx}
      style={categoryTxt(parent.gender)}
      className={clsx(classes.flex, classes.categoryTxt)}>
      <ListItem
        key={categoryIdx}
        className={clsx(classes.flex, classes.categoryListItem)}>
        <span
          className={clsx(
            classes.noEvent,
            categoryVal.issues && classes.issue,
            className
          )}>
          {[
            categoryVal.team_id ? `(${categoryVal.team_id}) ` : '',
            categoryVal[paramN]
          ].join('')}
        </span>
      </ListItem>
    </span>
  );

  const singleCategoryComponent = (item, categoryID) => {
    return (
      <span className={classes.flex}>
        <span
          id="clearIcon"
          className={clsx(classes.onHover)}
          onClick={(e) => {
            e.stopPropagation();
            showModal(e, item, null, categoryID);
          }}>
          <Clear
            className={clsx(
              classes.noEvent,
              classes.buttonsSize,
              classes.buttonsBorder
            )}
          />
        </span>
      </span>
    );
  };

  const getSortString = (
    sortDirection,
    currentFilters,
    directionFilters,
    filters,
    innerPageIdx
  ) => {
    const { direction, field } = sortDirection;

    const currentFilterLabel = currentFilters.find(
      (filter) => filter.id === field
    );
    const directionNameLabel = directionFilters.find(
      (filter) => filter.id === direction
    );

    const assignedLabel =
      innerPageIdx === 0
        ? filters?.showAssignedParticipants && t('assigned')
        : filters?.showAssignedJudges && t('assigned');

    const archiveLabel =
      innerPageIdx === 0 && filters?.archive && t('showDeleted');

    const labels = [
      currentFilterLabel?.name,
      directionNameLabel?.name,
      assignedLabel,
      archiveLabel
    ].filter(Boolean);
    return <span>{labels.join(' / ')}</span>;
  };

  const isActive =
    innerPageIdx === 0
      ? Object.entries(sortLabelParticipants).length === 0
      : Object.entries(sortLabelJudges).length === 0;

  const sortLabel =
    innerPageIdx === 0 ? sortLabelParticipants : sortLabelJudges;

  return (
    <>
      <span
        className={clsx(
          classes.flex,
          classes.filter,
          classes.centerVertically
        )}>
        <span
          id={MODAL_FILTER_IC}
          style={{ height: 'fit-content' }}
          onClick={(evt) => {
            showModal(evt, null, null, null, null, MODAL_FILTER_IC);
          }}
          className={clsx(classes.onHover)}>
          <Tooltip arrow title={t('filter')}>
            <span className={clsx(classes.flex)}>
              {isActive ? (
                <FilterIc className={clsx(classes.radiusIc, classes.noEvent)} />
              ) : (
                <FilterIcActive
                  className={clsx(classes.radiusIc, classes.noEvent)}
                />
              )}
            </span>
          </Tooltip>
        </span>
        <span className={clsx(classes.marginLeft10, classes.sortLabel)}>
          {!isMobile &&
            getSortString(
              sortLabel,
              currentFilters,
              directionFilters,
              filters,
              innerPageIdx
            )}
        </span>
      </span>
      {savedCoachForm.id && tournamentData && (
        <HeaderTournInfo
          {...{ summaryHeader }}
          {...{ tournamentData }}
          {...{ viewportWidth }}
        />
      )}
      <span className={clsx(classes3.gridAction, classes.marginBottom1)}>
        {!!+tournamentData?.reg_judge_form && (
          <>
            <Button
              className={innerPageIdx === 0 && classes.textColor}
              onClick={() => onChangeInnerPage(0)}
              label={t('participants')}
            />

            <Button
              className={innerPageIdx === 1 && classes.textColor}
              onClick={() => onChangeInnerPage(1)}
              label={t('judges')}
            />
          </>
        )}
        {(innerPageIdx === 0
          ? openParticipantRegistration
          : openJudgeRegistration) && (
          <Button
            id={MODAL_EDIT_IC}
            isSaveBtn={true}
            onClick={(evt) => {
              showModal(evt, null, null, null, null, MODAL_EDIT_IC);
            }}
            className={clsx(classes.addNewBtn)}
            label={innerPageIdx === 0 ? t('addAthlete') : t('addJudge')}
          />
        )}
        <SearchInput
          onChange={searchText}
          id="search-input"
          onKeyPress={pressEnter}
          isSearchInactive={!searchBar}
          value={searchBar || ''}
          {...{ clearSearch }}
          className={clsx(classes.searchIpnut)}
        />

        <Button
          isSaveBtn
          onClick={innerPageIdx === 0 ? exportParticipants : undefined}
          className={clsx(classes.exportParticipantList)}
          label={t('participantList')}
        />
        {innerPageIdx === 0 && openParticipantRegistration && (
          <Button
            isSaveBtn
            id={SEND_FINAL_IC}
            onClick={(evt) => {
              showModal(evt, null, null, null, null, SEND_FINAL_IC);
            }}
            className={clsx(classes.submitApplication)}
            label={t('sendRequest')}
          />
        )}
      </span>

      <span>
        {!isLoading &&
          savedCoachForm.id &&
          [
            ...(innerPageIdx === 0
              ? filters?.showAssignedParticipants
                ? filters?.assignedParticipants
                : participants
              : filters?.showAssignedJudges
              ? filters?.assignedJudges
              : allJudges)
          ].map((item, idx) => {
            const avatarAndName = (
              <span
                className={clsx(
                  classes.flex,
                  classes.avatarAndName,
                  item.gender === 'F' ? classes.female : classes.male
                )}>
                <img
                  className={clsx(
                    classes.thumbnail,
                    item.gender === 'M'
                      ? classes.borderMale
                      : classes.borderFemale
                  )}
                  alt="photo_participant"
                  src={
                    item.photo
                      ? serverDefaultPath + item.photo
                      : NEW_DEFAULT_AVATAR
                  }
                />
                <span
                  className={clsx(
                    classes.flex,
                    classes.column,
                    !isMobile && classes.marginLeft0dot75,
                    classes.nameWithoutAvatar
                  )}>
                  <h5
                    className={clsx(
                      classes.recordName,
                      classes.ellipsis,
                      classes.marginTop05
                    )}>
                    {[
                      item?.[recordDataParams?.mainName?.firstName],
                      item?.[recordDataParams?.mainName?.lastName],
                      item?.[recordDataParams?.mainName?.patronymic]
                    ].join(' ')}
                  </h5>
                  <span className={clsx(classes.flex, classes.actionIc)}>
                    {(innerPageIdx === 0
                      ? !filters?.archive && openParticipantRegistration
                      : openJudgeRegistration) && (
                      <>
                        <Tooltip
                          arrow
                          title={
                            innerPageIdx === 0
                              ? t('updateRecord', {
                                  name: t('participant')
                                })
                              : t('updateJudge')
                          }>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.buttonsSize,
                              classes.onHover
                            )}
                            id={MODAL_EDIT_IC}
                            onClick={(evt) =>
                              showModal(evt, item, null, null, idx)
                            }>
                            <EditRecordGrey
                              className={clsx(
                                classes.buttonsSize,
                                classes.noEvent,
                                classes.buttonsBorder
                              )}
                            />
                          </span>
                        </Tooltip>
                        {innerPageIdx === 0 && (
                          <Tooltip arrow title={t('assignParticipantTooltip')}>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.buttonsSize,
                                classes.marginLeft1,
                                classes.onHover
                              )}
                              id={ADD_CATEGORIES}
                              onClick={(evt) => showModal(evt, item)}>
                              <AssignGrey
                                className={clsx(
                                  classes.buttonsSize,
                                  classes.noEvent,
                                  classes.buttonsBorder
                                )}
                              />
                            </span>
                          </Tooltip>
                        )}
                        {innerPageIdx !== 0 && (
                          <Tooltip
                            arrow
                            title={t(
                              item.status === '1'
                                ? 'deleteApplication'
                                : 'submitApplication'
                            )}>
                            <>
                              {!!+item.status ? (
                                <Tooltip arrow title={t('removeFromJudging')}>
                                  <SelectedCheckboxGrey
                                    className={clsx(
                                      classes.buttonsSize,
                                      classes.buttonsBorder,
                                      classes.onHover,
                                      classes.marginLeft1
                                    )}
                                    onClick={(evt) =>
                                      onToggleJudgeStatus(evt, item, false)
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip arrow title={t('assignToJudging')}>
                                  <UnselectedCheckboxGrey
                                    className={clsx(
                                      classes.buttonsSize,
                                      classes.buttonsBorder,
                                      classes.onHover,
                                      classes.marginLeft1
                                    )}
                                    onClick={(evt) =>
                                      onToggleJudgeStatus(evt, item, true)
                                    }
                                  />
                                </Tooltip>
                              )}
                            </>
                          </Tooltip>
                        )}
                        <Tooltip
                          arrow
                          title={t('deleteRecord', {
                            name: t(innerPageIdx === 0 ? 'participant' : '')
                          })}>
                          <span
                            id={DELETE_ICON}
                            className={clsx(
                              classes.buttonsSize,
                              classes.onHover,
                              classes.marginLeft1
                            )}
                            onClick={(evt) => showModal(evt, item)}>
                            <DeleteGrey
                              className={clsx(
                                classes.buttonsSize,
                                classes.noEvent,
                                classes.buttonsBorder
                              )}
                            />
                          </span>
                        </Tooltip>
                      </>
                    )}
                    {innerPageIdx === 0 && filters.archive && (
                      <Tooltip arrow title={t('restore')}>
                        <RestoreIc
                          className={clsx(
                            classes.buttonsSize,
                            classes.buttonsBorder,
                            classes.onHover
                          )}
                          onClick={(evt) => restoreParticipant(evt, item)}
                        />
                      </Tooltip>
                    )}
                  </span>
                </span>
              </span>
            );

            return (
              <span
                className={clsx(
                  classes.gridRecords,
                  classes.marginBottom1,
                  classes.padding75
                )}
                key={idx}>
                {isMobile ? (
                  <span className={clsx(classes.avatarAndNameWrapper)}>
                    {avatarAndName}
                  </span>
                ) : (
                  avatarAndName
                )}
                <span
                  className={clsx(
                    classes.gridRecordsValue,
                    classes.paddingLeft01,
                    innerPageIdx === 0
                      ? classes1.gridRecordsValue
                      : classes2.gridRecordsValue
                  )}>
                  {recordDataParams.info.map((param, paramIdx) => {
                    let recordValue = '-';

                    if (item?.[param.key] && !param.list) {
                      recordValue = item?.[param.key];
                    } else if (param.list && item?.[param.key]?.length > 0) {
                      recordValue = (
                        <>
                          {[
                            ...(item?.[param.key]?.length > 1 ? [0, 1] : [0]) //this method displays up to two categories. Dropdown menu shows for three or more
                          ].map((valIdx) => {
                            return (
                              <span
                                key={item?.[param.key][valIdx]?.category_id}
                                className={clsx(
                                  classes.gridDropdown,
                                  classes.centerVertically
                                )}>
                                {categoryComponent(
                                  item,
                                  item?.[param.key][valIdx],
                                  valIdx,
                                  param.paramN
                                )}
                                <span className={clsx(classes.flex)}>
                                  {openParticipantRegistration &&
                                    singleCategoryComponent(
                                      item,
                                      item?.[param.key][valIdx]?.category_id
                                    )}
                                </span>
                              </span>
                            );
                          })}
                        </>
                      );
                    }

                    return (
                      <span
                        key={paramIdx}
                        className={clsx(
                          classes.grid,
                          classes.recordValuesInnerWrapper,
                          param.list && classes.isCategoriesColumn
                        )}>
                        <span className={clsx(classes.flex, classes.column)}>
                          <span
                            className={clsx(
                              classes.placeholderRecord,
                              classes.flex,
                              classes.centerVertically
                            )}>
                            {param.name}
                            {param.list && item?.[param.key]?.length > 2 && (
                              <DropdownUp
                                className={clsx(
                                  classes.flex,
                                  classes.onHover,
                                  classes.marginLeft04,
                                  !collapsed[item.id] && classes.rotate180
                                )}
                                onClick={() => {
                                  subItemToggle(item.id);
                                }}
                              />
                            )}
                            {param.list && item?.[param.key]?.length > 2 && (
                              <span
                                className={clsx(
                                  classes.marginLeft04,
                                  classes.numOfCategories
                                )}>
                                {item?.[param.key]?.length}
                              </span>
                            )}
                          </span>
                          <span
                            className={clsx(
                              classes.valueRecord,
                              classes.ellipsis
                            )}>
                            {recordValue}
                            {param.list && (
                              <Collapse
                                in={collapsed[item.id]}
                                timeout="auto"
                                unmountOnExit>
                                {[
                                  ...(item?.categories_info?.slice(2) ?? [])
                                ].map((categoryVal, categoryIdx) => {
                                  return (
                                    <span
                                      key={categoryIdx}
                                      className={clsx(
                                        classes.gridDropdown,
                                        classes.centerVertically
                                      )}>
                                      {categoryComponent(
                                        item,
                                        categoryVal,
                                        categoryIdx,
                                        'category_name'
                                      )}
                                      {openParticipantRegistration &&
                                        singleCategoryComponent(
                                          item,
                                          categoryVal?.category_id
                                        )}
                                    </span>
                                  );
                                })}
                              </Collapse>
                            )}
                          </span>
                        </span>
                      </span>
                    );
                  })}
                </span>
              </span>
            );
          })}

        <ArrowTop
          id="scroll_to_top"
          onClick={() =>
            window.scroll({
              top: document.querySelector('#top').offsetTop,
              behavior: 'smooth'
            })
          }
          className={classes.scrollToTopBtn}
        />
      </span>
    </>
  );
};

export default QuickTournRegistration;
