import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  emptyState: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '20px',
    backgroundColor: theme.palette.background.default
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    width: 'fit-content',
    alignItems: 'center'
  },
  msgWrapper: {
    backgroundColor: theme.palette.background.default,
    border: '2px solid grey',
    width: '17em',
    minHeight: '10em',
    padding: '20px'
  },
  btn: {
    marginTop: '20px'
  },
  rightHand: {
    marginLeft: '-5px'
  },
  leftHand: {
    marginRight: '-5px'
  }
}));
