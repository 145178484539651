import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SwipeableDrawer, Avatar } from '@material-ui/core';

import { ReactComponent as Home } from '../../../../assets/img/home.svg';
import { ReactComponent as Users } from '../../../../assets/img/users.svg';
import { ReactComponent as Tournament } from '../../../../assets/img/tournament.svg';
import { ReactComponent as Participants } from '../../../../assets/img/participants.svg';
import { ReactComponent as LogOut } from '../../../../assets/img/logout.svg';
import { ReactComponent as Federation } from '../../../../assets/img/federation.svg';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import WarningIcon from '@material-ui/icons/Warning';
import { ReactComponent as Download } from '../../../../assets/img/downloads.svg';

import { IconButton } from '@material-ui/core';

import { DEFAULT_AVATAR } from '../../../../helpers/constants';
import { onLogout } from 'helpers/util';
import { roleName } from 'helpers/selectors';

import { AuthContext } from '../../../../AuthContext';
import { SidebarNav } from './components';

import { useStyles } from './StylesSideBar';
import { Pages } from '@material-ui/icons';

const Sidebar = (props) => {
  const { open, onClose, onOpen } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const authContext = useContext(AuthContext);
  const role = authContext.authState.role;

  const adminPages = [
    {
      title: 'Home',
      href: '/',
      icon: <Home />,
      id: 'sidebar-home-link'
    },
    {
      title: t('events'),
      href: '/events',
      icon: <Tournament />,
      id: 'sidebar-events-link'
    },
    {
      title: t('athletes'),
      href: '/participants',
      icon: <Participants />,
      id: 'sidebar-participants-link'
    },
    {
      title: t('categories'),
      href: '/categories',
      icon: <CardMembershipIcon />,
      id: 'sidebar-categories-link'
    },
    {
      title: t('users'),
      href: '/users',
      icon: <Users />,
      id: 'sidebar-users-link'
    },
    {
      title: t('reference'),
      href: '/reference',
      icon: <Pages />,
      id: 'sidebar-reference-link'
    },
    {
      title: t('moreApps'),
      href: '/more-apps',
      icon: <Download />,
      id: 'sidebar-settings-link'
    },
    {
      title: t('logs'),
      href: '/logs',
      icon: <WarningIcon />,
      id: 'sidebar-logs-link'
    }
  ];
  const masterPages = [
    {
      title: 'Home',
      href: '/',
      icon: <Home />,
      id: 'sidebar-home-link'
    },
    {
      title: t('events'),
      href: '/events',
      icon: <Tournament />,
      id: 'sidebar-events-link'
    },
    {
      title: t('myTeam'),
      href: '/participants',
      icon: <Participants />,
      id: 'sidebar-participants-link'
    },
    {
      title: t('myFederation'),
      href: '/federation',
      icon: <Federation />,
      id: 'sidebar-federation-link'
    },
    {
      title: t('categories'),
      href: '/categories',
      icon: <CardMembershipIcon />,
      id: 'sidebar-categories-link'
    },
    {
      title: t('users'),
      href: '/users',
      icon: <Users />,
      id: 'sidebar-users-link'
    },
    {
      title: t('moreApps'),
      href: '/more-apps',
      icon: <Download />,
      id: 'sidebar-settings-link'
    }
  ];
  const coachPages = [
    {
      title: 'Home',
      href: '/',
      icon: <Home />,
      id: 'sidebar-home-link'
    },
    {
      title: t('myTeam'),
      href: '/participants',
      icon: <Participants />,
      id: 'sidebar-participants-link'
    }
  ];

  const recorderPages = [
    {
      title: 'Home',
      href: '/',
      icon: <Home />,
      id: 'sidebar-home-link'
    }
  ];
  const operatorPages = [
    {
      title: t('moreApps'),
      href: '/more-apps',
      icon: <Download />,
      id: 'sidebar-settings-link'
    }
  ];

  const navItems = () => {
    switch (role) {
      case 'administrator':
        return adminPages;
      case 'master':
        return masterPages;
      case 'operator':
        return operatorPages;
      case 'recorder':
        return recorderPages;
      default:
        return coachPages;
    }
  };

  const avatarIcon = localStorage.getItem('avatar');
  const isRuLang = localStorage.getItem('i18nextLng') === 'ru';
  const firstN = localStorage.getItem('first_name');
  const lastN = localStorage.getItem('last_name');

  const FIRST_NAME = !isRuLang ? firstN : lastN;
  const LAST_NAME = !isRuLang ? lastN : firstN;

  return (
    <SwipeableDrawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      {...{ onClose }}
      {...{ open }}
      {...{ onOpen }}>
      <span className={clsx(classes.flex, classes.profile)}>
        <Avatar
          alt={t('profile')}
          className={classes.avatar}
          component={Link}
          src={avatarIcon ? avatarIcon : DEFAULT_AVATAR}
          to="/account"
          id="sidebar_avatar"
          onClick={onClose}
        />
        <span className={classes.profileDetailsWrapper}>
          <span className={clsx(classes.name, classes.ellipsis)}>
            {[FIRST_NAME, LAST_NAME].join(' ')}
          </span>
          <span className={clsx(classes.role, classes.text, classes.ellipsis)}>
            {roleName(role)}
          </span>
          {role !== 'operator' && role !== 'recorder' && (
            <Link
              onClick={onClose}
              className={clsx(classes.flex, classes.viewProfile, classes.blue)}
              to="/account"
            >
              {t('profile')}
            </Link>
          )}
        </span>
      </span>
      <SidebarNav pages={navItems()} {...{ onClose }}>
        <IconButton
          className={classes.signOutButton}
          onClick={() => {
            sessionStorage.clear();
            onLogout(authContext, () =>
              navigate('/sign-in', {
                state: { prevUrl: location.pathname }
              })
            );
          }}
          disableRipple>
          <LogOut className={classes.icon} />
          <span className={classes.text}>{t('logout')}</span>
        </IconButton>
      </SidebarNav>
    </SwipeableDrawer>
  );
};

export default Sidebar;
