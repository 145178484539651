import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as PublishedIcon } from '../../assets/img/published.svg';
import { ReactComponent as NoCountryIcon } from '../../assets/img/no_country.svg';
import { ReactComponent as CoachesIcon } from '../../assets/img/coaches.svg';
import { ReactComponent as TimerIcon } from '../../assets/img/timer.svg';
import { ReactComponent as BronzeFightIcon } from '../../assets/img/bronze_fight.svg';
import { ReactComponent as ParticipantsIcon } from '../../assets/img/event_participants.svg';
import { ReactComponent as NoParticipantNumIcon } from '../../assets/img/no_participant_num.svg';
import { ReactComponent as CloseFlagIcon } from '../../assets/img/close_flag.svg';
import CategoriesIcon from '@material-ui/icons/CardMembership';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { AuthContext } from '../../AuthContext';
import { ID, serverDefaultPath, DELETE_ICON } from '../../helpers/constants';
import {
  fetchTournaments,
  deleteTournament,
  changeModal,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchReferenceRegion,
  fetchReference
} from '../../helpers/util';
import LoadingState from '../../components/LoadingState/LoadingState';
import { selectedValue } from '../../helpers/selectors';
import SideModal from '../../components/Snackbar/SideModal';
import _ from 'lodash';
import { changeTitle } from '../../helpers/actions';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import AddTournament from '../AddTournament/AddTournament';
import Pagination from '../../components/TablePagination/TablePagination';

import styles from './Styles';
import QuestionMark from 'components/QuestionMark/QuestionMark';

class Tournaments extends Component {
  state = {
    tournamentsList: [],
    showModal: false,
    shouldShowForm: false,
    shouldUpdateTable: false,
    success: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    open: false,
    tournamentId: null,
    filteredTournaments: [],
    sortingFilters: [],
    tournamentsCount: 0,
    allRegions: [],
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      10,
    page: 1,
    iframeURL: {}
  };

  fetchTournaments = fetchTournaments.bind(this);
  deleteTournament = deleteTournament.bind(this);
  changeModal = changeModal.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  fetchReference = fetchReference.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { shouldUpdateTable, type, searchBar, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      shouldUpdateTable !== prevState.shouldUpdateTable
    ) {
      this.fetchTournaments(null, type, searchBar, null, true);
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('event'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { langOnLoad } = this.state;
    const { role } = this.context.authState;
    this.fetchTournaments(null, null, null, () =>
      this.setState({ loading: false })
    );

    changeTitle(this.props.t('event'));

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter((item) => item.name === 'events');
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );

    role === 'administrator' && this.setState({ type: 1 });
  }

  selectType = (event, value) => {
    const { searchBar } = this.state;
    if (value === null) {
      this.setState({ type: value });
      this.fetchTournaments(null, value, searchBar, null, true);
    } else {
      this.setState({ type: value.id });
      this.fetchTournaments(null, value.id, searchBar, null, true);
    }
  };

  rowClickedHandler = (evt, item) => {
    evt.preventDefault();

    const { navigate, location } = this.props;

    navigate('/events/event/' + item.id, {
      state: { prevUrl: location.pathname }
    });
  };

  searchHandler = (event) => {
    const { value } = event.target;
    const { tournamentsList } = this.state;
    this.setState(
      {
        searchBar: value,
        page: 1,
        filteredTournaments: tournamentsList.filter((tournament) => {
          return tournament.tournament_name
            .toLowerCase()
            .includes(value.toLowerCase().trim());
        })
      },
      () =>
        this.setState({
          tournamentsCount: this.state.filteredTournaments.length
        })
    );
  };

  orderTournamentsByColumn = (columnName) => {
    const { sortingFilters, filteredTournaments } = this.state;
    const sortingFiltersExisting = sortingFilters.indexOf(columnName);
    if (sortingFiltersExisting !== -1) {
      sortingFilters.splice(sortingFiltersExisting, 1);
    } else {
      sortingFilters.push(columnName);
    }
    const sortedFilteredTournament = _.orderBy(
      filteredTournaments,
      [...sortingFilters],
      ['asc']
    );
    this.setState({
      sortingFilters,
      filteredTournaments: sortedFilteredTournament
    });
  };

  showFormHandler = (evt, tournament) => {
    evt.preventDefault();

    const id = tournament && tournament.id;

    this.setState({
      shouldShowForm: true,
      tournamentId: id
    });

    if (id) {
      this.fetchReferenceRegion(tournament?.c_id);

      this.setState({
        tournamentData: {
          ...tournament,
          ...(tournament.poster
            ? { imagePreview: serverDefaultPath + tournament.poster }
            : {}),
          country_id: tournament.c_id,
          date: tournament.start_date,
          tour_time: tournament.start_time,
          tournament_id: tournament.id,
          id: tournament.id
        },
        selectedRow: id
      });
    } else {
      this.setState({ tournamentData: {} });
    }

    window.scroll({
      top: document.querySelector('#top').offsetTop,
      behavior: 'smooth'
    });
  };

  showModal = (item) => {
    this.setState({ tournamentId: item.id, open: true, modalData: item });
  };

  triggerTableUpdate = () => this.setState({ shouldUpdateTable: true });

  closeFormHandler = () => {
    this.setState({ shouldShowForm: false, selectedRow: null });
  };

  hideModal = () => this.setState({ open: false });

  closeModalHandler = () => this.setState({ showModal: false });

  clearSearch = () => {
    const { tournamentsList } = this.state;
    const len = tournamentsList?.length;

    this.setState({
      searchBar: '',
      filteredTournaments: tournamentsList,
      tournamentsCount: len
    });
  };

  render() {
    const {
      showModal,
      success,
      modalInfo,
      filteredTournaments,
      open,
      shouldShowForm,
      loading,
      searchBar,
      tournamentId,
      selectedRow,
      type,
      tournamentsCount,
      page,
      rowsPerPage,
      modalData,
      iframeURL
    } = this.state;
    const { t, classes } = this.props;
    const { role } = this.context.authState;

    const TOURNAMENT_TYPE = [
      { id: 1, label: t('upcoming') },
      { id: 2, label: t('finished') }
    ];

    const header = [
      { label: t('code') },
      ...(role === 'administrator' ? [{ label: t('organizer') }] : []),
      { label: t('nameEvent') },
      { label: t('eventType') },
      { label: t('participantsNum') },
      { label: t('date') },
      ...(role === 'administrator' ? [{ label: `Reg. ${t('date')}` }] : []),
      ...(role === 'master' ? [{ label: t('time') }] : []),
      { label: t('eventStatus') },
      { label: t('country') },
      { label: t('region') },
      { label: t('city') },
      ...(role === 'administrator' ? [{ icon: <PublishedIcon />, tooltipLabel: t('publishTourn') }] : []),
      ...(role === 'administrator' ? [{ icon: <CloseFlagIcon />, tooltipLabel: t('eventClosed') }] : []),
      ...(role === 'administrator' ? [{ icon: <BronzeFightIcon />, tooltipLabel: t('enableBronzeFight') }] : []),
      ...(role === 'administrator' ? [{ icon: <NoParticipantNumIcon />, tooltipLabel: t('noPartNumber') }] : []),
      ...(role === 'administrator' ? [{ icon: <NoCountryIcon />, tooltipLabel: t('noCountry') }] : []),
      ...(role === 'administrator' ? [{ icon: <TimerIcon />, tooltipLabel: t('active') }] : []),
      ...(role === 'administrator' ? [{ icon: <AutorenewIcon />, tooltipLabel: t('сreationDate') }] : []),
      ...(role === 'administrator' ? [{ icon: <CategoriesIcon />, tooltipLabel: t('categoriesCount') }] : []),
      ...(role === 'administrator' ? [{ icon: <CoachesIcon />, tooltipLabel: t('registrarsCount') }] : []),
      ...(role === 'master' ? [{ label: t('address') }] : [])
    ];

    const tableBody = [
      'id',
      ...(role === 'administrator' ? ['organizer'] : []),
      'tournament_name',
      'tournament_type',
      'participants_count',
      'start_date',
      ...(role === 'administrator' ? ['registration_date'] : []),
      ...(role === 'master' ? ['start_time'] : []),
      'registration_active',
      'iso3',
      'region',
      'city',
      ...(role === 'administrator' ? ['published'] : []),
      ...(role === 'administrator' ? ['finished'] : []),
      ...(role === 'administrator' ? ['bronze_fight_enabled'] : []),
      ...(role === 'administrator' ? ['no_participant_number'] : []),
      ...(role === 'administrator' ? ['no_country'] : []),
      ...(role === 'administrator' ? ['active'] : []),
      ...(role === 'administrator' ? ['update_date'] : []),

      ...(role === 'administrator' ? ['categories_count'] : []),
      ...(role === 'administrator' ? ['coaches_count'] : []),
      ...(role === 'master' ? ['address'] : [])
    ];

    const condensedTableData = {
      mainName: 'tournament_name',
      info: [
        { key: 'tournament_type', name: t('eventType') },
        { key: 'participants_count', name: t('participantsNum') },
        { key: 'start_date', name: t('date') },
        { key: 'start_time', name: t('time') },
        { key: 'registration_active', name: t('eventStatus') },
        { key: 'iso3', name: 'ISO' },
        { key: 'region', name: t('region') },
        { key: 'city', name: t('city') },
        { key: 'categories_count', name: t('categories') }
      ]
    };

    let tournamentForm = null;
    if (shouldShowForm) {
      tournamentForm = (
        <AddTournament
          state={this.state}
          closeFormHandler={this.closeFormHandler}
          triggerTableUpdate={this.triggerTableUpdate}
        />
      );
    }

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.closeModalHandler}
          success={success}
          show={showModal}
          info={modalInfo}
        />
        {tournamentForm}
        <Modal
          {...{ open }}
          close={this.hideModal}
          onClick={() => this.deleteTournament(tournamentId)}
          dialogTitle={t('deleteRecord', { name: t('event') })}
          dialogContent={t('deleteEventMsg')}
          subHeader={
            modalData &&
            modalData.id && (
              <span className={clsx(classes.flex, classes.marginTop1)}>
                {modalData.tournament_name}
              </span>
            )
          }
          buttonPurpose={t('deleteRecord', { name: '' })}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <Table
            maxColumnWidth={classes.maxColumnWidth}
            shouldShowCondensedView
            {...{ condensedTableData }}
            search={searchBar}
            clearSearch={this.clearSearch}
            textChange={this.searchHandler}
            showButton={!shouldShowForm}
            {...{ role }}
            filter={[
              {
                options: TOURNAMENT_TYPE,
                onChange: this.selectType,
                label: t('events'),
                item: 'label',
                value: selectedValue(TOURNAMENT_TYPE, ID, type)
              }
            ]}
            {...{ header }}
            body={filteredTournaments.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )}
            openEmptyForm={(evt) => this.showFormHandler(evt)}
            data={tableBody}
            onClick={(evt, item) => this.rowClickedHandler(evt, item)}
            shouldAllowEditing={() => true}
            actionIcons={(
              item,
              _,
              className,
              iconWrapper,
              marginLeft,
              marginBottom
            ) => (
              <>
                {!(
                  role === 'master' &&
                  (item.finished === '1' || !+item.is_master)
                ) && (
                  <>
                    <Tooltip title={t('updateRecord', { name: t('event') })}>
                      <EditIcon
                        className={clsx(iconWrapper, className, marginBottom)}
                        onClick={(evt) => this.showFormHandler(evt, item)}
                      />
                    </Tooltip>
                    <Tooltip title={t('deleteRecord', { name: t('event') })}>
                      <DeleteIcon
                        id={DELETE_ICON}
                        className={clsx(className, iconWrapper, marginLeft)}
                        onClick={() => this.showModal(item)}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )}
            selected={(item) => selectedRow === item.id}
            paginationComponent={
              filteredTournaments.length > 0 && (
                <Pagination
                  count={tournamentsCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              )
            }
          />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Tournaments)));
