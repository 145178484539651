import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    tablePagination: {
        padding: '20px 0',
        position: 'relative',
        '&.MuiCardActions-spacing > div > div > p:nth-child(4)': {
            width: '100%',
            textAlign: 'center',
        },
        '&.MuiCardActions-spacing > div > div > div.MuiTablePagination-actions > button:nth-child(2)': {
            marginLeft: 'auto',
            marginRight: '20px',
        },
        '&.MuiCardActions-spacing > div > div > div.MuiTablePagination-actions > button': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            width: '70px',
            height: '40px',
            borderRadius: '4px',
            '@media (max-width:900px)': {
                maxWidth: '50px',
            },
        },
    },
    toolbar: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        '&.MuiToolbar-gutters': {
            padding: 0,
        },
    },
    fullWidth: {
        width: '100%',
    },
    action: {
        flexShrink: 1,
        display: 'flex',
        position: 'absolute',
        maxWidth: 'calc(100% - 20px)',
    },
    selectRoot: {
        marginRight: 0,
    },
    input: {
        zIndex: 1,
    },
}))