import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  root: {
    height: '5em',
    boxShadow: 'none',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex'
  },
  column: {
    flexDirection: 'column'
  },
  alignRight: {
    marginLeft: 'auto'
  },
  marginRight: {
    marginRight: '1em'
  },
  pageTitle: {
    fontSize: '2rem',
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    display: 'block',
    margin: '0 1em',
    '@media (max-width:900px)': {
      fontSize: '22px',
      margin: '0 .5em'
    }
  },
  bell: {
    maxWidth: '3em'
  },
  toolbar: {
    '&.MuiToolbar-gutters': {
      padding: '0 1em'
    }
  },
  menu: {
    width: '3em',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.3
    }
  },
  logo: {
    height: '3em',
    '@media (max-width:900px)': {
      height: '2em',
      width: 'auto'
    },
    width: 'fit-content'
  },
  btn: {
    border: '1px solid #fff',
    borderRadius: '4px',
    height: '40px',
    padding: '5px 10px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    minWidth: 'fit-content'
  },
  dropdownIcon: {
    '& .MuiAutocomplete-hasPopupIcon, & .MuiAutocomplete-inputRoot': {
      paddingRight: '1em'
    }
  },
  txt: {
    fontSize: '1rem',
    color: '#fff'
  },
  exit: {
    cursor: 'pointer',
    marginLeft: '.65em'
  }
});
