import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  content: {
    '@media (min-width:901px)': {
      paddingBottom: '10px'
    },
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginLeft: 'calc(100vw - 100%)'
  },
  paddingTop: {
    paddingTop: '7em'
  },
  body: {
    minHeight: 'calc(100vh - 10em)',
    '@media (max-width:900px)': {
      width: 'calc(100vw - 3em)'
    },
    '@media (min-width:901px)': {
      width: 'calc(100vw - 5.125em)'
    },
    margin: '0 auto'
  },
  footer: {
    marginTop: '15px',
    textAlign: 'center'
  },
  footerTxt: {
    color: '#000',
    fontSize: '1rem'
  }
}));
