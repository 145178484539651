import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { PRODUCT_LANG, LANG, LABEL } from '../../../../helpers/constants';
import { selectedValue } from '../../../../helpers/selectors';
import { AppBar, Toolbar } from '@material-ui/core';
import { ReactComponent as Menu } from '../../../../assets/img/menu.svg';
import { ReactComponent as Logo } from '../../../../assets/img/short_logo_white.svg';
import { ReactComponent as Bell } from '../../../../assets/img/bell.svg';
import Filter from '../../../../components/Filter/Filter';
import { useStyles } from './StyleTopbarP2';

const TopbarP2 = (props) => {
  const { onSidebarOpen, pageTitle } = props;

  const classes = useStyles();
  const { i18n } = useTranslation();
  const switchLang = (evt, item) => {
    i18n.changeLanguage(item.lang);
  };

  return (
    <AppBar className={clsx(classes.flex, classes.root)}>
      <Toolbar classes={{ gutters: classes.toolbar }}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <span id="top" className={classes.pageTitle}>
          {pageTitle}
        </span>
        <Bell
          className={clsx(
            classes.alignRight,
            classes.marginRight,
            classes.bell
          )}
        />
        <Filter
          className={classes.marginRight}
          classes={{
            inputRoot: clsx(classes.btn, classes.txt),
            hasPopupIcon: classes.dropdownIcon
          }}
          shouldLookLikeBtn={true}
          options={PRODUCT_LANG}
          onChange={switchLang}
          item={LABEL}
          value={
            selectedValue(
              PRODUCT_LANG,
              LANG,
              localStorage.getItem('i18nextLng') || 'en'
            ) || ''
          }
        />
        <Menu className={classes.menu} onClick={onSidebarOpen} />
      </Toolbar>
    </AppBar>
  );
};

export default TopbarP2;
