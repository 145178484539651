import React, { useState } from 'react';
import { Close } from '@material-ui/icons';
import { useStyles } from './styles';
import clsx from 'clsx';
import { ReactComponent as QuestionMarkIc } from '../../assets/img/question_mark.svg';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

const QuestionMark = (props) => {
  const { src, tooltip } = props;
  const classes = useStyles();
  const [iframeVisible, setIframeVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {iframeVisible ? (
        <Close
          className={clsx(
            classes.fixed,
            classes.pointer,
            classes.scale12,
            classes.close
          )}
          onClick={() => setIframeVisible(!iframeVisible)}
        />
      ) : (
        <Tooltip arrow title={tooltip ?? ''}>
          <QuestionMarkIc
            className={clsx(
              classes.fixed,
              classes.pointer,
              classes.scale,
              classes.scale08,
              classes.questionMark
            )}
            onClick={() => setIframeVisible(!iframeVisible)}
          />
        </Tooltip>
      )}
      {iframeVisible && (
        <div
          className={clsx(
            classes.main,
            classes.fixed,
            classes.iframeLoc,
            classes.border
          )}>
          {iframeVisible && src ? (
            <div className={classes.iframe}>
              <iframe
                src={src}
                height="100%"
                width="100%"
                allowTransparency="true"
              />
            </div>
          ) : (
            iframeVisible && (
              <div
                className={clsx(
                  classes.iframe,
                  classes.backColorWhite,
                  classes.wrapper,
                  classes.border
                )}>
                <div>
                  <img
                    alt="Under development"
                    className={classes.image}
                    src="/images/404_Error.png"
                  />
                  <span className={classes.txt}>
                    404: {t('docIsBeingWritten')}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default QuestionMark;
