import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useStyles } from './styles';

const CheckboxBtn = (props) => {
  const {
    onChange,
    label,
    checked,
    indeterminate,
    className,
    classControlLabel,
    formControlClassName,
    labelPlacement,
    disabled,
    classForLabel
  } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      className={clsx(classes.checkboxIcon, formControlClassName)}
      classes={{
        root: classControlLabel,
        label: classForLabel
      }}
      {...{ onChange }}
      control={
        <Checkbox
          classes={{ root: className }}
          {...{ checked }}
          {...{ indeterminate }}
          color="primary"
          {...{ disabled }}
        />
      }
      {...{ label }}
      {...{ labelPlacement }}
    />
  );
};
export default CheckboxBtn;
