import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import ArrowIc from '@material-ui/icons/ArrowForward';
import { ReactComponent as CloseFlagIcon } from '../../assets/img/close_flag.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
  USERS,
  DELETE_ICON,
  DB_ROLES,
  DB_STATUSES,
  DB_QUERY_TYPE,
  ID,
  serverDefaultPath,
  NAME
} from '../../helpers/constants';
import {
  pageChangeHandler,
  rowsPerPageHandler,
  selectOption,
  onLogout,
  signIn,
  fetchUsersAsAdmin,
  deleteTableRow,
  textChangeHandler,
  fetchReferenceRegion,
  changeDate,
  fetchUsers,
  updateUserDetails,
  validateForm,
  fetchCountries,
  changeImage,
  changeModal
} from '../../helpers/util';
import { changeTitle } from 'helpers/actions';
import { selectedValue, findSortDirection } from 'helpers/selectors';

import { AuthContext } from '../../AuthContext';

import Pagination from '../../components/TablePagination/TablePagination';
import SideModal from '../../components/Snackbar/SideModal';
import Modal from '../../components/Modal/ModalNewDesign';
import Table from '../../components/Table/Table';
import LoadingState from '../../components/LoadingState/LoadingState';
import UserFrom from '../../components/AccountDetails/AccountDetailsForm';

class Users extends Component {
  state = {
    users: [],
    open: false,
    showModal: false,
    success: true,
    filteredUsers: [],
    loading: true,
    filters: { statusID: 1 },
    modalData: {},
    countries: [],
    allRegions: [],
    errors: {},
    pressedBtn: null,
    sortDirection: [],
    page: 1,
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      10,
    langOnLoad: localStorage.getItem('i18nextLng')
  };

  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  selectOption = selectOption.bind(this);
  onLogout = onLogout.bind(this);
  signIn = signIn.bind(this);
  fetchUsersAsAdmin = fetchUsersAsAdmin.bind(this);
  deleteTableRow = deleteTableRow.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  changeDate = changeDate.bind(this);
  fetchCountries = fetchCountries.bind(this);
  fetchUsers = fetchUsers.bind(this);
  updateUserDetails = updateUserDetails.bind(this);
  validateForm = validateForm.bind(this);
  changeImage = changeImage.bind(this);
  changeModal = changeModal.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeTitle(t('users'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    this.fetchData();
    this.fetchCountries();

    changeTitle(this.props.t('users'));
  }

  fetchData = () => {
    const { filters, page, rowsPerPage, sortDirection } = this.state;

    this.fetchUsersAsAdmin(
      filters,
      page,
      rowsPerPage,
      (data) => {
        const recordCount = data?.length ? 10000 : 0;
        this.setState({
          loading: false,
          users: data,
          filteredUsers: data,
          recordCount
        });
      },
      sortDirection
    );
  };

  triggerTableUpdate = () => {
    this.fetchData();
    this.hideModal();
  };

  showModal = (evt, item, idx) => {
    evt.preventDefault();

    if (item?.country) {
      this.fetchReferenceRegion(item?.country);
    }

    this.fetchUsers(item.id, 'modalData', (user) => {
      const pressedBtn = evt.target.id;
      const modalData = {
        ...user,
        ...(user.photo ? { imagePreview: serverDefaultPath + user.photo } : {}),
        coach_first_name: user.coach_first_name ?? '',
        coach_last_name: user.coach_last_name ?? '',
        region: user.region ?? '',
        currentRecordIdx: idx
      };

      this.setState({ modalData, pressedBtn, open: true });
    });
  };

  hideModal = () => {
    this.setState({ modalData: {}, pressedBtn: null, open: false });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  editUserDetails = (evt) => {
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const { modalData } = this.state;
        const form = { ...modalData };

        this.validateForm(
          evt,
          {
            first_name: '',
            last_name: ''
          },
          form,
          'errors',
          () => {
            this.updateUserDetails(evt, modalData, () => {
              this.triggerTableUpdate();
            });
          }
        );
      }
    );
  };

  onPageChange = (e, next) => {
    const { isSearching } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.searchHandler();
      } else {
        this.fetchData();
      }
    });
  };

  searchHandler = () => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          searchedValue: this.state.filters?.searchBar
        },
        page: 1
      }),
      () => {
        this.fetchData();
      }
    );
  };

  handleTextChange = (evt) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, searchBar: evt.target.value }
    }));
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.searchHandler()
    );
  };

  pressEnterHandler = (evt) => {
    if (evt.key === 'Enter') {
      this.searchHandler();
    }
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onSort = (sortField, idxCell) => {
    const { sortDirection } = this.state;
    const defaultDirection = findSortDirection(sortDirection, sortField);
    const direction = defaultDirection === 2 ? 0 : defaultDirection;

    this.setState(
      { sortDirection: [direction, idxCell, sortField], page: 1 },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const {
      success,
      open,
      showModal,
      modalInfo,
      filters,
      modalData,
      errors,
      pressedBtn,
      filteredUsers,
      allRegions,
      countries,
      users,
      page,
      rowsPerPage,
      recordCount,
      sortDirection,
      loading
    } = this.state;
    const { t } = this.props;

    const header = [
      { label: 'ID' },
      { num: 'status_name', label: t('status') },
      { num: 'role_name', label: t('role') },
      { icon: <CloseFlagIcon /> },
      { num: 'full_name', label: t('name') },
      { num: 'username', label: 'Username' },
      { num: 'email', label: 'Email' },
      { label: t('gender') },
      { num: 'country_name', label: t('countries') },
      { num: 'city', label: t('city') },
      { num: 'region', label: t('region') },
      { num: 'club', label: t('club') },
      { num: 'phone_number', label: t('phone') },
      { num: 'update_date', icon: <AutorenewIcon /> }
    ];

    const tableBody = [
      'id',
      'status_name',
      'role_name',
      'language_id',
      { firstName: 'first_name', lastName: 'last_name', photo: 'photo' },
      'username',
      'email',
      'gender',
      'country_iso3',
      'city',
      'region',
      'club',
      'phone_number',
      'update_date'
    ];

    const condensedTableData = {
      mainName: 'full_name',
      photo: 'photo',
      info: [
        { key: 'email', name: 'Email' },
        { key: 'country_iso3', name: t('country') },
        { key: 'club', name: t('club') },
        { key: 'roleName', name: t('role') }
      ]
    };

    const ALL_FILTERS = [
      {
        options: DB_STATUSES,
        onChange: (e, val) =>
          this.selectOption(
            e,
            val,
            'filters',
            'statusID',
            ID,
            null,
            this.fetchData
          ),
        label: t('status'),
        item: NAME,
        value: selectedValue(DB_STATUSES, ID, filters.statusID, true)
      },
      {
        options: DB_ROLES,
        onChange: (e, val) =>
          this.selectOption(
            e,
            val,
            'filters',
            'roleID',
            ID,
            null,
            this.fetchData
          ),
        label: t('role'),
        item: NAME,
        value: selectedValue(DB_ROLES, ID, filters.roleID, true)
      },
      {
        options: DB_QUERY_TYPE,
        onChange: (e, val) =>
          this.selectOption(
            e,
            val,
            'filters',
            'queryTypeID',
            ID,
            null,
            this.fetchData
          ),
        label: 'Type',
        item: NAME,
        value: selectedValue(DB_QUERY_TYPE, ID, filters.queryTypeID, true)
      }
    ];

    let dialogTitle, onSwitchBetween, dialogContent, onClick, buttonPurpose;

    if (pressedBtn === DELETE_ICON) {
      dialogTitle = t('deleteUser');
      dialogContent = t('deleteUserMsg');
      onClick = () => {
        this.deleteTableRow(USERS, modalData?.id, this.triggerTableUpdate);
      };
      buttonPurpose = t('deleteRecord', { name: '' });
    } else {
      dialogTitle = t('updateRecord', { name: t('user') });
      onSwitchBetween = {
        prev: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const item = filteredUsers[nextItemIdx];

            this.showModal(evt, item, nextItemIdx);
          }
        },
        next: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const len = filteredUsers?.length - 1;

          if (len >= nextItemIdx) {
            const item = filteredUsers[nextItemIdx];

            this.showModal(evt, item, nextItemIdx);
          }
        }
      };

      dialogContent = (
        <UserFrom
          updateUser={(evt) => this.editUserDetails(evt)}
          values={modalData}
          handleChange={(evt) =>
            this.textChangeHandler(evt, 'modalData', errors)
          }
          dateChange={(date) => this.changeDate(date, 'modalData', errors)}
          {...{ errors, countries, allRegions }}
          selectOption={this.selectOption}
          formName={'modalData'}
          fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
          onChangeImg={this.changeImage}
        />
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              {...{
                dialogTitle,
                onSwitchBetween,
                dialogContent,
                onClick,
                buttonPurpose
              }}
              isOldDesign
            />
            <Table
              filter={[...ALL_FILTERS]}
              {...{ condensedTableData }}
              search={filters.searchBar}
              pressEnter={this.pressEnterHandler}
              clearSearch={this.clearSearch}
              textChange={this.handleTextChange}
              {...{ header }}
              body={filteredUsers}
              onClick={(evt, item, idx) => this.showModal(evt, item, idx)}
              selected={(item) => +modalData?.id === +item?.id}
              onSort={this.onSort}
              {...{ sortDirection }}
              data={tableBody}
              shouldAllowEditing={() => true}
              actionIcons={(
                item,
                idx,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) => (
                <>
                  <EditIcon
                    className={clsx(className, iconWrapper, marginBottom)}
                    onClick={(evt) => this.showModal(evt, item, idx)}
                  />
                  {item.id !== localStorage.getItem('user_id') && (
                    <DeleteIcon
                      id={DELETE_ICON}
                      className={clsx(className, iconWrapper, marginLeft)}
                      onClick={(evt) => this.showModal(evt, item)}
                    />
                  )}
                  {+item?.status_id === 1 && (
                    <ArrowIc
                      className={clsx(className, iconWrapper, marginLeft)}
                      onClick={(evt) => {
                        const token = localStorage.getItem('token');

                        this.onLogout(this.context, () => {
                          localStorage.setItem('token', token);

                          this.signIn(
                            evt,
                            { values: { user_id: item?.id } },
                            (key) => {
                              this.context.changeAuthState(key);
                            }
                          );
                        });
                      }}
                    />
                  )}
                </>
              )}
              paginationComponent={
                users?.length > 0 && (
                  <Pagination
                    count={recordCount}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={(evt) =>
                      this.rowsPerPageHandler(evt, this.searchHandler)
                    }
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              }
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(Users));
