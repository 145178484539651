import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ro, ru, de } from 'date-fns/locale';
import clsx from 'clsx';
import UploadImage from '../UploadImage/UploadImage';
import { TextField } from '@material-ui/core';
import Filter from '../Filter/Filter';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import {
  DATE_PLACEHOLDER,
  ID,
  COUNTRY_ID,
  TYPE,
  PRIZE_PLACES,
  LABEL,
  ENABLED,
  NAME,
  getEventTypes,
  EMAIL
} from '../../helpers/constants';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import { selectedValue, countryLabel, isSchool } from '../../helpers/selectors';
import { ReactComponent as DateIcon } from '../../assets/img/date.svg';
import PagesHeader from '../PagesHeader/PagesHeader';
import Button from '../Buttons/ActionButtons';
import { useStyle, styleFilter } from './Styles';
import { AuthContext } from 'AuthContext';

const TournamentForm = ({
  handleTextChange,
  countries,
  tournamentDateChange,
  registrationDateChange,
  tournamentTimeChange,
  errors,
  imageChange,
  values,
  updateAble,
  saveForm,
  toggleTournSettings,
  selectedCheckboxes,
  shouldShowTournSettings,
  selectCheckbox,
  selectOption,
  state,
  closeFormHandler,
  goBack,
  switchRegistration,
  showModal,
  calculateNumberOfYearsDateChange,
  tatamisTheme,
  fetchRegionBySelectedCountry,
  allRegions,
  tournamentData,
  tournamentToImportSettings,
  tournamentsList,
  importSettings
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const isTraining = isSchool(values);

  const currentLang = localStorage.getItem('i18nextLng');
  const context = useContext(AuthContext);
  const role = context.authState.role;
  const isNotMaster =
    role === 'master' && tournamentData?.id && !+tournamentData?.is_master;
  const finishedTourn = !!+tournamentData?.finished || isNotMaster;

  const colors = [
    { id: 'white', name: t('white') },
    { id: 'red', name: t('red') },
    { id: 'blue', name: t('blue') },
    { id: 'transparent', name: t('transparent') }
  ];

  const filteredDownColors =
    values.participant_up_color !== 'transparent'
      ? colors.filter((color) => color.id !== values.participant_up_color)
      : colors;

  const filteredTopColors =
    values.participant_down_color !== 'transparent'
      ? colors.filter((color) => color.id !== values.participant_down_color)
      : colors;

  const tournSettings = [
    ...(!isTraining
      ? [
          {
            name: t('kumiteSettings'),
            items: [
              {
                id: 'bronze_fight_enabled',
                label: t('enableBronzeFight'),
                hasCheckbox: true
              },
              {
                id: 'circle_round',
                label: t('circleRound'),
                hasCheckbox: true
              },
              { id: 'tameshiwari', hasCheckbox: true },
              { id: 'places_number' }
            ]
          }
        ]
      : []),
    {
      name: t('tournSettings'),
      className: classes.paddingInput05,
      items: [
        { id: 'published', label: t('publishTourn'), hasCheckbox: true },
        ...(!isTraining
          ? [
              {
                id: 'no_participant_number',
                label: t('noPartNumber'),
                hasCheckbox: true
              }
            ]
          : []),
        { id: 'last_name_first', label: t('lastNameFirst'), hasCheckbox: true },
        { id: 'kata_final' },
        {
          id: 'use_alt_category_name',
          label: t('useAlternativeCategoryName'),
          hasCheckbox: true
        },
        {
          id: 'use_notification_win',
          label: t('sendingNotifications'),
          hasCheckbox: true
        }
      ]
    },
    ...(!isTraining
      ? [
          {
            name: t('leaderboardSettings'),
            items: [
              { id: 'scoreboard_theme' },
              { id: 'participant_up_color' },
              { id: 'participant_down_color' }
            ]
          }
        ]
      : []),
    {
      name: t('generalSettings'),
      items: [
        {
          id: 'reg_judge_form',
          label: t('registrationOfJudges'),
          hasCheckbox: true
        },
        {
          id: 'use_qr',
          label: t('useQRcode'),
          hasCheckbox: true
        },
        {
          id: 'use_reg_online',
          label: t('preTournamentRegistration'),
          hasCheckbox: true
        },
        { id: 'calc_age_date', label: t('date2CalculateNumberOfYears') }
      ]
    },
    {
      name: t('registration'),
      className: classes.paddingInput05,
      items: [
        { id: 'show_reg_iko', label: t('showIkoField'), hasCheckbox: true },
        { id: 'show_reg_qdan', label: t('showQdanField'), hasCheckbox: true },
        {
          id: 'show_reg_patronymic',
          label: t('showMiddleName'),
          hasCheckbox: true
        },
        { id: 'no_country', label: t('noCountry'), hasCheckbox: true },
        {
          id: 'show_reg_region',
          label: t('showRegionField'),
          hasCheckbox: true
        }
      ]
    },
    {
      className: classes.marginTop1,
      items: [
        { id: 'show_reg_city', label: t('showCityField'), hasCheckbox: true },
        { id: 'show_reg_club', label: t('showClubField'), hasCheckbox: true },
        { id: 'height_needed', label: t('specifyHeight'), hasCheckbox: true },
        {
          id: 'sport_rank_needed',
          label: t('indicateSportsRank'),
          hasCheckbox: true
        },
        { id: 'show_reg_coach_2', label: t('specifyCoach2'), hasCheckbox: true }
      ]
    }
  ];

  const showSettings = () => (
    <span className={clsx(classes.flex, classes.wrap)}>
      {tournSettings.map((compartment, compartmentIdx) => (
        <span
          className={clsx(
            classes.flex,
            classes.column,
            classes.settingCompartment,
            compartment.className
          )}
          key={compartmentIdx}>
          <span className={classes.settingsName}>{compartment.name}</span>
          {compartment.items &&
            compartment.items.map(({ id, label, hasCheckbox }) => (
              <Fragment key={id}>
                {hasCheckbox && (
                  <CheckboxBtn
                    classForLabel={id === 'tameshiwari' && classes.tameshiwari}
                    disabled={finishedTourn}
                    classControlLabel={classes.marginRight0}
                    checked={selectedCheckboxes.includes(id)}
                    onChange={() => selectCheckbox(id)}
                    label={
                      id === 'tameshiwari'
                        ? textFieldComponent(
                            'tameshiwari',
                            t('tameshiwari'),
                            classes.tameshiwariInput,
                            null,
                            null,
                            true,
                            !selectedCheckboxes.includes('tameshiwari')
                          )
                        : label
                    }
                  />
                )}
                {!isTraining &&
                  id === 'kata_final' &&
                  textFieldComponent(
                    'kata_final',
                    t('kataFinal'),
                    null,
                    null,
                    null,
                    true
                  )}
                {!isTraining &&
                  id === 'places_number' &&
                  filter(
                    t('numberOfPlaces'),
                    PRIZE_PLACES,
                    'places_number',
                    LABEL,
                    LABEL,
                    null,
                    t('numberOfPlacesHelperTxt')
                  )}
                {!isTraining &&
                  id === 'scoreboard_theme' &&
                  filter(
                    t('scoreboardColor'),
                    tatamisTheme,
                    'scoreboard_theme',
                    ID,
                    NAME,
                    null,
                    null
                  )}
                {!isTraining &&
                  id === 'participant_up_color' &&
                  filter(
                    t('participantUpColor'),
                    filteredTopColors,
                    'participant_up_color',
                    ID,
                    NAME,
                    null,
                    null
                  )}
                {!isTraining &&
                  id === 'participant_down_color' &&
                  filter(
                    t('participantDownColor'),
                    filteredDownColors,
                    'participant_down_color',
                    ID,
                    NAME,
                    null,
                    t('colorsHelperTxt')
                  )}
                {!isTraining &&
                  id === 'calc_age_date' &&
                  dateComponent(
                    calculateNumberOfYearsDateChange,
                    label,
                    id,
                    classes.fullWidth
                  )}
              </Fragment>
            ))}
        </span>
      ))}
    </span>
  );

  const inputs = [
    [
      { type: true, className: [classes.input, classes.maxHeight] },
      {
        name: 'tournament_name',
        label: t('eventName'),
        required: true,
        className: [
          classes.input,
          classes.paddingInput05,
          classes.maxHeightTxtInput
        ]
      },
      {
        country: true,
        required: true,
        className: [
          classes.input,
          classes.inputCondensedView,
          classes.maxHeight
        ]
      }
    ],
    [
      allRegions?.length > 0
        ? {
            region: true,
            className: [
              classes.input,
              classes.inputCondensedView,
              classes.maxHeight
            ]
          }
        : {
            name: 'region',
            label: t('district'),
            className: [
              classes.input,
              classes.maxHeightTxtInput,
              classes.inputCondensedView
            ]
          },
      {
        name: 'city',
        label: t('city'),
        required: true,
        className: [
          classes.input,
          classes.maxHeightTxtInput,
          classes.paddingInput05,
          classes.inputCondensedView
        ]
      },
      {
        name: 'address',
        label: t('address'),
        required: true,
        className: [
          classes.input,
          classes.maxHeightTxtInput,
          classes.inputCondensedView
        ]
      }
    ],
    [
      { time: true },
      { startEvent: true },
      {
        name: 'duration_days',
        label: t('durationDays'),
        className: [
          classes.input,
          classes.maxHeightTxtInput,
          classes.inputCondensedView
        ]
      }
    ],
    [
      isTraining && { endReg: true },
      updateAble && {
        name: 'description',
        label: t('description'),
        rows: true,
        className: [classes.width100, classes.inputCondensedView]
      }
    ].filter(Boolean),
    !isTraining && [{ comissionLabel: true }],
    !isTraining && [
      {
        name: 'reg_address',
        label: t('address'),
        className: [
          classes.input,
          classes.maxHeightTxtInput,
          classes.inputCondensedView
        ]
      },
      { regDate: true },
      { reg_time: true }
    ],
    !isTraining && [{ endReg: true }, { reg_judge_date: true }],
    !isTraining && [{ committeeLabel: true }],
    !isTraining &&
      [
        {
          name: 'main_judge',
          label: t('mainJudge'),
          className: [
            classes.input,
            classes.maxHeightTxtInput,
            classes.inputCondensedView
          ]
        },
        {
          name: 'secretary',
          label: t('secretary'),
          className: [
            classes.input,
            classes.maxHeightTxtInput,
            classes.inputCondensedView,
            classes.paddingInput05
          ]
        },
        updateAble && {
          name: 'chairman',
          label: t('chairman'),
          className: [
            classes.input,
            classes.maxHeightTxtInput,
            classes.inputCondensedView
          ]
        }
      ].filter(Boolean),
    !isTraining &&
      updateAble && [
        {
          name: 'org_address',
          label: t('organizingCommitteeAddress'),
          className: [
            classes.input,
            classes.maxHeightTxtInput,
            classes.inputCondensedView
          ]
        },
        {
          name: 'org_phone',
          label: t('phone'),
          className: [
            classes.input,
            classes.maxHeightTxtInput,
            classes.inputCondensedView,
            classes.paddingInput05
          ]
        },
        {
          name: 'org_email',
          label: EMAIL,
          className: [
            classes.input,
            classes.maxHeightTxtInput,
            classes.inputCondensedView
          ]
        }
      ]
  ];

  const switchBtns = [
    {
      onClick: switchRegistration,
      label:
        values.registration_active === ENABLED
          ? t('closeRegistrationBtn')
          : t('openRegistrationBtn'),
      id: 'SWITCH_REGISTRATION',
      isNoActionBtn: values.registration_active === ENABLED,
      className: [classes.marginRight, classes.disabled]
    },
    {
      onClick: showModal,
      label:
        values.finished === ENABLED
          ? t('eventClosed')
          : [t('close'), t('event')].join(' '),
      id: 'CLOSE_TOURNAMENT',
      className: [classes.marginRight, classes.disabled]
    },
    ...(!isTraining
      ? [
          {
            onClick: showModal,
            label: t('deleteEventGrid'),
            id: 'REMOVE_TBK',
            className: [classes.marginRight, classes.disabled]
          }
        ]
      : [])
  ];

  const disabled = (values && values.finished === ENABLED) || isNotMaster;
  const header = (head) => (
    <span className={clsx(classes.settingsName)}>{head}</span>
  );

  const buttonComponent = (
    label,
    onClick,
    className,
    isNoActionBtn,
    id,
    disabled
  ) => (
    <Button
      {...{ id }}
      className={clsx(classes.marginTopBottom, className)}
      isSaveBtn={!isNoActionBtn}
      {...{ onClick }}
      {...{ label }}
      {...{ disabled }}
    />
  );

  const buttonData = [
    updateAble && {
      label: !shouldShowTournSettings ? t('more') : t('less'),
      onClick: toggleTournSettings,
      className: classes.marginRight,
      isNoActionBtn: true
    },
    (!disabled || localStorage.getItem('role') === 'administrator') && {
      label: t('save'),
      onClick: saveForm
    }
  ];

  const dateComponent = (onChange, label, param, className) => (
    <MuiPickersUtilsProvider
      locale={[ru, ro, de].find((it) => it.code === currentLang)}
      utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={clsx(classes.asterisk, classes.maxHeightTxtInput, className)}
        InputLabelProps={{
          classes: {
            root: classes.overlappingLabel,
            shrink: classes.labelShrinked
          }
        }}
        disableToolbar
        format="dd/MM/yyyy"
        id="date-picker-inline"
        keyboardIcon={<DateIcon />}
        name="date"
        placeholder={DATE_PLACEHOLDER}
        variant="inline"
        {...{ label }}
        value={values[param]}
        onChange={(date) => onChange(date, param)}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        error={Boolean(errors[param])}
        helperText={errors[param]}
        required
        disabled={finishedTourn}
      />
    </MuiPickersUtilsProvider>
  );

  const filter = (
    label,
    options,
    param,
    key,
    item,
    error,
    helperText,
    required,
    className,
    cb,
    freeSolo,
    additionalItem
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      values[param],
      param === COUNTRY_ID
    );

    return (
      <Filter
        {...{ options }}
        className={clsx(
          classes.asterisk,
          classes.maxHeightAutocomplete,
          className
        )}
        onChange={(evt, val) =>
          selectOption(evt, val, 'tournamentData', param, key, errors, cb)
        }
        {...{ label }}
        {...{ item }}
        value={currentVal || ''}
        {...{ required }}
        {...{ helperText }}
        {...{ error }}
        id={param}
        disabled={finishedTourn}
      />
    );
  };

  const textFieldComponent = (
    name,
    label,
    className,
    required,
    rows,
    int,
    disabled
  ) => (
    <TextField
      disabled={finishedTourn}
      multiline={rows}
      className={clsx(classes.asterisk, className)}
      value={values[name] || ''}
      {...{ name }}
      {...{ label }}
      {...{ required }}
      onChange={(evt) =>
        handleTextChange(
          evt,
          'tournamentData',
          errors,
          int,
          null,
          null,
          null,
          name === 'city'
        )
      }
      error={Boolean(errors[name])}
      helperText={errors[name]}
    />
  );

  const importSetting = (
    tournamentsList,
    tournamentToImportSettings,
    values,
    disabled
  ) => {
    return (
      <span className={clsx(classes.marginTop5, classes.flex)}>
        <Filter
          style={styleFilter}
          key={'id'}
          options={tournamentsList}
          label={t('importSettingsFromOtherTournament')}
          value={
            selectedValue(
              tournamentsList,
              ID,
              tournamentToImportSettings['id']
            ) || ''
          }
          onChange={(evt, val) =>
            selectOption(
              evt,
              val,
              'tournamentToImportSettings',
              'id',
              ID,
              errors,
              null,
              true
            )
          }
          item={'tournament_name'}
          disabled={disabled}
        />
        {buttonComponent(
          t('OK'),
          tournamentToImportSettings['id'] && showModal,
          [classes.addWrapper, classes.disabled],
          false,
          'SAVE_IMPORT_SETTINGS',
          disabled
        )}
      </span>
    );
  };

  return (
    <>
      <div className={classes.paper}>
        <PagesHeader onClick={state ? closeFormHandler : goBack}>
          {updateAble
            ? t('updateRecord', { name: t('event') })
            : t('addRecord', { name: t('event') })}
        </PagesHeader>
        <span className={clsx(classes.flex, classes.marginTop)}>
          <UploadImage
            className={classes.img}
            id="upload-file"
            isPoster={true}
            img={values.imagePreview}
            onChange={imageChange}
          />
          <span className={clsx(classes.formWrapper)}>
            {inputs.map(
              (key, idx) =>
                key && (
                  <span
                    key={idx}
                    className={clsx(classes.flex, classes.condensedView)}>
                    {key.map((it, index) => (
                      <Fragment key={index}>
                        {!it.country &&
                          !it.startEvent &&
                          !it.endReg &&
                          !it.time &&
                          !it.type &&
                          !it.comissionLabel &&
                          !it.regDate &&
                          !it.reg_time &&
                          !it.reg_judge_date &&
                          !it.committeeLabel &&
                          !it.region &&
                          textFieldComponent(
                            it.name,
                            it.label,
                            it.className,
                            it.required,
                            it.rows
                          )}
                        {it.country &&
                          filter(
                            t('country'),
                            countries,
                            COUNTRY_ID,
                            ID,
                            {
                              firstName: 'iso3',
                              lastName: countryLabel(currentLang),
                              parathesis: true
                            },
                            Boolean(errors[COUNTRY_ID]),
                            errors[COUNTRY_ID],
                            true,
                            [it.className],
                            (val) => fetchRegionBySelectedCountry(val)
                          )}
                        {it.startEvent &&
                          dateComponent(
                            tournamentDateChange,
                            t('eventStartDate'),
                            'date',
                            [
                              classes.input,
                              classes.inputCondensedView,
                              classes.paddingInput05
                            ]
                          )}
                        {it.endReg &&
                          dateComponent(
                            registrationDateChange,
                            t('registrationDate'),
                            'registration_date',
                            [
                              classes.input,
                              classes.inputCondensedView,
                              classes.paddingRightInput05
                            ]
                          )}
                        {it.time && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              className={clsx(
                                classes.asterisk,
                                classes.input,
                                classes.inputCondensedView,
                                classes.maxHeight,
                                classes.marginBottom15
                              )}
                              KeyboardButtonProps={{
                                'aria-label': 'change time'
                              }}
                              cancelLabel={t('cancel')}
                              okLabel={t('apply')}
                              ampm={false}
                              error={Boolean(errors.tour_time)}
                              helperText={errors.tour_time}
                              id="time-picker"
                              label={t('time')}
                              onChange={(date, time) =>
                                tournamentTimeChange(date, time, 'tour_time')
                              }
                              required
                              disabled={finishedTourn}
                              value={
                                values.tour_time
                                  ? new Date(
                                      null,
                                      null,
                                      null,
                                      values.tour_time.split(':')[0],
                                      values.tour_time.split(':')[1]
                                    )
                                  : null
                              }
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        {it.type &&
                          filter(
                            t('eventType'),
                            getEventTypes(),
                            TYPE,
                            ID,
                            LABEL,
                            Boolean(errors[TYPE]),
                            errors[TYPE],
                            true,
                            it.className
                          )}
                        {it.region &&
                          filter(
                            t('district'),
                            allRegions,
                            'region',
                            NAME,
                            NAME,
                            Boolean(errors?.region),
                            errors?.region,
                            null,
                            it.className,
                            null,
                            true,
                            { name: values.region }
                          )}
                        {it.comissionLabel && header(t('theCommission'))}
                        {it.committeeLabel && header(t('organisingСommittee'))}
                        {it.regDate &&
                          dateComponent(
                            registrationDateChange,
                            t('commissionStartDate'),
                            'reg_date',
                            [
                              classes.input,
                              classes.inputCondensedView,
                              classes.paddingInput05
                            ]
                          )}
                        {it.reg_time && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              className={clsx(
                                classes.asterisk,
                                classes.input,
                                classes.inputCondensedView,
                                classes.maxHeight,
                                classes.marginBottom15
                              )}
                              KeyboardButtonProps={{
                                'aria-label': 'change time'
                              }}
                              cancelLabel={t('cancel')}
                              okLabel={t('apply')}
                              ampm={false}
                              error={Boolean(errors.reg_time)}
                              helperText={errors.reg_time}
                              id="time-picker"
                              label={t('time')}
                              onChange={(date, time) =>
                                tournamentTimeChange(date, time, 'reg_time')
                              }
                              required
                              disabled={finishedTourn}
                              value={
                                values.reg_time
                                  ? new Date(
                                      null,
                                      null,
                                      null,
                                      values.reg_time.split(':')[0],
                                      values.reg_time.split(':')[1]
                                    )
                                  : null
                              }
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        {it.reg_judge_date &&
                          dateComponent(
                            registrationDateChange,
                            t('applicationsForRefereeingAreAcceptedUntil'),
                            'reg_judge_date',
                            [classes.input, classes.inputCondensedView]
                          )}
                      </Fragment>
                    ))}
                  </span>
                )
            )}
            {shouldShowTournSettings && showSettings()}
            <span className={clsx(classes.flex, classes.end)}>
              {buttonData.map(
                (it, idx) =>
                  it && (
                    <Fragment key={idx}>
                      {buttonComponent(
                        it.label,
                        it.onClick,
                        [it.className, classes.btn],
                        it.isNoActionBtn
                      )}
                    </Fragment>
                  )
              )}
            </span>
          </span>
        </span>
      </div>
      {!state && values?.id && (
        <span
          className={clsx(
            classes.flex,
            disabled && classes.disabledBtn,
            classes.column,
            classes.btn,
            classes.leftAuto
          )}>
          {importSetting(
            tournamentsList,
            tournamentToImportSettings,
            values,
            disabled
          )}
          {switchBtns.map((it, btnIdx) => (
            <Fragment key={btnIdx}>
              {buttonComponent(
                it.label,
                it.onClick,
                it.className,
                it.isNoActionBtn,
                it.id,
                disabled
              )}
            </Fragment>
          ))}
        </span>
      )}
    </>
  );
};
export default TournamentForm;
