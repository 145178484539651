import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { Tooltip, Switch, FormControlLabel } from '@material-ui/core';

import { serverDefaultPath } from '../../helpers/constants';
import {
  fetchCountries,
  textChangeHandler,
  selectOption,
  usersRegisterNewUser,
  changeModal,
  fetchTournaments,
  changeImage,
  onBlur,
  validateForm,
  toggleUserStatus,
  fetchParticipationCandidates,
  fetchParticipantsByTournamentV2,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchQdan,
  changeHead,
  exportParticipationCandidatesReport,
  downloadFile,
  fetchReferenceRegion,
  fetchReference,
  changeDate
} from '../../helpers/util';
import {
  finishedTournament,
  isSchool,
  findSortDirection,
  compareValueForSorting
} from '../../helpers/selectors';

import SideModal from '../../components/Snackbar/SideModal';
import CoachForm from '../../components/QuickTournRegistration/CoachForm/CoachForm';
import Table from '../../components/Table/Table';
import LoadingState from '../../components/LoadingState/LoadingState';
import Pagination from '../../components/TablePagination/TablePagination';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import EmptyState from '../../components/EmptyState/EmptyState';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Button from 'components/Buttons/ActionButtons';
import Modal from '../../components/Modal/ModalNewDesign';

import styles from './styles';
import QuestionMark from 'components/QuestionMark/QuestionMark';

const defaultForm = {
  first_name: '',
  last_name: '',
  email: '',
  country_id: ''
};

const TABLE_BODY_PARAMS = (value, tournamentData, isTraining) => [
  {
    firstName: value?.firstName,
    lastName: value?.lastName,
    ...(!!+tournamentData?.show_reg_patronymic
      ? { patronymic: 'patronymic' }
      : {}),
    photo: 'photo'
  },
  'email',
  ...(!+tournamentData?.no_country ? ['iso3'] : []),
  ...(!!+tournamentData?.show_reg_region ? ['region'] : []),
  ...(!!+tournamentData?.show_reg_city ? ['city'] : []),
  ...(!!+tournamentData?.show_reg_club ? ['club'] : []),
  { dropdown: 'participants_count' },
  !isTraining ? 'categories_count' : 'examine_count'
];

class AddCoach extends Component {
  state = {
    countries: [],
    allRegions: [],
    tournamentId: this.props.match.params.tourId,
    modalData: {},
    errors: {},
    statistics: {},
    filteredCoaches: [],
    showModal: false,
    modalInfo: '',
    success: false,
    open: false,
    showError: {},
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    tournamentData: {},
    users: [],
    collapsed: [],
    sortDirection: [],
    usersCount: 0,
    rowsPerPage: 10,
    page: 1,
    qdanList: [],
    filters: { showNotAccepted: true },
    iframeURL: {}
  };

  fetchCountries = fetchCountries.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  selectOption = selectOption.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  changeImage = changeImage.bind(this);
  onBlur = onBlur.bind(this);
  validateForm = validateForm.bind(this);
  toggleUserStatus = toggleUserStatus.bind(this);
  fetchParticipationCandidates = fetchParticipationCandidates.bind(this);
  fetchParticipantsByTournamentV2 = fetchParticipantsByTournamentV2.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  fetchQdan = fetchQdan.bind(this);
  changeHead = changeHead.bind(this);
  exportParticipationCandidatesReport =
    exportParticipationCandidatesReport.bind(this);
  downloadFile = downloadFile.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  fetchReference = fetchReference.bind(this);
  changeDate = changeDate.bind(this);

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, sortDirection, shouldUpdateTable, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(() => {
        this.setState({ shouldUpdateTable: false });

        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('coaches'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing = finishedTournament(tournamentData);
      const isTraining = isSchool(tournamentData);

      this.fetchData(() => {
        const tableBody = TABLE_BODY_PARAMS({}, tournamentData, isTraining);
        const findIdx = tableBody.findIndex(
          (it) => it.dropdown === 'participants_count'
        );

        this.onSort(7, findIdx);
      });

      this.setState({ shouldDisableEditing });

      changeHead(tournamentData, t('coaches'));
    });

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter(
          (item) => item.name === 'coaches_requests'
        );
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  fetchData = (cb) => {
    const { tournamentId } = this.state;

    this.fetchParticipationCandidates(tournamentId, () => {
      const data = this.state.users;
      const usersCount = data?.length;
      const totalAcceptedRequests =
        data?.filter((it) => !!+it.application_status)?.length ?? 0;
      const totalFemale = data?.filter((it) => it.gender === 'F')?.length ?? 0;
      const totalMale = data?.filter((it) => it.gender === 'M')?.length ?? 0;
      const statistics = {
        totalRequests: usersCount,
        totalAcceptedRequests,
        totalFemale,
        totalMale
      };

      this.setState(
        {
          statistics,
          filteredCoaches: data,
          usersCount,
          loading: false
        },
        () => cb && cb()
      );
    });
  };

  onSearch = (evt) => {
    const { value } = evt?.target;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          searchBar: value
        },
        page: 1
      }),
      () => this.onFilterRecords()
    );
  };

  onFilterRecords = () => {
    const { users, filters } = this.state;
    const len = users?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = users[i];
      const bySearch = filters.searchBar
        ? [el.first_name, el.last_name].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const byAssigned = filters.showNotAccepted
        ? !+el?.application_status
        : true;

      if (bySearch && byAssigned) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }
    this.setState({ filteredCoaches: newFilteredRecords }, () => {
      const { filteredCoaches } = this.state;
      const len = filteredCoaches?.length;

      this.setState({ usersCount: len, loading: false });
    });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  onSwitchToggle = (evt, checked) => {
    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, showNotAccepted: checked }
      }),
      () => this.onFilterRecords()
    );
  };

  showModalForm = (evt, item, idx) => {
    evt.preventDefault();

    const id = item?.user_id;
    let state = { selectedRow: id, open: true, errors: {} };

    this.fetchCountries();

    if (item?.country_id) {
      this.fetchReferenceRegion(item?.country_id);
    }

    if (id) {
      state = {
        ...state,
        modalData: {
          ...item,
          ...(item.photo
            ? { imagePreview: serverDefaultPath + item.photo }
            : {}),
          phone: item.phone_number,
          currentRecordIdx: idx
        }
      };
    }

    this.setState(state);
  };

  hideModal = () =>
    this.setState({
      open: false,
      selectedRow: null,
      allRegions: [],
      modalData: {}
    });

  closeModalHandler = () => this.setState({ showModal: false });

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { tournamentData, collapsed } = this.state;
    const isTraining = isSchool(tournamentData);
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => it.id === item.user_id
      );

      if (findIndex === -1) {
        this.fetchParticipantsByTournamentV2(
          null,
          null,
          '',
          item.user_id,
          tournamentData && tournamentData.id,
          true,
          (body) => {
            let rowSubTable = {
              id: item.user_id,
              participants: body?.data,
              sortDirection: []
            };

            this.setState((prevState) => ({
              collapsed: [...prevState.collapsed, rowSubTable]
            }));
          },
          null
        );

        //Training camp, find next_qdan_id loopin through all qdans.
        isTraining && this.fetchQdan();
      } else {
        allCollapsedTables.splice(findIndex, 1);
        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { users, sortDirection } = this.state;
    const clone = [...users];

    let field;
    switch (sortField) {
      case 1:
        field = 'first_name';
        break;
      case 2:
        field = 'email';
        break;
      case 3:
        field = 'iso3';
        break;
      case 4:
        field = 'region';
        break;
      case 5:
        field = 'city';
        break;
      case 6:
        field = 'club';
        break;
      case 7:
        field = 'participants_count';
        break;
      case 8:
        field = 'categories_count';
        break;
      case 9:
        field = 'examine_count';
        break;
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );

    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        users: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  onToggleStatus = (evt, item) => {
    this.toggleUserStatus(evt, item.user_id, () =>
      this.setState({ shouldUpdateTable: true })
    );
  };

  onSortCollapsibleTable = (id, sortField, idxCell) => {
    const { collapsed } = this.state;
    const clone = [...collapsed];
    const findIdxInArray = clone.findIndex((it) => it.id === id);

    let field;

    switch (sortField) {
      case 1:
        field = 'participant_first_name';
        break;
      case 2:
        field = 'participant_last_name';
        break;
      case 3:
        field = 'participant_patronymic';
        break;
      case 4:
        field = 'age';
        break;
      case 5:
        field = 'gender';
        break;
      case 6:
        field = 'weight';
        break;
      case 7:
        field = 'qdan_name';
        break;
      case 8:
        field = 'next_qdan_id';
        break;
      case 9:
        field = 'participant_country';
        break;
    }

    const direction = findSortDirection(
      clone[findIdxInArray].sortDirection,
      sortField
    );
    const sortedData = compareValueForSorting(
      clone[findIdxInArray].participants,
      field,
      direction
    );

    clone[findIdxInArray].sortDirection = [direction, idxCell, sortField];
    clone[findIdxInArray].filteredParticipants = sortedData;

    this.setState({ collapsed: clone });
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onSave = (evt) => {
    const { tournamentData } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          { ...defaultForm },
          form,
          'errors',
          () => {
            this.usersRegisterNewUser(evt, tournamentData?.id, form, () => {
              this.setState({
                modalData: {},
                errors: {},
                imagePreview: null,
                shouldUpdateTable: true,
                loading: true
              });
              this.hideModal();
            });
          },
          () => {
            this.setState({
              showError: { email: true }
            });
          }
        );
      }
    );
  };

  render() {
    const {
      countries,
      allRegions,
      modalData,
      errors,
      filteredCoaches,
      success,
      modalInfo,
      showModal,
      filters,
      open,
      selectedRow,
      showError,
      shouldDisableEditing,
      loading,
      tournamentData,
      collapsed,
      statistics,
      usersCount,
      page,
      rowsPerPage,
      sortDirection,
      qdanList,
      iframeURL
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;
    const isTraining = isSchool(tournamentData);
    const FIRST_NAME = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';
    const LAST_NAME = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';
    const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_first_name'
      : 'coach_last_name';

    const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_last_name'
      : 'coach_first_name';

    const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachFirstName')
      : t('coachLastName');

    const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachLastName')
      : t('coachFirstName');

    const dojoInfo = [
      {
        name: FIRST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('firstname')
          : t('lastname'),
        required: true
      },
      {
        name: LAST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('lastname')
          : t('firstname'),
        required: true
      },
      {
        name: 'patronymic',
        label: t('patronymic')
      },
      {
        name: 'email',
        label: t('email'),
        shouldEditCoach: false,
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        name: 'branch_chief',
        label: t('nameBranchChief')
      },
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL
      },
      {
        country: true,
        isFilter: true
      },
      allRegions?.length > 0
        ? {
            region: true,
            isFilter: true
          }
        : {
            name: 'region',
            label: t('region')
          },
      {
        name: 'city',
        label: t('city')
      },
      {
        name: 'club',
        label: t('cluborFederationName')
      },
      {
        label: t('phone'),
        name: 'phone'
      },
      { label: t('website'), name: 'website' },
      { save: true }
    ];

    const header = [
      { num: 1, label: t('nameRegistrar'), noTooltip: true },
      { num: 2, label: t('email') },
      ...(!+tournamentData?.no_country
        ? [{ num: 3, label: t('country') }]
        : []),
      ...(!!+tournamentData?.show_reg_region
        ? [{ num: 4, label: t('region') }]
        : []),
      ...(!!+tournamentData?.show_reg_city
        ? [{ num: 5, label: t('city') }]
        : []),
      ...(!!+tournamentData?.show_reg_club
        ? [{ num: 6, label: t('club') }]
        : []),
      { num: 7, label: t('participants') },
      !isTraining
        ? { num: 8, label: t('categories') }
        : { num: 9, label: t('exams') },
      { label: t('approve') }
    ];

    const tableBody = TABLE_BODY_PARAMS(
      { firstName: FIRST_NAME, lastName: LAST_NAME },
      tournamentData,
      isTraining
    );

    const condensedTableData = {
      mainName: {
        firstName: FIRST_NAME,
        lastName: LAST_NAME,
        ...(!!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'patronymic' }
          : {})
      },
      photo: 'photo',
      info: [
        { key: 'email', name: t('email') },
        ...(!+tournamentData?.no_country
          ? [{ key: 'iso3', name: t('country') }]
          : []),
        ...(!!+tournamentData?.show_reg_city
          ? [{ key: 'city', name: t('city') }]
          : []),
        ...(!!+tournamentData?.show_reg_club
          ? [{ key: 'club', name: t('club') }]
          : []),
        { key: 'branch', name: 'Branch' },
        { dropdown: { key: 'participants_count', name: t('participants') } },
        !isTraining
          ? { key: 'categories_count', name: t('categories') }
          : { key: 'examine_count', name: t('exams') }
      ]
    };

    const FIRST_NAME_PART = !+tournamentData?.last_name_first
      ? 'participant_first_name'
      : 'participant_last_name';
    const LAST_NAME_PART = !+tournamentData?.last_name_first
      ? 'participant_last_name'
      : 'participant_first_name';

    const collapseTableBody = [
      {
        firstName: FIRST_NAME_PART,
        lastName: LAST_NAME_PART,
        ...(!!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'participant_patronymic' }
          : {}),
        photo: 'photo'
      },
      'gender',
      'age',
      'weight',
      ...(!!+tournamentData?.height_needed ? ['height'] : []),
      ...(!!+tournamentData?.show_reg_qdan ? ['qdan_name'] : []),
      ...(!!+tournamentData?.sport_rank_needed ? ['sport_rank_short'] : []),
      ...(isTraining ? ['next_qdan_id'] : []),
      ...(!isTraining ? ['categories_info'] : []),
      ...(!+tournamentData?.no_country ? ['participant_country'] : []),
      'coach_name'
    ];

    const collapseTableHeader = [
      {
        num: !+tournamentData?.last_name_first ? 1 : 2,
        label: t('name')
      },
      { num: 5, label: t('gender') },
      { num: 4, label: t('age') },
      { num: 6, label: t('weight') },
      ...(!!+tournamentData?.height_needed ? [{ label: t('height') }] : []),
      ...(!!+tournamentData?.show_reg_qdan
        ? [{ num: 7, label: t('kyuDan') }]
        : []),
      ...(!!+tournamentData?.sport_rank_needed
        ? [{ label: t('sportsCategory') }]
        : []),
      ...(isTraining ? [{ num: 8, label: t('nextKyuDan') }] : []),
      ...(isTraining ? [] : [{ label: t('categories') }]),
      ...(!+tournamentData?.no_country
        ? [{ num: 9, label: t('country') }]
        : []),
      { label: t('coach') }
    ];

    const collapsedCondensedTableData = {
      mainName: {
        firstName: FIRST_NAME_PART,
        lastName: LAST_NAME_PART,
        ...(!!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'participant_patronymic' }
          : {}),
        photo: 'photo'
      },
      list: true,
      info: [
        { key: 'gender', name: t('gender') },
        { key: 'age', name: t('age') },
        { key: 'weight', name: t('weight') },
        ...(!!+tournamentData?.height_needed
          ? [{ key: 'height', name: t('height') }]
          : []),
        ...(!!+tournamentData?.show_reg_qdan
          ? [{ key: 'qdan_name', name: t('kyuDan') }]
          : []),
        ...(!!+tournamentData?.sport_rank_needed
          ? [{ key: 'sport_rank_short', name: t('sportsCategory') }]
          : []),
        ...(isTraining ? [{ key: 'next_qdan_id', name: t('nextKyuDan') }] : []),
        ...(!+tournamentData?.no_country
          ? [{ key: 'participant_country', name: t('country') }]
          : []),
        { key: 'coach_name', name: t('coach') }
      ]
    };

    const pageHeaderStatistics = {
      info: [
        {
          label: [t('total'), t('requests').toLowerCase()].join(' '),
          name: statistics?.totalRequests
        },
        {
          label: [t('total'), t('accesptedRequests')].join(' '),
          name: statistics?.totalAcceptedRequests
        },
        {
          label: [t('total'), t('femaleGender')].join(' '),
          name: statistics?.totalFemale
        },
        {
          label: [t('total'), t('maleGender')].join(' '),
          name: statistics?.totalMale
        }
      ]
    };

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              dialogTitle={
                modalData.user_id ? t('infRegistrar') : t('addCoach')
              }
              onSwitchBetween={
                modalData?.user_id && {
                  prev: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx - 1;

                    if (nextItemIdx >= 0) {
                      const item = filteredCoaches[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  },
                  next: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx + 1;
                    const len = filteredCoaches?.length - 1;

                    if (len >= nextItemIdx) {
                      const item = filteredCoaches[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  }
                }
              }
              dialogContent={
                <CoachForm
                  inputs={dojoInfo}
                  onBlur={this.onBlur}
                  {...{ showError }}
                  changeTxt={this.textChangeHandler}
                  {...{ countries }}
                  saveCoachData={(evt) => this.onSave(evt)}
                  changeImage={this.changeImage}
                  changeDate={(date) =>
                    this.changeDate(date, 'modalData', errors, true)
                  }
                  values={modalData}
                  param={'modalData'}
                  {...{ errors }}
                  selectOption={this.selectOption}
                  preventEvent={modalData.user_id}
                  fetchRegionBySelectedCountry={
                    this.fetchRegionBySelectedCountry
                  }
                  {...{ allRegions }}
                  isOldDesign
                />
              }
              isOldDesign
            />

            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics }}
              shouldShowBtn
            />
            <Table
              maxColumnWidth={classes.maxWidth}
              toolbarIndividualContent={
                <>
                  <FormControlLabel
                    className={clsx(classes.switchWrapper, classes.switch)}
                    control={
                      <Switch
                        checked={filters.showNotAccepted}
                        onChange={(evt, checked) => {
                          this.onSwitchToggle(evt, checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={t('showNotAccepted')}
                  />

                  <Button
                    className={classes.exportBtn}
                    onClick={() =>
                      this.exportParticipationCandidatesReport(
                        tournamentData.id
                      )
                    }
                    label={t('saveList')}
                  />
                </>
              }
              {...{ qdanList }}
              {...{ condensedTableData }}
              {...{ collapsedCondensedTableData }}
              search={filters.searchBar}
              clearSearch={this.clearSearch}
              textChange={this.onSearch}
              showButton={!shouldDisableEditing}
              {...{ collapsed }}
              {...{ collapseTableHeader }}
              {...{ collapseTableBody }}
              shouldDisableEditing
              id="user_id"
              shouldShowNoncollapsibleList
              rowOrder={(page - 1) * rowsPerPage}
              {...{ header }}
              body={filteredCoaches.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )}
              openEmptyForm={(evt) => this.showModalForm(evt)}
              expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
              subTableDataKey="participants"
              data={tableBody}
              onClick={(evt, item, idx) => this.showModalForm(evt, item, idx)}
              selected={(item) => selectedRow === item.user_id}
              onSort={this.onSort}
              onSortCollapsibleTable={this.onSortCollapsibleTable}
              {...{ sortDirection }}
              shouldAllowEditing={() => true}
              settedActionIconsHead={true}
              actionIcons={(user) => (
                <Tooltip
                  arrow
                  title={t(
                    user.application_status === '1'
                      ? 'rejectRequest'
                      : 'acceptRequest'
                  )}>
                  <span>
                    <CheckboxBtn
                      checked={!!+user.application_status}
                      onChange={(evt) => this.onToggleStatus(evt, user)}
                      disabled={shouldDisableEditing}
                    />
                  </span>
                </Tooltip>
              )}
              paginationComponent={
                usersCount > 0 && (
                  <Pagination
                    count={usersCount}
                    onPageChange={this.pageChangeHandler}
                    onRowsPerPageChange={this.rowsPerPageHandler}
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              }
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(AddCoach)));
