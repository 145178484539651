export const styles = () => ({
  flex: { display: 'flex' },

  buttonWrapper: {
    margin: '1rem 0  0 1rem',
    alignItems: 'center'
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  marginTop1: {
    margin: '1rem'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },

  checkboxItem: {
    margin: '10px 5px',
    minWidth: '120px',
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },

  button: {
    margin: '0.2rem',
    width: '15%'
  }
});
