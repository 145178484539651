import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { selectedValue } from '../../../helpers/selectors';
import Dropzone from './Dropzone';
import Radio from '../../RadioBtn/RadioBtn';
import { TextField } from '@material-ui/core';
import { ID } from '../../../helpers/constants';
import Alert from '../../../components/Alerts/Alert';
import Filter from '../../Filter/Filter';
import useStyles from './StylesDropzone';

const DropFile = (props) => {
  const {
    onDrop,
    removeFile,
    changeTextHandler,
    fileData,
    errors,
    selectOption,
    fileDataErrors,
    onFilter
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const DOWNLOAD_PERMISSION = [
    { id: '1', label: t('allPersons') },
    { id: '2', label: t('masterOnly') },
    { id: '3', label: t('coachesOnly') }
  ];
  const options = [
    { id: '3', type: 'certificate', label: t('certificate') },
    { id: '2', type: 'document', label: t('document') }
  ];

  return (
    <>
      <Dropzone
        {...{ onDrop }}
        fileData={
          Array.isArray(fileData.files) ? fileData : { ...fileData, files: [] }
        }
        {...{ removeFile }}
        preview={(file) => file.name}
      />
      {Boolean(fileDataErrors.files) && (
        <Alert message={fileDataErrors.files} />
      )}
      <Filter
        className={classes.filter}
        value={selectedValue(options, ID, fileData.tournament_file_type) || ''}
        onChange={onFilter}
        {...{ options }}
        label={t('type')}
        item="label"
        error={Boolean(fileDataErrors.tournament_file_type)}
        helperText={fileDataErrors.tournament_file_type}
      />
      <TextField
        name="attachment_name"
        label={t('nameDocument')}
        fullWidth
        onChange={changeTextHandler}
        value={fileData.attachment_name || ''}
        error={Boolean(errors.attachment_name)}
        helperText={Boolean(errors.attachment_name) && errors.attachment_name}
      />
      {fileData.tournament_file_type === '2' && (
        <>
          <span className={clsx(classes.flex, classes.marginTop)}>
            {t('permissionToDownload')}
          </span>
          {Boolean(fileDataErrors.allowUser) && (
            <Alert message={fileDataErrors.allowUser} />
          )}
          <Radio
            value={fileData.allowUser || ''}
            options={DOWNLOAD_PERMISSION}
            onClick={selectOption}
            item="id"
            label="label"
          />
        </>
      )}
    </>
  );
};

export default DropFile;
