const styles = () => ({
  tabs: {
    maxWidth: 'calc(100%/4)',
    width: '100%',
    textTransform: 'none',
    '@media (min-width:901px)': {
      fontSize: '1.2rem'
    },
    '@media (max-width:900px)': {
      fontSize: '0.7rem'
    }
  }
});

export default styles;
