import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    padding: '1em'
  },
  flex: {
    display: 'flex'
  },
  fullWidth: {
    width: '100%'
  },
  condensedView: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },
  marginRight: {
    marginRight: '1em'
  },

  marginLeft: {
    marginLeft: '1em'
  },

  marginBottom: {
    marginBottom: '1em'
  },
  inputInHalf: {
    '@media (min-width:901px)': {
      width: 'calc(100%/2)'
    }
  },
  inputHeight: {
    height: '55px'
  },
  btn: {
    width: '10em',
    marginLeft: 'auto',
    '@media (max-width:900px)': {
      marginTop: '20px'
    }
  },
  outlinedTxtInput: {
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    }
  },
  outlined: {
    '& .MuiOutlinedInput-input, &&[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  },
  label: {
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },
  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#ff0000'
    }
  },
  column: {
    flexDirection: 'column'
  },
  h1: {
    fontSize: '1.5rem',
    fontWeight: 500,
    paddingBottom: '.5em',
    wordBreak: 'break-word'
  },
  cardImg: {
    width: '5em',
    height: '5.5em'
  },
  cardTxt: {
    width: 'calc(100% - 5em)',
    paddingRight: '.5em'
  },
  role: {
    fontSize: '1rem'
  },
  avatar: {
    width: '7em',
    height: '9.7em'
  },
  about: {
    width: 'calc(100% - 7em)'
  },
  divider: {
    color: theme.palette.primary.main,
    margin: '1.5em -1em 0 -1em'
  },
  marginTop: {
    marginTop: '1em'
  },
  formWrapper: {
    '@media (min-width:901px)': {
      width: '60%'
    }
  },
  uploadWrapper: {
    '@media (min-width:901px)': {
      width: '40%',
      marginRight: '1.5em'
    }
  }
}));

export const GlobalCss = withStyles({
  '@global': {
    '.MuiPickersToolbarButton-toolbarBtn': {
      marginBottom: '8px',
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)'
    },
    '.MuiAutocomplete-listbox': {
      '@media (max-height:900px)': {
        maxHeight: '170px'
      }
    }
  }
})(() => null);
