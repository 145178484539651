import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dialog } from '@material-ui/core';
import Button from '../Buttons/ActionButtons';
import {
  KEEP_EDITING,
  CLOSE_DISCARD,
  CLOSE,
  SAVE
} from '../../helpers/constants';
import { useStyles } from './stylesModal';

const Modal = (props) => {
  const {
    open,
    close,
    children,
    buttonPurpose,
    onClick,
    dialogTitle,
    dialogContent,
    shouldShowDiscardChanges,
    discardOrKeepEditing,
    subHeader,
    shouldShowTopBottomDivider,
    closeButtonlabel,
    setPaperWidth
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const buttonComponent = (
    action,
    label,
    autoFocus,
    isSaveBtn,
    className,
    id,
    warning
  ) => (
    <Button
      {...{ id }}
      {...{ className }}
      {...{ isSaveBtn }}
      {...{ autoFocus }}
      onClick={action}
      {...{ label }}
      specificallyStyledBtn={warning}
    />
  );
  const buttonData = [
    onClick && {
      id: SAVE,
      label: buttonPurpose,
      onClick,
      isSaveBtn: true,
      className: classes.marginRight,
      autoFocus: true
    },
    {
      id: CLOSE,
      label: closeButtonlabel ? closeButtonlabel : t('close'),
      onClick: close,
      className: classes.left
    }
  ];
  const discardBtns = [
    {
      id: KEEP_EDITING,
      label: t('Cancel'),
      onClick: discardOrKeepEditing,
      className: [classes.left, classes.marginRight]
    },
    {
      id: CLOSE_DISCARD,
      label: t('closeDiscard'),
      onClick: discardOrKeepEditing,
      warning: classes.discardBtn
    }
  ];
  const modalContent = (
    <>
      <div
        className={clsx(
          shouldShowTopBottomDivider && classes.wrapper_header,
          classes.paddingTop30,
          classes.paddingBottom08,
          shouldShowDiscardChanges ? classes.bgGrey : classes.bgWhite,
          shouldShowTopBottomDivider && classes.boxShadowHeader
        )}>
        <span className={clsx(classes.flex, classes.header)}>
          {dialogTitle}
        </span>
        {subHeader && subHeader}
      </div>
      {dialogContent}
      {children}
    </>
  );

  return (
    <Dialog
      {...{ open }}
      classes={{ paper: setPaperWidth ?? classes.maxWidth }}
      onClose={close}>
      {shouldShowDiscardChanges ? (
        <>
          <span
            className={clsx(
              classes.onHoldEditing,
              classes.modalpaddingLeftRight30
            )}>
            {modalContent}
          </span>
          <span
            className={clsx(classes.discardWrapper, classes.wrapper_footer)}>
            <span className={clsx(classes.flex, classes.wrap)}>
              <span className={classes.discardChanges}>
                {t('discardChanges')}
              </span>
              {t('cancelUnsavedChangessMsg')}
            </span>
            <span className={clsx(classes.flex, classes.marginTop20)}>
              {discardBtns.map(
                (it, idx) =>
                  it && (
                    <Fragment key={idx}>
                      {buttonComponent(
                        it.onClick,
                        it.label,
                        null,
                        null,
                        clsx(classes.btn, it.className),
                        it.id,
                        it.warning
                      )}
                    </Fragment>
                  )
              )}
            </span>
          </span>
        </>
      ) : (
        <span className={classes.modalpaddingLeftRight30}>
          {modalContent}
          <span
            className={clsx(
              classes.flex,
              classes.paddingTop30,
              classes.wrapper_footer,
              shouldShowTopBottomDivider && classes.boxShadowFooter
            )}>
            {buttonData.map(
              (it, idx) =>
                it && (
                  <Fragment key={idx}>
                    {buttonComponent(
                      it.onClick,
                      it.label,
                      it.autoFocus,
                      it.isSaveBtn,
                      clsx(classes.btn, it.className)
                    )}
                  </Fragment>
                )
            )}
          </span>
        </span>
      )}
    </Dialog>
  );
};

export default Modal;
