import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  checkboxIcon: {
    '& .MuiSvgIcon-root': {
      width: '35px',
      height: '35px'
    }
  }
}));
