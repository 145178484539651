import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import {
  selectedValue,
  categoryTypesPresentOnTournament
} from '../../helpers/selectors';
import TournamentForm from '../../components/AddTournament/TournamentForm';
import SideModal from '../../components/Snackbar/SideModal';
import {
  TOURN_BANNER,
  ENABLED,
  serverDefaultPath,
  OK,
  TYPE,
  ID,
  KEEP_EDITING,
  CLOSE_DISCARD,
  DISABLED
} from '../../helpers/constants';
import { changeTitle } from 'helpers/actions';
import {
  fetchCountries,
  selectOption,
  textChangeHandler,
  fetchTournaments,
  addTournament,
  updateTournament,
  changeModal,
  changeImage,
  validateForm,
  setRegistrationStatus,
  closeTournament,
  removeTBK,
  fetchAllCategoryTypes,
  getTatamisTheme,
  fetchReferenceRegion,
  importSettings,
  fetchReference
} from '../../helpers/util';
import LoadingState from '../../components/LoadingState/LoadingState';
import Modal from '../../components/Modal/Modal';
import Filter from '../../components/Filter/Filter';
import QuestionMark from 'components/QuestionMark/QuestionMark';

const defaultInputs = {
  tournament_name: '',
  country_id: '',
  type: '',
  address: '',
  city: '',
  date: '',
  registration_date: '',
  tour_time: '',
  reg_date: '',
  reg_judge_date: '',
  reg_time: ''
};

class AddTournament extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      tournamentData: { ...(props.state ? props.state.tournamentData : {}) },
      langOnLoad: localStorage.getItem('i18nextLng'), //need this to update the list of colors based on the selected language
      errors: {},
      success: false,
      showModal: false,
      isSending: false,
      shouldShowTournSettings: false,
      selectedCheckboxes: [],
      isLoading: true,
      open: false,
      selectedOption: {},
      selectedOptionErrors: {},
      categoryTypes: [],
      tatamisTheme: [],
      allRegions: [...(props?.state?.allRegions ?? [])],
      tournamentToImportSettings: {},
      iframeURL: {}
    };

    this.tournamentParamId = new URLSearchParams(window.location.search).get(
      'tournament_id'
    );
    this.fetchCountries = fetchCountries.bind(this);
    this.selectOption = selectOption.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.addTournament = addTournament.bind(this);
    this.updateTournament = updateTournament.bind(this);
    this.changeModal = changeModal.bind(this);
    this.changeImage = changeImage.bind(this);
    this.validateForm = validateForm.bind(this);
    this.closeTournament = closeTournament.bind(this);
    this.removeTBK = removeTBK.bind(this);
    this.setRegistrationStatus = setRegistrationStatus.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.fetchReferenceRegion = fetchReferenceRegion.bind(this);
    this.importSettings = importSettings.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOption, isEditing, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');
    const { state } = this.props;

    if (state && state.tournamentData !== prevProps.state.tournamentData) {
      this.setState({
        tournamentData: { ...(state.tournamentId ? state.tournamentData : {}) },
        errors: state.tournamentId ? {} : { ...defaultInputs }
      });
    }

    if (state?.allRegions !== prevProps.state?.allRegions) {
      this.setState({ allRegions: state?.allRegions });
    }

    if (langOnLoad !== currentLang) {
      this.getTatamisTheme();
      this.setState({ langOnLoad: currentLang });

      changeTitle(t('updateRecord', { name: t('event') }));
    }

    if (
      state &&
      state.tournamentId &&
      state.tournamentId !== prevProps.state.tournamentId
    ) {
      this.fetchTournData(state.tournamentId);
    }

    if (
      isEditing &&
      selectedOption &&
      selectedOption !== prevState.selectedOption
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }
  }

  componentDidMount() {
    const { state, t, location } = this.props;
    const { langOnLoad } = this.state;

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter((item) => item.name === 'edit-event');
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );

    this.fetchCountries();
    this.getTatamisTheme();

    if (this.tournamentParamId || (state && state.tournamentId)) {
      this.fetchTournData(
        this.tournamentParamId ? this.tournamentParamId : state.tournamentId,
        true
      );
    }

    if (
      (state && !state.tournamentId) ||
      location.pathname === '/events/add-event'
    ) {
      this.setState({ isLoading: false });
    }

    !state && changeTitle(t('updateRecord', { name: t('event') }));
  }

  fetchTournData = (tournamentId, hasCountrySelected) => {
    const permissionVal = (tournament) => [
      ...(tournament?.published === ENABLED ? ['published'] : []),
      ...(tournament?.bronze_fight_enabled === ENABLED
        ? ['bronze_fight_enabled']
        : []),
      ...(tournament?.no_participant_number === ENABLED
        ? ['no_participant_number']
        : []),
      ...(tournament?.no_country === ENABLED ? ['no_country'] : []),
      ...(tournament?.circle_round === ENABLED ? ['circle_round'] : []),
      ...(tournament?.reg_judge_form === ENABLED ? ['reg_judge_form'] : []),
      ...(tournament?.last_name_first === ENABLED ? ['last_name_first'] : []),
      ...(tournament?.tameshiwari > 0 ? ['tameshiwari'] : []),
      ...(tournament?.height_needed === ENABLED ? ['height_needed'] : []),
      ...(tournament?.sport_rank_needed === ENABLED
        ? ['sport_rank_needed']
        : []),
      ...(!!+tournament?.show_reg_coach_2 ? ['show_reg_coach_2'] : []),
      ...(tournament?.use_alt_category_name === ENABLED
        ? ['use_alt_category_name']
        : []),
      ...(tournament?.use_qr === ENABLED ? ['use_qr'] : []),
      ...(tournament?.use_notification_win === ENABLED
        ? ['use_notification_win']
        : []),
      ...(tournament?.report_head === DISABLED ? ['report_head'] : []), //Disable Report Header on Reports page
      ...(tournament?.use_rep_stamp === ENABLED ? ['use_rep_stamp'] : []),
      ...(tournament?.use_rep_signature === ENABLED
        ? ['use_rep_signature']
        : []),
      ...(tournament?.show_reg_iko === ENABLED ? ['show_reg_iko'] : []),
      ...(!!+tournament?.show_reg_region ? ['show_reg_region'] : []),
      ...(!!+tournament?.show_reg_city ? ['show_reg_city'] : []),
      ...(!!+tournament?.show_reg_club ? ['show_reg_club'] : []),
      ...(tournament?.show_reg_qdan === ENABLED ? ['show_reg_qdan'] : []),
      ...(tournament?.show_reg_patronymic === ENABLED
        ? ['show_reg_patronymic']
        : []),
      ...(tournament?.use_reg_online === ENABLED ? ['use_reg_online'] : [])
    ];

    this.setState({ shouldShowTournSettings: false });

    if (this.tournamentParamId) {
      this.fetchTournaments(null, null, null, null, true, 1);
      this.fetchTournaments(tournamentId, null, null, () => {
        const { tournamentData } = this.state;
        const checkedValues = permissionVal(tournamentData);

        if (hasCountrySelected) {
          this.fetchReferenceRegion(tournamentData?.c_id);
        }

        this.setState((prevState) => ({
          tournamentData: {
            ...prevState.tournamentData,
            ...(tournamentData?.poster
              ? {
                  imagePreview: serverDefaultPath + tournamentData.poster
                }
              : {}),
            country_id: tournamentData?.c_id,
            date: tournamentData?.start_date,
            tour_time: tournamentData?.start_time,
            tournament_id: tournamentData?.id
          },
          selectedCheckboxes: checkedValues,
          errors: {},
          isLoading: false
        }));
      });
    } else {
      const checkedValues = permissionVal(this.state.tournamentData);

      this.setState({
        isLoading: false,
        selectedCheckboxes: checkedValues,
        errors: {}
      });
    }
  };

  formatDate = (date) => {
    const selectedYear = date.getFullYear();
    const selectedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const selectedDay = date.getDate().toString().padStart(2, '0');
    return `${selectedYear}-${selectedMonth}-${selectedDay}`;
  };

  fetchTournamentData = () => {
    const { tournamentData } = this.state;

    this.setState((prevState) => ({
      tournamentData: {
        ...prevState.tournamentData,
        ...(tournamentData.poster
          ? {
              imagePreview: serverDefaultPath + tournamentData.poster
            }
          : {}),
        country_id: tournamentData.c_id,
        date: tournamentData.start_date,
        tour_time: tournamentData.start_time,
        tournament_id: tournamentData.id
      }
    }));
  };

  tournamentDateChangeHandler = (date) => {
    const { errors, tournamentData } = this.state;
    const { t } = this.props;
    const copyErrors = { ...errors };
    let cpyData = { ...tournamentData };
    const now = new Date().setHours(0, 0, 0, 0);

    if (date === null) {
      copyErrors.date = t('required');
    } else if (isNaN(Date.parse(date))) {
      copyErrors.date = t('invalidDate');
    } else if (date < now) {
      copyErrors.date = t('futureDate');
    } else {
      if (
        ['registration_date', 'reg_judge_date', 'reg_date'].some(
          (it) =>
            new Date(date) < new Date(tournamentData?.[it]).setHours(0, 0, 0, 0)
        )
      ) {
        copyErrors.date = t('futureDate');
      } else {
        delete copyErrors.date;
      }

      cpyData = { ...cpyData, date: this.formatDate(date) };
    }

    this.setState({
      tournamentData: cpyData,
      errors: copyErrors
    });
  };

  tournamentTimeChangeHandler = (date, time, param) => {
    const { errors } = this.state;
    const { t } = this.props;
    let tourTime = time;
    let cpyErrors = { ...errors };

    if (date === null) {
      cpyErrors[param] = t('required');
    } else if (isNaN(date)) {
      cpyErrors[param] = t('invalidTime');
    } else {
      delete cpyErrors[param];
    }

    this.setState((prevState) => ({
      tournamentData: {
        ...prevState.tournamentData,
        [param]: tourTime
      },
      errors: cpyErrors
    }));
  };

  registrationDateChangeHandler = (date, param) => {
    const { errors, tournamentData } = this.state;
    const { t } = this.props;
    let cpyErrors = { ...errors };
    let cpyForm = { ...tournamentData };

    if (date === null) {
      cpyErrors[param] = t('required');
    } else if (isNaN(Date.parse(date))) {
      cpyErrors[param] = t('invalidDate');
    } else {
      const newCalcDate = new Date(tournamentData.date);
      newCalcDate.setDate(
        newCalcDate.getDate() + (+tournamentData?.duration_days ?? 0) - 1
      );

      if (date > newCalcDate) {
        cpyErrors.registration_date = t('registrationDateError');
      } else {
        delete cpyErrors[param];
      }

      cpyForm = { ...cpyForm, [param]: this.formatDate(date) };
    }

    this.setState({
      tournamentData: cpyForm,
      errors: cpyErrors
    });
  };

  closeModalHandler = () => this.setState({ showModal: false });

  selectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.includes(key)
      ? selectedCheckboxes.filter((it) => it !== key)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues });
  };

  toggleTournSettings = () =>
    this.setState({
      shouldShowTournSettings: !this.state.shouldShowTournSettings
    });

  showModal = (evt) => {
    const { tournamentData } = this.state;
    const { t } = this.props;
    const { category_types } = tournamentData;

    this.setState({ open: true, pressedIcon: evt.target.id }, () => {
      if (this.state.pressedIcon === 'REMOVE_TBK') {
        this.fetchAllCategoryTypes((allSystemTypes) => {
          let categoryTypes = categoryTypesPresentOnTournament(
            allSystemTypes || [],
            category_types
          );

          categoryTypes.unshift({
            id: '0',
            name: t('all')
          });

          this.setState({ categoryTypes });
        });
      }
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        selectedOption: {},
        selectedOptionErrors: {},
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;

    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  goBack = () => {
    const { navigate, location } = this.props;
    navigate(-1, {
      state: { prevUrl: location.pathname }
    });
  };

  onRemoveTbk = (evt) => {
    const { tournamentData, selectedOption } = this.state;

    this.validateForm(
      evt,
      { type: '' },
      selectedOption,
      'selectedOptionErrors',
      () => {
        this.removeTBK(
          evt,
          tournamentData?.id,
          selectedOption?.type,
          null,
          () => {
            this.setState({ isAttemptingToEditModalFields: false }, () =>
              this.hideModal()
            );
          }
        );
      }
    );
  };

  calculateNumberOfYearsDateChange = (date) => {
    const { errors } = this.state;
    const { t } = this.props;
    const copyErrors = { ...errors };
    let tourDate;

    if (date === null) {
      copyErrors.calc_age_date = t('required');
    } else if (isNaN(Date.parse(date))) {
      copyErrors.calc_age_date = t('invalidDate');
    } else {
      const selectedYear = date.getFullYear();
      const selectedMonth =
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const selectedDay =
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      tourDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;

      delete copyErrors.calc_age_date;
    }

    this.setState((prevState) => ({
      ...(tourDate
        ? {
            tournamentData: {
              ...prevState.tournamentData,
              calc_age_date: tourDate
            }
          }
        : {}),
      errors: copyErrors
    }));
  };

  onSaveForm = (evt) => {
    const { tournamentData, isSending } = this.state;
    let newDefaultInputs = { ...defaultInputs };
    const regionVal = document.getElementById('region')?.value;

    if (+tournamentData?.type === 3) {
      delete newDefaultInputs.reg_date;
      delete newDefaultInputs.reg_judge_date;
      delete newDefaultInputs.reg_time;
    }

    this.setState(
      (prevState) => ({
        tournamentData: {
          ...prevState.tournamentData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.tournamentData;

        this.validateForm(evt, newDefaultInputs, form, 'errors', () => {
          form.id
            ? this.updateTournament(evt, form)
            : !isSending && this.addTournament(evt, form);
        });
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { tournamentData } = this.state;
    let cpy = { ...tournamentData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ tournamentData: cpy });
  };

  render() {
    const {
      countries,
      errors,
      success,
      showModal,
      modalInfo,
      tournamentData,
      shouldShowTournSettings,
      selectedCheckboxes,
      isLoading,
      pressedIcon,
      categoryTypes,
      open,
      selectedOption,
      selectedOptionErrors,
      shouldShowDiscardChanges,
      tatamisTheme,
      allRegions,
      tournamentsList,
      tournamentToImportSettings,
      iframeURL
    } = this.state;
    const { state, closeFormHandler, t } = this.props;

    let onClick, dialogTitle, dialogContent;

    let importedTournament =
      tournamentToImportSettings['id'] &&
      tournamentsList.filter(
        (tournament) => tournament.id === tournamentToImportSettings['id']
      );

    switch (pressedIcon) {
      case 'CLOSE_TOURNAMENT': {
        onClick = () =>
          this.closeTournament(tournamentData && tournamentData.id, () =>
            this.fetchTournamentData()
          );
        dialogTitle = t('closeTournament');
        dialogContent = t('closeTournamentMsg');
        break;
      }
      case 'REMOVE_TBK': {
        onClick = (evt) => this.onRemoveTbk(evt);
        dialogTitle = t('deleteEventGridMsg');
        break;
      }
      case 'SAVE_IMPORT_SETTINGS': {
        onClick = (evt) =>
          this.importSettings(
            evt,
            tournamentToImportSettings['id'],
            tournamentData.id
          );
        dialogTitle = `${t('AreYouSureYouWantToImportSettings', {
          from: importedTournament[0].tournament_name,
          to: tournamentData.tournament_name
        })}`;
        break;
      }
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{ onClick }}
          {...{ dialogTitle }}
          {...{ dialogContent }}
          buttonPurpose={OK}
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}>
          {pressedIcon === 'REMOVE_TBK' && (
            <Filter
              options={categoryTypes}
              value={
                selectedValue(
                  categoryTypes,
                  ID,
                  selectedOption?.[TYPE],
                  true
                ) || ''
              }
              onChange={(evt, val) =>
                this.selectOption(
                  evt,
                  val,
                  'selectedOption',
                  TYPE,
                  ID,
                  selectedOptionErrors,
                  null,
                  true
                )
              }
              helperText={selectedOptionErrors?.type}
              error={selectedOptionErrors?.type?.length > 0}
              label={t('type')}
              item="name"
              variant="outlined"
            />
          )}
        </Modal>
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <QuestionMark
              src={iframeURL?.shortName}
              tooltip={iframeURL?.altName}
            />
            <TournamentForm
              tournamentData={tournamentData}
              handleTextChange={this.textChangeHandler}
              {...{ countries }}
              tournamentDateChange={this.tournamentDateChangeHandler}
              registrationDateChange={this.registrationDateChangeHandler}
              tournamentTimeChange={this.tournamentTimeChangeHandler}
              calculateNumberOfYearsDateChange={
                this.calculateNumberOfYearsDateChange
              }
              {...{ errors }}
              imageChange={(evt) =>
                this.changeImage(
                  evt,
                  'tournamentData',
                  TOURN_BANNER,
                  'imagePreview'
                )
              }
              values={tournamentData}
              updateAble={tournamentData.id}
              saveForm={(evt) => this.onSaveForm(evt)}
              selectOption={this.selectOption}
              {...{ shouldShowTournSettings }}
              {...{ selectedCheckboxes }}
              toggleTournSettings={this.toggleTournSettings}
              selectCheckbox={this.selectCheckbox}
              {...{ state }}
              {...{ closeFormHandler }}
              goBack={this.goBack}
              switchRegistration={(evt) =>
                this.setRegistrationStatus(
                  evt,
                  tournamentData.id,
                  tournamentData.registration_active,
                  this.fetchTournamentData
                )
              }
              showModal={this.showModal}
              {...{ tatamisTheme }}
              fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
              {...{ allRegions }}
              {...{ tournamentsList }}
              {...{ tournamentToImportSettings }}
              importSettings={(evt) =>
                this.importSettings(
                  evt,
                  tournamentToImportSettings['id'],
                  tournamentData.id
                )
              }
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(AddTournament));
