import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minWidth: 'fit-content',
        textTransform: 'none',
    },
    btn: {
        height: '40px',
        boxShadow: '0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)',
    },
    label: {
        '&.MuiButton-label': {
            pointerEvents: 'none',
        },
    },
    save: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgb(0, 25, 51, 0.85)',
        },
        '&:disabled': {
            cursor: 'not-allowed',
            color: '#fff',
        },
    },
    noActionBtn: {
        border: '1px solid #B5BAC2',
        color: '#546e7a',
    },
}))