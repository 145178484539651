import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';

import { Edit } from '@material-ui/icons';
import { ReactComponent as Bronze } from '../../assets/img/bronze.svg';

import { Tooltip, IconButton } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  isSmallScreen,
  selectedValue,
  convertMs,
  setCategoryName
} from '../../helpers/selectors';
import {
  ID,
  DELETE_ICON,
  FILTERS_CHECKBOXES,
  MODAL_EDIT_IC
} from '../../helpers/constants';

import EmptyState from '../EmptyState/EmptyState';
import Button from '../Buttons/ActionButtons';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import SearchInput from '../SearchInput/SearchInput';

import { useStyles } from './style';

const EXTRA_TIME = (item) => [
  {
    label: 'MT',
    value: item.main_time_ms && convertMs(item.main_time_ms, true),
    param: 'main_time_ms'
  },
  {
    label: 'ET',
    value: item.extra_time_ms && convertMs(item.extra_time_ms, true),
    param: 'extra_time_ms'
  },
  {
    label: 'FMT',
    value: item.final_main_time_ms && convertMs(item.final_main_time_ms, true),
    param: 'final_main_time_ms'
  },
  {
    label: 'FET',
    value:
      item.final_extra_time_ms && convertMs(item.final_extra_time_ms, true),
    param: 'final_extra_time_ms'
  }
];
const getMax = (category) => {
  return category?.weight_f
    ?.filter((cat) => !!+cat?.tournament_presence)
    ?.reduce(
      (max, currentVal) => (max.name > currentVal.name ? max : currentVal),
      0
    );
};

export default ({
  categories,
  tournamentId,
  selectKata,
  selectCategory,
  addCategory,
  shouldDisableEditing,
  isSending,
  onSelectCheckbox,
  selectedCheckboxes,
  kata_names,
  categoryTypes,
  viewportWidth,
  showModal,
  onSearch,
  onClearSearch,
  searchBar,
  exportTournamentCategories
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentLang = localStorage.getItem('i18nextLng');
  const KATA_ROUND = ['kara_round_1', 'kara_round_2'];

  const categoryName = (category) => {
    //Display the option to change the ALT name only for custom-made categories in the tournament
    const showEditAltName =
      `${category.type}` !== '4' &&
      ((category?.kata && +category.kata.tournament_presence === 1) ||
        category?.weight_t?.some(
          (kumite_cat) =>
            +kumite_cat.tournament_presence === 1 && !kumite_cat.justSelected
        )) &&
      !shouldDisableEditing;

    const convertNumberToRoman = (num) => {
      if (!isNaN(num)) {
        switch (num) {
          case 0:
            return 'I';
          case 1:
            return 'II';
          case 2:
            return 'III';
        }
      }
    };

    return (
      <span
        className={clsx(
          classes.flex,
          classes.wrap,
          classes.kataCategoryHeader,
          classes.verticallyCentered
        )}>
        <span className={classes.text}>{setCategoryName(category)}</span>
        <span
          className={clsx(
            classes.kataCustomNameMarginLeftRight,
            classes.fontSize09
          )}>
          {`(${category.g_name})`}
          {showEditAltName && (
            <span>
              <IconButton
                onClick={(evt) => showModal(evt, category, MODAL_EDIT_IC)}>
                <Edit style={{ pointerEvents: 'none' }} />
              </IconButton>
              {`${category.type}` === '1' &&
                `${t('round')} II: ${category.kata.kata_final}`}
            </span>
          )}
        </span>
        <span
          className={clsx(classes.flex, classes.column, classes.fontSize09)}>
          {category.kata && (
            <span className={clsx(classes.flex, classes.verticallyCentered)}>
              <span className={clsx(classes.flex, classes.column)}>
                {[
                  ...KATA_ROUND,
                  ...(+category.type === 5 ? ['kata_round_3'] : [])
                ].map(
                  (kataRound, kataRoundIdx) =>
                    category?.kata?.[kataRound]?.length > 0 && (
                      <span key={kataRoundIdx}>
                        {`${t('round')} ${convertNumberToRoman(
                          kataRoundIdx
                        )}: `}
                        {category.kata[kataRound].map((kata_n, idx) => {
                          const currentVal = selectedValue(
                            kata_names,
                            ID,
                            `${kata_n}`
                          );
                          return (
                            <span
                              className={classes.kataNamesMargin05}
                              key={idx}>
                              {currentVal &&
                                currentVal[
                                  currentLang === 'ru' ? 'name_ru' : 'name_en'
                                ]}
                              ;
                            </span>
                          );
                        })}
                      </span>
                    )
                )}
              </span>
              {[
                ...KATA_ROUND,
                ...(+category.type === 5 ? ['kata_round_3'] : [])
              ].some(
                (kataRound) =>
                  Array.isArray(category.kata[kataRound]) &&
                  category.kata[kataRound].length > 0 &&
                  !shouldDisableEditing
              ) && (
                <span
                  className={clsx(classes.flex, classes.deleteIc)}
                  id={DELETE_ICON}
                  onClick={(evt) => showModal(evt, category, DELETE_ICON)}>
                  <DeleteIcon className={classes.noEvent} />
                </span>
              )}
            </span>
          )}
        </span>
      </span>
    );
  };

  const Kata = (category, idx) =>
    category.kata && (
      <>
        <span
          className={clsx(
            classes.flex,
            classes.kumiteWrapper,
            classes.paddingTop1
          )}>
          <Tooltip arrow title={category.kata.category_name}>
            <span className={clsx(classes.flex, classes.relative)}>
              {!!+category?.kata?.tournament_presence && (
                <span className={classes.badge}>
                  {category?.kata?.participants_count}
                </span>
              )}
              <Button
                label={+category.type !== 4 ? t('kata') : t('tameshiwari')}
                isSaveBtn={category.kata.tournament_presence !== 0}
                className={clsx(
                  +category.type !== 4 && classes.buttons,
                  category.kata.tournament_presence === 0 &&
                    classes.notSelected,
                  shouldDisableEditing && classes.noEvent,
                  classes.marginLeft
                )}
                onClick={() => selectKata(category.kata)}
              />
            </span>
          </Tooltip>
        </span>
      </>
    );

  const KumiteMax = (category) => {
    const maxCategory = getMax(category);

    return (
      category.weight_t &&
      category.weight_f && (
        <span className={clsx(classes.relative, classes.kumiteBtns)}>
          {maxCategory?.category_id && (
            <span className={classes.badge}>
              {maxCategory?.participants_count}
            </span>
          )}
          <Button
            className={clsx(
              classes.max,
              !maxCategory?.category_id && classes.notSelected,
              classes.noEvent
            )}
            isSaveBtn={maxCategory?.category_id}
            label={
              maxCategory?.category_id
                ? parseFloat(maxCategory.name)
                  ? [parseFloat(maxCategory.name), '+'].join('')
                  : maxCategory.name
                : 'Max'
            }
          />
        </span>
      )
    );
  };

  return (
    <>
      <span className={clsx(classes.flex, classes.checkboxesWrapper)}>
        {FILTERS_CHECKBOXES(categoryTypes, true, true).map(
          (group, groupIdx) => (
            <span
              key={groupIdx}
              className={clsx(classes.flex, classes.wrap, classes.marginRight)}>
              {group.map((item, idx) => (
                <Fragment key={idx}>
                  <CheckboxBtn
                    formControlClassName={classes.paper}
                    classControlLabel={classes.checkboxItem}
                    className={classes.checkboxIcon}
                    checked={selectedCheckboxes.some((it) => it.id === item.id)}
                    onChange={() => onSelectCheckbox(item)}
                    label={item.name}
                    labelPlacement="start"
                  />
                </Fragment>
              ))}
            </span>
          )
        )}
      </span>
      <span className={clsx(classes.flex, classes.verticallyCentered)}>
        <Button
          className={classes.exportBtn}
          onClick={() => exportTournamentCategories(tournamentId)}
          label={t('saveList')}
        />
        <SearchInput
          className={classes.search}
          onChange={onSearch}
          clearSearch={onClearSearch}
          value={searchBar}
          isSearchInactive={!searchBar}
        />
      </span>
      {Array.isArray(categories) && categories.length > 0 ? (
        categories.map((category, index) => {
          return (
            <div className={clsx(classes.paper, classes.marginTop)} key={index}>
              <span
                className={clsx(
                  classes.flex,
                  classes.verticallyCentered,
                  classes.wrap
                )}>
                {categoryName(category)}
                {!!+category.bronze_fight_enabled && <Bronze />}
                {category?.hasOwnProperty('final_main_time_ms') &&
                  EXTRA_TIME(category).map(({ label, value }, timeIdx) => (
                    <MuiPickersUtilsProvider key={timeIdx} utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        KeyboardButtonProps={{
                          disabled: true,
                          style: { display: 'none' }
                        }}
                        ampm={false}
                        value={
                          (value &&
                            new Date(
                              null,
                              null,
                              null,
                              value.split(':')[0],
                              value.split(':')[1]
                            )) ||
                          ''
                        }
                        views="seconds"
                        classes={{ root: classes.timeInputWrapper }}
                        InputProps={{
                          disableUnderline: true,
                          className: clsx(classes.noEvent, classes.time),
                          startAdornment: (
                            <span className={classes.marginRight0dot5}>
                              {label}:
                            </span>
                          )
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ))}
              </span>
              <span className={clsx(classes.flex, classes.kumiteWrapper)}>
                {Array.isArray(category.weight_t) &&
                  category.weight_t.map((weightTo, weightIndex) => {
                    return (
                      <Tooltip
                        key={weightIndex}
                        arrow
                        title={weightTo.category_name}>
                        <span
                          className={clsx(
                            classes.relative,
                            classes.kumiteBtns
                          )}>
                          {!!+weightTo?.tournament_presence && (
                            <span className={classes.badge}>
                              {weightTo?.participants_count}
                            </span>
                          )}
                          <Button
                            className={clsx(
                              classes.buttons,
                              weightTo.tournament_presence === 0 &&
                                classes.notSelected,
                              shouldDisableEditing && classes.noEvent
                            )}
                            isSaveBtn={weightTo.tournament_presence !== 0}
                            label={weightTo.name}
                            onClick={() =>
                              selectCategory(
                                category.weight_t,
                                category.weight_f,
                                weightIndex
                              )
                            }
                          />
                        </span>
                      </Tooltip>
                    );
                  })}
                {!isSmallScreen(viewportWidth) && (
                  <>
                    {!(
                      +category?.type === 2 &&
                      +category?.age_from >= 18 &&
                      !category.age_to &&
                      !+category.group_id
                    ) && KumiteMax(category)}
                    {Kata(category, index)}
                  </>
                )}
              </span>
              {isSmallScreen(viewportWidth) && (
                <>
                  <span
                    className={clsx(classes.flex, classes.condensedViewMaxBtn)}>
                    {!(
                      +category?.type === 2 &&
                      +category?.age_from >= 18 &&
                      !category.age_to &&
                      !+category.group_id
                    ) && KumiteMax(category)}
                  </span>
                  {Kata(category, index)}
                </>
              )}
            </div>
          );
        })
      ) : (
        <EmptyState />
      )}
      {!shouldDisableEditing && (
        <Button
          label={t('save')}
          className={classes.saveBtn}
          onClick={addCategory}
          disabled={isSending}
          isSaveBtn={true}
        />
      )}
    </>
  );
};
