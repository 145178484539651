const styles = () => ({
  flex: {
    display: 'flex'
  },

  marginTop1: {
    marginTop: '1rem'
  }
});

export default styles;
