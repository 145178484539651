import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.2rem',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    padding: '0.2rem',
    margin: '.65rem',
    height: '40px'
  },

  maxWidth: {
    width: '100%'
  },

  icon: {
    marginRight: '0.2rem',
    color: '#546e7a'
  },

  clear: {
    cursor: 'pointer',
    '&:hover': {
      color: '#b4c4cb'
    }
  }
}));
