import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },
  padding: {
    padding: '0 .65em'
  },
  paddingRight: {
    paddingRight: '.65em'
  },
  paddingLeft: {
    '@media (min-width:900px)': {
      paddingLeft: '.65em'
    }
  },
  plus: {
    marginRight: '.5em',
    fontSize: '26px'
  },
  filter: {
    width: '100%',
    '@media (min-width:901px)': {
      width: '87.5em'
    }
  },
  addButton: {
    width: '10em',
    margin: '.5em 0',
    marginLeft: 'auto'
  }
}));

export default useStyles;
