import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  column: {
    flexDirection: 'column'
  },
  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },
  marginTop: {
    margin: '20px 0',
    '@media (max-width:900px)': {
      margin: '10px 0'
    }
  },

  kumiteWrapper: {
    margin: '0 -10px',
    flexWrap: 'wrap',
    alignItems: 'end'
  },
  kumiteBtns: {
    margin: '20px 10px 0 10px'
  },

  paddingTop1: {
    paddingTop: '1rem'
  },

  max: {
    width: '80px'
  },
  buttons: {
    width: '80px',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: '#808080'
    }
  },
  marginLeft: {
    marginLeft: '20px',
    '@media (max-width:900px)': {
      marginLeft: '10px'
    }
  },

  text: {
    fontSize: '22px',
    fontWeight: 500
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },
  checkboxItem: {
    margin: '10px 5px',
    minWidth: '120px',
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },
  wrap: {
    flexWrap: 'wrap'
  },
  marginRight: {
    marginRight: '20px'
  },

  checkboxesWrapper: {
    margin: '30px -10px 0 -10px',
    flexWrap: 'wrap'
  },

  notSelected: {
    backgroundColor: '#808080',
    color: '#fff',
    border: 'none'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  verticallyCentered: {
    alignItems: 'center'
  },

  condensedViewMaxBtn: {
    marginLeft: '-10px'
  },

  saveBtn: {
    width: '15em',
    position: 'fixed',
    bottom: '0.3rem',
    right: '3.9rem'
  },

  kataNamesMargin05: {
    margin: '0 0.2rem'
  },
  kataCategoryHeader: {
    maxWidth: 'calc(100% - 5em)',
    alignItems: 'center'
  },
  kataCustomNameMarginLeftRight: {
    margin: '0 0.5rem'
  },
  fontSize09: {
    fontSize: '0.9rem'
  },

  deleteIc: {
    marginLeft: '0.5rem',
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  search: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: '1rem',
    width: '100%'
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content',
      marginRight: '0.5rem'
    }
  },

  relative: {
    position: 'relative'
  },

  time: {
    border: '1px solid #001D3D',
    borderRadius: '0.2rem',
    padding: '0 0.2rem',
    width: '6em'
  },

  timeInputWrapper: {
    height: '2.5em',
    margin: '0.2rem 0.5rem',
    marginBottom: 0
  },

  badge: {
    background: '#dc3545',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    padding: '0.35em 0.65em',
    fontSize: '.75em',
    fontWeight: 700,
    lineHeight: 1,
    color: '#fff',
    whiteSpace: 'nowrap',
    borderRadius: '50%',
    left: '100%',
    top: 0,
    zIndex: 1
  }
}));

export { useStyles };
