import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse } from '@material-ui/core';
import Button from '../../components/Buttons/ActionButtons';
import { useStyles } from './styles';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import EmptyState from '../../components/EmptyState/EmptyState';

const Accordion = (props) => {
  const {
    accordionContent,
    itemToggle,
    data,
    collapsed,
    blockData,
    selectCheckbox
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {blockData.map((block, index) => (
        <div key={index} className={clsx(classes.flex, classes.checkboxes)}>
          <span className={clsx(classes.center, classes.flex)}>
            {block.name}
          </span>
          <div className={classes.marginLeft10}>
            {block?.checkboxes.map((checkbox, index) => (
              <Fragment key={index}>
                <CheckboxBtn
                  checked={props[block.paramName]?.includes(checkbox.id)}
                  className={clsx(classes.flex)}
                  onChange={() => selectCheckbox(block.paramName, checkbox.id)}
                  label={checkbox.label}
                />
              </Fragment>
            ))}
          </div>
          <Button
            isSaveBtn
            className={clsx(
              classes.flex,
              classes.export,
              classes.left,
              classes.marginTop5
            )}
            onClick={block.onClick}
            label={
              <>
                <SaveIcon className={classes.marginRight} />
                {block.buttonName}
              </>
            }
          />
        </div>
      ))}
      {data?.length > 0 ? (
        data.map((it, idx) => (
          <Fragment key={idx}>
            <span
              className={clsx(classes.flex, classes.paper)}
              onClick={() => itemToggle(idx)}>
              {`${idx + 1}. ${it.category_name}`}
              <ExpandMore
                className={clsx(
                  classes.left,
                  collapsed[idx] && classes.expandLess
                )}
              />
            </span>
            <Collapse in={collapsed[idx]} timeout="auto" unmountOnExit>
              {accordionContent && accordionContent(it.winners)}
            </Collapse>
          </Fragment>
        ))
      ) : (
        <EmptyState />
      )}
    </>
  );
};
export default Accordion;
