import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Tooltip, IconButton } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import videoIc from '../../assets/img/video.png';

import { selectedValue } from '../../helpers/selectors';
import { ID } from '../../helpers/constants';

import Button from '../Buttons/ActionButtons';

import { useStyles, tatamiStyle } from './Styles';

export default ({
  tatamis,
  addTatamis,
  shouldDisableEditing,
  openEditTatamiForm,
  tatamisTheme,
  lastBlockWithSelection,
  onAddBlock,
  onRemoveBlock,
  allBlockName
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let tatamiCounter = 0;

  return (
    <>
      {Array.isArray(tatamis) &&
        tatamis.map((tatami, tatamiIdx) => {
          const findPickedTatamiInBlock =
            Array.isArray(tatami) && tatami?.find((it) => !!+it.is_picked);

          return (
            +tatami[0]?.tatami_block <= lastBlockWithSelection && (
              <span
                key={tatamiIdx}
                className={clsx(
                  classes.flex,
                  classes.marginTop1and5,
                  classes.column
                )}>
                {tatamiIdx === 0 && (
                  <span
                    className={clsx(classes.flex, classes.verticallyCentered)}>
                    {t('addDeleteBlock')}
                    <Button
                      className={clsx(
                        classes.btn,
                        classes.marginBottom,
                        classes.marginRight0And5,
                        classes.marginLeft0And5
                      )}
                      label={<AddIcon />}
                      onClick={onAddBlock}
                      isSaveBtn={true}
                      disabled={tatamis.length - 1 === lastBlockWithSelection}
                    />
                    <Button
                      className={clsx(classes.btn, classes.marginBottom)}
                      label={<RemoveIcon />}
                      onClick={onRemoveBlock}
                      isSaveBtn={true}
                      disabled={lastBlockWithSelection === 0}
                    />
                  </span>
                )}
                <span className={clsx(classes.flex, classes.column)}>
                  <span
                    className={clsx(classes.flex, classes.verticallyCentered)}>
                    {t('block', { count: tatamiIdx })}
                    {findPickedTatamiInBlock?.day &&
                      ` / ${t('day')} ${findPickedTatamiInBlock.day}`}
                    {!shouldDisableEditing && findPickedTatamiInBlock && (
                      <IconButton
                        onClick={() => {
                          openEditTatamiForm('EDIT_BLOCK', {
                            ...findPickedTatamiInBlock
                          });
                        }}>
                        <EditIcon className={clsx(classes.noEvent)} />
                      </IconButton>
                    )}
                  </span>
                  {allBlockName?.[`block_name_${tatamiIdx}`] && (
                    <>
                      <span
                        style={{
                          opacity: '0.6',
                          fontSize: '.8rem',
                          borderBottom: '1px solid #BFBFBF'
                        }}>
                        {allBlockName?.[`block_name_${tatamiIdx}`]}
                      </span>
                    </>
                  )}
                </span>
                <span
                  className={clsx(
                    classes.flex,
                    classes.wrap,
                    classes.tatamiWrapper
                  )}>
                  {Array.isArray(tatami) &&
                    tatami.map((item, idx) => {
                      const tatamiBg =
                        !!+item.is_picked &&
                        selectedValue(tatamisTheme, ID, item.theme_id)?.color1;

                      !!+item.is_picked && tatamiCounter++;

                      return (
                        <span
                          className={clsx(
                            classes.flex,
                            classes.paper,
                            classes.column
                          )}
                          key={idx}>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.verticallyCentered
                            )}>
                            <span
                              className={clsx(classes.flex, classes.relative)}>
                              {!!+item.is_picked && (
                                <span className={classes.badge}>
                                  {item?.categories_count}
                                </span>
                              )}
                              <Tooltip
                                arrow
                                title={[
                                  t('select'),
                                  t('tatami'),
                                  '-',
                                  item.tatami_name
                                ].join(' ')}>
                                <span
                                  onClick={(evt) => {
                                    if (
                                      !!+item.is_picked &&
                                      !!+item.categories_count
                                    ) {
                                      openEditTatamiForm(
                                        'REMOVE_CONFIRMATION',
                                        item,
                                        tatamiCounter
                                      );
                                    } else {
                                      addTatamis(evt, item);
                                    }
                                  }}
                                  style={tatamiStyle(tatamiBg)}
                                  className={clsx(
                                    shouldDisableEditing && classes.noEvent,
                                    classes.tatami
                                  )}>
                                  {item.tatami_name}

                                  {item.translation_link_1 && (
                                    <img
                                      src={videoIc}
                                      alt="link"
                                      className={classes.videoIc}
                                    />
                                  )}
                                </span>
                              </Tooltip>
                            </span>
                            {!!+item.is_picked && (
                              <IconButton
                                className={clsx(
                                  classes.left,
                                  shouldDisableEditing && classes.noEvent
                                )}
                                onClick={() => {
                                  if (!shouldDisableEditing) {
                                    openEditTatamiForm('EDIT_TATAMI', item);
                                  }
                                }}>
                                <EditIcon className={clsx(classes.noEvent)} />
                              </IconButton>
                            )}
                          </span>
                        </span>
                      );
                    })}
                </span>
              </span>
            )
          );
        })}
    </>
  );
};
