import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ListItem, Tooltip } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './Styles';

const List = (props) => {
  const classes = useStyles();
  const { openModal, shouldDisableEditing, rowData, data, itemName } = props;

  const { t } = useTranslation();

  return (
    <>
      {Array.isArray(data) &&
        data.map((val, index) => (
          <ListItem
            key={index}
            className={clsx(
              classes.flex,
              classes.maxWidth,
              index !== 0 && classes.marginTop,
              classes.listRecord,
              classes.condensedViewLeftAligned
            )}>
            <span className={clsx(classes.noEvent, classes.breakWord)}>
              {itemName && itemName(val)}
            </span>
            {!shouldDisableEditing && (
              <span className={clsx(classes.flex, classes.marginLeft)}>
                <Tooltip arrow title={t('remove')}>
                  <ClearIcon
                    id="clearIcon"
                    className={clsx(
                      classes.icon,
                      classes.iconWrapper,
                      classes.marginLeft
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(e, rowData, val);
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </ListItem>
        ))}
    </>
  );
};

export default List;
