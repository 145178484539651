import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    marginLeft: 0,
    '@media (min-width:651px)': {
      padding: '1em',
      margin: '1em',
      width: '28em'
    },
    '@media (max-width:650px)': {
      width: 'calc(100vw - 3em)',
      marginTop: '.65em',
      padding: '.65em'
    },
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`
  },

  noOwnerBorder: {
    border: `3px solid ${theme.palette.primary.main}`
  },

  statisticsLable: {
    width: '6em'
  },
  orderFirst: {
    order: 1
  },
  auto: {
    margin: 'auto'
  },
  txtAlignCenter: {
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  icon: {
    width: '2.2rem',
    height: '2.2rem',
    marginRight: '1rem',
    color: '#001D3D'
  },
  icColumn: {
    width: 'calc(100%/2)',
    wordBreak: 'break-word'
  },
  icTxt: {
    width: 'calc(100% - 3.2em)'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  bold: {
    fontWeight: 500
  },
  verticallyCenter: {
    alignItems: 'center'
  },
  marginRight: {
    marginRight: '.5em'
  },
  marginBottom: {
    marginBottom: '1em'
  },
  marginLeft: {
    marginLeft: '1em'
  },
  tournamentTypeTitle: {
    fontSize: '2rem',
    borderBottom: '1px solid rgba(0, 29, 61,0.4)',
    '@media (max-width:900px)': {
      fontSize: '18px'
    }
  },
  marginLeftCenter: {
    marginLeft: 'auto'
  },
  padding5: {
    padding: '5px'
  },
  fullWidth: {
    width: '100%'
  },
  clockSvg: {
    '@media (min-width:651px)': {
      height: '14em'
    },
    '@media (max-width:650px)': {
      height: '11em'
    }
  },
  img: {
    height: '14em',
    width: '10em',
    objectFit: 'cover',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8
    },
    '@media (max-width: 500px)': {
      width: 'calc(50% - 0.65em)'
    }
  },
  spinner: {
    height: '20em',
    margin: 'auto'
  },
  column: {
    flexDirection: 'column'
  },
  cardsWrapperOnly: {
    overflow: 'hidden',
    '@media (min-width:651px)': {
      width: 'calc(100% - 7em)'
    }
  },
  empty: {
    width: '100%'
  },
  day: {
    top: '35%',
    left: '10%'
  },
  hour: {
    top: '35%',
    right: '10%'
  },
  min: {
    bottom: '20%',
    left: '10%'
  },
  sec: {
    bottom: '20%',
    right: '10%'
  },
  timerDigits: {
    width: '4em',
    position: 'absolute',
    color: '#fff'
  },
  clockWrapper: {
    '@media(min-width:651px)': {
      marginRight: '-1em'
    },
    '@media(max-width:650px)': {
      marginRight: '-.65em'
    },
    height: '100%',
    justifyContent: 'center'
  },

  relative: {
    position: 'relative'
  },

  noOwnerInnerIc: {
    position: 'absolute',
    color: '#FF0000',
    fontWeight: 700,
    bottom: '1rem',
    right: '0.8rem'
  },

  wrapper: {
    position: 'relative',
    justifyContent: 'center'
  },
  activeArrow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#001D3D'
    }
  },
  arrow: {
    position: 'absolute',
    height: '2.5em',
    width: '2.5em',
    backgroundColor: 'rgba(0, 29, 61, 0.5)',
    color: '#fff',
    borderRadius: '50%',
    transition: '.3s',
    userSelect: 'none',
    zIndex: 10
  },
  disableIcon: {
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.3)'
  },
  nextArrow: {
    right: '5px',
    transform: 'rotate(180deg)'
  },
  prevArrow: {
    left: '5px'
  },
  hiddenHeadText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  breakWord: {
    wordBreak: 'break-word'
  },

  coachRecorderUseQuickRegLinkInfo: {
    color: 'brown'
  },

  marginTop05: {
    marginTop: '.5rem'
  },

  txtField: {
    width: 'calc(100% - 4em)'
  },

  iconBtn: {
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    padding: '5px',
    marginLeft: '0.5rem',
    width: '3em',
    height: '2.2em',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  noEvent: {
    pointerEvents: 'none'
  }
}));
