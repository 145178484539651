import { createRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import clsx from 'clsx';
import Filter from '../../components/Filter/Filter';
import InvitationInput from './InvitationInput';
import { Tooltip, Chip, IconButton, CircularProgress } from '@material-ui/core';
import CopyTxt from '../../components/CopyTxt/CopyTxt';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import {
  LANG,
  NETINO_EMAIL,
  PREVIEW,
  ADD_RECIPIENTS,
  ENV_URL,
  serverDefaultPath
} from '../../helpers/constants';
import { selectedValue, organizerName } from '../../helpers/selectors';

import ActionButton from '../../components/Buttons/ActionButtons';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Aux from '../../hoc/Auxiliar/Auxiliar';

import Modal from '../Modal/Modal';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';

import { useStyles, spinner } from './StylesInvitation';

const Invitation = (props) => {
  const {
    selectOption,
    editTemplateBody,
    templateLang,
    invitationToken,
    open,
    hideModal,
    showModal,
    previewTemplate,
    templateTxt,
    updateTemplate,
    sendInvitation,
    recipients,
    subject,
    editEmailReference,
    onKeyDown,
    pressedIcon,
    pressItem,
    coachesList,
    selectEmails,
    selectedCheckboxes,
    applyEmails,
    selectAllEmails,
    checkAll,
    indeterminate,
    sendTo,
    deleteEmail,
    errors,
    pasteEmails,
    addNewEmail,
    fetchDefaultTemplate,
    isSending,
    tournamentData,
    shouldShowDiscardChanges,
    discardOrKeepEditing
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const noRecipients = Array.isArray(recipients) && recipients.length === 0;
  const LANGUAGES = [
    { lang: '1', label: t('defaultInvitationTxt') },
    { lang: '2', label: t('invitationTxtRu') },
    { lang: '3', label: t('invitationTxtRo') }
  ];
  const buttons = [
    {
      purpose: t('preview'),
      onClick: (e) => {
        showModal();
        pressItem(e);
      },
      id: PREVIEW
    },
    {
      purpose: [t('save'), t('template')].join(' '),
      onClick: updateTemplate,
      id: 'save'
    },
    {
      purpose: isSending ? <CircularProgress style={spinner} /> : t('send'),
      onClick: sendInvitation,
      id: 'sendInvitation'
    }
  ];

  const inputRefSendTo = createRef();
  const inputRefSubject = createRef();

  const blocks = [
    {
      label: t('link2QuickRegistration'),
      info: (
        <CopyTxt
          copiedTxt={`${ENV_URL}/register_to_tournament/${invitationToken}`}
        />
      )
    },
    {
      label: t('eventCode'),
      info: tournamentData.id
    }
  ];

  return (
    <Aux>
      <HeaderTournInfo {...{ tournamentData }} shouldShowBtn={true} />
      <Modal
        {...{ open }}
        close={hideModal}
        dialogTitle={
          pressedIcon === PREVIEW ? t('preview') : t('selectCoaches')
        }
        onClick={pressedIcon !== PREVIEW ? applyEmails : undefined}
        buttonPurpose={pressedIcon !== PREVIEW && t('apply')}
        {...{ shouldShowDiscardChanges }}
        {...{ discardOrKeepEditing }}>
        {open &&
          (pressedIcon === PREVIEW ? (
            <>
              {t('from')}:
              <a className={classes.textField} href={`mailto:${NETINO_EMAIL}`}>
                {NETINO_EMAIL}
              </a>
              <span className={clsx(classes.sendTo, classes.flex)}>
                <span className={classes.marginRight10}>{t('sendTo')}:</span>
                <span className={classes.previewEmailsList}>
                  {recipients.join(', ')}
                </span>
              </span>
              <hr className={classes.divider} />
              <p className={classes.templatePreview}>
                {previewTemplate && parse(previewTemplate)}
              </p>
            </>
          ) : (
            Array.isArray(coachesList) &&
            coachesList.length > 0 && (
              <>
                <CheckboxBtn
                  className={classes.checkboxes}
                  classControlLabel={classes.flex}
                  onChange={selectAllEmails}
                  checked={checkAll}
                  indeterminate={indeterminate}
                  label={[t('select'), t('all')].join(' ')}
                />
                {coachesList.map(({ id, email, c_name, club }) => (
                  <Fragment key={id}>
                    <CheckboxBtn
                      className={classes.checkboxes}
                      classControlLabel={classes.flex}
                      onChange={() => selectEmails(email)}
                      checked={selectedCheckboxes.includes(email)}
                      label={
                        <span className={classes.flex}>
                          {[c_name, club].join(' - ')}
                          <sub className={clsx(classes.sub)}>{email}</sub>
                        </span>
                      }
                    />
                  </Fragment>
                ))}
              </>
            )
          ))}
      </Modal>
      <div>
        {/* Commented out the code based on the ticket #440 */}
        {/* <InvitationInput
          onClick={() => inputRefSendTo.current.focus()}
          value={sendTo || ''}
          onChange={editEmailReference}
          name="sendTo"
          placeholder={noRecipients && t('typeEmails')}
          {...{ onKeyDown }}
          onBlur={addNewEmail}
          onPaste={pasteEmails}
          inputRef={inputRefSendTo}
          startAdornment={`${t('sendTo')}:`}
          error={errors.sendTo}
          endAdornment={
            <Tooltip arrow title={t('selectCoaches')}>
              <IconButton
                id={ADD_RECIPIENTS}
                className={clsx(
                  classes.iconButton,
                  Boolean(errors) && classes.errorText
                )}
                onClick={(e) => {
                  showModal();
                  pressItem(e);
                }}>
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>
          }>
          <>
            {recipients.map((email, index) => (
              <Chip
                classes={{
                  root: classes.chips
                }}
                key={email + index}
                label={email}
                onDelete={() => deleteEmail(email)}
              />
            ))}
          </>
        </InvitationInput>
        <InvitationInput
          onClick={() => inputRefSubject.current.focus()}
          value={subject || ''}
          onChange={editEmailReference}
          inputRef={inputRefSubject}
          name="subject"
          startAdornment={`${t('subject')}:`}
          className={classes.subjectInput}
          styleInput={classes.fullWidth}
        />
        <span className={clsx(classes.flex, classes.alignColumn)}>
          <InvitationInput
            value={templateTxt || ''}
            onChange={editTemplateBody}
            name="templateTxt"
            multiline="true"
            onBlur={fetchDefaultTemplate}
            className={clsx(classes.fullWidth, classes.comboBox)}
            styleInput={classes.fullWidth}
            error={errors.templateTxt}
          />
          <span
            className={clsx(
              classes.flex,
              classes.btnWrapper,
              classes.fullWidth,
              classes.column
            )}>
            <Filter
              className={clsx(
                classes.label,
                classes.fullWidth,
                classes.marginBottom
              )}
              classes={{ inputRoot: classes.outlined }}
              options={LANGUAGES}
              onChange={selectOption}
              label={t('templates')}
              value={selectedValue(LANGUAGES, LANG, templateLang) || ''}
              item="label"
              variant="outlined"
            />
            {buttons.map(({ purpose, onClick, id }) => (
              <Fragment key={id}>
                <ActionButton
                  id={PREVIEW}
                  className={clsx(classes.fullWidth, classes.marginBottom)}
                  label={purpose}
                  {...{ onClick }}
                  isSaveBtn={true}
                />
              </Fragment>
            ))}
          </span>
        </span> */}
        {blocks.map((block, blockIdx) => (
          <div
            key={blockIdx}
            className={clsx(classes.root, classes.marginBottom20)}>
            <span className={clsx(classes.flex, classes.link2CopyHeader)}>
              {block.label}
            </span>
            <span>{block.info}</span>
          </div>
        ))}
      </div>
    </Aux>
  );
};
export default Invitation;
