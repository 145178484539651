import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    flex: {
        display: 'flex',
    },
    paper: {
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    },
    informativeMsg: {
        fontSize: '14px',
    },
    container: {
        minHeight: '60px',
        alignItems: 'center',
        border: '1px solid #d1d6dd',
        borderRadius: '4px',
        transition: 'border .24s ease-in-out',
        margin: 'auto',
        cursor: 'pointer',
        padding: '5px',
    },
    centerHorizontally: {
        justifyContent: 'center',
    },
    textLink: {
        color: theme.palette.text.link,
        '&:hover': {
            opacity: 0.8,
        }
    },
    browseText: {
        paddingLeft: '5px',
    },
    removeButton: {
        marginLeft: '15px',
    },
    rejectFile: {
        color: '#fff',
        backgroundColor: '#ff8080',
    },
    marginTop: {
        marginTop: '20px',
    },
    alertWrapper: {
        marginBottom: '10px',
        justifyContent: 'center',
    },
    alertBanner: {
        width: '60%',
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '350px',
        textOverflow: 'ellipsis',
        display: 'block',
    },
}));

export default useStyles;