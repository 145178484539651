import { useState } from 'react';
import clsx from 'clsx';
import { Sidebar, TopbarP2, Footer } from './components';
import { useStyles } from './StylesMain';

const Main = (props) => {
  const { children, pageTitle } = props;

  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleDrawer = (open) => {
    setOpenSidebar(open);
  };

  return (
    <>
      <TopbarP2 {...{ pageTitle }} onSidebarOpen={() => toggleDrawer(true)} />
      {openSidebar && (
        <Sidebar
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          open={openSidebar}
        />
      )}
      <main className={clsx(classes.content, classes.paddingTop)}>
        <div className={classes.body}>{children}</div>
        <Footer className={classes.footer} styleTxt={classes.footerTxt} />
      </main>
    </>
  );
};

export default Main;
