import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ENABLED, LOCALE } from '../../helpers/constants';
import {
  finishedRegistration,
  finishedTournament
} from '../../helpers/selectors';

import { useStyles } from './Styles';

export default ({ tournamentData, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentLang = localStorage.getItem('i18nextLng');
  const { registration_date, reg_judge_date, registration_active, finished } =
    tournamentData;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(LOCALE(currentLang), {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const openRegistration = registration_active === ENABLED;
  const endDate = formatDate(registration_date);
  const endJudgeDate = formatDate(reg_judge_date);

  return (
    <span>
      <div
        className={clsx(
          className,
          !finishedRegistration(registration_date, finished) && openRegistration
            ? classes.active
            : classes.closed
        )}>
        {finishedTournament(tournamentData)
          ? t('eventClosed')
          : !openRegistration
          ? t('registrationClosed')
          : finishedRegistration(registration_date, finished)
          ? t('closedRegistration', { date: endDate })
          : t('openRegistration', { date: endDate })}
      </div>
      {tournamentData.reg_judge_form === '1' && (
        <div
          className={clsx(
            className,
            !finishedRegistration(reg_judge_date, finished) && openRegistration
              ? classes.active
              : classes.closed
          )}>
          {!finishedTournament(tournamentData) &&
            openRegistration &&
            (finishedRegistration(reg_judge_date, finished)
              ? t('closedJudgeRegistration', { date: endJudgeDate })
              : t('openJudgeRegistration', { date: endJudgeDate }))}
        </div>
      )}
    </span>
  );
};
