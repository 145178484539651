import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Tooltip } from '@material-ui/core';

import { DEFAULT_TOURNAMENT, serverDefaultPath } from '../../helpers/constants';
import { DDMMYYYY, isSmallScreen, organizerName } from '../../helpers/selectors';

import Button from '../Buttons/ActionButtons';
import RegistrationStatus from '../RegistrationStatus/RegistrationStatus';

import { useStyles, useStylesPageHeader } from './styles';

const HeaderTournInfo = (props) => {
  const {
    summaryHeader,
    tournamentData,
    shouldShowBtn,
    pageHeaderStatistics,
    viewportWidth
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1, {
      state: { prevUrl: location.pathname }
    });
  };

  const tournType = () => {
    switch (tournamentData?.tournament_type) {
      case 'local':
        return t('local');
      case 'international':
        return t('international');
      case 'training_school':
        return t('trainingSchool');
    }
  };

  const headerTournMaster = {
    img: {
      src: tournamentData?.poster && serverDefaultPath + tournamentData?.poster
    },
    button: {
      id: 'goBack',
      onClick: () => goBack(),
      label: <ArrowBackIcon />
    },
    info: [
      { name: tournamentData?.tournament_name },
      {
        name: DDMMYYYY(tournamentData?.start_date, true) 
      },
      {
        label: t('organizer'),
        name:
          tournamentData &&
          [
            organizerName(tournamentData)?.firstN,
            organizerName(tournamentData)?.lastN
          ].join(' ')
      },
      {
        label: t('eventType'),
        name: tournType()
      }
    ],
    showRegistrationStatus: true
  };

  const currentDataObj = (summaryHeader && summaryHeader(tournType())) ?? [
    headerTournMaster,
    ...(pageHeaderStatistics ? [pageHeaderStatistics] : [])
  ];

  const classes1 = useStylesPageHeader(currentDataObj);

  const btnComponent = (key, className) => (
    <Button
      id={key.button.id}
      className={clsx(classes.flex, className, classes.left)}
      onClick={key.button.onClick}
      label={key.button.label}
      isSaveBtn
    />
  );

  const cardHeaderContent = (key, idx, className, defaultImg) =>
    tournamentData &&
    Object.keys(tournamentData).length > 0 && (
      <span
        key={idx}
        className={clsx(
          classes.flex,
          className,
          classes.column,
          classes.fullWidth
        )}>
        <span className={classes.flex}>
          {key?.img && (
            <img
              className={classes.img}
              alt="pic"
              src={key?.img?.src ?? defaultImg}
            />
          )}
          <span
            className={clsx(
              classes.marginLeftRight,
              key?.button?.className
                ? classes.panelWithoutImgBtn
                : classes.fullWidth
            )}>
            {key.info.map((it, infoIdx) => (
              <span
                key={`${it.label}_${idx}_${infoIdx}`}
                className={clsx(
                  classes.flex,
                  isSmallScreen(viewportWidth) && classes.column,
                  classes.paddingBottom
                )}>
                {it.label && (
                  <span className={clsx(classes.secondaryTxt)}>
                    {[it.label, !isSmallScreen(viewportWidth) ? ':' : ''].join(
                      ''
                    )}
                  </span>
                )}
                {it.name}
              </span>
            ))}
          </span>
          {shouldShowBtn && key.button && !isSmallScreen(viewportWidth) ? (
            key?.button?.className ? (
              btnComponent(key, key.button.className)
            ) : (
              <Tooltip arrow title={t('goBack')}>
                <span className={classes.heightFitContent}>
                  {btnComponent(key, classes.goBackArrow)}
                </span>
              </Tooltip>
            )
          ) : (
            Array.isArray(key.button) && (
              <span
                className={clsx(
                  classes.flex,
                  classes.heightFitContent,
                  classes.column
                )}>
                {key.button.map((icBtn, idxIcBtn) => (
                  <Fragment key={idxIcBtn}>{icBtn.ic}</Fragment>
                ))}
              </span>
            )
          )}
        </span>
        {key.showRegistrationStatus && (
          <RegistrationStatus {...{ tournamentData }} />
        )}
      </span>
    );

  return (
    <span className={clsx(classes1.grid, classes.marginBottom40)}>
      {currentDataObj.map((item, idx) =>
        cardHeaderContent(
          item,
          idx,
          [
            classes.paper,
            classes.paperShadow,
            ...(idx === 0 ? [classes.paddingRight] : [classes.paddingLeft])
          ],
          DEFAULT_TOURNAMENT
        )
      )}
    </span>
  );
};

export default HeaderTournInfo;
