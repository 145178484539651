const styles = () => ({
  flex: {
    display: 'flex'
  },

  maxColumnWidth: {
    maxWidth: '20em',
    whiteSpace: 'unset !important'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },

  checkboxesWrapper: {
    margin: '0 -5px 10px -5px',
    flexWrap: 'wrap'
  },

  checkboxItem: {
    margin: '10px 5px',
    '@media (max-width:900px)': {
      width: '8.7em'
    },
    '@media (min-width:901px)': {
      width: '11.5em'
    },
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },

  checkbox: {
    marginLeft: 'auto',
    marginRight: 0
  },

  disabledIc: {
    opacity: 0.4,
    pointerEvents: 'none'
  },

  marginTop1: {
    marginTop: '1rem'
  }
});
export default styles;
