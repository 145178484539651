import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import Button from '../../Buttons/ActionButtonsP2';
import clsx from 'clsx';
import { finishedTournament, isSchool } from '../../../helpers/selectors';
import { useStyles } from './StylesHeader';

const Header = ({ tournamentData, showBracketInformation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isApprove = (tournamentData) =>
    tournamentData && +tournamentData.reg_judge_form === 1;
  const goTo = (path) =>
    navigate(path, {
      state: { prevUrl: location.pathname }
    });

  const isDisabled = finishedTournament(tournamentData);
  const isTraining = isSchool(tournamentData);
  const actions = tournamentData
    ? [
        ...(!isTraining
          ? [
              {
                onClick: () =>
                  goTo(`/events/add-categories-to-event/${tournamentData.id}`),
                label: [t('tatami'), t('categories')].join('/')
              }
            ]
          : []),
        {
          onClick: () => goTo(`/events/select-attachment/${tournamentData.id}`),
          label: t('documents')
        },
        {
          onClick: () => goTo(`/events/invite/${tournamentData.id}`),
          label: t('invite'),
          disabled: isDisabled
        },
        {
          onClick: () =>
            goTo(`/events/participants?tournament_id=${tournamentData.id}`),
          label: t('participants')
        },
        {
          onClick: () => goTo(`/events/coaches_requests/${tournamentData.id}`),
          label: t('approveRegistration')
        },
        ...(isApprove(tournamentData)
          ? [
              {
                onClick: () => goTo(`/events/staff/${tournamentData.id}`),
                label: t('judges')
              }
            ]
          : []),
        ...(isApprove(tournamentData)
          ? [
              {
                onClick: () =>
                  goTo(`/events/judges_requests/${tournamentData.id}`),
                label: t('approveJudges')
              }
            ]
          : []),
        {
          onClick: () =>
            goTo(`/events/event_reports?tournament_id=${tournamentData.id}`),
          label: t('reports')
        },
        ...(!isTraining
          ? [
              {
                onClick: (evt) => showBracketInformation(evt),
                label: t('bracketInformation'),
                id: "bracket"
              }
            ]
          : []),
      ]
    : [];
  const button = (label, onClick, disabled, id, isNoActionBtn) => (
    <Button
      {...{ id }}
      {...{ disabled }}
      className={classes.button}
      {...{ onClick }}
      {...{ label }}
      isSaveBtn={!isNoActionBtn}
    />
  );

  return (
    <div className={clsx(classes.flex, classes.buttonsWrapper)}>
      {actions.map(({ label, onClick, disabled, id, isNoActionBtn }, index) => (
        <span
          key={index}
          className={clsx(disabled && classes.disabledBtn)}>
          {button(label, onClick, disabled, id, isNoActionBtn)}
        </span>
      ))}
    </div>
  );
};
export default Header;
