import React, { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { finishedTournament } from '../../helpers/selectors';
import {
  changeModal,
  fetchTournaments,
  fetchAllCategoryTypesV2,
  changeHead,
  fetchReference
} from '../../helpers/util';

import SideModal from '../../components/Snackbar/SideModal';
import TabItems from '../../components/Tabs/TabItems';
import DragDrop from '../DragDrop/DragDrop';
import DistributeParticipantsByCategories from '../DistributeParticipantsByCategories/DistributeParticipantsByCategories';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import EmptyState from '../../components/EmptyState/EmptyState';
import AddTatami from '../TournamentAddTatamis/TournamentAddTatamis';
import TournamentAddCategories from '../TournamentAddCategories/TournamentAddCategories';

import styles from './styles';
import QuestionMark from 'components/QuestionMark/QuestionMark';

class TournamentTatamisAndCategories extends Component {
  state = {
    tournamentId: +this.props.match.params.tournamentId,
    langOnLoad: localStorage.getItem('i18nextLng'),
    role: localStorage.getItem('role'),
    tab: 1,
    tournamentData: {},
    categoryTypes: [],
    viewportWidth: window.innerWidth,
    shouldDisableEditing: false,
    iframeURL: {}
  };

  changeModal = changeModal.bind(this);
  fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  changeHead = changeHead.bind(this);
  fetchReference = fetchReference.bind(this);

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, role, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      this.fetchAllCategoryTypesV2(
        role === 'administrator' && { user_id: tournamentData?.organizer_id },
        (types) => {
          this.setState({
            ...(types ? { categoryTypes: types } : {})
          });
        }
      );

      changeHead(tournamentData, t('tatamiCategories'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { tournamentId, role, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing = finishedTournament(tournamentData);

      this.fetchAllCategoryTypesV2(
        role === 'administrator' && { user_id: tournamentData?.organizer_id },
        (types) => {
          this.setState({
            ...(types ? { categoryTypes: types } : {})
          });
        }
      );

      this.setState({ shouldDisableEditing });
      changeHead(tournamentData, t('tatamiCategories'));
    });

    window.addEventListener('resize', this.onResize);

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter(
          (item) => item.name === 'add-categories-to-event'
        );
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => this.setState({ viewportWidth: window.innerWidth });

  switchTabs = (_, key) => {
    const { tab } = this.state;

    this.setState({
      tab: key,
      ...(tab !== key
        ? { statistics: null, shouldRenderHeaderDetails: null }
        : {}) //This value adjusts the header styles to toggle the display between single and dual panel views
    });
  };

  hideSnackbar = () => this.setState({ showModal: false });

  onGetPageHeaderStatistics = (result) => {
    this.setState({ statistics: result, shouldRenderHeaderDetails: true });
  };

  render() {
    const {
      tournamentId,
      showModal,
      modalInfo,
      tab,
      shouldDisableEditing,
      success,
      tournamentData,
      categoryTypes,
      statistics,
      shouldRenderHeaderDetails,
      viewportWidth,
      iframeURL
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;

    const TABS = [
      { id: 1, label: t('assignTatami') },
      { id: 2, label: t('assignCategories') },
      { id: 3, label: t('distributeCategories') },
      { id: 4, label: t('distributeParticipantsByCategories') }
    ];

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {tournamentData ? (
          <>
            {shouldRenderHeaderDetails && ( //re-render the header
              <HeaderTournInfo
                {...{ tournamentData }}
                pageHeaderStatistics={statistics}
                shouldShowBtn={true}
              />
            )}
            <TabItems
              className={classes.tabs}
              type={tab}
              onChange={this.switchTabs}
              data={TABS}
              value="id"
              label="label"
            />
            {tab === 1 && tournamentData?.id && (
              <AddTatami
                {...{ tournamentId }}
                {...{ shouldDisableEditing, tournamentData }}
                onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
              />
            )}
            {tab === 2 && (
              <TournamentAddCategories
                {...{ shouldDisableEditing }}
                {...{ tournamentId }}
                {...{ viewportWidth }}
                {...{ categoryTypes }}
                {...{ tournamentData }}
                onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
              />
            )}
            {tab === 3 && (
              <DragDrop
                {...{ tournamentData }}
                {...{ categoryTypes }}
                {...{ shouldDisableEditing }}
                onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
              />
            )}
            {tab === 4 && (
              <DistributeParticipantsByCategories
                {...{ categoryTypes }}
                {...{ tournamentData }}
                {...{ shouldDisableEditing }}
                onGetPageHeaderStatistics={this.onGetPageHeaderStatistics}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(TournamentTatamisAndCategories))
);
