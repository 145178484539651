import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { DEFAULT_AVATAR, DEFAULT_TOURNAMENT } from '../../helpers/constants';
import { useStyles } from './StylesUploadImage';

const UploadImage = (props) => {
  const { onChange, img, className, id, isPoster, disabled, header } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <input
        accept="image/jpeg, image/jpg, image/png"
        className={classes.input}
        {...{ id }}
        type="file"
        {...{ onChange }}
        disabled={disabled}
      />
      <label htmlFor={id}>
        <img
          src={img ? img : !isPoster ? DEFAULT_AVATAR : DEFAULT_TOURNAMENT}
          alt={t('photo')}
          className={clsx(
            !header 
              ? classes.cover
              : classes.coverHeader, 
            classes.image, 
            classes.fullSize
          )}
        />
        <span
          className={clsx(img && classes.uploadedImgExist, classes.imgShadow)}>
          <PhotoCamera className={classes.camera} />
          <span className={classes.text}>{t('photo')}</span>
        </span>
      </label>
    </div>
  );
};
export default UploadImage;
