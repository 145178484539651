import { Fragment, Component } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { Tooltip } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { AuthContext } from '../../AuthContext';

import {
  pageChangeHandler,
  rowsPerPageHandler,
  clearSearch,
  handleTextChange,
  deleteCategory,
  deleteCategoryGroup,
  fetchCategories,
  fetchAllCategoryTypes,
  changeModal
} from '../../helpers/util';
import {
  DELETE_ICON,
  ADD_NEW_GROUP_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_GROUP,
  ADD_CATEGORY,
  UPDATE_GROUP_CATEGORY,
  TEST_ID_FORM,
  FILTERS_CHECKBOXES
} from '../../helpers/constants';
import { changeTitle } from '../../helpers/actions';

import LoadingState from '../../components/LoadingState/LoadingState';
import Pagination from '../../components/TablePagination/TablePagination';
import Modal from '../../components/Modal/ModalNewDesign';
import AddCategory from '../AddCategory/AddCategory';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import SideModal from '../../components/Snackbar/SideModal';
import Table from '../../components/Table/Table';

import styles from './styles';

class Categories extends Component {
  state = {
    categories: [],
    categoryTypes: [],
    open: false,
    showModal: false,
    shouldUpdateTable: false,
    loading: true,
    success: false,
    isSearching: false,
    searchedValue: '',
    searchBar: '',
    filteredCategories: [],
    langOnLoad: localStorage.getItem('i18nextLng'),
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      10,
    page: 1,
    categoriesCount: 0,
    modalData: {},
    selectedCheckboxes: [],
    collapsed: []
  };

  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  clearSearch = clearSearch.bind(this);
  handleTextChange = handleTextChange.bind(this);
  deleteCategory = deleteCategory.bind(this);
  deleteCategoryGroup = deleteCategoryGroup.bind(this);
  changeModal = changeModal.bind(this);
  fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
  fetchCategories = fetchCategories.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const {
      shouldUpdateTable,
      page,
      searchBar,
      rowsPerPage,
      typeList,
      genderList,
      ageList,
      ownerList,
      collapsed,
      langOnLoad
    } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      shouldUpdateTable !== prevState.shouldUpdateTable
    ) {
      this.fetchData(
        page,
        rowsPerPage,
        () => {
          const { categories } = this.state;
          const copyCollapsed = [...collapsed];
          const len = copyCollapsed.length;

          for (let i = 0; i < len; i++) {
            const findCategory = categories.find(
              (it) => +it?.group_id === +copyCollapsed[i]?.id
            );

            if (findCategory) {
              copyCollapsed[i].categories = findCategory.categories;

              this.setState({ collapsed: copyCollapsed });
            }
          }
        },
        typeList,
        genderList,
        ageList,
        ownerList,
        searchBar
      );
    } else if (searchBar !== prevState.searchBar) {
      this.setState({ isSearching: true });
    }

    if (langOnLoad !== currentLang) {
      this.fetchAllCategoryTypes((types) => {
        this.setState({
          ...(types ? { categoryTypes: types } : {})
        });
      });

      this.setState({ langOnLoad: currentLang });

      changeTitle(t('categories'));
    }
  }

  componentDidMount() {
    const { page, rowsPerPage } = this.state;

    this.fetchData(page, rowsPerPage, () =>
      this.fetchAllCategoryTypes((types) => {
        this.setState({
          ...(types ? { categoryTypes: types } : {}),
          loading: false
        });
      })
    );

    changeTitle(this.props.t('categories'));
  }

  fetchData = (
    page,
    rowsPerPage,
    action,
    typeList,
    genderList,
    ageList,
    ownerList,
    search
  ) => {
    this.fetchCategories(
      () => {
        action && action();
        this.setState({ isSearching: false });
      },
      null,
      null,
      page,
      rowsPerPage,
      null,
      null,
      typeList,
      genderList,
      ageList,
      ownerList,
      search,
      true // grouped_mode (group categories by group_id)
    );
  };

  showModal = (item) => {
    this.setState({
      categoryId: item.category_id,
      modalData: item,
      open: true
    });
  };

  showModalForm = (evt, item, showFormName, parentValue, idx) => {
    evt.preventDefault();

    const { categoryTypes } = this.state;

    const id =
      showFormName === ADD_NEW_GROUP_CATEGORY || showFormName === ADD_CATEGORY
        ? null
        : showFormName === UPDATE_GROUP
        ? item?.group_id
        : item?.category_id;
    const selectedRow =
      showFormName === ADD_NEW_GROUP_CATEGORY
        ? null
        : showFormName === UPDATE_GROUP || showFormName === ADD_CATEGORY
        ? item?.group_id
        : item?.category_id;
    const shouldUpdateGroup = showFormName === 'UPDATE_GROUP';

    this.setState({
      shouldShowForm: showFormName,
      pressedIdBtn: TEST_ID_FORM,
      open: true,
      selectedRow
    });
    const currentCategoryType = categoryTypes?.find(
      (it) => +it.id === +item?.category_type_id
    );

    if (id) {
      this.setState({
        modalData: {
          group_category_name: shouldUpdateGroup
            ? item?.group_name
            : item?.category_name,
          group_category_alt_name: shouldUpdateGroup
            ? item?.group_alt_name
            : item?.category_alt_name,
          group_category_age_from: shouldUpdateGroup
            ? item?.group_age_from
            : item?.age_from,
          group_category_age_to: shouldUpdateGroup
            ? item?.group_age_to
            : item?.age_to,
          group_category_gender: shouldUpdateGroup
            ? item?.group_gender
            : item?.gender,
          group_category_type: +item?.category_type_id,
          category_id: item?.category_id,
          group_id: item?.group_id ?? parentValue?.group_id,
          category_name_plus: item?.category_name_plus,
          category_alt_name_plus: item?.category_alt_name_plus,
          category_weight_from: item?.weight_from || item?.weight_to,
          weighted: currentCategoryType?.weighted,
          category_type_name: currentCategoryType?.name,
          ...(showFormName === UPDATE_CATEGORY ||
          showFormName === UPDATE_GROUP_CATEGORY
            ? { form_title_name: item?.category_name }
            : {}),
          isCategoryAbsolute:
            +item?.category_type_id === 2 &&
            item?.age_from &&
            !item?.age_to &&
            !item?.weight_from &&
            !item?.weight_to,
          is_owner: item?.is_owner,
          currentRecordIdx: idx
        }
      });
    } else {
      this.setState({
        //autofill in the category group form with group data
        modalData: {
          ...(showFormName === ADD_CATEGORY
            ? { form_title_name: item?.group_name }
            : {}),
          group_id: item?.group_id,
          group_category_name: item?.group_name,
          group_category_alt_name: item?.group_alt_name ?? item?.group_name,
          category_name_plus: item?.group_name,
          category_alt_name_plus: item?.group_alt_name ?? item?.group_name,
          group_category_age_from: item?.group_age_from,
          group_category_age_to: item?.group_age_to,
          group_category_gender: item?.group_gender,
          group_category_type: +item?.category_type_id,
          weighted: currentCategoryType?.weighted,
          category_type_name: currentCategoryType?.name,
          isAbsoluteChecked: false
        }
      });
    }
  };

  triggerTableUpdate = () => {
    const { open } = this.state;

    this.setState({ shouldUpdateTable: true });

    if (open) {
      this.hideModal();
    }
  };

  hideModal = () => {
    this.setState({ open: false, selectedRow: null, pressedIdBtn: null });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  onSelectCheckbox = (item) => {
    const { selectedCheckboxes, rowsPerPage, searchBar } = this.state;
    let genderList = [];
    let typeList = [];
    let ageList = [];
    let ownerList = [];

    const checkedValues = selectedCheckboxes.some((it) => it.id === item.id)
      ? selectedCheckboxes.filter((it) => it.id !== item.id)
      : [...selectedCheckboxes, item];

    this.setState({ page: 1, selectedCheckboxes: checkedValues }, () => {
      const len = this.state.selectedCheckboxes.length;

      for (let i = 0; i < len; i++) {
        const item = this.state.selectedCheckboxes[i];

        if (item.type) {
          typeList = [...typeList, item.id];
        } else if (item.gender) {
          genderList = [...genderList, item.gender];
        } else if (item.owner) {
          ownerList = [...ownerList, item.owner];
        } else {
          ageList = [...ageList, item.ageInterval];
        }
      }

      this.setState({ typeList, genderList, ageList, ownerList });

      this.fetchData(
        this.state.page,
        rowsPerPage,
        null,
        typeList,
        genderList,
        ageList,
        ownerList,
        searchBar
      );
    });
  };

  searchHandler = () => {
    const { searchBar, rowsPerPage, typeList, genderList, ageList, ownerList } =
      this.state;

    this.setState({ searchedValue: searchBar, page: 1 }, () => {
      this.fetchData(
        this.state.page,
        rowsPerPage,
        null,
        typeList,
        genderList,
        ageList,
        ownerList,
        searchBar
      );
    });
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  onPageChange = (e, next) => {
    const {
      isSearching,
      rowsPerPage,
      searchBar,
      typeList,
      genderList,
      ageList,
      ownerList
    } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.searchHandler();
      } else {
        this.fetchData(
          this.state.page,
          rowsPerPage,
          null,
          typeList,
          genderList,
          ageList,
          ownerList,
          searchBar
        );
      }
    });
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsed } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => it.id === +item?.group_id
      );

      if (findIndex === -1) {
        this.setState((prevState) => ({
          collapsed: [
            ...prevState.collapsed,
            {
              id: +item.group_id,
              categories: item?.categories
            }
          ]
        }));
      } else {
        allCollapsedTables.splice(findIndex, 1);

        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  updateNewFormNameOnCategorySelection = (data) => {
    //used to Add New, when choosing a type of category
    this.setState(data);
  };

  render() {
    const {
      showModal,
      modalInfo,
      success,
      loading,
      filteredCategories,
      categoryTypes,
      rowsPerPage,
      page,
      open,
      shouldShowForm,
      selectedRow,
      categoriesCount,
      selectedCheckboxes,
      searchedValue,
      searchBar,
      modalData,
      pressedIdBtn,
      collapsed
    } = this.state;
    const { t } = this.props;
    const { role } = this.context.authState;
    const { classes } = this.props;

    const isUpdateForm =
      shouldShowForm === UPDATE_CATEGORY ||
      shouldShowForm === UPDATE_GROUP_CATEGORY ||
      shouldShowForm === UPDATE_GROUP;

    const header = [
      { label: t('name') },
      { label: `${t('age')} ${t('from').toLowerCase()}` },
      { label: `${t('age')} ${t('to').toLowerCase()}` },
      { label: t('type') },
      { label: t('gender') },
      ...(role === 'administrator' ? [{ label: t('owner') }] : []),
      { label: t('categories'), noTooltip: true }
    ];

    const collapseTableHeader = (item) => [
      { label: t('inanimateName') },
      { label: `${t('age')} ${t('from').toLowerCase()}` },
      { label: `${t('age')} ${t('to').toLowerCase()}` },
      { label: t('type') },
      { label: t('gender') },
      { label: +item?.category_type_id !== 8 ? t('weight') : t('units') },
      { label: [t('time'), '(' + t('min') + ':' + t('sec') + ')'].join(' ') },
      ...(role === 'administrator' ? [{ label: t('owner') }] : [])
    ];

    const tableBody = (item) => [
      !+item.group_id ? 'category_name' : 'group_name',
      !+item.group_id ? 'age_from' : 'group_age_from',
      !+item.group_id ? 'age_to' : 'group_age_to',
      { defaultN: 'category_type_name', paramN: 'category_type_id' },
      !+item.group_id ? 'gender' : 'group_gender',
      ...(role === 'administrator' ? ['master_id'] : []),
      !+item.group_id || !+item.categories_in_group_count
        ? 'NO_VALUE'
        : { dropdown: 'categories_in_group_count' }
    ];

    const collapseTableBody = [
      'category_name',
      'age_from',
      'age_to',
      { defaultN: 'category_type_name', paramN: 'category_type_id' },
      'gender',
      'weight_to',
      'main_time_ms',
      ...(role === 'administrator' ? ['master_id'] : [])
    ];

    const condensedTableData = (item) => ({
      mainName: !+item.group_id
        ? {
            categoryName: 'category_name',
            categoryNamePlus: 'category_name_plus'
          }
        : 'group_name',
      info: [
        {
          key: !+item.group_id ? 'age_from' : 'group_age_from',
          name: `${t('age')} ${t('from').toLowerCase()}`
        },
        {
          key: !+item.group_id ? 'age_to' : 'group_age_to',
          name: `${t('age')} ${t('to').toLowerCase()}`
        },
        {
          defaultN: 'category_type_name',
          paramN: 'category_type_id',
          name: t('type')
        },
        { key: !+item.group_id ? 'gender' : 'group_gender', name: t('gender') },
        ...(role === 'administrator'
          ? [{ key: 'master_id', name: t('owner') }]
          : []),
        !+item.group_id
          ? { key: 'weight_to', name: t('weight') }
          : !+item.categories_in_group_count
          ? 'NO_VALUE'
          : {
              dropdown: {
                key: 'categories_in_group_count',
                name: t('categories')
              }
            }
      ]
    });

    const collapsedCondensedTableData = (item) => ({
      mainName: {
        categoryName: 'category_name',
        categoryNamePlus: 'category_name_plus'
      },
      info: [
        { key: 'age_from', name: `${t('age')} ${t('from').toLowerCase()}` },
        { key: 'age_to', name: `${t('age')} ${t('to').toLowerCase()}` },
        {
          defaultN: 'category_type_name',
          paramN: 'category_type_id',
          name: t('type')
        },
        { key: 'gender', name: t('gender') },
        {
          key: 'weight_to',
          name: +item?.category_type_id !== 8 ? t('weight') : t('units')
        },
        { key: 'main_time_ms', name: t('time') },
        ...(role === 'administrator'
          ? [{ key: 'master_id', name: t('owner') }]
          : [])
      ]
    });
    let dialogTitle,
      onSwitchBetween,
      dialogContent,
      buttonPurpose,
      subHeader,
      onClick;

    if (pressedIdBtn === TEST_ID_FORM) {
      dialogTitle = isUpdateForm
        ? shouldShowForm === UPDATE_GROUP
          ? t('updateRecord', {
              name: [
                t('categoryGroup'),
                modalData?.category_type_name?.toUpperCase()
              ].join(' ')
            })
          : [
              t('updateCategory'),
              modalData?.category_type_name?.toUpperCase(),
              `(${modalData?.form_title_name})`
            ].join(' ')
        : shouldShowForm === ADD_CATEGORY
        ? [
            t('addCategory'),
            `${modalData?.category_type_name?.toUpperCase()} (${
              modalData.form_title_name
            })`
          ].join(' ')
        : [
            modalData.weighted &&
            (+modalData.group_category_type === 2
              ? !modalData.isAbsoluteChecked
              : true)
              ? t('addRecord', { name: t('categoryGroup') })
              : t('addCategory'),
            modalData?.category_type_name?.toUpperCase()
          ].join(' ');
      dialogContent = (
        <AddCategory
          state={this.state}
          {...{ isUpdateForm }}
          updateNewFormNameOnCategorySelection={
            this.updateNewFormNameOnCategorySelection
          }
          triggerTableUpdate={this.triggerTableUpdate}
        />
      );

      const onOpenForm = (evt, item, nextItemIdx) => {
        this.showModalForm(
          evt,
          item,
          item?.category_id ? UPDATE_CATEGORY : UPDATE_GROUP,
          null,
          nextItemIdx
        );
      };
      onSwitchBetween = (shouldShowForm === UPDATE_CATEGORY ||
        shouldShowForm === UPDATE_GROUP) && {
        prev: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const item = filteredCategories[nextItemIdx];

            onOpenForm(evt, item, nextItemIdx);
          }
        },
        next: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const len = filteredCategories?.length - 1;

          if (len >= nextItemIdx) {
            const item = filteredCategories[nextItemIdx];

            onOpenForm(evt, item, nextItemIdx);
          }
        }
      };
    } else {
      dialogTitle = t(
        !!+modalData?.category_id ? 'deleteCategory' : 'deleteCategoryGroup'
      );
      dialogContent = t(
        !!+modalData?.category_id
          ? 'deleteCategoryMsg'
          : 'deleteCategoryGroupMsg'
      );
      buttonPurpose = t('deleteRecord', { name: '' });
      subHeader = modalData && (
        <span className={clsx(classes.flex, classes.marginTop1)}>
          {!!+modalData?.category_id
            ? modalData.category_name
            : modalData.group_name}
        </span>
      );
      onClick = () =>
        !!+modalData?.category_id
          ? this.deleteCategory()
          : this.deleteCategoryGroup(modalData?.group_id);
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{
            onClick,
            subHeader,
            dialogTitle,
            onSwitchBetween,
            dialogContent,
            buttonPurpose
          }}
          isOldDesign
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <span className={clsx(classes.flex, classes.checkboxesWrapper)}>
              {FILTERS_CHECKBOXES(categoryTypes, true).map(
                (group, groupIdx) => (
                  <span
                    key={groupIdx}
                    className={clsx(
                      classes.flex,
                      classes.wrap,
                      classes.marginRight1
                    )}>
                    {group.map((item, idx) => (
                      <Fragment key={idx}>
                        <CheckboxBtn
                          formControlClassName={classes.paper}
                          classControlLabel={classes.checkboxItem}
                          className={classes.checkboxIcon}
                          checked={selectedCheckboxes.some(
                            (it) => it.id === item.id
                          )}
                          onChange={() => this.onSelectCheckbox(item)}
                          label={item?.name}
                          labelPlacement="start"
                        />
                      </Fragment>
                    ))}
                  </span>
                )
              )}
            </span>
            <Table
              {...{ categoryTypes }}
              maxColumnWidth={classes.maxColumnWidth}
              search={searchBar}
              clearSearch={() =>
                this.clearSearch(searchedValue, () => this.searchHandler())
              }
              pressEnter={this.pressEnterHandler}
              textChange={this.handleTextChange}
              {...{ condensedTableData }}
              {...{ header }}
              body={filteredCategories}
              showButton
              openEmptyForm={(evt) =>
                this.showModalForm(evt, null, ADD_NEW_GROUP_CATEGORY)
              }
              onClick={(evt, item, idx) =>
                this.showModalForm(
                  evt,
                  item,
                  item?.category_id ? UPDATE_CATEGORY : UPDATE_GROUP,
                  null,
                  idx
                )
              }
              data={tableBody}
              {...{ role }}
              subTableDataKey="categories"
              id="group_id"
              shouldAllowEditing={(item) =>
                +item?.is_owner === 1 || role === 'administrator'
              }
              actionIcons={(
                item,
                idx,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) =>
                (item.is_owner === '1' || role === 'administrator') && (
                  <>
                    <Tooltip
                      title={t('updateRecord', {
                        name: t(
                          !+item?.group_id ? 'category' : 'groupAddUpdate'
                        )
                      })}>
                      <EditIcon
                        className={clsx(className, iconWrapper, marginBottom)}
                        onClick={(evt) =>
                          this.showModalForm(
                            evt,
                            item,
                            item?.category_id ? UPDATE_CATEGORY : UPDATE_GROUP,
                            null,
                            idx
                          )
                        }
                      />
                    </Tooltip>
                    {!!+item?.group_id && (
                      <Tooltip arrow title={t('addCategory')}>
                        <AddIcon
                          className={clsx(
                            className,
                            iconWrapper,
                            marginLeft,
                            marginBottom
                          )}
                          onClick={(evt) =>
                            this.showModalForm(evt, item, ADD_CATEGORY)
                          }
                        />
                      </Tooltip>
                    )}
                    <Tooltip
                      title={t('deleteRecord', {
                        name: t(
                          !+item?.group_id ? 'category' : 'groupAddUpdate'
                        )
                      })}>
                      <DeleteIcon
                        id={DELETE_ICON}
                        className={clsx(className, iconWrapper, marginLeft)}
                        onClick={() => this.showModal(item)}
                      />
                    </Tooltip>
                  </>
                )
              }
              expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
              {...{ collapsedCondensedTableData }}
              {...{ collapsed }}
              {...{ collapseTableHeader }}
              {...{ collapseTableBody }}
              subTableActionIcons={(
                item,
                _,
                className,
                iconWrapper,
                marginLeft,
                marginBottom,
                parentValue
              ) =>
                +item?.is_owner === 1 && (
                  <>
                    <Tooltip title={t('updateRecord', { name: t('category') })}>
                      <EditIcon
                        className={clsx(className, iconWrapper, marginBottom)}
                        onClick={(evt) =>
                          this.showModalForm(
                            evt,
                            item,
                            UPDATE_GROUP_CATEGORY,
                            parentValue
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title={t('deleteRecord', { name: t('category') })}>
                      <DeleteIcon
                        id={DELETE_ICON}
                        className={clsx(className, iconWrapper, marginLeft)}
                        onClick={() => this.showModal(item)}
                      />
                    </Tooltip>
                  </>
                )
              }
              selected={(item) => {
                const id = item?.category_id || item?.group_id;

                return selectedRow === id;
              }}
              selectedSubTable={(item) => {
                const id = item.category_id;

                return selectedRow === id;
              }}
              paginationComponent={
                filteredCategories.length > 0 && (
                  <Pagination
                    count={categoriesCount}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={(evt) =>
                      this.rowsPerPageHandler(evt, this.searchHandler)
                    }
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              }
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(Categories));
