import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { changeTitle } from 'helpers/actions';
import {
  fetchMembers,
  pageChangeHandler,
  rowsPerPageHandler,
  selectOption,
  fetchFederation,
  changeModal,
  validateForm,
  fetchQdan,
  fetchUsers
} from '../../helpers/util';
import {
  serverDefaultPath,
  DEFAULT_FEDERATION,
  COACH_ID,
  NOT_SET,
  EMAIL
} from '../../helpers/constants';
import {
  selectedValue,
  findSortDirection,
  compareValueForSorting
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import AddParticipant from '../AddParticipant/AddParticipant';
import Modal from '../../components/Modal/ModalNewDesign';
import Pagination from '../../components/TablePagination/TablePagination';
import Table from '../../components/Table/Table';
import EmptyState from '../../components/EmptyState/EmptyState';
import HeaderInfo from 'components/HeaderInfo/HeaderInfo';

class Federation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      langOnLoad: localStorage.getItem('i18nextLng'),
      filters: {},
      modalData: {},
      success: false,
      showModal: false,
      page: 1,
      rowsPerPage:
        (localStorage.getItem('rowsPerPage') &&
          +localStorage.getItem('rowsPerPage')) ||
        10,
      coachesCount: 0,
      filteredCoaches: [],
      open: false,
      shouldUpdateTable: false,
      coachesList: [],
      sortDirection: [],
      qdanList: [],
      collapsed: [],
      userForm: {}
    };

    this.fetchMembers = fetchMembers.bind(this);
    this.pageChangeHandler = pageChangeHandler.bind(this);
    this.rowsPerPageHandler = rowsPerPageHandler.bind(this);
    this.selectOption = selectOption.bind(this);
    this.fetchFederation = fetchFederation.bind(this);
    this.changeModal = changeModal.bind(this);
    this.validateForm = validateForm.bind(this);
    this.fetchQdan = fetchQdan.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { shouldUpdateTable, sortDirection, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(() => {
        this.setState({ shouldUpdateTable: false });

        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('myFederation'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    this.fetchMembers((body) => {
      const len = body.length;
      const coachesCount = len > 0 ? len : 0;

      this.fetchQdan();
      this.fetchUsers(localStorage.getItem('user_id'), 'userForm'); //retrieve user info to populate federation header automatically
      this.fetchFederation((data) => {
        let filteredParticipantsByCoach = [];
        const len = data.length;

        for (let i = 0; i < len; i++) {
          const item = data[i];

          const findIndex = filteredParticipantsByCoach.findIndex(
            (it) => +it.coach_id === +item.coach_id
          );

          if (findIndex === -1) {
            const coach =
              body && body.find((it) => +it.coach_id === +item.coach_id);
            filteredParticipantsByCoach.push({
              ...coach,
              participants: [item]
            });
          } else {
            filteredParticipantsByCoach[findIndex].participants.push({
              ...item
            });
          }
        }

        this.setState({
          coachesList: filteredParticipantsByCoach,
          filteredCoaches: filteredParticipantsByCoach,
          coachesCount,
          loading: false
        });
      });
    });

    changeTitle(this.props.t('myFederation'));
  }

  fetchData = (cb) => {
    const { coachesList } = this.state;
    const len = coachesList.length;
    const coachesCount = len > 0 ? len : 0;

    this.fetchFederation((data) => {
      let filteredParticipantsByCoach = [];
      const len = data.length;

      for (let i = 0; i < len; i++) {
        const item = data[i];

        const findIndex = filteredParticipantsByCoach.findIndex(
          (it) => +it.coach_id === +item.coach_id
        );

        if (findIndex === -1) {
          const coach = coachesList.find(
            (it) => +it.coach_id === +item.coach_id
          );
          filteredParticipantsByCoach.push({
            ...coach,
            participants: [item]
          });
        } else {
          filteredParticipantsByCoach[findIndex].participants.push({
            ...item
          });
        }
      }

      this.setState(
        {
          coachesList: filteredParticipantsByCoach,
          filteredCoaches: filteredParticipantsByCoach,
          coachesCount
        },
        () => cb && cb()
      );
    });
  };

  triggerTableUpdate = () => {
    this.setState({ shouldUpdateTable: true });
    this.hideModal();
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, searchBar: value },
        page: 1
      }),
      () => this.onFilterRecords()
    );
  };

  onFilterRecords = () => {
    const { coachesList, filters } = this.state;
    const len = coachesList?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = coachesList[i];
      const bySearch = filters.searchBar
        ? [
            [el.coach_first_name, el.coach_last_name].join(' '),
            el.coach_email,
            el.coach_club,
            el.coach_city
          ].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const bySelectOption = filters?.filteredByCoachId
        ? +filters?.filteredByCoachId === +el?.[COACH_ID]
        : true;

      if (bySearch && bySelectOption) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }

    this.setState({ filteredCoaches: newFilteredRecords }, () => {
      const { filteredCoaches } = this.state;
      const len = filteredCoaches?.length;

      this.setState({ coachesCount: len, loading: false });
    });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  showFormModal = (evt, item) => {
    evt.preventDefault();
    const id = item && item.p_id;
    let modalData = {};

    if (id) {
      modalData = {
        ...item,
        id,
        birthdayInputDate: item.p_birthday,
        birthday: item.p_birthday,
        ...(item.p_photo
          ? { imagePreview: serverDefaultPath + item.p_photo }
          : {}),
        first_name: item.p_first_name,
        last_name: item.p_last_name,
        ...(item.p_last_name_national
          ? { last_name_national: item.p_last_name_national }
          : {}),
        ...(item.p_first_name_national
          ? { first_name_national: item.p_first_name_national }
          : {}),
        gender: item.p_gender,
        age: item.p_age,
        weight: item.p_weight,
        history: item.p_history === NOT_SET ? '' : item.p_history,
        iko: item.p_iko === NOT_SET ? '' : item.p_iko,
        club: item.p_club !== NOT_SET ? item.p_club : '',
        country_id: item.participant_country_id,
        city: item.p_city,
        height: item.p_height !== '-1' ? item.p_height : '',
        region: item.p_region
      };
    } else {
      modalData = {};
    }

    this.setState({
      selectedRow: id,
      modalData,
      participantId: id,
      open: true
    });
  };

  hideModal = () => {
    this.setState({ open: false, selectedRow: null, participantId: null });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { coachesList, sortDirection } = this.state;
    const clone = [...coachesList];

    let field;
    if (sortField === 1) {
      field = 'coach_first_name';
    } else if (sortField === 2) {
      field = 'coach_email';
    } else if (sortField === 3) {
      field = 'coach_city';
    } else if (sortField === 4) {
      field = 'coach_club';
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        coachesList: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsed, coachesList } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => it.id === item.coach_id
      );

      if (findIndex === -1) {
        let coach = coachesList.find((it) => +it.coach_id === +item.coach_id);

        this.setState((prevState) => ({
          collapsed: [
            ...prevState.collapsed,
            {
              id: item.coach_id,
              participants: coach?.participants,
              sortDirection: []
            }
          ]
        }));
      } else {
        allCollapsedTables.splice(findIndex, 1);
        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onSortCollapsibleTable = (id, sortField, idxCell) => {
    const { collapsed } = this.state;
    const clone = [...collapsed];
    const findIdxInArray = clone.findIndex((it) => it.id === id);

    let field;
    switch (sortField) {
      case 1:
        field = 'p_first_name';
        break;
      case 2:
        field = 'p_age';
        break;
      case 3:
        field = 'p_gender';
        break;
      case 4:
        field = 'p_weight';
        break;
      case 5:
        field = 'find_qdan';
        break;
    }

    const direction = findSortDirection(
      clone[findIdxInArray].sortDirection,
      sortField
    );
    const sortedData = compareValueForSorting(
      clone[findIdxInArray].participants,
      field,
      direction
    );

    clone[findIdxInArray].sortDirection = [direction, idxCell, sortField];
    clone[findIdxInArray].filteredParticipants = sortedData;

    this.setState({ collapsed: clone });
  };

  onSelectOptionCoach = (evt, val) => {
    if (val === null) {
      this.setState((prevState) => ({
        page: 1,
        ...(!val
          ? { filters: { ...prevState.filters, filteredByCoachId: null } }
          : {})
      }));

      this.triggerTableUpdate();
    } else {
      this.selectOption(
        evt,
        val,
        'filters',
        'filteredByCoachId',
        COACH_ID,
        null,
        () => {
          this.setState({ page: 1 });
          this.triggerTableUpdate();
        }
      );
    }
  };

  render() {
    const {
      loading,
      success,
      showModal,
      modalInfo,
      rowsPerPage,
      page,
      coachesCount,
      coachesList,
      open,
      filters,
      selectedRow,
      shouldDisableEditing,
      sortDirection,
      qdanList,
      filteredCoaches,
      collapsed,
      userForm
    } = this.state;
    const { t, navigate, location } = this.props;
    const { role } = this.context.authState;
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    const filter = [
      {
        options: coachesList,
        label: t('coach'),
        item: { firstName: 'coach_first_name', lastName: 'coach_last_name' },
        optional: 'coach_email',
        value: selectedValue(coachesList, COACH_ID, filters?.filteredByCoachId),
        onChange: (evt, val) => this.onSelectOptionCoach(evt, val)
      }
    ];
    const header = [
      { num: 1, label: t('name'), noTooltip: true },
      { num: 2, label: EMAIL },
      { num: 3, label: t('city') },
      { num: 4, label: t('club') },
      { label: t('participants'), noTooltip: true }
    ];

    const FIRST_NAME_COACH = !isRuLang ? 'coach_first_name' : 'coach_last_name';
    const LAST_NAME_COACH = !isRuLang ? 'coach_last_name' : 'coach_first_name';

    const tableBody = [
      {
        firstName: FIRST_NAME_COACH,
        lastName: LAST_NAME_COACH,
        photo: 'coach_photo'
      },
      'coach_email',
      'coach_city',
      'coach_club',
      { dropdown: 'participants_count' }
    ];

    const condensedTableData = {
      mainName: { firstName: FIRST_NAME_COACH, lastName: LAST_NAME_COACH },
      photo: 'coach_photo',
      info: [
        { key: 'coach_email', name: EMAIL },
        { key: 'coach_city', name: t('city') },
        { key: 'coach_club', name: t('club') },
        { dropdown: { key: 'participants_count', name: t('participants') } }
      ]
    };

    const collapseTableHeader = [
      { num: 1, label: t('name') },
      { num: 2, label: t('age') },
      { num: 3, label: t('gender') },
      { num: 4, label: t('weight') },
      { num: 5, label: t('kyuDan') }
    ];

    const FIRST_NAME_PART = !isRuLang ? 'p_first_name' : 'p_last_name';
    const LAST_NAME_PART = !isRuLang ? 'p_last_name' : 'p_first_name';

    const collapseTableBody = [
      {
        firstName: FIRST_NAME_PART,
        lastName: LAST_NAME_PART,
        patronymic: 'p_patronymic',
        photo: 'p_photo'
      },
      'p_age',
      'p_gender',
      'p_weight',
      'find_qdan'
    ];

    const collapsedCondensedTableData = {
      mainName: {
        firstName: FIRST_NAME_PART,
        lastName: LAST_NAME_PART,
        patronymic: 'p_patronymic',
        photo: 'photo'
      },

      info: [
        { key: 'p_age', name: t('age') },
        { key: 'p_gender', name: t('gender') },
        { key: 'p_weight', name: t('weight') },
        { key: 'find_qdan', name: t('kyuDan') }
      ]
    };

    const partSummary = [
      ...(userForm
        ? [
            {
              label: t('name'),
              name: userForm.club
            },
            {
              label: t('city'),
              name: userForm.city
            }
          ]
        : [])
    ];

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : coachesList ? (
          <>
            <HeaderInfo
              data={{
                src:
                  userForm && userForm.logoPreview
                    ? userForm.logoPreview
                    : DEFAULT_FEDERATION,
                info: partSummary
              }}
            />

            <Modal
              {...{ open }}
              close={this.hideModal}
              dialogTitle={t('updateRecord', { name: t('athlete') })}
              dialogContent={
                <AddParticipant
                  state={this.state}
                  closeParticipantForm={this.closeParticipantFormHandler}
                  triggerTableUpdate={this.triggerTableUpdate}
                  isCoachOptional="coach_email"
                  isCoachItem={{
                    firstName: 'coach_first_name',
                    lastName: 'coach_last_name'
                  }}
                />
              }
              isOldDesign
            />

            <Table
              search={filters.searchBar}
              clearSearch={(evt) => this.onSearch(evt)}
              textChange={this.onSearch}
              openEmptyForm={(evt) => this.showFormModal(evt)}
              onClick={(evt, item) => this.expandTableRow(evt, item)}
              expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
              {...{ filter }}
              {...{ role }}
              {...{ condensedTableData }}
              {...{ collapsedCondensedTableData }}
              {...{ collapsed }}
              {...{ collapseTableHeader }}
              {...{ collapseTableBody }}
              subTableDataKey={'participants'}
              id="coach_id"
              data={tableBody}
              body={
                filteredCoaches &&
                filteredCoaches.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              }
              {...{ header }}
              selectedSubTable={(item) => {
                return selectedRow === item.p_id;
              }}
              shouldAllowEditing={() => true}
              subTableActionIcons={(
                item,
                _,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) =>
                !shouldDisableEditing && (
                  <>
                    <Tooltip arrow title={t('payment')}>
                      <AccountBalanceWalletIcon
                        id="PAID_ICON"
                        className={clsx(className, iconWrapper, marginBottom)}
                        onClick={() =>
                          navigate(`/participant/${item.p_id}/payments`, {
                            state: { prevUrl: location.pathname }
                          })
                        }
                      />
                    </Tooltip>

                    <Tooltip
                      arrow
                      title={t('updateRecord', {
                        name: t('athlete')
                      })}>
                      <EditIcon
                        className={clsx(
                          className,
                          iconWrapper,
                          marginLeft,
                          marginBottom
                        )}
                        onClick={(evt) => this.showFormModal(evt, item)}
                      />
                    </Tooltip>
                  </>
                )
              }
              onSort={this.onSort}
              onSortCollapsibleTable={this.onSortCollapsibleTable}
              {...{ sortDirection }}
              paginationComponent={
                Array.isArray(coachesList) &&
                coachesList.length > 0 && (
                  <Pagination
                    count={coachesCount}
                    onPageChange={this.pageChangeHandler}
                    onRowsPerPageChange={this.rowsPerPageHandler}
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              }
              {...{ qdanList }}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(Federation));
