import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '../../components/Buttons/ActionButtons';
import { ReactComponent as Dana } from '../../assets/img/dana.svg';
import { ReactComponent as DanaHand } from '../../assets/img/dana_hand.svg';
import { useStyles } from './StylesEmptyState';

const EmptyState = (props) => {
  const { className, purpose, onClick, setNewComponent, specialTxt } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <span className={clsx(classes.emptyState, classes.flex, className)}>
      <Dana />
      <span className={clsx(classes.flex, classes.wrapper)}>
        <DanaHand className={classes.leftHand} />
        <span className={classes.msgWrapper}>
          {setNewComponent && setNewComponent}
          {!setNewComponent &&
            (onClick
              ? [t('noRecords'), t('createRecordsMsg')].join(' ')
              : specialTxt
              ? specialTxt
              : t('noRecords'))}
          {onClick && (
            <Button
              className={classes.btn}
              label={purpose}
              isSaveBtn={true}
              {...{ onClick }}
            />
          )}
        </span>
        <DanaHand className={classes.rightHand} />
      </span>
    </span>
  );
};
export default EmptyState;
