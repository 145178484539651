import clsx from 'clsx';

import { useStyles } from './StylesActionButtonsP2';

const ActionButtonP2 = (props) => {
  const { label, id, onClick, className, isSaveBtn, style } = props;

  const classes = useStyles();

  return (
    <span
      {...{ id }}
      {...{ onClick }}
      className={clsx(
        classes.flex,
        classes.btn,
        !isSaveBtn ? classes.defaultBtn : classes.activeBtn,
        classes.onHover,
        className
      )}
      {...{ style }}>
      {label}
    </span>
  );
};
export default ActionButtonP2;
