const styles = () => ({
  backdrop: {
    zIndex: 10
  },
  img: {
    height: 'calc(100% - 100px)',
    marginTop: '80px',
    '@media (max-width:900px)': {
      width: 'calc(100% - 20px)',
      height: 'auto'
    }
  },
  modalContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  }
});

export default styles;
