import { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../components/withRouter';
import { withStyles } from '@material-ui/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  OK,
  BRACKET,
  KEEP_EDITING,
  CLOSE_DISCARD,
  CATEGORY_TYPE,
  ID
} from '../../helpers/constants';

import {
  fetchTournaments,
  fetchTournamentTatamis,
  fetchTatamis,
  fetchTournamentScheme,
  fetchAllTBKS,
  fetchTBK,
  downloadFile,
  changeModal,
  validateForm,
  selectOption,
  changeHead,
  fetchAllCategoryTypes,
  removeTBK,
  fetchReference
} from '../../helpers/util';
import {
  categoryTypesPresentOnTournament,
  findSortDirection,
  compareValueForSorting
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import SideModal from '../../components/Snackbar/SideModal';
import LoadingState from '../../components/LoadingState/LoadingState';
import Table from '../../components/Table/Table';
import Body from '../../components/Tournament/Body/Body';
import TournamentBracket from '../../components/Tournament/TournamentBracket';
import Modal from '../../components/Modal/Modal';
import FilterBracketData from '../../components/Tournament/FilterBracket/FilterBracketData';
import PageNotFound from '../../components/NotFound/NotFound';
import TournamentBracketSmallScreen from '../../components/Tournament/BracketSmallViewport/Bracket';

import styles from './Styles';

class Tournament extends Component {
  state = {
    tournamentId: this.props.match.params.tourId,
    tournamentData: {},
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    showModal: false,
    success: false,
    bracket: [],
    open: false,
    categoryTypes: [],
    collapsed: {},
    tourTatamis: [],
    sortDirection: [],
    bracketErrors: {},
    bracketForm: {},
    viewportWidth: window.innerWidth,
    iframeURL: {}
  };
  fetchTournaments = fetchTournaments.bind(this);
  fetchTournamentTatamis = fetchTournamentTatamis.bind(this);
  fetchTatamis = fetchTatamis.bind(this);
  fetchTournamentScheme = fetchTournamentScheme.bind(this);
  fetchAllTBKS = fetchAllTBKS.bind(this);
  fetchTBK = fetchTBK.bind(this);
  downloadFile = downloadFile.bind(this);
  changeModal = changeModal.bind(this);
  removeTBK = removeTBK.bind(this);
  validateForm = validateForm.bind(this);
  selectOption = selectOption.bind(this);
  changeHead = changeHead.bind(this);
  fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
  fetchReference = fetchReference.bind(this);

  static contextType = AuthContext;

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t, navigate } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;

      this.setState({ loading: false });

      // below section is used to set the tournament name in the page header
      navigate('.', {
        replace: true,
        state: {
          tournament_name: tournamentData?.tournament_name
        }
      });

      changeHead(tournamentData, t('tournament'));
    });

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter((item) => item.name === 'event');
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('tournament'));

      this.setState({ langOnLoad: currentLang });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => this.setState({ viewportWidth: window.innerWidth });

  selectTatami = (_, value) => {
    const { bracketErrors } = this.state;
    if (value !== null) {
      this.setState((prevState) => ({
        bracketForm: {
          ...prevState.bracketForm,
          selectedTatami: value
        }
      }));
      delete bracketErrors.selectedTatami;
    }
  };

  onSelectCategoryType = (_, value) => {
    const { tournamentData, bracketErrors, sortDirection } = this.state;
    const { role } = this.context.authState;

    this.selectOption(
      _,
      value,
      'bracketForm',
      CATEGORY_TYPE,
      ID,
      bracketErrors,
      () => {
        this.fetchTournamentTatamis(tournamentData.id, value.id);

        if (role === 'administrator') {
          this.fetchAllTBKS(tournamentData.id, value.id, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }
          });
        }
      },
      true
    );
  };

  itemToggle = (key) =>
    this.setState((prevState) => ({
      collapsed: {
        ...prevState.collapsed,
        [key]: !this.state.collapsed[key]
      }
    }));

  showModal = (evt) => {
    const { tournamentData } = this.state;
    const { category_types } = tournamentData;

    this.setState({ open: true, pressedIcon: evt.target.id }, () => {
      if (this.state.pressedIcon === BRACKET) {
        this.fetchAllCategoryTypes((allSystemTypes) => {
          const categoryTypes = categoryTypesPresentOnTournament(
            allSystemTypes || [],
            category_types
          );

          this.setState({ tourTatamis: [], categoryTypes });
        });
      }
    });
  };

  hideModal = () => {
    this.setState({
      open: false,
      bracketForm: {},
      allTBKS: [],
      bracketErrors: {},
      shouldShowDiscardChanges: false,
      isEditing: false
    });
  };

  closeSideModalHandler = () => this.setState({ showModal: false });

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { allTBKS, sortDirection } = this.state;
    const clone = [...allTBKS];

    let field;
    switch (sortField) {
      case 'user_name':
        field = 'user_name';
        break;
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState({
      ...(!noFirstTimeSort
        ? { sortDirection: [direction, idxCell, sortField] }
        : {}),
      allTBKS: sortedData
    });
  };

  render() {
    const {
      tournamentData,
      loading,
      showModal,
      success,
      modalInfo,
      open,
      categoryTypes,
      pressedIcon,
      collapsed,
      tourTatamis,
      bracketCategoryType,
      bracketTatami,
      sortDirection,
      allTBKS,
      bracketErrors,
      bracketForm,
      viewportWidth,
      currentRounds,
      iframeURL
    } = this.state;
    const { t, classes } = this.props;
    const { role } = this.context.authState;

    const header = [
      { label: 'ID' },
      { label: t('attachment') },
      { label: [t('owner'), ' - ID'].join('') },
      { label: t('info') },
      { num: 'user_name', label: [t('owner'), ` - ${t('name')}`].join('') },
      { label: t('tatamis') },
      { label: t('date') }
    ];
    const tableBody = [
      'id',
      'file_name',
      'user_id',
      'user_info',
      'user_name',
      'tatamis',
      'updated_at'
    ];
    const condensedTableData = {
      mainName: 'file_name',
      info: [
        { key: 'id', name: 'ID' },
        { key: 'user_id', name: [t('owner'), ' - ID'].join('') },
        { key: 'user_info', name: t('info') },
        { key: 'user_name', name: t('owner') },
        { key: 'tatamis', name: t('tatamis') },
        { key: 'updated_at', name: t('date') }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.closeSideModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          setPaperWidth={role === 'administrator' && classes.maxWidth}
          {...{ open }}
          close={this.hideModal}
          onClick={(evt) =>
            this.validateForm(
              evt,
              { selectedTatami: '', category_type: '' },
              bracketForm,
              'bracketErrors',
              this.fetchTournamentScheme
            )
          }
          dialogTitle={this.props.t('generateBracket')}
          buttonPurpose={OK}
          subHeader={
            pressedIcon === BRACKET && (
              <FilterBracketData
                {...{ categoryTypes }}
                {...{ bracketErrors }}
                {...{ bracketForm }}
                selectCategoryType={this.onSelectCategoryType}
                selectedTatamis={tourTatamis}
                selectTatami={this.selectTatami}
                {...{ pressedIcon }}
              />
            )
          }
          shouldShowTopBottomDivider={
            role === 'administrator' &&
            allTBKS?.length > 0 &&
            pressedIcon === BRACKET
          }>
          {role === 'administrator' &&
            allTBKS?.length > 0 &&
            pressedIcon === BRACKET && (
              <Table
                {...{ header }}
                {...{ condensedTableData }}
                shouldHideTableToolbar={true}
                body={allTBKS}
                data={tableBody}
                onClick={(evt, item) => this.fetchTBK(evt, item)}
                onSort={this.onSort}
                {...{ sortDirection }}
                shouldAllowEditing={(item) => item?.file_name}
                actionIcons={(item, _, className, iconWrapper) =>
                  item.file_name && (
                    <DeleteIcon
                      className={clsx(className, iconWrapper)}
                      onClick={(evt) =>
                        this.removeTBK(
                          evt,
                          tournamentData?.id,
                          bracketForm.category_type,
                          item.id,
                          () => {
                            this.fetchAllTBKS(
                              tournamentData.id,
                              bracketForm.category_type,
                              () => {
                                if (sortDirection?.length > 0) {
                                  this.onSort(sortDirection[2], null, true);
                                }
                              }
                            );
                          }
                        )
                      }
                    />
                  )
                }
              />
            )}
        </Modal>
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <Body
            src={iframeURL?.shortName}
            tooltip={iframeURL?.altName}
            {...{ tournamentData }}
            {...{ viewportWidth }}
            showBracketInformation={this.showModal}
          />
        ) : (
          <PageNotFound />
        )}
        {viewportWidth > 901 ? (
          <TournamentBracket
            {...{ tournamentData }}
            {...{ bracketTatami }}
            {...{ bracketCategoryType }}
            itemToggle={this.itemToggle}
            {...{ collapsed }}
          />
        ) : (
          Array.isArray(currentRounds) && (
            <TournamentBracketSmallScreen
              {...{ tournamentData }}
              {...{ categoryTypes }}
              {...{ bracketTatami }}
              itemToggle={this.itemToggle}
              {...{ collapsed }}
              initialCategoriesArrayWithIndexes={currentRounds}
            />
          )
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Tournament)));
