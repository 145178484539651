import clsx from 'clsx';
import { useStyles } from './StylesTablePagination';
import { CardActions, TablePagination } from '@material-ui/core';

const Pagination = (props) => {
  const {
    count,
    onRowsPerPageChange,
    rowsPerPage,
    page,
    onPageChange,
    className
  } = props;

  const classes = useStyles();

  return (
    <CardActions className={classes.tablePagination}>
      <TablePagination
        classes={{
          ...className,
          toolbar: clsx(classes.toolbar, classes.fullWidth),
          root: classes.fullWidth,
          actions: clsx(classes.fullWidth, classes.action),
          selectRoot: classes.selectRoot,
          input: classes.input
        }}
        component="div"
        count={parseInt(count)}
        {...{ onPageChange }}
        {...{ onRowsPerPageChange }}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </CardActions>
  );
};
export default Pagination;
