import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  drawer: {
    overflowX: 'hidden',
    top: '5em',
    height: 'calc(100% - 5em)',
    backgroundColor: theme.palette.primary.main,
    padding: '1.2em 3em',
    width: 'fit-content',
    '@media (max-width:900px)': {
      padding: '1.2em',
      width: '85%'
    }
  },
  signOutButton: {
    padding: '10px 5px',
    fontSize: '1.2rem',
    '&:hover': {
      opacity: 0.3
    },
    '@media (max-width:900px)': {
      fontSize: '1rem'
    }
  },
  icon: {
    marginRight: '.8em',
    width: '2em'
  },
  avatar: {
    width: '3em',
    height: '3em'
  },
  profile: {
    borderBottom: '1px solid #fff',
    marginLeft: '-3em',
    width: 'calc(100% + 6em)',
    padding: '0 3em .5em 3em'
  },
  profileDetailsWrapper: {
    paddingLeft: '.5em'
  },
  viewProfile: {
    paddingTop: '.5em',
    fontSize: '1.3rem',
    '&:hover': {
      opacity: 0.7
    },
    '&:active': {
      opacity: 0.5
    },
    '@media (max-width:900px)': {
      fontSize: '1.2rem'
    }
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10em',
    textOverflow: 'ellipsis',
    display: 'block'
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#fff',
    '@media (max-width:900px)': {
      fontSize: '1.3rem'
    }
  },
  text: {
    color: '#fff'
  },
  blue: {
    color: '#00FFFF'
  },
  role: {
    opacity: '0.5',
    fontSize: '1rem',
    textTransform: 'lowercase'
  }
}));
