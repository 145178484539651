import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

import { Tooltip } from '@material-ui/core';

import {
  ID,
  ADD_ICON,
  DELETE_ICON,
  KEEP_EDITING,
  CLOSE_DISCARD,
  FORM,
  CLEAR_ICON_PERMISSION_MODAL
} from '../../helpers/constants';
import {
  textChangeHandler,
  changeModal,
  changeImage,
  fetchRegistrarsCoaches,
  deleteRegistrarsCoaches,
  selectOption,
  newRegistrarCoach,
  updateRegistarCoach,
  fetchPermissionRegistrarCoach,
  setPermissionRegistrarCoach,
  fetchTournaments,
  resetPermissionRegistrarCoach,
  onBlur,
  validateForm
} from '../../helpers/util';
import {
  selectedValue,
  compareValueForSorting,
  findSortDirection
} from '../../helpers/selectors';
import { changeTitle } from '../../helpers/actions';

import SideModal from '../../components/Snackbar/SideModal';
import Modal from '../../components/Modal/Modal';
import LoadingState from '../../components/LoadingState/LoadingState';
import Form from './Form';
import Permissions from './Permissions';
import Table from '../../components/Table/Table';

import styles from './StylesRegisterCoaches';

const initialState = {
  first_name: '',
  last_name: '',
  username: '',
  role_id: ''
};
const defaultPermission = { name: '', value: '' };
const CLEAR_ICON_SUBTABLE = 'CLEAR_ICON_SUBTABLE';

class RegistrarsCoaches extends Component {
  state = {
    form: {},
    errors: {},
    users: [],
    allPermissions: [],
    setPermissionForm: {},
    setPermissionErrors: {},
    showError: {},
    filters: {},
    tournamentsList: [],
    collapsed: [],
    sortDirection: [],
    showModal: false,
    success: false,
    open: false,
    isPasswordVisible: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    shouldShowPermissionsModal: false
  };

  textChangeHandler = textChangeHandler.bind(this);
  changeModal = changeModal.bind(this);
  changeImage = changeImage.bind(this);
  fetchRegistrarsCoaches = fetchRegistrarsCoaches.bind(this);
  deleteRegistrarsCoaches = deleteRegistrarsCoaches.bind(this);
  selectOption = selectOption.bind(this);
  newRegistrarCoach = newRegistrarCoach.bind(this);
  updateRegistarCoach = updateRegistarCoach.bind(this);
  fetchPermissionRegistrarCoach = fetchPermissionRegistrarCoach.bind(this);
  setPermissionRegistrarCoach = setPermissionRegistrarCoach.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  resetPermissionRegistrarCoach = resetPermissionRegistrarCoach.bind(this);
  onBlur = onBlur.bind(this);
  validateForm = validateForm.bind(this);

  componentDidMount() {
    changeTitle(this.props.t('users'));

    this.fetchRegistrarsCoaches();
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, setPermissionForm, isEditing, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      ((form && form !== prevState.form) ||
        (setPermissionForm &&
          setPermissionForm !== prevState.setPermissionForm))
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('users'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  onSearch = (event) => {
    const query = event.currentTarget.value;

    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: query } }),
      () => this.onFilterRecords()
    );
  };

  onFilterRecords = () => {
    const { users, filters } = this.state;
    const len = users?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = users[i];
      const bySearch = filters.searchBar
        ? [el.first_name, el.last_name].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const byFilterRole = !!+filters.filteredRole
        ? +el?.role_id === +filters.filteredRole
        : true;

      if (bySearch && byFilterRole) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }

    this.setState({ filteredUsers: newFilteredRecords });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  closeSnackbar = () => this.setState({ showModal: false });

  showModal = (evt, item, parentValue) => {
    const targetId = evt.target.id;

    this.setState({ pressedBtn: targetId }, () => {
      const { pressedBtn } = this.state;

      if (pressedBtn === ADD_ICON) {
        this.setState({ shouldShowPermissionsModal: false });
        this.fetchPermissionRegistrarCoach(item.id, true);
        this.setState({
          tournamentsList: [],
          setPermissionForm: {}
        });
      } else if (!pressedBtn) {
        if (item) {
          this.setState({ form: { ...item } });
        }
      }
    });

    this.setState({
      open: true,
      modalData: item,
      userId:
        targetId !== CLEAR_ICON_SUBTABLE
          ? item
            ? item.id
            : null
          : parentValue?.id
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        errors: {},
        form: {},
        setPermissionForm: {},
        setPermissionErrors: {},
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;
    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  selectRole = (_, value) => {
    const { sortDirection } = this.state;

    if (value === null) {
      this.setState(
        (prevState) => ({
          filters: { ...prevState.filters, filteredRole: value }
        }),
        () => {
          const { filters } = this.state;

          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            } else {
              this.onFilterRecords();
            }
          });
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          filters: { ...prevState.filters, filteredRole: value.id }
        }),
        () => {
          const { filters } = this.state;

          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            } else {
              this.onFilterRecords();
            }
          });
        }
      );
    }
  };

  toggleShow = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  onSetPermission = (evt) => {
    const { userId, setPermissionForm, filters, collapsed, sortDirection } =
      this.state;

    this.validateForm(
      evt,
      defaultPermission,
      setPermissionForm,
      'setPermissionErrors',
      () =>
        this.setPermissionRegistrarCoach(evt, userId, setPermissionForm, () => {
          let copyCollapsed = [...collapsed];
          const findIdx = collapsed.findIndex((it) => +it?.id === +userId);

          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            } else {
              this.onFilterRecords();
            }
          });

          this.fetchPermissionRegistrarCoach(userId, true, (body) => {
            if (findIdx !== -1) {
              if (copyCollapsed[findIdx]) {
                copyCollapsed[findIdx].tournaments = body;
              }

              this.setState({
                collapsed: copyCollapsed,
                setPermissionForm: {}
              });
            }
          });
        })
    );
  };

  saveUser = (evt) => {
    const { form, filters, userId, sortDirection } = this.state;

    const onResponse = () => {
      this.fetchRegistrarsCoaches(filters.filteredRole, () => {
        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }

        this.setState({ isAttemptingToEditModalFields: false }, () =>
          this.hideModal()
        );
      });
    };

    this.validateForm(
      evt,
      userId ? initialState : { ...initialState, password: '' },
      form,
      'errors',
      () => {
        userId
          ? this.updateRegistarCoach(evt, form, onResponse)
          : this.newRegistrarCoach(
              evt,
              {
                ...form,
                username: `${form.username}@${localStorage.getItem('user_id')}`
              },
              onResponse
            );
      },
      () => {
        this.setState({
          showError: {
            first_name: true,
            last_name: true,
            username: true,
            password: true,
            role_id: true
          }
        });
      }
    );
  };

  onTxtEdit = (evt) => {
    const { form, errors } = this.state;
    this.textChangeHandler(
      evt,
      FORM,
      errors,
      null,
      form,
      true,
      null,
      null,
      () => {
        this.setState({ isEditing: true });
      }
    );
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsed } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => +it?.id === +item?.id
      );

      if (findIndex === -1) {
        this.fetchPermissionRegistrarCoach(item.id, null, (body) => {
          const rowSubTable = {
            id: item.id,
            tournaments: body,
            sortDirection: []
          };

          this.setState((prevState) => ({
            collapsed: [...prevState.collapsed, rowSubTable]
          }));
        });
      } else {
        allCollapsedTables.splice(findIndex, 1);
        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onResetPermissionInsidePermissionsModal = (evt, item) => {
    const targetId = evt.target.id;

    this.setState((prevState) => ({
      pressedBtn: targetId,
      modalData: {
        ...prevState.modalData,
        tournament_name: item.tournament_name,
        permision_id: item.id
      }
    }));
  };

  goBackPermissionsModal = () => {
    const { modalData } = this.state;
    const copyModalData = { ...modalData };
    delete copyModalData.tournament_name;
    delete copyModalData.permision_id;

    this.setState((prevState) => ({
      pressedBtn: ADD_ICON,
      modalData: copyModalData
    }));
  };

  onResetPermissionSubtable = (evt, permissionID, cb) => {
    const { collapsed, filters, userId, sortDirection } = this.state;

    this.resetPermissionRegistrarCoach(evt, permissionID, () => {
      this.fetchRegistrarsCoaches(filters.filteredRole, () => {
        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });

      this.fetchPermissionRegistrarCoach(userId, true, (body) => {
        let copyCollapsed = [...collapsed];
        const findIdx = copyCollapsed?.findIndex((it) => +it.id === +userId);

        if (findIdx !== -1) {
          copyCollapsed[findIdx].tournaments = [...body];
        }

        this.setState({ collapsed: copyCollapsed });
      });

      cb && cb();
    });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { users, sortDirection } = this.state;
    const clone = [...users];
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    let field;
    switch (sortField) {
      case 'name':
        field = !isRuLang ? 'first_name' : 'last_name';
        break;
      case 'username':
        field = 'username';
        break;
      case 'role':
        field = 'role_id';
        break;
      case 'status':
        field = 'status_id';
        break;
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        users: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  render() {
    const {
      form,
      errors,
      success,
      modalInfo,
      showModal,
      loading,
      filteredUsers,
      collapsed,
      open,
      userId,
      filters,
      pressedBtn,
      tournamentsList,
      allPermissions,
      showError,
      isPasswordVisible,
      shouldShowPermissionsModal,
      setPermissionErrors,
      shouldShowDiscardChanges,
      setPermissionForm,
      sortDirection,
      modalData
    } = this.state;
    const { t, classes } = this.props;
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    const REGISTRAR_COACH = [
      { id: '4', label: t('registrar') },
      { id: '7', label: t('operator') }
    ];

    const header = [
      { num: 'name', label: t('name') },
      { num: 'username', label: t('username') },
      { num: 'role', label: t('role') },
      { num: 'status', label: t('status') },
      { label: t('permissions') }
    ];

    const FIRST_NAME = !isRuLang ? 'first_name' : 'last_name';
    const LAST_NAME = !isRuLang ? 'last_name' : 'first_name';

    const tableBody = [
      {
        firstName: FIRST_NAME,
        lastName: LAST_NAME,
        noPhoto: true
      },
      'username',
      'registrar_role',
      'status_recorder',
      { dropdown: 'permissions' }
    ];

    const condensedTableData = {
      mainName: { firstName: FIRST_NAME, lastName: LAST_NAME },
      info: [
        { key: 'username', name: t('username') },
        { key: 'registrar_role', name: t('role') },
        { key: 'status_recorder', name: t('status') },
        { dropdown: { key: 'permissions', name: t('permissions') } }
      ]
    };

    const collapseTableHeader = [
      { label: t('name') },
      { label: t('eventType') },
      { label: t('eventStartDate') },
      { label: t('participants') }
    ];

    const collapseTableBody = [
      'tournament_name',
      'tournament_type',
      'start_date',
      'participants_count'
    ];

    const collapsedCondensedTableData = {
      mainName: 'tournament_name',
      info: [
        { key: 'tournament_type', name: t('eventType') },
        { key: 'start_date', name: t('eventStartDate') },
        { key: 'participants_count', name: t('participants') }
      ]
    };

    let onClick, dialogTitle, dialogContent, subHeader, children;

    if (pressedBtn === DELETE_ICON) {
      onClick = (evt) =>
        this.deleteRegistrarsCoaches(evt, userId, () => {
          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            this.onFilterRecords();
            this.hideModal();
          });
        });
      dialogTitle = t('deleteUser');
      dialogContent = t('deleteUserMsg');
    } else if (pressedBtn === ADD_ICON) {
      dialogTitle = t('permissions');
      onClick = (evt) => this.onSetPermission(evt);
      children = (
        <Permissions
          selectOption={this.selectOption}
          {...{ allPermissions }}
          {...{ tournamentsList }}
          fetchTournaments={this.fetchTournaments}
          resetPermission={this.onResetPermissionInsidePermissionsModal}
          errors={setPermissionErrors}
          {...{ setPermissionForm }}
        />
      );
    } else if (
      pressedBtn === CLEAR_ICON_SUBTABLE ||
      pressedBtn === CLEAR_ICON_PERMISSION_MODAL
    ) {
      dialogTitle = t('deleteRecord', { name: t('event') });
      dialogContent = t('deleteEventMsg');
      subHeader = modalData?.tournament_name;
      onClick = (evt) =>
        pressedBtn === CLEAR_ICON_SUBTABLE
          ? this.onResetPermissionSubtable(evt, modalData.id, this.hideModal)
          : this.onResetPermissionSubtable(
              evt,
              modalData.permision_id,
              this.goBackPermissionsModal
            );
    } else {
      dialogTitle = userId
        ? t('updateRecord', { name: t('user') })
        : t('addRecord', { name: t('user') });
      children = (
        <Form
          {...{ errors }}
          {...{ form }}
          registrarCoachOptions={REGISTRAR_COACH}
          selectOption={this.selectOption}
          onChange={this.onTxtEdit}
          onBlur={this.onBlur}
          {...{ showError }}
          toggleShow={this.toggleShow}
          {...{ isPasswordVisible }}
        />
      );
      onClick = (evt) => this.saveUser(evt);
    }

    return (
      <>
        <SideModal
          closeModal={this.closeSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          open={
            pressedBtn === ADD_ICON ? shouldShowPermissionsModal && open : open
          }
          close={
            pressedBtn === CLEAR_ICON_PERMISSION_MODAL
              ? this.goBackPermissionsModal
              : this.hideModal
          }
          {...{ onClick }}
          {...{ dialogTitle }}
          {...{ dialogContent }}
          buttonPurpose={
            pressedBtn === DELETE_ICON ||
            pressedBtn === CLEAR_ICON_SUBTABLE ||
            pressedBtn === CLEAR_ICON_PERMISSION_MODAL
              ? t('deleteRecord', { name: '' })
              : t('save')
          }
          subHeader={
            pressedBtn === CLEAR_ICON_SUBTABLE ||
            pressedBtn === CLEAR_ICON_PERMISSION_MODAL
              ? subHeader
              : modalData?.id && (
                  <span className={clsx(classes.flex, classes.marginTop1)}>
                    {[modalData[FIRST_NAME], modalData[LAST_NAME]].join(' ')}
                    {', '}
                    {modalData.username}
                  </span>
                )
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}>
          {children}
        </Modal>
        {!loading ? (
          <Table
            {...{ condensedTableData }}
            search={filters.searchBar}
            clearSearch={this.clearSearch}
            textChange={this.onSearch}
            showButton={true}
            filter={[
              {
                options: REGISTRAR_COACH,
                onChange: this.selectRole,
                label: t('role'),
                item: 'label',
                value: selectedValue(REGISTRAR_COACH, ID, filters.filteredRole)
              }
            ]}
            {...{ header }}
            body={filteredUsers}
            {...{ collapsedCondensedTableData }}
            {...{ collapsed }}
            {...{ collapseTableHeader }}
            {...{ collapseTableBody }}
            openEmptyForm={this.showModal}
            expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
            subTableDataKey="tournaments"
            onClick={this.showModal}
            onSort={this.onSort}
            {...{ sortDirection }}
            id="id"
            data={tableBody}
            shouldAllowEditing={() => true}
            actionIcons={(
              item,
              _,
              className,
              iconWrapper,
              marginLeft,
              marginBottom
            ) => (
              <>
                <Tooltip arrow title={t('updateRecord', { name: t('user') })}>
                  <EditIcon
                    className={clsx(className, iconWrapper, marginBottom)}
                    onClick={(evt) => this.showModal(evt, item)}
                  />
                </Tooltip>
                <Tooltip arrow title={t('permissions')}>
                  <AddIcon
                    id={ADD_ICON}
                    className={clsx(
                      className,
                      iconWrapper,
                      marginLeft,
                      marginBottom
                    )}
                    onClick={(evt) => this.showModal(evt, item)}
                  />
                </Tooltip>
                <Tooltip arrow title={t('deleteRecord', { name: t('user') })}>
                  <DeleteIcon
                    id={DELETE_ICON}
                    className={clsx(className, iconWrapper, marginLeft)}
                    onClick={(evt) => this.showModal(evt, item)}
                  />
                </Tooltip>
              </>
            )}
            order={true}
            subTableActionIcons={(
              item,
              _,
              className,
              iconWrapper,
              marginLeft,
              marginBottom,
              parentValue
            ) => (
              <Tooltip title={t('deleteRecord', { name: t('tournament') })}>
                <ClearIcon
                  id={CLEAR_ICON_SUBTABLE}
                  className={clsx(className, iconWrapper, marginLeft)}
                  onClick={(evt) => this.showModal(evt, item, parentValue)}
                />
              </Tooltip>
            )}
            selected={(item) => {
              const id = open && item?.id;

              return userId === id;
            }}
            selectedSubTable={(item) => {
              const id = open && item?.id;

              return modalData?.id === id;
            }}
          />
        ) : (
          <LoadingState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(RegistrarsCoaches));
