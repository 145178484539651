import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { useStyles } from './StylesActionButtons';

const ActionButton = (props) => {
  const {
    label,
    id,
    onClick,
    disabled,
    className,
    isSaveBtn,
    style,
    autoFocus,
    heightException,
    specificallyStyledBtn
  } = props;

  const classes = useStyles();

  return (
    <Button
      classes={{
        root: clsx(
          classes.root,
          !heightException && classes.btn,
          isSaveBtn
            ? classes.save
            : specificallyStyledBtn
            ? specificallyStyledBtn
            : classes.noActionBtn,
          className
        ),
        label: classes.label
      }}
      {...{ id }}
      {...{ style }}
      {...{ onClick }}
      {...{ disabled }}
      {...{ autoFocus }}>
      {label}
    </Button>
  );
};
export default ActionButton;
