import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/img/tournament_expert_LOGO.svg';
import Button from '../Buttons/ActionButtonsP2';
//old version
//import Button from '../Buttons/ActionButtons';
import { useStyles } from './stylesP2';
//   import { ReCaptcha } from 'react-recaptcha-google';

const EmailFormP2 = ({
  textChange,
  errors,
  sentEmailToResetPassword
  // onLoadRecaptcha,
  // verifyCallback,
  // captchaDemo
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const goToSignIn = () =>
    navigate('/sign-in', {
      state: { prevUrl: location.pathname }
    });

  const buttonComponent = (label, onClick, isSaveBtn, className) => (
    <Button
      {...{ className }}
      {...{ label }}
      {...{ isSaveBtn }}
      {...{ onClick }}
    />
  );
  const buttonData = [
    {
      label: t('send'),
      onClick: (evt) => sentEmailToResetPassword(evt, goToSignIn),
      isSaveBtn: true,
      className: clsx(classes.btn, classes.blue)
    },
    {
      label: t('cancel'),
      className: clsx(classes.marginTop12, classes.borders)
    }
  ];

  return (
    <div className={clsx(classes.size)}>
      <span className={clsx(classes.flex, classes.title, classes.marginBottom)}>
        {t('loginIssue')}
      </span>
      <span className={classes.textWrapper}>{t('resetPasswordMsg')}</span>
      <TextField
        className={clsx(classes.flex, classes.marginTop20)}
        classes={{ root: classes.txtField }}
        label="Email"
        name="email"
        onChange={textChange}
        variant="outlined"
        error={Boolean(errors.email)}
        helperText={Boolean(errors.email) && errors.email}
      />
      {/* <ReCaptcha
            ref={captchaDemo}
            size="normal"
            render="explicit"
            sitekey="6Lc2Y9IUAAAAAFXPO7T7QIK-XXvYm3-SRTJnjK0r"
            onloadCallback={onLoadRecaptcha}
            verifyCallback={verifyCallback}
        /> */}
      <span className={clsx(classes.marginTop40)}>
        {buttonData.map((it, idx) =>
          it.onClick ? (
            <Fragment key={idx}>
              {buttonComponent(
                it.label,
                it.onClick,
                it.isSaveBtn,
                it.className
              )}
            </Fragment>
          ) : (
            <Link
              to="/sign-in"
              className={clsx(classes.btn, classes.left)}
              key={idx}>
              {buttonComponent(
                it.label,
                it.onClick,
                it.isSaveBtn,
                it.className
              )}
            </Link>
          )
        )}
      </span>
    </div>
  );
};
export default EmailFormP2;
