import React, { useState } from 'react';
import { Close } from '@material-ui/icons';
import { useStyles } from './styles';
import clsx from 'clsx';
import { ReactComponent as QuestionMarkIc } from '../../assets/img/question_mark.svg';
import { useTranslation } from 'react-i18next';

const QuestionMarkNew = () => {
  const classes = useStyles();
  const [iframeVisible, setIframeVisible] = useState(false);
  const { t } = useTranslation();

  const openWindow = () => {
    setIframeVisible((prevVisible) => !prevVisible);
  }

  return (
    <>
      <QuestionMarkIc
        className={clsx(classes.pointer, classes.radiusIc)}
        onClick={() => openWindow()}
      />
      {iframeVisible && 
        <div className={clsx(classes.main, classes.borderRadius, classes.fixed, classes.backgroundColor)}>
          <Close 
            onClick={() => setIframeVisible(!iframeVisible)}
            className={clsx(classes.pointer, classes.white)}/>
          {iframeVisible ? (
            <>
              <div className={clsx(classes.iframe, classes.border)}>
                <iframe
                  src={'https://tournament.expert/ru/FAQ/'}
                  height="100%"
                  width="100%"
                  allowTransparency="true"
                />
              </div>
            </>
          ) : (
            iframeVisible && (
              <div className={clsx(classes.iframe, classes.backColorWhite, classes.wrapper, classes.border)}>
                <div>
                  <img
                    alt="Under development"
                    className={classes.image}
                    src="/images/404_Error.png"
                  />
                  <span className={classes.txt}>404: {t('docIsBeingWritten')}</span>
                </div>
              </div>
            )
          )}
      </div>}
    </>
  );
};

export default QuestionMarkNew;
