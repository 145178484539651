import clsx from 'clsx';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './StylesTabs';

const TabItems = (props) => {
  const {
    onChange,
    type,
    data,
    label,
    value,
    style,
    additional,
    subItem,
    className,
    classNameTabs
  } = props;
  const classes = useStyles();

  return (
    <Tabs
      className={clsx(classes.tabs, classes.scrollBtn, classNameTabs)}
      {...{ style }}
      value={type}
      {...{ onChange }}
      scrollButtons="auto"
      variant="scrollable"
      indicatorColor="primary"
      textColor="primary">
      {Array.isArray(data) &&
        data.map((it) => (
          <Tab
            classes={{ root: className }}
            key={it[value]}
            label={
              <div>
                {it[label]}
                {it[additional] && (
                  <sub className={classes.additional}>{subItem(it)}</sub>
                )}
              </div>
            }
            value={it[value]}
          />
        ))}
    </Tabs>
  );
};
export default TabItems;
