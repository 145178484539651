import clsx from 'clsx';

import { ReactComponent as Bronze } from '../../assets/img/medal_bronze.svg';
import { ReactComponent as Gold } from '../../assets/img/medal_gold.svg';
import { ReactComponent as Silver } from '../../assets/img/medal_silver.svg';

import { useStyles } from './styles';

const Places2Win = (props) => {
  const { item, paramName } = props;

  const classes = useStyles();
  const medal =
    `${item[paramName]}` === '1' ? (
      <Gold className={classes.icon} />
    ) : `${item[paramName]}` === '2' ? (
      <Silver className={classes.icon} />
    ) : (
      `${item[paramName]}` === '3' && <Bronze className={classes.icon} />
    );

  return <div className={clsx(classes.iconWrapper, classes.left)}>{medal}</div>;
};

export default Places2Win;
