import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  btn: {
    height: '3em',
    minWidth: 'fit-content',
    padding: '0.2em 0.5rem',
    borderRadius: '.5rem',
    alignItems: 'center',
    justifyContent: 'center'
  },

  defaultBtn: {
    color: '#8996AC',
    border: '1px solid #8996AC'
  },

  activeBtn: {
    backgroundColor: '#2D63EE',
    color: '#fff'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  }
}));
