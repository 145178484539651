import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import {
  ANDROID_DOWNLOAD,
  iOS_DOWNLOAD,
  CASTER,
  MANAGER,
  WINDOWS,
  DIPLOMA
} from '../../helpers/constants';

import AppStore from '../../assets/img/app_sotre.png';
import GooglePlay from '../../assets/img/google_play.png';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/img/tournament_expert_LOGO.svg';

import Button from '../../components/Buttons/ActionButtons';

import { useStyles } from './StylesDownloadApp';

const DownloadApp = (props) => {
  const { apps, getApp, loading, appId } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [toggled, onToggle] = useState([...apps.map((it) => false)]);

  const data = [
    {
      isDesktop: true
    },
    {
      buttons: [
        { src: GooglePlay, alt: 'Google play', href: ANDROID_DOWNLOAD },
        { src: AppStore, alt: 'App store', href: iOS_DOWNLOAD }
      ]
    }
  ];

  const onToggleOthers = (idx) => {
    onToggle(toggled.map((it, itIdx) => (idx === itIdx ? !it : it)));
  };

  const WINDOWS_COMONENT = (myApp, myAppIdx) => (
    <>
      <a
        className={clsx(classes.fullWidth, classes.marginTop)}
        href={myApp.path}
        download>
        <Button
          id={`TEST_ID_APP_${myApp.app_type}`}
          heightException={true}
          className={clsx(classes.fullWidth)}
          isSaveBtn={true}
          label={
            <>
              {loading && appId === myApp.id ? (
                <CircularProgress classes={{ root: classes.spinner }} />
              ) : (
                <span className={clsx(classes.flex, classes.column)}>
                  {myApp.name}
                  <span>
                    {t('versionNew')} {t('from').toLowerCase()}
                    {'  '}
                    {moment(myApp.uploading_date).format('DD-MM-YYYY')}
                  </span>
                </span>
              )}
            </>
          }
        />
      </a>
      <span className={classes.others} onClick={() => onToggleOthers(myAppIdx)}>
        {t('otherVersions')}
      </span>
      {toggled[myAppIdx] && (
        <span className={classes.othersWrapper}>
          {myApp.others &&
            myApp.others.map(
              (it, idx) =>
                it && (
                  <a
                    style={{ color: 'inherit' }}
                    href={it.path}
                    className={clsx(classes.flex, classes.othersItem)}
                    key={it.id}
                    download>
                    <div>
                      <span>{it.name}</span>
                      <div>{moment(it.uploading_date).format('DD-MM-YYYY')}</div>
                    </div>
                  </a>
                )
            )}
        </span>
      )}

      <span className={clsx(classes.centerTxt, classes.marginTop)}>
        {t('availableFor')} {WINDOWS}
      </span>
    </>
  );

  const MOBILE_COMPONENT = (buttons) =>
    Array.isArray(buttons) &&
    buttons.map((key) => (
      <a className={classes.fullWidth} key={key.src} href={key.href}>
        <img
          className={clsx(classes.marginTop, classes.img, classes.fullWidth)}
          src={key.src}
          alt={key.alt}
        />
      </a>
    ));

  const CARD = (component, app, about) => (
    <span
      key={app}
      className={clsx(classes.flex, classes.wrapper, classes.paper)}>
      <span className={clsx(classes.column, classes.card)}>
        <span className={clsx(classes.flex, classes.centerVertically)}>
          <Logo className={classes.titleImg} />
          <span className={classes.app}>{app}</span>
        </span>
        <p className={classes.infoTxt}>{about}</p>
      </span>
      <span
        className={clsx(
          classes.flex,
          classes.btnWrapper,
          classes.column,
          classes.centerHorizontally,
          classes.maxWidth,
          classes.fullWidth,
          classes.centerVertically
        )}>
        {component}
      </span>
    </span>
  );

  return (
    <>
      {data.map(({ isDesktop, buttons }, dataIdx) => (
        <Fragment key={dataIdx}>
          {isDesktop
            ? Array.isArray(apps) &&
              apps.map((app, appIdx) => (
                <Fragment key={appIdx}>
                  {CARD(
                    WINDOWS_COMONENT(app, appIdx),
                    `${app.app_type}` === '1'
                      ? t('tournamentManager')
                      : `${app.app_type}` === '2'
                      ? CASTER
                      : `${app.app_type}` === '3'
                      ? MANAGER
                      : `${app.app_type}` === '4' && DIPLOMA,
                    `${app.app_type}` === '1'
                      ? t('downloadTournamentManagerInfo')
                      : `${app.app_type}` === '2'
                      ? t('downloadCasterInfo')
                      : `${app.app_type}` === '3'
                      ? t('downloadCastManager')
                      : `${app.app_type}` === '4' && t('downloadDiploma')
                  )}
                </Fragment>
              ))
            : CARD(
                MOBILE_COMPONENT(buttons),
                t('forMobile'),
                t('downloadMobileInfo')
              )}
        </Fragment>
      ))}
    </>
  );
};
export default DownloadApp;
