import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Droppable, Draggable } from 'react-beautiful-dnd';

import { ReactComponent as FlipCard } from 'assets/img/flip_card.svg';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { convertMs } from '../../helpers/selectors';

import SearchInput from '../../components/SearchInput/SearchInput';
import Radio from 'components/RadioBtn/RadioBtn';

import {
  getListStyle,
  getItemStyle,
  useStyles,
  getTatamiTabStyle
} from './StylesDroppableContent';

const DroppableContent = (props) => {
  const {
    windowWidth,
    onChangeTab,
    activeTab,
    filteredDroppables,
    searchBar,
    onSearch,
    onClearSearch,
    numOfTatamis,
    tatamisTheme,
    findBlock,
    selectCheckbox,
    categoryTypes,
    selCheckbox
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const summary = (array, key, cond) =>
    array.reduce((accumulator, value) => accumulator + value[key], 0);

  const onFlipCard = (item) => {
    const allFlippedElem = document.querySelector(classes.cardIsFliped);
    if (allFlippedElem) {
      allFlippedElem.classList.remove(classes.cardIsFliped);
    }
    const card = document.getElementById(`TEST_ID_CARD_${item.category_id}`);

    card.classList.toggle(classes.cardIsFliped);
  };

  const onFlipAllCards = () => {
    filteredDroppables[currentBlockIdx][currentDroppabaleIdx][droppabeleId].map(
      (item) => {
        const allFlippedElem = document.querySelector(classes.cardIsFliped);
        if (allFlippedElem) {
          allFlippedElem.classList.remove(classes.cardIsFliped);
        }
        const card = document.getElementById(
          `TEST_ID_CARD_${item.category_id}`
        );

        card.classList.toggle(classes.cardIsFliped);
      }
    );
  };

  const iconsTatami = (it, content) => [
    {
      icon: <FormatListNumberedIcon className={classes.tatamiIcons} />,
      count: it[content].length
    },
    {
      icon: <PersonIcon className={classes.tatamiIcons} />,
      count: summary(it[content], 'participants_count')
    },
    {
      icon: <SportsKabaddiIcon className={classes.tatamiIcons} />,
      count: summary(it[content], 'skirmishes_count')
    },
    {
      icon: <ScheduleIcon className={classes.tatamiIcons} />,
      count: convertMs(summary(it[content], 'total_time'), null, true)
    }
  ];

  const iconsCategories = (item) => [
    ...(+item?.category_type === 3
      ? [
          {
            icon: <GroupIcon />,
            count: item.teams_count
          }
        ]
      : []),
    { icon: <PersonIcon />, count: item.participants_count },
    ...(`${item.category_type}` !== '4'
      ? [
          {
            icon: <SportsKabaddiIcon />,
            count: item.skirmishes_count
          }
        ]
      : []),
    {
      icon: <ScheduleIcon />,
      count: convertMs(item.total_time, null, null, true)
    }
  ];

  const overConstraintWidth = windowWidth > 1680;
  const extraTime = (item) => [
    {
      label: overConstraintWidth ? 'Main Time' : 'MT',
      value: item.category_main_time
    },
    {
      label: overConstraintWidth ? 'Extra Time' : 'ET',
      value: item.extra_time_ms
    },
    {
      label: overConstraintWidth ? 'Final Main Time' : 'FMT',
      value: item.final_main_time_ms
    },
    {
      label: overConstraintWidth ? 'Final Extra Time' : 'FET',
      value: item.final_extra_time_ms
    }
  ];
  const SubItem = ({ icon, count, className, txtStyle }) => (
    <span className={clsx(classes.flex, classes.wrap, className)}>
      {icon}
      <span className={txtStyle}>{count}</span>
    </span>
  );

  const droppabeleId = `droppable_${activeTab}`;
  const currentBlockIdx = findBlock(filteredDroppables, droppabeleId);
  const currentDroppabaleIdx = filteredDroppables[currentBlockIdx].findIndex(
    (items) => items[droppabeleId]
  );
  const checkboxes = [
    { id: 'MFMF', label: t('MFMF'), tooltip: t('boyGirl') },
    { id: 'FMFM', label: t('FMFM'), tooltip: t('girlBoy') },
    { id: 'MMFF', label: t('MMFF'), tooltip: t('boyBoy') },
    { id: 'FFMM', label: t('FFMM'), tooltip: t('girlGirl') }
  ];

  return (
    <div className={clsx(classes.flex, classes.column)}>
      <SearchInput
        className={classes.search}
        onChange={onSearch}
        clearSearch={onClearSearch}
        value={searchBar}
        isSearchInactive={!searchBar}
      />
      <div className={clsx(classes.flex)}>
        <div className={clsx(classes.droppable, classes.padding05)}>
          <div
            className={clsx(
              classes.flex,
              classes.centerVertically,
              classes.textAlign
            )}>
            <FlipCard
              className={clsx(classes.flipIc, classes.marginRight)}
              onClick={onFlipAllCards}
            />
            <Radio
              value={selCheckbox ?? ''}
              tooltipTitleParam="tooltip"
              options={checkboxes}
              onClick={selectCheckbox}
              item="id"
              label="label"
            />
          </div>
          <Droppable droppableId={`droppable_active`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver, numOfTatamis)}>
                {filteredDroppables[currentBlockIdx][currentDroppabaleIdx][
                  droppabeleId
                ].map((item, index) => {
                  const findCategoryType = categoryTypes.find(
                    (it) => +it.id === +item.category_type
                  );

                  return (
                    <Draggable
                      key={item.category_id}
                      draggableId={`${item.category_id}`}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          id={`TEST_ID_CARD_${item.category_id}`}
                          className={clsx(classes.flipCard, classes.relative)}
                          onClick={() => onFlipCard(item)}>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.column,
                              classes.wordBreak,
                              classes.side,
                              classes.flipCardInner,
                              classes.front
                            )}>
                            <span className={classes.textContainer}>
                              {item.category_name}
                            </span>
                            <sub
                              className={clsx(
                                classes.subText,
                                classes.flex,
                                classes.wrap,
                                classes.centerVertically
                              )}>
                              {iconsCategories(item).map(
                                ({ icon, count }, idx) => (
                                  <span key={`${icon + idx}`}>
                                    <SubItem
                                      txtStyle={classes.subTextDigit}
                                      {...{ icon }}
                                      {...{ count }}
                                    />
                                  </span>
                                )
                              )}
                              {!findCategoryType?.weighted ? (
                                +item?.category_type !== 4 &&
                                !isNaN(item?.kata_final) && (
                                  <span className={clsx(classes.leftAuto)}>
                                    {[t('round'), 'II'].join(' ')}:{' '}
                                    {item?.kata_final ?? 0}
                                  </span>
                                )
                              ) : (
                                <SubItem
                                  className={classes.leftAuto}
                                  txtStyle={classes.extraTime}
                                  icon={extraTime(item)?.[0]?.label}
                                  count={convertMs(
                                    extraTime(item)?.[0]?.value,
                                    true
                                  )}
                                />
                              )}
                            </sub>
                          </span>
                          {+item?.category_type !== 4 && ( // tamishewari has not round II
                            <sub className={clsx(classes.back, classes.side)}>
                              {!findCategoryType?.weighted ? (
                                <div
                                  className={clsx(
                                    classes.flex,
                                    classes.centerVertically
                                  )}>
                                  {!isNaN(item.kata_final) && (
                                    <>
                                      {[t('round'), 'II'].join(' ')}:{' '}
                                      {item?.kata_final ?? 0}
                                    </>
                                  )}
                                </div>
                              ) : (
                                extraTime(item).map(({ label, value }) => (
                                  <Fragment key={label}>
                                    <SubItem
                                      txtStyle={classes.extraTime}
                                      icon={label}
                                      count={convertMs(value, true)}
                                    />
                                  </Fragment>
                                ))
                              )}
                            </sub>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className={classes.padding05}>
          {Array.isArray(filteredDroppables) &&
            filteredDroppables.map((block, blockIdx) => {
              const maxHeightBlock = `${block?.length * 7}em`;

              return (
                <span className={classes.flex} key={blockIdx}>
                  <span>
                    {Array.isArray(block) &&
                      block.map((tab, tabIdx) => {
                        const droppableId = `droppable_${tab.tatami_id}`;

                        const findThemeColor = tatamisTheme?.find(
                          (it) => +it?.id === +tab?.theme_id
                        )?.color1;

                        return (
                          <Droppable key={tabIdx} {...{ droppableId }}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                onClick={() => {
                                  onChangeTab(tab);
                                }}
                                className={clsx(
                                  classes.flex,
                                  classes.wrap,
                                  classes.column,
                                  classes.tab,
                                  tab.tatami_id === activeTab &&
                                    classes.seletedTab
                                )}
                                style={getTatamiTabStyle(findThemeColor)}>
                                {tab.tatami_name}
                                {tab[droppableId] && (
                                  <sub
                                    className={clsx(
                                      classes.flex,
                                      classes.wrap
                                    )}>
                                    {iconsTatami(tab, droppableId).map(
                                      ({ icon, count }, idx) => (
                                        <Fragment key={`${icon + idx}`}>
                                          <SubItem
                                            className={classes.subWrapper}
                                            {...{ icon }}
                                            {...{ count }}
                                          />
                                        </Fragment>
                                      )
                                    )}
                                  </sub>
                                )}
                              </div>
                            )}
                          </Droppable>
                        );
                      })}
                  </span>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.centerVertically,
                      classes.blockWrapper,
                      classes.marginBottom0And5,
                      classes.centerHorizontally,
                      classes.padding05
                    )}>
                    <span
                      className={clsx(classes.flex, classes.blockName)}
                      style={{ width: maxHeightBlock }}>
                      <span
                        className={clsx(classes.ellipsis, classes.blockTxt)}
                        style={{ width: maxHeightBlock }}>
                        {block[0]?.block_name ||
                          t('block', { count: block[0]?.block_id })}
                      </span>
                    </span>
                  </span>
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default DroppableContent;
