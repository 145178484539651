import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  permissionList: {
    alignItems: 'center',
    paddingTop: '10px'
  },
  left: {
    marginLeft: 'auto'
  },
  icon: {
    cursor: 'pointer',
    color: '#6E747C',
    '&:hover': {
      color: '#86BBE0'
    },
    '&:active': {
      color: '#1E5A8C'
    }
  },
  iconWrapper: {
    display: 'flex',
    width: '45px',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  maxHeight: {
    height: '65px'
  },
  eyeIcon: {
    color: '#7b88d1'
  },

  permissionWrapper: {
    borderBottom: '1px solid #6E747C',
    padding: '15px 35px',
    marginBottom: '45px'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  marginLR: {
    margin: '0 1rem 0 0.5rem'
  }
});

export const styleFilter = {
  width: '100%'
};

export const margins = {
  display: 'flex',
  flexDirection: 'column'
};
