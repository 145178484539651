import clsx from 'clsx';

import TopbarP2 from './TopbarP2';
import { useStyles } from './Styles';

const Minimal = (props) => {
  const { children, hideNav, showExitBtn, pageTitle, breadcrumbs } = props;
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <TopbarP2
        {...{ pageTitle }}
        {...{ showExitBtn }}
        {...{ hideNav }}
        {...{ breadcrumbs }}
      />
      {children}
    </main>
  );
};

export default Minimal;
