const styles = () => ({
  flex: {
    display: 'flex'
  },

  marginTop1: {
    marginTop: '1rem'
  },
  
  maxColumnWidth: {
    maxWidth: '100px'
  }
});

export default styles;
