import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Input } from '@material-ui/core';

import { ReactComponent as Search } from '../../assets/img/search.svg';
import { ReactComponent as Clear } from 'assets/img/clear.svg';

import { useStyles } from './StylesSearchInput';

const SearchInput = (props) => {
  const {
    className,
    onChange,
    isSearchInactive,
    clearSearch,
    value,
    componentBefore,
    ...rest
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Input
        {...rest}
        className={clsx(classes.root, className ? className : classes.maxWidth)}
        {...{ value }}
        disableUnderline
        {...{ onChange }}
        placeholder={t('search')}
        startAdornment={
          <>
            {componentBefore}
            <Search className={classes.icon} />
          </>
        }
        endAdornment={
          !isSearchInactive && (
            <Clear
              className={clsx(classes.icon, classes.clear)}
              onClick={clearSearch}
            />
          )
        }
      />
    </>
  );
};

export default SearchInput;
