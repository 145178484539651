import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    padding: '0 .5rem'
  },

  grid: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridColumnGap: '.5rem',
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumnGap: '.5rem',
      gridTemplateColumns: '1fr 1fr'
    }
  },

  marginTop1: {
    marginTop: '1rem'
  },

  endAdornment: {
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiIconButton-root': {
      paddingRight: '10px'
    },
    '@media (max-width:1023px)': {
      '& .MuiInputAdornment-positionEnd': {
        display: 'none'
      }
    }
  },

  flex: {
    display: 'flex'
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  formSave: {
    height: '3.35em'
  },

  formAvatarWrapper: {
    '@media (min-width:1024px)': {
      gridRow: 'span 10'
    },
    '@media (max-width:1023px)': {
      gridRow: 'span 3'
    }
  },

  historyInput: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  formAvatarInnerWrapper: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridRowGap: '1.5rem'
    }
  },

  formAvatar: {
    '@media (min-width:1024px)': {
      width: '16em',
      height: '18.5em'
    },
    '@media (max-width:1023px)': {
      height: '8.5em',
      width: '7em',
      justifySelf: 'center'
    },
    '@media (max-width:767px)': {
      marginBottom: '1rem'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  uploadFile: {
    borderRadius: '0.5rem',
    height: '3.82em',
    '@media (min-width:768px)': {
      gridColumn: 'span 2'
    },
    '@media (max-width:1023px)': {
      marginBottom: '1rem'
    },
    padding: '0.2em 0.5rem',
    backgroundColor: '#8996AC',
    color: '#fff',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center'
  },

  uploadFileHover: {
    cursor: 'pointer',
    transition: '.5s ease'
  },

  inputFile: {
    display: 'none'
  },

  text: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    padding: '0.4rem'
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  maxHeightTxtInput: {
    height: '5em'
  }
}));

export const bgColor = (isOldDesign) => ({
  backgroundColor: `${!isOldDesign ? '#2D63EE' : '#001D3D'}`
});
