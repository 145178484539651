import { Component, Fragment } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GetAppIcon from '@material-ui/icons/GetApp';
import HeadIMG from '../../assets/img/temp_ph.jpg';

import { Tooltip, CircularProgress } from '@material-ui/core';

import Button from '../../components/Buttons/ActionButtons';

import { isSchool } from '../../helpers/selectors';
import {
  changeModal,
  fetchTournaments,
  fetchAllCategoryTypes,
  exportParticipants,
  exportCategories,
  exportRegistration,
  exportRunner,
  exportRating,
  downloadFile,
  exportTournamentCategories,
  exportParticipantNumbers,
  exportReportsOfAllTypes,
  exportReportsJudgesComposition,
  exportReportsJudges,
  exportTournamentResultsReport,
  exportTournamentStatisticReport,
  changeHead,
  exportParticipationCandidatesReport,
  exportTournamentTatamiReport,
  changeImage,
  updateTournament,
  downloadUplaodedFile,
  fetchReference
} from '../../helpers/util';
import { serverDefaultPath, ENABLED, DISABLED } from '../../helpers/constants';
import { changeTitle } from '../../helpers/actions';
import { categoryTypesPresentOnTournament } from '../../helpers/selectors';

import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import SideModal from '../../components/Snackbar/SideModal';
import Table from '../../components/Table/Table';
import LoadingState from '../../components/LoadingState/LoadingState';
import EmptyState from '../../components/EmptyState/EmptyState';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

import styles from './Styles';
import UploadImage from 'components/UploadImage/UploadImage';
import RadioBtn from 'components/RadioBtn/RadioBtn';
import QuestionMark from 'components/QuestionMark/QuestionMark';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      success: false,
      tournamentData: {},
      loading: true,
      langOnLoad: localStorage.getItem('i18nextLng'),
      selectedCheckboxes: [],
      selectedCheckboxesTournamentStatistic: [],
      selectedCheckboxesMedalBronze: [],
      selectedCheckboxesReportApplicants: [],
      selectedCheckboxesCategoryDistribution: [],
      selectedCheckboxesCoachClub: [],
      selectedCheckboxesParticipants: [],
      selectedCheckboxesCategories: [],
      selectedCheckboxesRegistration: [],
      selectedCheckboxesRunners: [],
      selectedCheckboxesRatting: [],
      selectedCheckboxesTournamentCategories: [],
      selectedCheckboxesALLkumite: [],
      selectedCheckboxesAllTypesKATA: [],
      selectedCheckboxesAllTypesTEAM_KATA: [],
      selectedCheckboxesAllTypesTAMESHIWARI: [],
      selectedCheckboxesAllTypesKATAFLAGS: [],
      selectedCheckboxesAllTypesPOINTKUMITE: [],
      selectedRadioButtonMedalBronze: 0,
      selectedRadioButtonCoachClub: 0,
      iframeURL: {}
    };
    this.tournamentIdParams = new URLSearchParams(window.location.search).get(
      'tournament_id'
    );
    this.changeModal = changeModal.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.exportParticipants = exportParticipants.bind(this);
    this.exportCategories = exportCategories.bind(this);
    this.exportRegistration = exportRegistration.bind(this);
    this.exportRunner = exportRunner.bind(this);
    this.exportRating = exportRating.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.exportTournamentCategories = exportTournamentCategories.bind(this);
    this.exportReportsOfAllTypes = exportReportsOfAllTypes.bind(this);
    this.exportReportsJudgesComposition =
      exportReportsJudgesComposition.bind(this);
    this.exportReportsJudges = exportReportsJudges.bind(this);
    this.exportParticipantNumbers = exportParticipantNumbers.bind(this);
    this.exportTournamentResultsReport =
      exportTournamentResultsReport.bind(this);
    this.changeHead = changeHead.bind(this);
    this.exportTournamentStatisticReport =
      exportTournamentStatisticReport.bind(this);
    this.exportParticipationCandidatesReport =
      exportParticipationCandidatesReport.bind(this);
    this.exportTournamentTatamiReport = exportTournamentTatamiReport.bind(this);
    this.changeImage = changeImage.bind(this);
    this.updateTournament = updateTournament.bind(this);
    this.downloadUplaodedFile = downloadUplaodedFile.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  selectCheckbox = (paramName, key) => {
    this.setState((prevState) => {
      const selectedCheckboxes = prevState[paramName] ?? [];
      const checkedValues = selectedCheckboxes.includes(key)
        ? selectedCheckboxes.filter((it) => it !== key)
        : [...selectedCheckboxes, key];

      return { [paramName]: checkedValues };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      this.fetchData();
      changeHead(tournamentData, t('reports'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(this.tournamentIdParams, null, null, () => {
      const { tournamentData } = this.state;

      this.fetchData(() => {
        const updateSignaturePreview = (signatureId, previewField) => {
          this.downloadUplaodedFile(signatureId, null, (file) => {
            this.setState({
              tournamentData: {
                ...this.state.tournamentData,
                [previewField]: URL.createObjectURL(file)
              }
            });
          });
        };

        tournamentData?.signature_1_id &&
          updateSignaturePreview(
            tournamentData?.signature_1_id,
            'signature_1_preview'
          );
        tournamentData?.signature_2_id &&
          updateSignaturePreview(
            tournamentData?.signature_2_id,
            'signature_2_preview'
          );
      });

      changeHead(tournamentData, t('reports'));
    });

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter(
          (item) => item.name === 'event_reports'
        );
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  fetchData = (cb) => {
    const { t } = this.props;
    const { tournamentData } = this.state;
    const isTraining = isSchool(tournamentData);
    const isParticipant = !!+tournamentData?.no_participant_number;
    const checkboxNames = [
      ...(!!+tournamentData?.show_reg_region
        ? [{ id: 'regions', label: t('regions') }]
        : []),
      ...(!!+tournamentData?.show_reg_city
        ? [{ id: 'cities', label: t('cities') }]
        : []),
      ...(!!+tournamentData?.show_reg_club
        ? [{ id: 'clubs', label: t('club') }]
        : []),
      { id: 'coaches', label: t('coach') }
    ];
    let checkboxesNameNoCoach = [...checkboxNames].slice(0, 3);
    const isRegisterJudges = !!+tournamentData?.reg_judge_form;

    this.fetchAllCategoryTypes((allSystemTypes) => {
      const tournCategories = categoryTypesPresentOnTournament(
        allSystemTypes || [],
        tournamentData?.category_types
      );

      const permissionVal = (tournament) => [
        ...(tournament?.published === ENABLED ? ['published'] : []),
        ...(tournament?.bronze_fight_enabled === ENABLED
          ? ['bronze_fight_enabled']
          : []),
        ...(tournament?.no_participant_number === ENABLED
          ? ['no_participant_number']
          : []),
        ...(tournament?.no_country === ENABLED ? ['no_country'] : []),
        ...(tournament?.circle_round === ENABLED ? ['circle_round'] : []),
        ...(tournament?.reg_judge_form === ENABLED ? ['reg_judge_form'] : []),
        ...(tournament?.last_name_first === ENABLED ? ['last_name_first'] : []),
        ...(tournament?.tameshiwari > 0 ? ['tameshiwari'] : []),
        ...(tournament?.height_needed === ENABLED ? ['height_needed'] : []),
        ...(tournament?.sport_rank_needed === ENABLED
          ? ['sport_rank_needed']
          : []),
        ...(!!+tournament?.show_reg_coach_2 ? ['show_reg_coach_2'] : []),
        ...(tournament?.use_alt_category_name === ENABLED
          ? ['use_alt_category_name']
          : []),
        ...(tournament?.use_qr === ENABLED ? ['use_qr'] : []),
        ...(tournament?.use_notification_win === ENABLED ? ['use_notification_win'] : []),
        ...(tournament?.report_head === DISABLED ? ['report_head'] : []), //Disable Report Header on Reports page
        ...(tournament?.use_rep_stamp === ENABLED ? ['use_rep_stamp'] : []),
        ...(tournament?.use_rep_signature === ENABLED
          ? ['use_rep_signature']
          : []),
        ...(tournament?.show_reg_iko === ENABLED ? ['show_reg_iko'] : []),
        ...(!!+tournament?.show_reg_region ? ['show_reg_region'] : []),
        ...(!!+tournament?.show_reg_city ? ['show_reg_city'] : []),
        ...(!!+tournament?.show_reg_club ? ['show_reg_club'] : []),
        ...(tournament?.show_reg_qdan === ENABLED ? ['show_reg_qdan'] : []),
        ...(tournament?.show_reg_patronymic === ENABLED
          ? ['show_reg_patronymic']
          : []),
        ...(tournament?.use_reg_online === ENABLED ? ['use_reg_online'] : [])
      ];

      const tableData = [
        {
          paramName: 'selectedCheckboxesTournamentStatistic',
          name: t('tournamentReference'),
          onClick: () =>
            this.exportTournamentStatisticReport(this.tournamentIdParams),
          checkboxes: [
            ...(!+tournamentData?.no_country
              ? [{ id: 'countries', label: t('countries') }]
              : []),
            ...checkboxNames,
            { id: 'orientation', label: t('portraitPageOrientation') }
          ]
        },
        ...(!isTraining
          ? [
              {
                name: t('reportWinners'),
                href: '/events/event_winners?tournament_id='
              }
            ]
          : []),
        {
          paramName: 'selectedCheckboxesMedalBronze',
          name: t('tournamentResults'),
          onClick: () =>
            this.exportTournamentResultsReport(this.tournamentIdParams),
          checkboxes: [
            ...tournCategories?.map((it) => ({
              id: it?.id,
              label: it.name
            })),
            { id: 'orientation', label: t('portraitPageOrientation') }
          ],
          paramNameRadio: 'selectedRadioButtonMedalBronze',
          radioButtons: [
            ...(!+tournamentData?.no_country
              ? [{ id: 'countries', label: t('countries') }]
              : []),
            ...checkboxNames
          ]
        },
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesReportApplicants',
                name: t('reportApplicants'),
                onClick: () =>
                  this.exportParticipationCandidatesReport(
                    this.tournamentIdParams
                  ),
                checkboxes: [
                  ...(!+tournamentData?.no_country
                    ? [{ id: 'countries', label: t('countries') }]
                    : []),
                  ...checkboxNames,
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              }
            ]
          : []),
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesCategoryDistribution',
                name: t('reportCategoryDistribution'),
                onClick: () =>
                  this.exportTournamentTatamiReport(this.tournamentIdParams),
                checkboxes: [
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              }
            ]
          : []),
        {
          paramName: 'selectedCheckboxesCoachClub',
          name: t('reportCoachClub'),
          onClick: () => this.exportParticipants(this.tournamentIdParams),
          checkboxes: [
            ...(!+tournamentData?.no_country
              ? [{ id: 'countries', label: t('countries') }]
              : []),
            ...checkboxNames,
            { id: 'orientation', label: t('portraitPageOrientation') }
          ],
          paramNameRadio: 'selectedRadioButtonCoachClub',
          radioButtons: [
            ...(!+tournamentData?.no_country
              ? [{ id: 'countries', label: t('countries') }]
              : []),
            ...checkboxNames
          ]
        },
        ...(!isParticipant
          ? [
              {
                paramName: 'selectedCheckboxesParticipants',
                name: t('reportParticipants'),
                onClick: () =>
                  this.exportParticipantNumbers(this.tournamentIdParams)
              }
            ]
          : []),
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesCategories',
                name: t('reportListOfCategoties'),
                onClick: () => this.exportCategories(this.tournamentIdParams),
                checkboxes: [
                  ...(!+tournamentData?.no_country
                    ? [{ id: 'countries', label: t('countries') }]
                    : []),
                  ...checkboxNames,
                  { id: 'show_history', label: t('achievements') },
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              }
            ]
          : []),
        {
          paramName: 'selectedCheckboxesRegistration',
          name: t('reportRegistration'),
          onClick: () => this.exportRegistration(this.tournamentIdParams),
          checkboxes: [
            ...(!+tournamentData?.no_country
              ? [{ id: 'countries', label: t('countries') }]
              : []),
            ...checkboxNames,
            { id: 'orientation', label: t('portraitPageOrientation') }
          ]
        },
        {
          paramName: 'selectedCheckboxesRunners',
          name: t('reportRunners'),
          onClick: () => this.exportRunner(this.tournamentIdParams)
        },
        {
          paramName: 'selectedCheckboxesRatting',
          name: t('reportRatting'),
          onClick: () => this.exportRating(this.tournamentIdParams),
          checkboxes: [
            ...(!+tournamentData?.no_country
              ? [{ id: 'countries', label: t('countries') }]
              : []),
            ...checkboxNames,
            { id: 'orientation', label: t('portraitPageOrientation') }
          ]
        },
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesTournamentCategories',
                name: t('reportCategories'),
                onClick: () =>
                  this.exportTournamentCategories(this.tournamentIdParams),
                checkboxes: [
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              },
              ...tournCategories?.map((cType) => ({
                paramName: `selectedCheckboxes${cType.key}`,
                name: t('reportCategoryType', { name: cType.name }),
                onClick: () =>
                  this.exportReportsOfAllTypes(
                    this.tournamentIdParams,
                    cType.id,
                    `selectedCheckboxes${cType.key}`
                  ),
                checkboxes: [
                  ...(!+tournamentData?.no_country
                    ? [{ id: 'countries', label: t('countries') }]
                    : []),
                  ...checkboxNames,
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              }))
            ]
          : []),
        ...(isRegisterJudges
          ? [
              {
                paramName: `selectedCheckboxesJudges`,
                name: t('listOfJudges'),
                onClick: () =>
                  this.exportReportsJudges(
                    this.tournamentIdParams,
                    `selectedCheckboxesJudges`
                  ),
                checkboxes: [
                  ...(!+tournamentData?.no_country
                    ? [{ id: 'countries', label: t('countries') }]
                    : []),
                  ...checkboxesNameNoCoach,
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              },
              {
                paramName: `selectedCheckboxesJudgesComposition`,
                name: t('compositionOfTheJury'),
                onClick: () =>
                  this.exportReportsJudgesComposition(
                    this.tournamentIdParams,
                    `selectedCheckboxesJudgesComposition`
                  ),
                checkboxes: [
                  ...(!+tournamentData?.no_country
                    ? [{ id: 'countries', label: t('countries') }]
                    : []),
                  ...checkboxesNameNoCoach,
                  { id: 'orientation', label: t('portraitPageOrientation') }
                ]
              }
            ]
          : [])
      ];

      this.setState((prevState) => ({
        tableData,
        filteredTable: tableData,
        loading: false,
        ...(tournamentData.category_types && {
          selectedCheckboxesMedalBronze: [
            ...tournamentData.category_types,
            'orientation'
          ]
        }),
        selectedCheckboxes: permissionVal(tournamentData),
        selectedCheckboxesRatting: ['orientation'],
        selectedCheckboxesTournamentCategories: ['orientation'],
        selectedCheckboxesTournamentStatistic: ['orientation'],
        selectedCheckboxesJudgesComposition: ['orientation'],
        selectedCheckboxesJudges: ['orientation'],
        tournamentData: {
          ...prevState.tournamentData,
          ...(tournamentData?.rep_head_h
            ? {
                rep_head_h_preview:
                  serverDefaultPath + tournamentData.rep_head_h
              }
            : {}),
          ...(tournamentData?.rep_head_v
            ? {
                rep_head_v_preview:
                  serverDefaultPath + tournamentData.rep_head_v
              }
            : {})
        }
      }));

      cb && cb();
    });
  };

  hideSnackbar = () => this.setState({ showModal: false });
  tableSearch = (evt) => {
    const { value } = evt.target;
    const { tableData } = this.state;
    const filteredTable = tableData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase().trim())
    );
    this.setState(
      {
        filteredTable,
        searchBar: value
      },
      () => this.setState({ filesCount: this.state.filteredTable.length })
    );
  };
  clearSearch = () =>
    this.setState({ searchBar: '', filteredTable: this.state.tableData });

  goToNewPage = (link) => {
    const { navigate, location } = this.props;
    navigate(link, {
      state: { prevUrl: location.pathname }
    });
  };

  onUpdateTourn = (e) => {
    const { tournamentData } = this.state;
    const form = {
      ...tournamentData,
      ...(tournamentData.poster
        ? { imagePreview: serverDefaultPath + tournamentData.poster }
        : {}),
      country_id: tournamentData.c_id,
      date: tournamentData.start_date,
      tour_time: tournamentData.start_time,
      tournament_id: tournamentData.id
    };

    this.updateTournament(e, form);
  };

  selectRadioButton = (paramName, newState) => {
    this.setState({ [paramName]: newState });
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      tournamentData,
      searchBar,
      loading,
      filteredTable,
      selectedCheckboxes,
      iframeURL
    } = this.state;
    const { t, classes } = this.props;

    const header = [
      { label: '#' },
      { label: t('titleReport') },
      { label: t('reportSettings') },
      { label: t('pageSettings') },
      { label: t('run') }
    ];
    const tableBody = ['Idx', 'name', 'settingsRep', 'settingsPage'];
    const condensedTableData = { mainName: 'name', info: [] };

    const useReportHead = selectedCheckboxes.includes('report_head');
    const useSignature = selectedCheckboxes.includes('use_rep_signature');

    const titles = [
      {
        title: t('titleForReportsPortrait'),
        id: 'TEST_ID_UPLOAD_REPORTS_PORT',
        description: t('titleDescriptionPortrait'),
        src: HeadIMG,
        param: 'rep_head_v',
        preview: 'rep_head_v_preview',
        recomendSize: `${t('recommendSize', { size: '1920 x 200' })} `,
        className: classes.cardImgPortrait,
        classNameforBLock: useReportHead && classes.opacity
      },
      {
        title: t('titleForReportsLandscape'),
        id: 'TEST_ID_UPLOAD_REPORTS_LAND',
        description: t('titleDescriptionLandscape'),
        src: HeadIMG,
        param: 'rep_head_h',
        preview: 'rep_head_h_preview',
        recomendSize: `${t('recommendSize', { size: '2880 x 200' })} `,
        className: classes.cardImgLandscape,
        classNameforBLock: [
          classes.marginTop15,
          useReportHead && classes.opacity
        ]
      },
      {
        title: t('judgeSignature'),
        id: 'TEST_ID_JUDGE_SIGNATURE',
        src: HeadIMG,
        param: 'signature_1',
        preview: 'signature_1_preview',
        description: tournamentData.main_judge,
        className: classes.sizeForSignature,
        classNameforBLock: clsx(
          classes.inlineContainer,
          classes.marginRight,
          !useSignature && classes.opacity
        )
      },
      {
        title: t('secretarySignature'),
        id: 'TEST_ID_SECRETARY_SIGNATURE',
        src: HeadIMG,
        param: 'signature_2',
        preview: 'signature_2_preview',
        description: tournamentData.secretary,
        className: classes.sizeForSignature,
        classNameforBLock: clsx(
          classes.inlineContainer,
          !useSignature && classes.opacity
        )
      }
    ];

    const switchingSignatureAndTitles = [
      {
        label: t('toggleReportHead'),
        id: 'report_head'
      },
      {
        label: t('stampOnReports'),
        id: 'use_rep_stamp'
      },
      {
        label: t('signatureOnReports'),
        id: 'use_rep_signature'
      }
    ];

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo {...{ tournamentData }} shouldShowBtn={true} />
            <div className={classes.inlineBlock}>
              {switchingSignatureAndTitles.map((check, checkIdx) => (
                <Fragment key={checkIdx}>
                  <CheckboxBtn
                    label={check.label}
                    onChange={() =>
                      this.selectCheckbox('selectedCheckboxes', check.id)
                    }
                    checked={this.state.selectedCheckboxes.includes(check.id)}
                  />
                </Fragment>
              ))}
            </div>
            <div className={classes.marginBottom20}>
              <div>
                {titles.map((card, cardIdx) => (
                  <div
                    key={cardIdx}
                    className={clsx(
                      classes.flex,
                      classes.paper,
                      classes.justifyContent,
                      card.classNameforBLock
                    )}>
                    <span
                      className={clsx(
                        classes.flex,
                        classes.column,
                        classes.cardTxt
                      )}>
                      <span className={clsx(classes.flex, classes.h1)}>
                        {card.title}
                      </span>
                      <span>{card.description}</span>
                      <span className={classes.fontSize12}>
                        {card.recomendSize}
                      </span>
                    </span>
                    <UploadImage
                      id={card.id}
                      className={card.className}
                      header={true}
                      onChange={(evt) =>
                        this.changeImage(
                          evt,
                          'tournamentData',
                          card.param,
                          card.preview
                        )
                      }
                      img={tournamentData[card.preview] || card.src}
                    />
                  </div>
                ))}
              </div>
              <div className={clsx(classes.flex, classes.justifyEnd)}>
                <Button
                  label={t('save')}
                  isSaveBtn={true}
                  onClick={this.onUpdateTourn}
                  className={classes.btn}
                />
              </div>
            </div>
            <Table
              maxColumnWidth={classes.unsetWhiteSpace}
              {...{ condensedTableData }}
              search={searchBar}
              clearSearch={this.clearSearch}
              textChange={this.tableSearch}
              {...{ header }}
              body={filteredTable}
              data={tableBody}
              reportCheckboxes={(item, key) => (
                <>
                  <span>
                    {item.checkboxes[0].id !== 'orientation' &&
                      key === 'settingsRep' &&
                      t('turnOn')}
                    {item.checkboxes.map((checkbox, index) => {
                      if (
                        (key === 'settingsPage' &&
                          checkbox.id === 'orientation') ||
                        (key === 'settingsRep' && checkbox.id !== 'orientation')
                      ) {
                        return (
                          <Fragment key={index}>
                            <CheckboxBtn
                              checked={this.state[item.paramName]?.includes(
                                checkbox.id
                              )}
                              className={classes.checkboxIcon}
                              onChange={() =>
                                this.selectCheckbox(item.paramName, checkbox.id)
                              }
                              label={checkbox.label}
                            />
                          </Fragment>
                        );
                      }
                    })}
                  </span>
                  <div>
                    {key === 'settingsRep' && item.radioButtons && (
                      <>
                        <span>{t('groupBy')}</span>
                        {item.radioButtons.map((button, index) => (
                          <Fragment key={index}>
                            <RadioBtn
                              item={button.id}
                              options={button.id}
                              value={
                                this.state[item.paramNameRadio] === button.id &&
                                this.state[item.paramNameRadio]
                              }
                              onClick={() =>
                                this.selectRadioButton(
                                  item.paramNameRadio,
                                  button.id
                                )
                              }
                              label={button.label}
                            />
                          </Fragment>
                        ))}
                      </>
                    )}
                  </div>
                </>
              )}
              actionIcons={(
                item,
                _,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) => (
                <Tooltip title={item.onClick ? t('download') : t('more')}>
                  <span style={{ height: 'fit-content' }}>
                    {item.onClick ? (
                      !this.state[item.paramName]?.includes('downloadFile') ? (
                        <GetAppIcon
                          className={clsx(className, iconWrapper)}
                          onClick={
                            !this.state[item.paramName]?.includes(
                              'downloadFile'
                            )
                              ? item.onClick
                              : null
                          }
                        />
                      ) : (
                        <Button
                          className={classes.spinnerBtn}
                          label={
                            <CircularProgress
                              size="1rem"
                              classes={{ root: classes.spinner }}
                            />
                          }
                          isSaveBtn={true}
                        />
                      )
                    ) : (
                      <ArrowForwardIcon
                        className={clsx(className, iconWrapper)}
                        onClick={() =>
                          this.goToNewPage(item.href + this.tournamentIdParams)
                        }
                      />
                    )}
                  </span>
                </Tooltip>
              )}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Reports)));
