import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Navigate, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  MinimalNewDesign
} from './layouts';
import { AuthContext } from './AuthContext';
import { default as TournamentCertificateView } from './containers/Tournaments/Attachments/Attachment';
import { default as LoginView } from './containers/Login/Login';
import { default as SignUpView } from './containers/Login/SignUp';
import { default as CouldNoutLoginView } from './containers/Login/CouldNoutLogin';
import { default as InviteView } from './containers/InviteCoaches/InviteCoaches';
import { default as RegisterToTournView } from './containers/RegisterToTourn/RegisterToTourn';
import { default as SettingsView } from './containers/Settings/Settings';
import { default as RegistrarsCoachesView } from './containers/RegistrarsCoaches/RegistrarsCoaches';
import { default as ReportsView } from './containers/Reports/Reports';
import { default as AccountView } from './containers/Account/Account';
import { default as AddCategoryToTournamentView } from './containers/TournamentTatamisAndCategories/TournamentTatamisAndCategories';
import { default as NotFoundView } from './components/NotFound/NotFound';
import { default as SingleTournamentView } from './containers/Tournamnet/Tournament';
import { default as AddCoachView } from './containers/AddCoach/AddCoach';
import { default as ApproveJudgesView } from './containers/ApproveJudges/ApproveJudges';
import { default as Staff } from './containers/Staff/Staff';
import { default as UsersView } from './containers/Users/Users';
import { default as LogsView } from 'containers/Logs/Logs';
import { default as WinnersView } from './containers/Winners/Winners';
import { default as UserListView } from './containers/UsersList/UserList';
import { default as TournamentsView } from './containers/Tournaments/Tournaments';
import { default as CategoriesView } from './containers/Categories/Categories';
import { default as FederationView } from './containers/Federation/Federation';
import { default as PaymentsView } from './containers/Payments/Payments';
import { default as NewPasswordView } from './containers/NewPassword/NewPassword';
import { default as DashboardView } from './containers/Dashboard/Dashboard';
import { default as AddTournamentView } from './containers/AddTournament/AddTournament';
import { default as ActivateAccountView } from './containers/ActivateAccout/ActivateAccout';
import { default as Reference } from 'containers/Reference/Reference';

const MyRoutes = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const context = useContext(AuthContext);
  const { role } = context.authState;

  return (
    <Routes>
      {/* PRIVATE ROUTES */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={!localStorage.getItem('invitation_tournId') && role}
          />
        }>
        <Route
          path="account"
          element={
            <MainLayout pageTitle={t('profile')}>
              <AccountView />
            </MainLayout>
          }
        />
      </Route>
      <Route element={<ProtectedRoute isAllowed={role} />}>
        <Route
          path="/"
          element={
            localStorage.getItem('invitation_tournId') &&
            (role === 'coach' || role === 'master') ? (
              <Navigate replace to="register_to_tournament" />
            ) : role === 'administrator' ||
              role === 'master' ||
              role === 'coach' ||
              role === 'recorder' ? (
              <MainLayout pageTitle=" ">
                <DashboardView />
              </MainLayout>
            ) : role === 'operator' ? (
              <Navigate replace to="more-apps" />
            ) : (
              <Navigate replace to="account" />
            )
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              role &&
              !localStorage.getItem('invitation_tournId') &&
              (role === 'administrator' ||
                role === 'master' ||
                role === 'coach')
            }
          />
        }>
        <Route
          path="/participants"
          element={
            <MainLayout
              pageTitle={
                localStorage.getItem('role') === 'administrator'
                  ? t('athletes')
                  : t('myTeam')
              }>
              <UserListView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN, MASTER, RECORDER */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              role &&
              (role === 'administrator' ||
                role === 'master' ||
                role === 'recorder')
            }
          />
        }>
        <Route
          path="/events/participants"
          element={
            <MainLayout pageTitle={t('participants')}>
              <UserListView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN, MASTER, OPERATOR */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              role &&
              (role === 'administrator' ||
                role === 'master' ||
                role === 'operator')
            }
          />
        }>
        <Route
          path="more-apps"
          element={
            <MainLayout pageTitle={t('moreApps')}>
              <SettingsView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN AND MASTER */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              !localStorage.getItem('invitation_tournId') &&
              role &&
              (role === 'administrator' || role === 'master')
            }
          />
        }>
        <Route
          path="events/invite/:tournamentId"
          element={
            <MainLayout pageTitle={t('invite')}>
              <InviteView />
            </MainLayout>
          }
        />
        <Route
          path="/events/select-attachment/:tournamentId"
          element={
            <MainLayout pageTitle={t('documents')}>
              <TournamentCertificateView />
            </MainLayout>
          }
        />
        <Route
          path="/events/coaches_requests/:tourId"
          element={
            <MainLayout pageTitle={t('approveRegistration')}>
              <AddCoachView />
            </MainLayout>
          }
        />
        <Route
          path="/events/judges_requests/:tourId"
          element={
            <MainLayout pageTitle={t('approveJudges')}>
              <ApproveJudgesView />
            </MainLayout>
          }
        />
        <Route
          path="/events/staff/:tourId"
          element={
            <MainLayout pageTitle={t('judges')}>
              <Staff />
            </MainLayout>
          }
        />
        <Route
          path="/events/edit-event"
          element={
            <MainLayout pageTitle={t('event')}>
              <AddTournamentView />
            </MainLayout>
          }
        />
        <Route
          path="/events/add-event"
          element={
            <MainLayout pageTitle={t('event')}>
              <AddTournamentView />
            </MainLayout>
          }
        />
        <Route
          path="/events"
          element={
            <MainLayout pageTitle={t('events')}>
              <TournamentsView />
            </MainLayout>
          }
        />
        <Route
          path="/events/event_reports"
          element={
            <MainLayout pageTitle={t('reports')}>
              <ReportsView />
            </MainLayout>
          }
        />
        <Route
          path="/events/event_winners"
          element={
            <MainLayout pageTitle={t('winners')}>
              <WinnersView />
            </MainLayout>
          }
        />
        <Route
          path="categories"
          element={
            <MainLayout pageTitle={t('categories')}>
              <CategoriesView />
            </MainLayout>
          }
        />
        <Route
          path="/events/event/:tourId"
          element={
            <MainLayout pageTitle={location.state?.tournament_name}>
              <SingleTournamentView />
            </MainLayout>
          }
        />

        <Route
          path="/events/add-categories-to-event/:tournamentId"
          element={
            <MainLayout pageTitle={[t('tatami'), t('categories')].join('/')}>
              <AddCategoryToTournamentView />
            </MainLayout>
          }
        />
        <Route
          path="users"
          element={
            <MainLayout pageTitle={t('users')}>
              {role === 'master' ? <RegistrarsCoachesView /> : <UsersView />}
            </MainLayout>
          }
        />
      </Route>

      {/* MASTER */}

      <Route element={<ProtectedRoute isAllowed={role && role === 'master'} />}>
        <Route
          path="federation"
          element={
            <MainLayout pageTitle={t('myFederation')}>
              <FederationView />
            </MainLayout>
          }
        />
        <Route
          path="participant/:id/payments/"
          element={
            <MainLayout pageTitle={t('payments')}>
              <PaymentsView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN */}
      <Route
        element={
          <ProtectedRoute isAllowed={role && role === 'administrator'} />
        }>
        <Route
          path="/reference"
          element={
            <MainLayout pageTitle={t('reference')}>
              <Reference />
            </MainLayout>
          }
        />
        <Route
          path="/logs"
          element={
            <MainLayout pageTitle={t('logs')}>
              <LogsView />
            </MainLayout>
          }
        />
      </Route>

      {/* QUICK REGISTRATION */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              localStorage.getItem('invitation_tournId') &&
              (role === 'coach' || role === 'master')
            }
          />
        }>
        <Route
          path="register_to_tournament"
          element={
            <MinimalNewDesign
              showExitBtn
              pageTitle={t('registerToTourn')}
              breadcrumbs={[
                { label: t('home'), href: '/' },
                { label: t('registerToTourn'), href: '/register_to_tournament' }
              ]}>
              <RegisterToTournView />
            </MinimalNewDesign>
          }
        />
      </Route>

      {/* PUBLIC ROUTES*/}
      <Route
        path="*"
        element={
          <MinimalLayout hideNav>
            <NotFoundView />
          </MinimalLayout>
        }
      />
      <Route
        path="/register_to_tournament/:token"
        element={
          localStorage.getItem('signinQuickRegister') ? (
            <MinimalLayout hideNav pageTitle={t('logIn')}>
              <LoginView />
            </MinimalLayout>
          ) : (
            <RegisterToTournView />
          )
        }
      />
      <Route
        path="sign-in"
        element={
          !role ? (
            <MinimalLayout hideNav pageTitle={t('logIn')}>
              <LoginView />
            </MinimalLayout>
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="forgot-password"
        element={
          !role ? (
            <MinimalLayout hideNav pageTitle={t('forgotPassword')}>
              <CouldNoutLoginView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="sign-up"
        element={
          !role ? (
            <MinimalLayout hideNav pageTitle={t('signUp')}>
              <SignUpView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="/reset_password/:token"
        element={
          !role ? (
            <MinimalLayout hideNav>
              <NewPasswordView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="/activate/:userToken"
        element={
          !role ? (
            <MinimalLayout hideNav>
              <ActivateAccountView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
    </Routes>
  );
};

export default MyRoutes;
