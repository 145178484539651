import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ClearIcon from '@material-ui/icons/Clear';

import { selectedValue } from '../../helpers/selectors';
import {
  LABEL,
  PERMISSION_PARAMS,
  CLEAR_ICON_PERMISSION_MODAL,
  ID
} from '../../helpers/constants';

import Filter from '../../components/Filter/Filter';

import { styleFilter, useStyles } from './Styles';

const Permissions = (props) => {
  const {
    selectOption,
    allPermissions,
    tournamentsList,
    fetchTournaments,
    resetPermission,
    errors,
    setPermissionForm
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const permissionsData = [
    {
      name: 'name',
      label: t('parameter'),
      options: PERMISSION_PARAMS,
      item: LABEL,
      action: () =>
        fetchTournaments(null, 1, null, null, null, 1, { current: 1 }),
      arg: LABEL,
      error: Boolean(errors.name),
      helperText: Boolean(errors.name) && errors.name
    },
    {
      name: 'value',
      label: t('value'),
      options: tournamentsList,
      item: 'tournament_name',
      arg: ID,
      error: Boolean(errors.value),
      helperText: Boolean(errors.value) && errors.value
    }
  ];

  return (
    <>
      {Array.isArray(allPermissions) && allPermissions.length > 0 && (
        <div className={classes.permissionWrapper}>
          {allPermissions.map((it, idx) => (
            <span
              key={idx}
              className={clsx(classes.flex, classes.permissionList)}>
              <span className={classes.flex}>
                {idx + 1}. {it.name}: {it.tournament_name}
              </span>
              <span
                className={clsx(classes.flex, classes.left, classes.icon)}
                id={CLEAR_ICON_PERMISSION_MODAL}
                onClick={(evt) => resetPermission(evt, it)}>
                <ClearIcon className={classes.noEvent} />
              </span>
            </span>
          ))}
        </div>
      )}
      {permissionsData.map(
        (it) =>
          it && (
            <Filter
              style={styleFilter}
              key={it.name}
              options={it.options}
              label={it.label}
              value={
                selectedValue(it.options, it.arg, setPermissionForm[it.name]) ||
                ''
              }
              onChange={(evt, val) =>
                selectOption(
                  evt,
                  val,
                  'setPermissionForm',
                  it.name,
                  it.arg,
                  errors,
                  it.action,
                  true
                )
              }
              item={it.item}
              helperText={it.helperText}
              error={it.error}
            />
          )
      )}
    </>
  );
};
export default Permissions;
