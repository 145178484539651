import { Component } from 'react';
import { withRouter } from '../../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import {
  fetchTournaments,
  changeModal,
  textChangeHandler,
  uploadFile,
  fetchUploadedFiles,
  downloadUplaodedFile,
  deleteUploadedFile,
  downloadFile,
  validateForm,
  pageChangeHandler,
  rowsPerPageHandler,
  changeHead,
  fetchReference
} from '../../../helpers/util';
import { changeTitle } from '../../../helpers/actions';
import {
  FILE_DATA,
  DELETE_ICON,
  ENABLED,
  serverDefaultPath,
  KEEP_EDITING,
  CLOSE_DISCARD
} from '../../../helpers/constants';
import {
  finishedTournament,
  resizeUploadedFile
} from '../../../helpers/selectors';

import { Backdrop } from '@material-ui/core';
import SideModal from '../../../components/Snackbar/SideModal';
import Modal from '../../../components/Modal/Modal';
import DropFile from '../../../components/CertificateBuilder/Dropzone/DropFile';
import LoadingState from '../../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../../components/HeaderTournInfo/HeaderTournInfo';
import Pagination from '../../../components/TablePagination/TablePagination';
import Table from '../../../components/Table/Table';
import EmptyState from '../../../components/EmptyState/EmptyState';
import styles from '../../Winners/styles';
import QuestionMark from 'components/QuestionMark/QuestionMark';

class Attachment extends Component {
  state = {
    tournamentId: +this.props.match.params.tournamentId,
    open: {},
    success: false,
    showModal: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    isModalOpened: false,
    fileData: {},
    fileDataErrors: {},
    errors: {},
    tab: 1,
    tournamentData: {},
    filteredFiles: [],
    statistics: {},
    uploadedFiles: [],
    page: 1,
    rowsPerPage: 10,
    filesCount: 0,
    iframeURL: {}
  };
  fetchTournaments = fetchTournaments.bind(this);
  changeModal = changeModal.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  uploadFile = uploadFile.bind(this);
  fetchUploadedFiles = fetchUploadedFiles.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  deleteUploadedFile = deleteUploadedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  validateForm = validateForm.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  changeHead = changeHead.bind(this);
  fetchReference = fetchReference.bind(this);

  componentDidUpdate(prevProps, prevState) {
    const { open, fileData, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (Object.keys(open).length > 0) {
      document.addEventListener('keydown', this.onKeyPress);
    }

    if (isEditing && fileData && fileData !== prevState.fileData) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('attachments'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
  }

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing = finishedTournament(
        this.state.tournamentData
      );

      this.setState({ shouldDisableEditing }, () => {
        this.fetchFiles(tournamentId);
      });

      changeHead(tournamentData, t('attachments'));
    });

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter(
          (item) => item.name === 'select-attachment'
        );
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  tableSearch = (event) => {
    const { value } = event.target;
    const { uploadedFiles } = this.state;

    this.setState(
      {
        searchBar: value,
        page: 1,
        filteredFiles: uploadedFiles.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase().trim())
        )
      },
      () => this.setState({ filesCount: this.state.filteredFiles.length })
    );
  };

  clearSearch = () => {
    const { uploadedFiles } = this.state;
    const len = uploadedFiles?.length;

    this.setState({
      searchBar: '',
      filteredFiles: uploadedFiles,
      filesCount: len
    });
  };

  onKeyPress = (evt) => {
    if (evt.key === 'Escape') this.setState({ open: {} });
  };
  showBackDrop = (key) =>
    this.setState({ open: { [key]: !this.state.open[key] } });
  hideBackDrop = () => this.setState({ open: {} });
  hideSnackBar = () => this.setState({ showModal: false });
  onFilter = (_, value) => {
    const { fileDataErrors } = this.state;
    if (value !== null) {
      this.setState((prevState) => ({
        isEditing: true,
        fileData: {
          ...prevState.fileData,
          tournament_file_type: value.id,
          type: value.type,
          ...(+value.id === 3 ? { allowUser: '2' } : {})
        }
      }));
      delete fileDataErrors.tournament_file_type;
    }
  };

  handleTournDownloadPermission = (evt) => {
    const { fileDataErrors } = this.state;
    const allowUser = evt.target.value;
    this.setState((prevState) => ({
      isEditing: true,
      fileData: {
        ...prevState.fileData,
        allowUser
      }
    }));
    delete fileDataErrors.allowUser;
  };
  showModal = (evt, item) => {
    const id = item && item.id;
    this.setState({ pressedIcon: evt.target.id }, () => {
      this.setState({
        isModalOpened: true,
        ...(this.state.pressedIcon === DELETE_ICON ? { fileId: id } : {}),
        selectedRow: id
      });
      if (item) {
        this.setState((prevState) => ({
          fileData: {
            ...prevState.fileData,
            tournament_file_type: item.tournament_file_type,
            attachment_name: item.name,
            id,
            type: item.type,
            allowUser:
              item.allow_all === ENABLED
                ? ENABLED
                : item.allow_recorder_in === ENABLED
                ? '2'
                : item.allow_coach_in === ENABLED && '3'
          }
        }));
      }
    });
  };
  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        isModalOpened: false,
        selectedRow: null,
        fileData: {},
        fileDataErrors: {},
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };
  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;
    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };
  onDropHandler = (acceptedFiles) => {
    const { fileDataErrors } = this.state;
    delete fileDataErrors.files;

    const setNewFile = (newFile) => {
      this.setState((prevState) => ({
        isEditing: true,
        fileData: {
          ...prevState.fileData,
          files: newFile
        }
      }));
    };

    if (acceptedFiles[0]) {
      if (acceptedFiles[0].type.includes('image/')) {
        resizeUploadedFile(acceptedFiles[0], (newFile) =>
          setNewFile([newFile])
        );
      } else {
        setNewFile(acceptedFiles);
      }
    }
  };
  removeFileHandler = () => {
    this.setState((prevState) => ({
      fileDataErrors: {
        ...prevState.fileDataErrors,
        files: this.props.t('required')
      },
      fileData: {
        ...prevState.fileData,
        files: null
      }
    }));
  };

  fetchFiles = (tournamentId) => {
    this.fetchUploadedFiles(null, tournamentId, null, () => {
      const { uploadedFiles } = this.state;
      const filteredFiles = [...uploadedFiles];
      const filesCount = uploadedFiles.length;
      const groupedByType = uploadedFiles.reduce((acc, currentVal) => {
        const key = currentVal.type;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(currentVal);

        return acc;
      }, {});
      const statistics = {
        totalCount: filesCount,
        documentsCount: groupedByType?.document?.length ?? 0,
        certificatesCount: groupedByType?.certificate?.length ?? 0
      };

      this.setState({ filteredFiles, filesCount, loading: false, statistics });
    });
  };

  addFile = (evt) => {
    const { fileData, tournamentId } = this.state;
    const requiredFields =
      fileData.tournament_file_type === '2'
        ? {
            ...(fileData.id ? {} : { files: '' }),
            tournament_file_type: '',
            allowUser: ''
          }
        : { ...(fileData.id ? {} : { files: '' }), tournament_file_type: '' };
    this.validateForm(evt, requiredFields, fileData, 'fileDataErrors', () => {
      this.uploadFile(evt, fileData, tournamentId, () => {
        this.fetchFiles(tournamentId);
        this.setState({ isAttemptingToEditModalFields: false }, () =>
          this.hideModal()
        );
      });
    });
  };
  deleteFile = (evt) => {
    const { fileId, tournamentId } = this.state;
    this.deleteUploadedFile(evt, fileId, () => {
      this.fetchFiles(tournamentId);
      this.hideModal();
    });
  };
  onFormEdit = (evt) => {
    const { errors } = this.state;
    this.textChangeHandler(
      evt,
      FILE_DATA,
      errors,
      null,
      null,
      null,
      null,
      null,
      () => {
        this.setState({ isEditing: true });
      }
    );
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      loading,
      isModalOpened,
      fileData,
      errors,
      pressedIcon,
      tournamentData,
      fileDataErrors,
      page,
      rowsPerPage,
      filteredFiles,
      statistics,
      selectedRow,
      filesCount,
      shouldDisableEditing,
      searchBar,
      open,
      shouldShowDiscardChanges,
      iframeURL
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;

    const header = [
      { label: t('nameDocument') },
      { label: t('attachmentType') },
      { label: t('uploadDate') }
    ];
    const tableBody = ['name', 'type', 'uploading_date'];
    const condensedTableData = {
      mainName: 'name',
      info: [
        { key: 'type', name: t('attachmentType') },
        { key: 'uploading_date', name: t('uploadDate') }
      ]
    };

    const pageHeaderStatistics = {
      info: [
        {
          label: t('documentsCount'),
          name: statistics?.totalCount
        },
        {
          label: t('regulations'),
          name: statistics?.documentsCount
        },
        { label: t('diplomas'), name: statistics?.certificatesCount }
      ]
    };

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics }}
              shouldShowBtn
            />
            <Modal
              open={isModalOpened}
              close={this.hideModal}
              onClick={(evt) =>
                pressedIcon !== DELETE_ICON
                  ? this.addFile(evt)
                  : this.deleteFile(evt)
              }
              dialogTitle={
                pressedIcon !== DELETE_ICON
                  ? t('importAttachment')
                  : t('deleteRecord', { name: 'attachment' })
              }
              dialogContent={
                pressedIcon === DELETE_ICON && t('deleteDocumenMsg')
              }
              buttonPurpose={
                pressedIcon !== DELETE_ICON
                  ? t('import')
                  : t('deleteRecord', { name: '' })
              }
              {...{ shouldShowDiscardChanges }}
              discardOrKeepEditing={this.discardOrKeepEditing}>
              {pressedIcon !== DELETE_ICON && (
                <DropFile
                  changeTextHandler={this.onFormEdit}
                  onDrop={this.onDropHandler}
                  removeFile={this.removeFileHandler}
                  {...{ errors }}
                  {...{ fileData }}
                  {...{ fileDataErrors }}
                  selectOption={this.handleTournDownloadPermission}
                  onFilter={this.onFilter}
                />
              )}
            </Modal>
            <Table
              {...{ condensedTableData }}
              search={searchBar}
              clearSearch={this.clearSearch}
              textChange={this.tableSearch}
              showButton={!shouldDisableEditing}
              {...{ header }}
              body={filteredFiles.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )}
              openEmptyForm={(evt) => this.showModal(evt)}
              data={tableBody}
              onClick={(evt, item) => this.showModal(evt, item)}
              selected={(item) => selectedRow === item.id}
              shouldAllowEditing={() => !shouldDisableEditing}
              actionIcons={(
                item,
                _,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) => (
                <>
                  {item.tournament_file_type === '3' && (
                    <>
                      <Tooltip arrow title={t('previewTooltip')}>
                        <VisibilityOutlinedIcon
                          className={clsx(className, iconWrapper, marginBottom)}
                          onClick={() => this.showBackDrop(item.id)}
                        />
                      </Tooltip>
                      {open[item.id] && (
                        <Backdrop
                          className={classes.backdrop}
                          onClick={this.hideBackDrop}
                          open={open[item.id]}>
                          <img
                            className={classes.img}
                            src={
                              item.path
                                ? serverDefaultPath + item.path
                                : item.path
                            }
                            alt={item.path}
                          />
                        </Backdrop>
                      )}
                    </>
                  )}
                  {!shouldDisableEditing && (
                    <Tooltip arrow title={t('edit')}>
                      <EditIcon
                        className={clsx(
                          className,
                          iconWrapper,
                          marginLeft,
                          marginBottom
                        )}
                        onClick={(evt) => this.showModal(evt, item)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip arrow title={t('download')}>
                    <GetAppIcon
                      className={clsx(
                        className,
                        iconWrapper,
                        marginLeft,
                        marginBottom
                      )}
                      onClick={() => this.downloadUplaodedFile(item.id)}
                    />
                  </Tooltip>
                  {!shouldDisableEditing && (
                    <Tooltip
                      arrow
                      title={t('deleteRecord', { name: 'attachment' })}>
                      <DeleteIcon
                        id="deleteIcon"
                        className={clsx(className, iconWrapper, marginLeft)}
                        onClick={(evt) => this.showModal(evt, item)}
                      />
                    </Tooltip>
                  )}
                </>
              )}
              paginationComponent={
                filteredFiles.length > 0 && (
                  <Pagination
                    count={filesCount}
                    onPageChange={this.pageChangeHandler}
                    onRowsPerPageChange={this.rowsPerPageHandler}
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              }
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Attachment)));
