import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    tabs: {
        borderBottom: '1px solid #BFBFBF',
    },
    additional: {
        display: 'flex',
    },
    scrollBtn: {
        '& .MuiTabScrollButton-root': {
            width: '15px',
        },
    },
})