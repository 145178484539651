import { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

import {
  isSchool,
  isSmallScreen,
  organizerName,
  DDMMYYYY
} from '../../../helpers/selectors';
import {
  serverDefaultPath,
  DEFAULT_TOURNAMENT,
  EMAIL
} from '../../../helpers/constants';
import { AuthContext } from '../../../AuthContext';

import Header from '../Header/Header';
import Button from '../../Buttons/ActionButtonsP2';
import RegistrationStatus from '../../RegistrationStatus/RegistrationStatus';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as PencilEdit } from 'assets/img/pencil_edit_grey.svg';

import { useStyles } from './StylesBody';

const Body = ({ tournamentData, showBracketInformation, viewportWidth}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const role = context.authState.role;
  const navigate = useNavigate();
  const location = useLocation();
  const isTraining = isSchool(tournamentData);

  const isRegisterJudgesForTheTournament = (tournamentData) =>
    tournamentData && +tournamentData.reg_judge_form === 1;
  const isRegisterJudges = isRegisterJudgesForTheTournament(tournamentData);

  const searchedAddress = [
    tournamentData.country,
    tournamentData.city,
    tournamentData.address
  ].join(', ');

  const smallScreen = viewportWidth <= 776;

  const [visibility, setVisibility] = useState({
    commission: false,
    committee: false,
    statistics: !smallScreen
  });

  useEffect(() => {
    setVisibility(prev => ({ ...prev, statistics: !smallScreen }));
  }, [smallScreen]);
  
  const toggleVisibility = (key) => {
    setVisibility(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const go2QuickRegistration = () => {
    localStorage.setItem('invitation_token', tournamentData?.token);
    localStorage.setItem('invitation_tournId', tournamentData?.id);

    navigate(`/register_to_tournament`, {
      state: { prevUrl: location.pathname }
    });

    // When multiple tabs are open, to refresh all tabs
    localStorage.setItem(
      'navigated_2_register_2_tournament',
      `navigate_${Math.random()}`
    );
  };

  const event = [
    { label: t('eventCode'), name: tournamentData.id },
    {
      label: t('eventType'),
      name:
        +tournamentData?.type === 1
          ? t('local')
          : +tournamentData?.type === 2
          ? t('international')
          : t('trainingSchool')
    },
    { label: t('denomination'), name: tournamentData.tournament_name },
    {
      label: t('organizer'),
      name: [
        organizerName(tournamentData)?.firstN,
        organizerName(tournamentData)?.lastN
      ].join(' ')
    },
    {
      label: `${t('country')}/${t('district')}/${t('city')}`,
      name: [
        tournamentData.country,
        tournamentData.region ? tournamentData.region : '',
        tournamentData.city
      ]
        .filter(Boolean)
        .join(' / ')
    },
    {
      label: t('address'),
      name: <a
      href={`https://www.google.com/maps?q=${encodeURIComponent(
        searchedAddress
        )}&t=m`}>
          {tournamentData.address}
      </a>
    },
    {
      label: t('startDateAndTime'),
      name: [DDMMYYYY(tournamentData.start_date, true), tournamentData.start_time].join(' | ')
    },
    {
      label: t('durationDays'),
      name: tournamentData.duration_days
    }
  ];

  const commission = [
    {
      label: t('address'),
      name: tournamentData.reg_address
    },
    {
      label: t('startDateAndTime'),
      name: [DDMMYYYY(tournamentData.reg_date, true), tournamentData.reg_time].join(' | ')
    },
    {
      label: t('applicationsForRegistrationAcceptedUntil'),
      name: DDMMYYYY(tournamentData.registration_date, true)
    },
    {
      label: t('applicationsForRefereeingAreAcceptedUntil'),
      name: DDMMYYYY(tournamentData.reg_judge_date, true)
    }
  ];

  const committee = [
    {
      label: t('chiefJudge'),
      name: tournamentData.main_judge
    },
    {
      label: t('chiefCompetitionSecretary'),
      name: tournamentData.secretary
    },
    {
      label: t('chairman'),
      name: tournamentData.chairman
    },
    {
      label: t('organizingCommitteeAddress'),
      name: tournamentData.org_address
    },
    {
      label: t('phone'),
      name: tournamentData.org_phone
    },
    {
      label: EMAIL,
      name: tournamentData.org_email
    }
  ];

  const moreAboutEvent = [
    {
      onClick: () => toggleVisibility('commission'),
      title: t('placeAndDateOfTheCommission'),
      currentState: visibility.commission,
      items: commission
    },
    {
      onClick: () => toggleVisibility('committee'),
      title: t('organisingСommittee'),
      currentState: visibility.committee,
      items: committee
    }
  ];

  const moveToEditEvent = (item) =>
    navigate(`/events/edit-event?tournament_id=${item.id}`, {
      state: { prevUrl: location.pathname }
    });

  const statisticsData = [
    { name: t('athleteForm'), param: 'participants_count' },
    { name: t('participantsTotal'), param: 'participation_count' },
    ...(isRegisterJudges
      ? [{ name: t('judgesForm'), param: 'judges_count' }]
      : []),
    { name: t('totalRegistrars'), param: 'coaches_count' },
    ...(!isTraining
      ? [{ name: t('categoriesForm'), param: 'categories_count' }]
      : []),
    { name: t('countriesForm'), param: 'countries_count' },
    ...(!isTraining ? [{ name: t('tatami'), param: 'tatami_count' }] : [])
  ];

  return (
    <>
      <div className={clsx(classes.flex, classes.mobileColumn)}>
        <div className={classes.imgStatistics}>
          <div
            className={clsx(
              classes.imgWrapper
            )}>
            <img
              className={clsx(classes.imgSize, classes.flex)}
              src={
                tournamentData.poster
                  ? serverDefaultPath + tournamentData.poster
                  : DEFAULT_TOURNAMENT
              }
              alt="Poster"
            />
          </div>
          <div
            className={clsx(
              classes.flex,
              classes.marginStatistics,
              classes.column,
              classes.maxHeight,
              !smallScreen && [classes.padding14, classes.paper]
            )}>
              <span 
                className={clsx(
                  classes.title,
                  classes.paper,
                  classes.nowrap,
                  classes.flex,
                  classes.centerVertically,
                  classes.spaceBetween,
                  smallScreen 
                    ? [
                      classes.padding14,
                      visibility.statistics && classes.marginBottom6
                    ]
                    : visibility.statistics && classes.marginBottom14
                )}>
                {t('eventStatistics')}
                {smallScreen && 
                  <ArrowDropDownIcon
                    className={
                      visibility.statistics ? classes.arrowDropUp : undefined
                    }
                    onClick={() => toggleVisibility('statistics')}
                  />
                }
              </span>
              {visibility.statistics && 
                <div className={clsx(classes.paper, classes.flex, classes.column, smallScreen && classes.padding14)}>
                  {statisticsData.map(({ name, param }, idx) => (
                    <span 
                      key={idx} 
                      className={clsx(
                        classes.defaultText, 
                        classes.paragraph, 
                        idx !== statisticsData.length - 1 && classes.marginBottom14
                      )}>
                      {[t('total'), name].join(' ')}:{' '}
                      {tournamentData && tournamentData[param]}
                    </span>
                  ))}
                </div>
              }
          </div>
        </div>
        <div className={clsx(classes.flex, classes.column, classes.fullWidth)}>
          <div
            className={clsx(
              classes.paper,
              classes.padding14,
              classes.marginLeft20,
              classes.minHeight
            )}>
            <span className={clsx(classes.flex, classes.spaceBetween)}>
              <span className={clsx(classes.title, classes.nowrap, classes.paddingBottom11)}>
                {t('venueAndDatesOfTheEvent')}
              </span>
              <div className={classes.nowrap}>
                {role === 'master' &&
                  !+tournamentData?.is_master &&
                  !+tournamentData?.finished && (
                    <Button
                      className={classes.marginRight05}
                      label={t('link2QuickRegistration')}
                      onClick={go2QuickRegistration}
                    />
                  )}
                <Tooltip arrow title={t('edit')}>
                  <PencilEdit 
                    onClick={() => moveToEditEvent(tournamentData)}
                    className={clsx(classes.radiusIc, classes.pointer)} 
                  />
                </Tooltip>
              </div>
            </span>
            {event.map(({ label, name }) => (
              <Fragment key={name}>
                <span
                  key={label}
                  className={clsx(
                    classes.flex,
                    classes.paddingBottom11,
                    classes.defaultText
                  )}>
                  <span className={clsx(classes.defaultText)}>
                    <span className={classes.weight500}>{label}</span>: {name}
                  </span>
                </span>
              </Fragment>
            ))}
            <div className={clsx(classes.flex, classes.centerVertically)}>
              <RegistrationStatus
                {...{ tournamentData }}
                className={clsx(classes.flex)}
              />
            </div>
          </div>
          <div className={clsx(classes.containerBtnsAndInfo, classes.flex, classes.column)}>
            <div className={clsx(classes.marginBottom16, classes.marginTop16, classes.orderSecond)}>
              {(!!+tournamentData?.is_master || role === 'administrator') && (
                <Header {...{ showBracketInformation }} {...{ tournamentData }} />
              )}
            </div>
            {!isTraining &&
              <div className={classes.orderFirst}>
                {moreAboutEvent.map((thisVal, thisValIdx) => (
                  <Fragment key={thisValIdx}>
                    <div
                      className={clsx(
                        classes.flex,
                        classes.marginBottom6,
                        classes.pointer,
                        classes.title,
                        classes.nowrap,
                        classes.paper,
                        classes.spaceBetween,
                        classes.padding14
                      )}
                      onClick={thisVal.onClick}>
                      {thisVal.title}
                      <ArrowDropDownIcon
                        className={
                          thisVal.currentState ? classes.arrowDropUp : undefined
                        }
                      />
                    </div>
                    {thisVal.currentState &&
                      <div 
                        className={clsx(
                          classes.paper, 
                          classes.padding14, 
                          classes.paper, 
                          thisVal.currentState && classes.marginBottom16
                        )}>
                        {thisVal.items.map(({ label, name }, index) => (
                          <Fragment key={index}>
                            <span
                              key={label}
                              className={clsx(
                                classes.flex,
                                classes.nowrap
                              )}>
                                <span 
                                  className={clsx(
                                    classes.defaultText, 
                                    index !== thisVal.items.length - 1 && classes.paddingBottom11
                                  )}>
                                  <span className={classes.weight500}>{label}</span>: {name}
                                </span>
                            </span>
                          </Fragment>
                        ))}
                      </div>
                    }
                  </Fragment>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
};
export default Body;