import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  categoriesPanel: {
    padding: '1rem',
    border: '2px solid rgb(209, 214, 221)',
    minWidth: '7.5em'
  },

  width30: {
    width: '30em'
  },

  btnHover: {
    '&:hover': { cursor: 'pointer', opacity: 0.5, background: '#D0D1D3' }
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  categoryItem: {
    border: '2px solid rgb(77, 77, 77)'
  },

  fullWidth: {
    width: '100%'
  },

  fullHeight: {
    height: '100%'
  },

  rotate270: {
    transform: 'rotate(270deg)'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  rotate90: {
    transform: 'rotate(90deg)'
  },

  dropdownIc: {
    border: '2px solid rgb(77, 77, 77)',
    borderLeft: 'none',
    height: '100%'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  minHeight: {
    minHeight: '15em'
  },

  centerVertically: {
    alignItems: 'center'
  },

  alignLeft: {
    marginLeft: 'auto',
    alignItems: 'center'
  },

  filterBtn: {
    border: '1px solid #B5BAC2',
    width: '10em',
    color: '#546e7a',

    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  fontSize08: {
    fontSize: '0.8rem'
  },

  filterWrapperPanel0: {
    width: '40em'
  },

  filterWrapperPanel1: {
    width: '44em'
  },

  filterSummary: {
    width: 'calc(100% - 10em - 0.5rem)',
    flexWrap: 'wrap'
  },

  activeBtn: {
    background: '#B5BAC2'
  },

  marginRight0: {
    marginRight: 0
  },

  marginRight05: {
    marginRight: '0.5rem'
  },

  marginLeft02: {
    marginLeft: '0.2rem'
  },

  padding0: {
    padding: 0
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginLeft4: {
    marginLeft: '4rem'
  },

  column: {
    flexDirection: 'column'
  },

  padding05: {
    padding: '0.5rem'
  },

  search: {
    marginRight: 0,
    marginLeft: 0
  },

  actionBtn: {
    maxWidth: '4em',
    overflow: 'hidden',
    height: 'calc((100% - 0.5rem)/2)',
    color: '#546e7a',
    marginLeft: '1rem',
    marginRight: '1rem',
    border: '2px solid rgb(209, 214, 221)',
    padding: '0 1rem',
    alignItems: 'center'
  },

  marginLeft2: {
    marginLeft: '2rem'
  }
});
