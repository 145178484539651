import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { serverDefaultPath } from '../../helpers/constants';
import {
  fetchTournamentWinners,
  changeModal,
  fetchTournaments,
  exportWinners,
  downloadFile,
  exportAwardSequenceReport
} from '../../helpers/util';
import { changeTitle } from '../../helpers/actions';

import { Backdrop } from '@material-ui/core';
import LoadingState from '../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import SideModal from '../../components/Snackbar/SideModal';
import Accordion from 'components/Accordion/Accordion';
import Table from 'components/Table/Table';

import styles from './styles';

class Winners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      success: false,
      loading: true,
      collapsed: {},
      open: {},
      winners: [],
      tournamentData: {},
      selectedCheckboxesWinners: [],
      selectedCheckboxesAwardSequenceReport: []
    };
    this.fetchTournamentWinners = fetchTournamentWinners.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.exportWinners = exportWinners.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.exportAwardSequenceReport = exportAwardSequenceReport.bind(this);

    this.urlParams = new URLSearchParams(window.location.search);
    this.myParam = this.urlParams.get('tournament_id');
  }

  componentDidMount() {
    changeTitle(this.props.t('winners'));
    this.fetchTournaments(this.myParam, null, null, () => {
      this.fetchTournamentWinners(this.myParam, () =>
        this.setState({ loading: false })
      );
    });
  }

  selectCheckbox = (paramName, key) => {
    this.setState((prevState) => {
      const selectedCheckboxes = prevState[paramName] ?? [];
      const checkedValues = selectedCheckboxes.includes(key)
        ? selectedCheckboxes.filter((it) => it !== key)
        : [...selectedCheckboxes, key];

      return { [paramName]: checkedValues };
    });
  };

  itemToggle = (key) =>
    this.setState((prevState) => ({
      collapsed: { ...prevState.collapsed, [key]: !this.state.collapsed[key] }
    }));
  showBackDrop = (key) =>
    this.setState({ open: { [key]: !this.state.open[key] } });
  closeBackDrop = () => this.setState({ open: {} });

  hideSnackbar = () => this.setState({ showModal: false });

  render() {
    const {
      success,
      showModal,
      modalInfo,
      winners,
      collapsed,
      open,
      loading,
      tournamentData,
      selectedCheckboxesWinners,
      selectedCheckboxesAwardSequenceReport
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;

    const header = [
      { label: t('name') },
      { label: t('age') },
      { label: t('weight') },
      { label: t('gender') },
      { label: t('coach') },
      { label: t('rank') }
    ];

    const tableBody = [
      'participant_name',
      'participant_age',
      'participant_weight',
      'participant_gender',
      'participant_coach',
      'participant_place'
    ];

    const condensedTableData = {
      mainName: 'participant_name',
      photo: 'participant_photo',
      info: [
        { key: 'participant_age', name: t('age') },
        { key: 'participant_weight', name: t('weight') },
        { key: 'participant_gender', name: t('gender') },
        { key: 'participant_coach', name: t('coach') },
        { key: 'participant_place', name: t('rank') }
      ]
    };

    const blockData = [
      ...(winners?.length > 0
        ? [
            {
              name: [t('listOfWinnersByCategory'), t('turnOn')].join('. '),
              onClick: () => this.exportWinners(this.myParam),
              buttonName: t('download'),
              paramName: 'selectedCheckboxesWinners',
              checkboxes: [
                ...(!+tournamentData?.no_country
                  ? [{ id: 'countries', label: t('countries') }]
                  : []),
                { id: 'regions', label: t('regions') },
                { id: 'cities', label: t('cities') },
                { id: 'club', label: t('club') },
                { id: 'coach', label: t('coach') }
              ]
            }
          ]
        : []),
      {
        name: [t('awardProcedure'), t('turnOn')].join('. '),
        onClick: () => this.exportAwardSequenceReport(this.myParam),
        buttonName: t('download'),
        paramName: 'selectedCheckboxesAwardSequenceReport',
        checkboxes: [{ id: 'orientation', label: t('portraitPageOrientation') }]
      }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <HeaderTournInfo {...{ tournamentData }} shouldShowBtn />
            <Accordion
              itemToggle={this.itemToggle}
              data={winners}
              {...{ collapsed }}
              blockData={blockData}
              selectCheckbox={this.selectCheckbox}
              selectedCheckboxesWinners={selectedCheckboxesWinners}
              selectedCheckboxesAwardSequenceReport={
                selectedCheckboxesAwardSequenceReport
              }
              accordionContent={(finalists) => (
                <Table
                  {...{ condensedTableData }}
                  {...{ header }}
                  body={finalists}
                  data={tableBody}
                  shouldHideTableToolbar
                  actionIcons={(item, _, className, iconWrapper) =>
                    item.participant_certificate && (
                      <>
                        <VisibilityOutlinedIcon
                          className={clsx(className, iconWrapper)}
                          onClick={() => this.showBackDrop(item.participant_id)}
                        />
                        {open[item.participant_id] && (
                          <Backdrop
                            className={classes.backdrop}
                            onClick={this.closeBackDrop}
                            open={open[item.participant_id]}>
                            <img
                              className={classes.img}
                              src={
                                item.participant_certificate
                                  ? serverDefaultPath +
                                    item.participant_certificate
                                  : item.participant_certificate
                              }
                              alt={item.participant_certificate}
                            />
                          </Backdrop>
                        )}
                      </>
                    )
                  }
                />
              )}
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Winners)));
