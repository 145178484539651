import { useState } from 'react';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useStyles } from './Styles';

const CopyTxt = (props) => {
  const { copiedTxt, componentClassName, informativeText } = props;

  const classes = useStyles();
  const [state, setState] = useState(null);
  const onCopyTxt = () => {
    navigator.clipboard.writeText(copiedTxt);
    setState(true);
    setTimeout(() => setState(false), 500);
  };

  return (
    <span
      className={clsx(
        classes.root,
        classes.wordBreak,
        classes.flex,
        componentClassName
      )}>
      <span className={clsx(classes.flex, classes.informativeTxt)}>
        {informativeText}
      </span>
      <span className={clsx(classes.flex, classes.center2Top)}>
        {copiedTxt}
        <FileCopyIcon className={classes.icon} onClick={onCopyTxt} />
        {state && <CheckIcon className={classes.success} />}
      </span>
    </span>
  );
};

export default CopyTxt;
