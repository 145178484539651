import { Component, Fragment } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { ReactComponent as TimerIcon } from '../../assets/img/timer.svg';
import { ReactComponent as CategoriesIc } from '../../assets/img/categories_list.svg';
import { ReactComponent as KaratekaIc } from '../../assets/img/karateka.svg';
import { ReactComponent as InfoIc } from '../../assets/img/info-white.svg';
import { ReactComponent as RestoreIc } from '../../assets/img/restore-white.svg';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import {
  Tooltip,
  Switch,
  FormControlLabel,
  IconButton
} from '@material-ui/core';

import { changeTitle } from 'helpers/actions';
import {
  serverDefaultPath,
  DELETE_ICON,
  ADD_ICON,
  CLEAR_ICON,
  ID,
  CATEGORY_ID,
  NAME,
  NOT_SET,
  TEST_ID_COPY,
  TEST_ID_INFO_BTN,
  FILTERS_CHECKBOXES,
  KEEP_EDITING,
  CLOSE_DISCARD
} from '../../helpers/constants';
import {
  fetchCoaches,
  fetchTournRegistrar,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchTournaments,
  selectTournament,
  selectOption,
  selectCategory,
  setCategory,
  fetchTournamentList,
  fetchParticipants,
  fetchParticipantsByTournamentV2,
  distributeParticipantByCategory,
  clearSearch,
  handleTextChange,
  deleteParticipant,
  fetchCategories,
  changeModal,
  validateForm,
  fetchQdan,
  selectCheckboxSchool,
  setParticipantToSchool,
  changeHead,
  fetchAllCategoryTypes,
  addNewCategory,
  removeCategory,
  selectMultipleCategories,
  selectMultipleCategoryType,
  selectMultipleTeams,
  validateMultipleCategoriesAssignment,
  updateParticipant,
  fetchParticipantsByTournamentCategories,
  applyCategories,
  selectCheckbox,
  downloadFile,
  exportCategories,
  exportParticipants,
  restoreParticipant,
  fetchUsers,
  fetchReference
} from '../../helpers/util';
import {
  compareValueForSorting,
  selectedValue,
  findSortDirection,
  finishedTournament,
  categoryTypesPresentOnTournament,
  onChangeText,
  isSchool
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import AddParticipant from '../AddParticipant/AddParticipant';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import Modal from '../../components/Modal/ModalNewDesign';
import AssignCategories from '../../components/QuickTournRegistration/AssignCategories';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Pagination from '../../components/TablePagination/TablePagination';
import Table from '../../components/Table/Table';
import EmptyState from '../../components/EmptyState/EmptyState';
import Assign2School from '../../components/School/Assign2School';
import Filter from '../../components/Filter/Filter';
import Button from 'components/Buttons/ActionButtons';

import styles from './Styles';
import QuestionMark from 'components/QuestionMark/QuestionMark';

const schoolInfo = {
  training: true,
  nextQdan: false,
  next_qdan_id: ''
};
const initialStateCategory = {
  type: '',
  id: '',
  team_id: '',
  filteredCategories: []
};
const TEST_ID_PARTICIPANT = 'TEST_ID_PARTICIPANT';
const INITIAL_EDITABLE_TABLE_CELL = {
  weight: null,
  values: {},
  errors: {}
};

const participantIndividualData = (item) => ({
  ...item,
  id: item.participant_id,
  first_name: item.participant_first_name,
  last_name: item.participant_last_name,
  patronymic: item.participant_patronymic,
  history: item.history === NOT_SET ? '' : item.history,
  iko: item.iko === NOT_SET ? '' : item.iko,
  club: item.club !== NOT_SET ? item.club : '',
  country_id: item.participant_country_id,
  city: item.participant_city,
  height: item.height !== '-1' ? item.height : '',
  region: item.participant_region
});

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [],
      loading: true,
      langOnLoad: localStorage.getItem('i18nextLng'),
      processing: true,
      searchBar: '',
      success: false,
      showModal: false,
      page: 1,
      rowsPerPage:
        (localStorage.getItem('rowsPerPage') &&
          +localStorage.getItem('rowsPerPage')) ||
        10,
      participantsCount: 0,
      filteredRecordsForView2Count: 0,
      searchedValue: '',
      searchByOption: { id: '0' },
      filteredParticipants: [],
      editableTableCell: INITIAL_EDITABLE_TABLE_CELL,
      shouldUpdateTable: false,
      open: false,
      categoryTypes: [],
      tournamentsList: [],
      allRecordsForView2: [],
      filteredRecordsForView2: [],
      filteredCategories: [],
      isFilterChecked: true,
      categories: [],
      selectedCheckboxes: [],
      tournamentApplications: [],
      collapsed: {},
      copyPasteData: {},
      statistics: {},
      filters: { switchedView: 1 },
      isSearching: false,
      tournamentData: {},
      sortDirection: [],
      selectedCategories: [initialStateCategory],
      selectedCategoriesErrors: [],
      qdanList: [],
      collapsedForView2: [],
      intermidiateSchoolInfo: schoolInfo,
      isInactiveParticipantsChecked: false,
      iframeURL: {}
    };

    this.role = localStorage.getItem('role');
    this.tournamentIdParams = new URLSearchParams(window.location.search).get(
      'tournament_id'
    );

    this.fetchCoaches = fetchCoaches.bind(this);
    this.fetchTournRegistrar = fetchTournRegistrar.bind(this);
    this.pageChangeHandler = pageChangeHandler.bind(this);
    this.rowsPerPageHandler = rowsPerPageHandler.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.selectTournament = selectTournament.bind(this);
    this.selectOption = selectOption.bind(this);
    this.selectCategory = selectCategory.bind(this);
    this.setCategory = setCategory.bind(this);
    this.fetchTournamentList = fetchTournamentList.bind(this);
    this.fetchParticipantsByTournamentV2 =
      fetchParticipantsByTournamentV2.bind(this);
    this.fetchParticipants = fetchParticipants.bind(this);
    this.clearSearch = clearSearch.bind(this);
    this.handleTextChange = handleTextChange.bind(this);
    this.deleteParticipant = deleteParticipant.bind(this);
    this.fetchCategories = fetchCategories.bind(this);
    this.changeModal = changeModal.bind(this);
    this.validateForm = validateForm.bind(this);
    this.fetchQdan = fetchQdan.bind(this);
    this.selectCheckboxSchool = selectCheckboxSchool.bind(this);
    this.setParticipantToSchool = setParticipantToSchool.bind(this);
    this.changeHead = changeHead.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.distributeParticipantByCategory =
      distributeParticipantByCategory.bind(this);
    this.addNewCategory = addNewCategory.bind(this);
    this.selectMultipleCategories = selectMultipleCategories.bind(this);
    this.selectMultipleCategoryType = selectMultipleCategoryType.bind(this);
    this.fetchParticipantsByTournamentCategories =
      fetchParticipantsByTournamentCategories.bind(this);
    this.selectMultipleTeams = selectMultipleTeams.bind(this);
    this.removeCategory = removeCategory.bind(this);
    this.validateMultipleCategoriesAssignment =
      validateMultipleCategoriesAssignment.bind(this);
    this.updateParticipant = updateParticipant.bind(this);
    this.applyCategories = applyCategories.bind(this);
    this.selectCheckbox = selectCheckbox.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.exportCategories = exportCategories.bind(this);
    this.exportParticipants = exportParticipants.bind(this);
    this.restoreParticipant = restoreParticipant.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    this.tournamentIdParams = new URLSearchParams(
      this.props.location.search
    ).get('tournament_id');

    const {
      shouldUpdateTable,
      page,
      rowsPerPage,
      searchBar,
      isEditing,
      langOnLoad,
      isFilterChecked,
      selectedCategories,
      collapsedForView2,
      filters,
      open,
      intermidiateSchoolInfo
    } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(page, rowsPerPage, searchBar, () => {
        if (filters?.switchedView === 2) {
          const { allRecordsForView2 } = this.state;
          const copyCollapsed = [...collapsedForView2];

          this.onFilterBySelection();

          if (filters?.currentParentID) {
            const currentCollapsedIdx = copyCollapsed.findIndex(
              (it) => +it.id === +filters.currentParentID
            );

            if (currentCollapsedIdx !== -1) {
              //after saving the changes, update the collapsible element
              const findRecord = allRecordsForView2.find(
                (it) => +it.id === +filters.currentParentID
              );

              copyCollapsed[currentCollapsedIdx].participants =
                findRecord?.participants;

              this.setState({ collapsedForView2: copyCollapsed });
            }
          } else {
            this.onCollapseForView2();
          }
        } else {
          const { filteredParticipants, modalData } = this.state;
          const findElemIdx = filteredParticipants?.findIndex(
            (it) =>
              +it?.[this.tournamentIdParams ? 'participant_id' : 'id'] ===
              +modalData?.id
          );

          if (findElemIdx === -1) {
            this.hideModal();
          }
        }
      });
    } else if (this.props.location !== prevProps.location) {
      this.searchHandler(() => {
        if (open) this.hideModal();

        if (!this.tournamentIdParams) {
          if (this.role !== 'coach') {
            this.fetchCoaches();
          }
        } else {
          this.fetchTournRegistrar(this.tournamentIdParams, 1, (data) => {
            this.setState({ coachesList: data });
          });
        }
      });
      this.onFetchCategoriesTypes((types) =>
        this.setState({ categoryTypes: types })
      );
      this.setState((prevState) => ({
        filters: { ...prevState.filters, switchedView: null },
        tournamentData: {},
        shouldDisableEditing: false
      }));

      if (this.tournamentIdParams) {
        this.fetchTournaments(this.tournamentIdParams, null, null, () => {
          const { tournamentData } = this.state;
          const shouldDisableEditing = finishedTournament(tournamentData);

          this.setState({ shouldDisableEditing });

          changeHead(tournamentData, t('participants'));
        });
      } else {
        changeHead(null, t('myTeam'));
      }
    } else if (searchBar !== prevState.searchBar) {
      this.setState({ isSearching: true });
    }

    if (langOnLoad !== currentLang) {
      if (this.tournamentIdParams) {
        changeHead(this.state.tournamentData, t('participants'));
      } else {
        changeHead(null, t('myTeam'));
      }

      this.setState({ langOnLoad: currentLang });
    }

    if (
      isEditing &&
      ((selectedCategories &&
        selectedCategories !== prevState.selectedCategories) ||
        isFilterChecked !== prevState.isFilterChecked ||
        (intermidiateSchoolInfo &&
          intermidiateSchoolInfo !== prevState.intermidiateSchoolInfo))
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }
  }

  componentDidMount() {
    const { t } = this.props;
    const { page, rowsPerPage, langOnLoad } = this.state;
    const { role } = this.context.authState;

    this.fetchQdan();

    changeTitle(t('myTeam'));

    if (this.tournamentIdParams) {
      this.fetchTournaments(this.tournamentIdParams, null, null, () => {
        const { tournamentData } = this.state;
        const shouldDisableEditing = finishedTournament(tournamentData);

        this.setState({ shouldDisableEditing });

        changeHead(tournamentData, t('participants'));

        this.onFetchCategoriesTypes((types) =>
          this.setState({ categoryTypes: types })
        );

        this.fetchData(page, rowsPerPage, '', () => {
          if (role !== 'recorder') {
            this.fetchParticipantsByTournamentCategories(
              this.tournamentIdParams,
              {},
              (data) => {
                this.setState((prevState) => ({
                  filters: {
                    ...prevState.filters,
                    filteredCategories: data,
                    categories: data
                  }
                }));
              }
            );
          }

          this.fetchTournRegistrar(this.tournamentIdParams, 1, (data) => {
            this.setState({ coachesList: data });
          });
        });
      });
    } else {
      this.fetchData(page, rowsPerPage, '', () => {
        // Retrieve details from coachesList, which is utilized to fill in the form with coach information.
        // Different endpoints are employed depending on the role used to log in.
        if (role !== 'coach') {
          this.fetchCoaches();
        } else {
          this.fetchUsers(null, null, null, null, (data) => {
            this.setState({ coachesList: data });
          });
        }
      });
    }

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter(
          (item) => item.name === 'participants'
        );
        this.setState({ iframeURL: filteredData[0] });
      },
      null,
      langOnLoad
    );
  }

  onFetchCategoriesTypes = (cb) => {
    this.fetchAllCategoryTypes((allSystemTypes) => {
      const types = categoryTypesPresentOnTournament(
        allSystemTypes || [],
        this.state.tournamentData?.category_types
      );

      cb && cb(types, allSystemTypes);
    });
  };

  fetchData = (page, rowsPerPage, search, cb) => {
    const { searchByOption, filters, sortDirection, tournamentData } =
      this.state;

    this.tournamentIdParams = new URLSearchParams(
      this.props.location.search
    ).get('tournament_id');

    if (filters.switchedView === 2) {
      this.fetchParticipantsByTournamentCategories(
        this.tournamentIdParams,
        { search },
        (categories) => {
          const count = categories?.length ?? 0;
          const statistics = {
            totalCount: count,
            athleteCount: tournamentData?.participants_count ?? 0
          };

          this.setState(
            {
              statistics,
              loading: false,
              shouldUpdateTable: false,
              allRecordsForView2: categories,
              filteredRecordsForView2: categories,
              filteredRecordsForView2Count: count
            },
            () => cb && cb()
          );
        },
        searchByOption
      );
    } else {
      const { coachesList } = this.state;
      const findCurrentUser = coachesList?.find(
        (it) => +it.id === +localStorage.getItem('user_id')
      );

      const data = (participants) => {
        const statistics = {
          athleteCount: tournamentData.participants_count,
          participantionCount: tournamentData?.participation_count,
          registeredParticipation: findCurrentUser?.registered_count ?? 0,
          notRegistered:
            findCurrentUser?.registered_count &&
            tournamentData?.participation_count
              ? tournamentData.participation_count -
                findCurrentUser.registered_count
              : 0
        };

        this.setState(
          {
            statistics,
            loading: false,
            isSearching: false,
            shouldUpdateTable: false,
            participants: participants.data,
            participantsCount: participants.count > 0 ? participants.count : 0,
            filteredParticipants: participants.data,
            collapsed: {}
          },
          () => cb && cb()
        );
      };

      if (!this.tournamentIdParams) {
        this.fetchParticipants(
          page,
          rowsPerPage,
          search,
          null,
          data,
          null,
          sortDirection?.[0],
          sortDirection?.[2],
          null,
          null,
          searchByOption,
          filters
        );
      } else {
        this.fetchParticipantsByTournamentV2(
          page,
          rowsPerPage,
          search,
          null,
          this.tournamentIdParams,
          this.state.isInactiveParticipantsChecked,
          data,
          filters.filteredByCategoryId,
          sortDirection?.[0],
          sortDirection?.[2],
          searchByOption,
          filters
        );
      }
    }
  };

  triggerTableUpdate = (shouldLeaveModalOpen) => {
    const { open } = this.state;

    this.setState({ shouldUpdateTable: true });

    if (!shouldLeaveModalOpen && open) {
      this.hideModal();
    }
  };

  searchHandler = (cb) => {
    const { filters, searchBar, rowsPerPage } = this.state;

    this.setState({ searchedValue: searchBar, page: 1 }, () => {
      this.fetchData(this.state.page, rowsPerPage, searchBar, () => {
        cb && cb();
        if (filters.switchedView === 2) {
          this.onCollapseForView2();
          this.onFilterBySelection();
        }
      });
    });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  showModalParticipant = (evt, item, idx, parent, parentIdx) => {
    evt && evt.preventDefault();

    const { tournamentData, filters } = this.state;
    const isTraining = isSchool(tournamentData);
    const id = item?.participant_id ?? item?.id;
    let personalAssignedCategories = [];

    if (!isTraining && item && Array.isArray(item.categories_info)) {
      personalAssignedCategories = item.categories_info.map((cat) => ({
        type: cat.category_type_id,
        id: cat.category_id,
        team_id: cat.team_id,
        name: cat.category_name,
        issue: cat.issues
      }));
    }

    this.setState((prevState) => ({
      pressedIcon: TEST_ID_PARTICIPANT,
      open: true,
      ...(filters.switchedView === 2 && parent?.id
        ? { filters: { ...prevState.filters, currentParentID: parent.id } }
        : {}),
      participantId: id,
      ...(!isTraining ? { personalAssignedCategories } : {}),
      selectedRow: id,
      ...(id
        ? {
            modalData: {
              ...item,
              id,
              currentRecordIdx: idx,
              currentRecordParentIdxForV2: parentIdx,
              ...(this.tournamentIdParams
                ? {
                    selectedTournamentInfo: tournamentData,
                    patronymic_required: true
                  }
                : {}),
              birthdayInputDate: item.birthday,
              ...(item.photo
                ? { imagePreview: serverDefaultPath + item.photo }
                : {}),
              ...(item.participant_first_name
                ? { first_name: item.participant_first_name }
                : {}),
              ...(item.participant_last_name
                ? { last_name: item.participant_last_name }
                : {}),
              ...(isTraining
                ? {
                    next_qdan_id: item.next_qdan_id,
                    training: Boolean(item.training),
                    nextQdan: Boolean(item.next_qdan_id)
                  }
                : {}),
              ...(this.tournamentIdParams
                ? { patronymic: item?.participant_patronymic ?? '' }
                : {}),
              history: item.history === NOT_SET ? '' : item.history,
              iko: item.iko === NOT_SET ? '' : item.iko,
              club: item.club !== NOT_SET ? item.club : '',
              country_id: item.participant_country_id,
              city: item.participant_city,
              height: item.height !== '-1' ? item.height : '',
              region: item.participant_region
            }
          }
        : {
            modalData: {
              ...(isTraining ? schoolInfo : {}),
              ...(this.tournamentIdParams
                ? {
                    selectedTournamentInfo: tournamentData,
                    patronymic_required: true
                  }
                : {})
            }
          })
    }));
  };

  showModal = (event, rowData, tournamentId, catId) => {
    const { tournamentData } = this.state;

    const isTraining = isSchool(tournamentData);
    const partID = rowData?.id ?? rowData?.participant_id;
    const iconID = event.target.id;

    this.setState(
      (prevState) => ({
        pressedIcon: iconID,
        ...(rowData.currentParentID
          ? {
              filters: {
                ...prevState.filters,
                currentParentID: rowData.currentParentID
              }
            }
          : {}),
        modalData: {
          ...rowData,
          id: partID,
          ...(rowData.participant_first_name
            ? { first_name: rowData.participant_first_name }
            : {}),
          ...(rowData.participant_last_name
            ? { last_name: rowData.participant_last_name }
            : {}),
          ...(this.tournamentIdParams
            ? { patronymic: rowData.participant_patronymic }
            : {}),
          history: rowData.history === NOT_SET ? '' : rowData.history,
          iko: rowData.iko === NOT_SET ? '' : rowData.iko,
          club: rowData.club !== NOT_SET ? rowData.club : '',
          country_id: rowData.participant_country_id,
          city: rowData.participant_city,
          height: rowData.height !== '-1' ? rowData.height : '',
          region: rowData.participant_region,
          ...(iconID === CLEAR_ICON
            ? {
                current_category_id: catId,
                current_tournament_id: tournamentId
              }
            : {})
        }
      }),
      () => {
        const isAddIconSelected = this.state.pressedIcon === ADD_ICON;
        const partID = rowData.id || rowData.participant_id;

        if (isAddIconSelected && !this.tournamentIdParams) {
          this.fetchTournaments(
            null,
            this.role !== 'administrator' && 1,
            '',
            null,
            true
          );
        }

        if (!isTraining && isAddIconSelected) {
          const { tournamentData } = this.state;
          const { category_types } = tournamentData;
          const onFilterCategoriesList = (allCategoriesTypes) => {
            this.onCategoriesFiltering(tournamentId, () => {
              const { filteredCategories } = this.state;

              const personalAssignedCategories = rowData?.categories_info?.map(
                (cat) => ({
                  type: cat.category_type_id,
                  id: cat.category_id,
                  team_id: cat.team_id,
                  name: cat.category_name,
                  issue: cat.issues
                })
              );

              const selectedCategories = personalAssignedCategories?.map(
                (it) => {
                  return {
                    ...it,
                    filteredCategories:
                      Array.isArray(filteredCategories) &&
                      (!it.type
                        ? filteredCategories
                        : filteredCategories.filter(
                            (cat) => `${cat.category_type_id}` === `${it.type}`
                          ))
                  };
                }
              );

              this.setState({
                ...(allCategoriesTypes ? allCategoriesTypes : {}),
                open: true,
                categoriesFitsParticipant: filteredCategories,
                personalAssignedCategories,
                selectedCategories:
                  selectedCategories?.length > 0
                    ? selectedCategories
                    : [{ ...initialStateCategory, filteredCategories }]
              });
            });
          };

          if (this.tournamentIdParams) {
            onFilterCategoriesList();
          } else {
            this.onFetchCategoriesTypes((types, allSystemTypes) =>
              onFilterCategoriesList({ types, allSystemTypes })
            );
          }
        }

        this.setState({
          participantId: partID,

          ...(isTraining
            ? {
                intermidiateSchoolInfo:
                  this.state.pressedIcon !== DELETE_ICON
                    ? {
                        training: Boolean(rowData.training),
                        next_qdan_id: rowData.next_qdan_id,
                        nextQdan: Boolean(rowData.next_qdan_id)
                      }
                    : { ...schoolInfo }
              }
            : {}),
          ...(!isAddIconSelected && !isTraining ? { open: true } : {})
        });
      }
    );
  };

  hideModal = () => {
    const { categories } = this.state;

    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        isFilterChecked: true,
        tournamentWeight: null,
        filteredCategories: categories,
        pressedIcon: null,
        shouldShowDiscardChanges: false,
        isEditing: false,
        selectedRow: null,
        selectedCategoriesErrors: [],
        ...(!this.tournamentIdParams ? { tournamentData: {} } : {})
      });
    }
  };

  discardOrKeepEditing = (evt) => {
    const pressedBtn = evt.target.id;

    if (pressedBtn === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (pressedBtn === CLOSE_DISCARD) {
      // clear the fields to be able to close the modal window
      this.setState({ isAttemptingToEditModalFields: false }, () => {
        this.hideModal();
      });
    }
  };

  itemToggle = (id) => {
    const { collapsed } = this.state;

    this.setState(
      {
        participantId: id,
        collapsed: {
          [id]: !collapsed[id]
        },
        pressedIcon: null,
        processing: true
      },
      () =>
        !this.tournamentIdParams &&
        this.fetchTournamentList(
          this.state.participantId,
          this.tournamentIdParams
        )
    );
  };

  onCategoriesFiltering = (tournId, cb) => {
    const { modalData } = this.state;

    this.fetchCategories(
      (list) => this.setState({ filteredCategories: list }, () => cb && cb()),
      tournId,
      null,
      null,
      null,
      this.state.isFilterChecked,
      { ...modalData }
    );
  };

  onSort = (sortField, idxCell) => {
    const { rowsPerPage, searchedValue, sortDirection } = this.state;
    const direction = findSortDirection(sortDirection, sortField);

    this.setState({ sortDirection: [direction, idxCell, sortField] }, () => {
      this.fetchData(1, rowsPerPage, searchedValue);
    });
  };

  onPageChange = (e, next) => {
    const { isSearching, rowsPerPage, searchBar } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.searchHandler();
      } else {
        this.fetchData(this.state.page, rowsPerPage, searchBar);
      }
    });
  };

  switchTournament = (evt, value) => {
    const isTraining = isSchool(value);

    if (value !== null) {
      this.setState({ tournamentData: value }, () => {
        const { tournamentData, allSystemTypes, modalData } = this.state;
        const categoryTypes = categoryTypesPresentOnTournament(
          allSystemTypes || [],
          tournamentData?.category_types
        );

        this.onCategoriesFiltering(tournamentData?.id, () => {
          const { filteredCategories } = this.state;
          let newSelectedCategories = [initialStateCategory];

          this.fetchTournamentList(
            modalData?.id,
            tournamentData?.id,
            (data) => {
              const tournamentCategories = data?.tournaments?.[0]?.categories;
              const assignedCategories = tournamentCategories?.map((cat) => ({
                type: cat.category_type_id,
                id: cat.category_id,
                team_id: cat.team_id,
                name: cat.category_name,
                issue: cat.issues
              }));

              if (assignedCategories?.length > 0) {
                newSelectedCategories = assignedCategories?.map((it) => {
                  return {
                    ...it,
                    filteredCategories:
                      Array.isArray(filteredCategories) &&
                      (!it.type
                        ? filteredCategories
                        : filteredCategories.filter(
                            (cat) => +cat?.category_type_id === +it?.type
                          ))
                  };
                });
              }

              this.setState({ selectedCategories: newSelectedCategories });
            }
          );
        });

        this.setState({ categoryTypes, isEditing: true });

        if (isTraining) {
          this.fetchQdan();
        }
      });
    }
  };

  saveParticipant = (evt) => {
    const { modalData, tournamentData } = this.state;

    this.applyCategories(modalData, (personalAssignedCategories) => {
      const assignCategories = personalAssignedCategories?.map((key) => {
        return {
          id: key.id,
          ...(key.team_id ? { team_id: key.team_id } : {})
        };
      });

      this.updateParticipant(
        evt,
        modalData,
        () => {
          this.triggerTableUpdate(true);
        },
        tournamentData,
        assignCategories
      );
    });
  };

  recorderToggleRegisteredStatus = (evt, item, parent) => {
    const { role } = this.context.authState;
    const { tournamentData } = this.state;
    const { checked } = evt.target;
    const form = {
      id: item.participant_id,
      registered: checked ? 1 : 0
    };

    this.updateParticipant(
      evt,
      form,
      () => {
        this.triggerTableUpdate();
      },
      tournamentData,
      null,
      null,
      role,
      true
    );
  };

  setParticipantToTraining = (evt) => {
    const { intermidiateSchoolInfo, tournamentData, participantId } =
      this.state;

    this.setParticipantToSchool(
      evt,
      intermidiateSchoolInfo,
      tournamentData,
      participantId,
      () => {
        //when the update form is opened and we use the icon on the right to assign the same participant, update the form with last changes
        this.triggerTableUpdate();
      }
    );
  };

  selectInactiveParticipants = (evt) => {
    const { checked } = evt.target;

    this.setState({ page: 1, isInactiveParticipantsChecked: checked }, () => {
      const { page, rowsPerPage, searchBar } = this.state;

      this.fetchData(page, rowsPerPage, searchBar);
    });
  };

  selectArchive = (evt) => {
    const { checked } = evt.target;

    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, archive: checked }
      }),
      () => {
        const { page, rowsPerPage, searchBar } = this.state;

        this.fetchData(page, rowsPerPage, searchBar);
      }
    );
  };

  selectIssues = (evt) => {
    const { checked } = evt.target;

    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, issues: checked }
      }),
      () => {
        const { page, rowsPerPage, searchBar } = this.state;

        this.fetchData(page, rowsPerPage, searchBar);
      }
    );
  };

  selectIssuesForView2 = (evt) => {
    const { checked } = evt.target;

    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, category_issue: checked }
      }),
      () => {
        this.onFilterBySelection();
      }
    );
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsedForView2, allRecordsForView2 } = this.state;
    const allCollapsedTables = [...collapsedForView2];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => +it.id === +item.id
      );

      if (findIndex === -1) {
        let record = allRecordsForView2.find((it) => +it.id === +item.id);

        this.setState((prevState) => ({
          collapsedForView2: [
            ...prevState.collapsedForView2,
            {
              id: item.id,
              participants: record?.participants,
              sortDirection: []
            }
          ]
        }));
      } else {
        allCollapsedTables.splice(findIndex, 1);

        this.setState({ collapsedForView2: allCollapsedTables });
      }
    }
  };

  onSwitchView = () => {
    const { page, rowsPerPage, searchBar } = this.state;

    this.setState(
      (prevState) => ({
        loading: true,
        page: 1,
        filters: {
          ...prevState.filters,
          collapseAll: this.state.filters.switchedView === 1,
          switchedView: this.state.filters.switchedView === 2 ? 1 : 2
        }
      }),
      () => {
        this.fetchData(page, rowsPerPage, searchBar, () => {
          const { filters } = this.state;

          if (filters.switchedView === 2) {
            this.onCollapseForView2();
            this.onFilterBySelection();
          }
        });
      }
    );
  };

  onCollapseForView2 = () => {
    const { filters, allRecordsForView2 } = this.state;

    if (filters.switchedView === 2) {
      const len = allRecordsForView2?.length;
      let newCollapsed = [];

      for (let i = 0; i < len; i++) {
        const record = allRecordsForView2[i];
        newCollapsed = [
          ...newCollapsed,
          {
            id: record.id,
            participants: record?.participants,
            sortDirection: []
          }
        ];
      }

      this.setState({ collapsedForView2: newCollapsed });
    }
  };

  onSwitchToggleForView2 = (evt, checked) => {
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, collapseAll: checked }
      }),
      () => {
        if (checked) {
          this.onCollapseForView2();
        } else {
          this.setState({ collapsedForView2: [] });
        }
      }
    );
  };

  onSortCollapsibleTable = (id, sortField, idxCell) => {
    const { allRecordsForView2, collapsedForView2, tournamentData } =
      this.state;
    const cloneRecords = [...allRecordsForView2];
    const cloneCollapsed = [...collapsedForView2];
    const findIdxInArray = cloneCollapsed.findIndex((it) => +it.id === +id);

    let field;

    switch (sortField) {
      case 'name':
        field = !+tournamentData?.last_name_first
          ? 'participant_first_name'
          : 'participant_last_name';
        break;
      case 'age':
        field = 'age';
        break;
      case 'gender':
        field = 'gender';
        break;
      case 'weight':
        field = 'weight';
        break;
    }

    const direction = findSortDirection(
      cloneCollapsed[findIdxInArray].sortDirection,
      sortField
    );
    const sortedData = compareValueForSorting(
      cloneCollapsed[findIdxInArray].participants,
      field,
      direction
    );

    const findRecordIdx = cloneRecords.findIndex((it) => +it.id === +id);

    cloneRecords[findRecordIdx].participants = sortedData;
    cloneCollapsed[findIdxInArray].sortDirection = [
      direction,
      idxCell,
      sortField
    ];
    cloneCollapsed[findIdxInArray].filteredParticipants = sortedData;

    this.setState({
      collapsed: cloneCollapsed,
      allRecordsForView2: cloneRecords
    });
  };

  onClickEditRecordForView2 = (item, action) => {
    this.fetchParticipantsByTournamentV2(
      null,
      null,
      null,
      null,
      this.tournamentIdParams,
      null,
      (response) => {
        action && action(response);
      },
      null,
      null,
      null,
      null,
      { participant_id: item.participant_id }
    );
  };

  onClickPasteIndicatorHeader = (item, actionBtnClicked) => {
    const { copyPasteData } = this.state;
    const clone = { ...copyPasteData };

    clone.paste = { id: item.id, type: item.type };

    this.setState({ actionBtnClicked, copyPasteData: clone }, () => {
      this.onSaveCopyPasteForView2();
    });
  };

  onClickCopyIndicatorBody = (evt, item, parent) => {
    const clone = {
      ...this.state.copyPasteData
    };

    if (!clone.copy) {
      clone.copy = [
        {
          category_id: parent.id,
          participants: [
            {
              participant_id: item.id,
              ...(item.team_id ? { team_id: `${item.team_id}` } : {})
            }
          ]
        }
      ];
    } else {
      const findCategoryIdx = clone.copy.findIndex(
        (it) => +it.category_id === +parent.id
      );

      if (findCategoryIdx !== -1) {
        const findPartIdx = clone.copy[findCategoryIdx].participants.findIndex(
          (it) => +it.participant_id === +item.id
        );

        if (findPartIdx !== -1) {
          clone.copy[findCategoryIdx].participants.splice(findPartIdx, 1);
        } else {
          clone.copy[findCategoryIdx].participants = [
            ...clone.copy[findCategoryIdx].participants,
            {
              participant_id: item.id,
              ...(item.team_id ? { team_id: `${item.team_id}` } : {})
            }
          ];
        }
      } else {
        clone.copy = [
          ...clone.copy,
          {
            category_id: parent.id,
            participants: [
              {
                participant_id: item.id,
                ...(item.team_id ? { team_id: `${item.team_id}` } : {})
              }
            ]
          }
        ];
      }
    }

    this.setState({ copyPasteData: clone });
  };

  onSaveCopyPasteForView2 = () => {
    const { copyPasteData, tournamentData, actionBtnClicked } = this.state;
    const { t } = this.props;

    if (copyPasteData.paste && copyPasteData.copy) {
      Promise.all([
        ...copyPasteData.copy.map((category) => {
          if (+copyPasteData?.paste?.type !== 3) {
            const participantsIds = category.participants.map(
              (it) => it.participant_id
            );

            if (participantsIds?.length > 0) {
              return new Promise((resolve) => {
                this.distributeParticipantByCategory(
                  tournamentData.id,
                  participantsIds,
                  copyPasteData.paste?.id,
                  copyPasteData.paste?.type,
                  actionBtnClicked !== TEST_ID_COPY && category.category_id,
                  (result) => {
                    resolve(result);
                  }
                );
              });
            }
          } else {
            return new Promise((resolve) => {
              Promise.all(
                category.participants.map((val) => {
                  return new Promise((resolve) =>
                    this.distributeParticipantByCategory(
                      tournamentData.id,
                      val,
                      copyPasteData.paste?.id,
                      copyPasteData.paste?.type,
                      actionBtnClicked !== TEST_ID_COPY && category.category_id,
                      (result) => resolve(result)
                    )
                  );
                })
              ).then((res) => {
                return resolve(res);
              });
            });
          }
        })
      ]).then(() => {
        this.triggerTableUpdate();
      });

      this.setState({ copyPasteData: {} });
    } else {
      this.changeModal(t('distributeParticipantsByCategoriesError'), 400);
    }
  };

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues, page: 1 }, () => {
      const { page, rowsPerPage, searchBar, filters } = this.state;

      if (filters.switchedView === 2) {
        this.onFilterBySelection(null, true);
      } else {
        this.onFilterBySelection(() => {
          this.fetchData(page, rowsPerPage, searchBar);
        }, true);
      }
    });
  };

  onEditableTableCell = (
    evt,
    item,
    key,
    toggleEditableField,
    activateEditableField
  ) => {
    const { role } = this.context.authState;
    const { editableTableCell, tournamentData } = this.state;
    let cpy = { ...editableTableCell };

    if (toggleEditableField) {
      if (activateEditableField) {
        cpy = {
          ...cpy,
          [key]: item.participant_id,
          values: {
            ...cpy.values,
            [key]: item[key]
          }
        };
      } else {
        if (Object.values(cpy.errors).length === 0) {
          this.updateParticipant(
            evt,
            {
              ...participantIndividualData(item),
              [key]: cpy.values[key]
            },
            () => {
              this.triggerTableUpdate();
            },
            tournamentData,
            null,
            null,
            role
          );
        }

        cpy = { ...INITIAL_EDITABLE_TABLE_CELL };
      }
    } else {
      const result = onChangeText(evt, cpy.values, cpy.errors);
      cpy.values[key] = result[key];
      cpy.errors = result.errors;
    }

    this.setState({ editableTableCell: cpy });
  };

  onTableCellCheckbox = (evt, item, key) => {
    const { checked } = evt.target;
    const { tournamentData } = this.state;
    let cpy = {
      ...participantIndividualData(item),
      [key]: checked ? 1 : 0
    };

    this.updateParticipant(
      evt,
      cpy,
      () => {
        this.triggerTableUpdate();
      },
      tournamentData,
      null,
      null,
      this.role,
      true
    );
  };

  onFilterBySelection = (cb, shouldFilterCategoriesList) => {
    const { allRecordsForView2, selectedCheckboxes, filters } = this.state;
    let newData = [];
    let genderArray = [];
    let ageArray = [];
    let ageArrayForView2 = [];
    let typeArray = [];
    let finalCategories = [];

    selectedCheckboxes?.map((it) => {
      if (it.gender) genderArray = [...genderArray, it.gender];
      if (it.age) {
        ageArray = [...ageArray, it.ageInterval];
        ageArrayForView2 = [...ageArrayForView2, it.age];
      }
      if (it.type) typeArray = [...typeArray, it.id];

      return true;
    });

    if (shouldFilterCategoriesList) {
      //show only the categories that match the table filter
      const { categories } = filters;
      const categoriesLen = categories?.length;

      for (let i = 0; i < categoriesLen; i++) {
        const category = categories[i];
        const filterByGenderList =
          genderArray.length > 0
            ? genderArray.some((el) => category.gender === el)
            : category;
        const filterByAgeList =
          ageArrayForView2.length > 0
            ? ageArrayForView2.some((el) =>
                category.age_to
                  ? +category.age_from >= el[0] && +category.age_to <= el[1]
                  : +category.age_from >= el[0] && +category.age_from <= el[1]
              )
            : category;
        const filterByTypeList =
          typeArray.length > 0
            ? typeArray.some((el) => +category.type === +el)
            : category;

        if (filterByGenderList && filterByAgeList && filterByTypeList) {
          finalCategories = [...finalCategories, category];
        }
      }
    }

    if (filters.switchedView === 2) {
      if (
        selectedCheckboxes?.length > 0 ||
        filters?.filteredByCategoryId ||
        filters?.category_issue
      ) {
        let categoryID = filters?.filteredByCategoryId;
        let categoryIssue = filters?.category_issue;

        const len = allRecordsForView2?.length;

        for (let i = 0; i < len; i++) {
          const category = allRecordsForView2[i];

          const filterByGenderList =
            genderArray.length > 0
              ? genderArray.some((el) => category.gender === el)
              : category;

          const filterByAgeList =
            ageArrayForView2.length > 0
              ? ageArrayForView2.some((el) =>
                  category.age_to
                    ? +category.age_from >= el[0] && +category.age_to <= el[1]
                    : +category.age_from >= el[0] && +category.age_from <= el[1]
                )
              : category;

          const filterByTypeList =
            typeArray.length > 0
              ? typeArray.some((el) => +category.type === +el)
              : category;

          const filterByCategoyID = categoryID
            ? +category.id === +categoryID
            : category;

          const filterByCategoryIssue = categoryIssue
            ? category.issue
            : category;

          if (
            filterByGenderList &&
            filterByAgeList &&
            filterByTypeList &&
            filterByCategoyID &&
            filterByCategoryIssue
          ) {
            newData = [...newData, category];
          }
        }
      } else {
        newData = [...allRecordsForView2];
      }

      const count = newData?.length ?? 0;

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          genderArray,
          ageArray,
          typeArray,
          ...(shouldFilterCategoriesList
            ? { filteredCategories: finalCategories }
            : {})
        },
        filteredRecordsForView2: newData,
        filteredRecordsForView2Count: count
      }));
    } else {
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            genderArray,
            ageArray,
            typeArray,
            ...(shouldFilterCategoriesList
              ? { filteredCategories: finalCategories }
              : {})
          }
        }),
        () => cb && cb()
      );
    }
  };

  onFilterRecordsBySelectedCategory = (evt, val) => {
    this.selectOption(
      evt,
      val,
      'filters',
      'filteredByCategoryId',
      ID,
      null,
      () => {
        this.setState({ page: 1 }, () => {
          const { filters, page, rowsPerPage, searchBar } = this.state;

          this.fetchData(page, rowsPerPage, searchBar, () => {
            if (filters.switchedView === 2) {
              this.onFilterBySelection();
            }
          });
        });
      }
    );
  };

  onFilterParticipantsBySelectedCategory = (item) => {
    this.fetchParticipantsByTournamentV2(
      null,
      null,
      null,
      null,
      this.tournamentIdParams,
      null,
      (data) => {
        this.setState((prevState) => ({
          modalData: {
            ...prevState.modalData,
            ...item,
            participants: data.data
          },
          open: true,
          pressedIcon: TEST_ID_INFO_BTN
        }));
      },
      item.category_id,
      1,
      1
    );
  };

  applyOnFilterByCategoryList = () => {
    const { modalData, rowsPerPage, filters } = this.state;
    const newFilters = {
      ...filters,
      filteredByCategoryId: modalData.category_id
    };

    this.setState({ filters: { ...newFilters }, searchBar: null }, () => {
      this.fetchData(1, rowsPerPage, null, () => this.hideModal());
    });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      success,
      showModal,
      modalInfo,
      rowsPerPage,
      page,
      participantsCount,
      participants,
      filteredRecordsForView2Count,
      filteredRecordsForView2,
      open,
      pressedIcon,
      categoryTypes,
      tournamentsList,
      collapsed,
      collapsedForView2,
      tournamentApplications,
      processing,
      searchBar,
      searchedValue,
      selectedRow,
      copyPasteData,
      categories,
      isFilterChecked,
      shouldDisableEditing,
      filteredParticipants,
      tournamentData,
      selectedCheckboxes,
      sortDirection,
      statistics,
      shouldShowDiscardChanges,
      selectedCategories,
      selectedCategoriesErrors,
      qdanList,
      intermidiateSchoolInfo,
      isInactiveParticipantsChecked,
      filters,
      modalData,
      searchByOption,
      editableTableCell,
      iframeURL
    } = this.state;

    const { t } = this.props;
    const { role } = this.context.authState;
    const isTraining = isSchool(tournamentData);
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    const CategoryCheckbox = () => (
      <CheckboxBtn
        label={t('autoSelectionByAgeSex')}
        disabled={
          !this.tournamentIdParams &&
          pressedIcon === ADD_ICON &&
          !tournamentData?.id
        }
        classControlLabel={classes.marginRight0}
        checked={isFilterChecked}
        onChange={(evt) =>
          this.selectCheckbox(evt, modalData, tournamentData?.id, true)
        }
      />
    );

    const MODAL_FIRST_NAME = (item) =>
      this.tournamentIdParams
        ? !+tournamentData?.last_name_first
          ? item.participant_first_name
          : item.participant_last_name
        : !isRuLang
        ? item.first_name
        : item.last_name;

    const MODAL_LAST_NAME = (item) =>
      this.tournamentIdParams
        ? !+tournamentData?.last_name_first
          ? item.participant_last_name
          : item.participant_first_name
        : !isRuLang
        ? item.last_name
        : item.first_name;

    const MODAL_COACH_FIRST_NAME = (item) =>
      (this.tournamentIdParams && !+tournamentData?.last_name_first) ||
      (!this.tournamentIdParams && !isRuLang)
        ? item.coach_first_name
        : item.coach_last_name;

    const MODAL_COACH_LAST_NAME = (item) =>
      (this.tournamentIdParams && !+tournamentData?.last_name_first) ||
      (!this.tournamentIdParams && !isRuLang)
        ? item.coach_last_name
        : item.coach_first_name;

    const FIRST_NAME = this.tournamentIdParams
      ? !+tournamentData?.last_name_first
        ? 'participant_first_name'
        : 'participant_last_name'
      : !isRuLang
      ? 'first_name'
      : 'last_name';

    const LAST_NAME = this.tournamentIdParams
      ? !+tournamentData?.last_name_first
        ? 'participant_last_name'
        : 'participant_first_name'
      : !isRuLang
      ? 'last_name'
      : 'first_name';

    let dialogContent,
      dialogTitle,
      onClick,
      modalContent,
      subHeader,
      onSwitchBetween;
    let buttonPurpose = t('save');

    if (pressedIcon === DELETE_ICON) {
      dialogTitle = !this.tournamentIdParams
        ? t('deleteRecord', { name: t('athlete') })
        : t('removeParticipantTourn');
      dialogContent = !this.tournamentIdParams
        ? t('deleteAthleteMsg')
        : t('removeParticipantTournMsg');
      buttonPurpose = !this.tournamentIdParams
        ? t('deleteRecord', { name: '' })
        : t('remove');
    } else if (pressedIcon === CLEAR_ICON) {
      dialogTitle = t('removeParticipantCateory');
      dialogContent = t('removeParticipantCateoryMsg', {
        name: modalData?.current_category_id
          ? this.tournamentIdParams
            ? `(${
                modalData?.categories_info?.find(
                  (it) => +it.category_id === +modalData?.current_category_id
                )?.category_name
              })`
            : `(${
                tournamentApplications
                  ?.find(
                    (tourn) =>
                      +tourn.tournament_id === +modalData.current_tournament_id
                  )
                  ?.categories?.find(
                    (it) => +it.category_id === +modalData?.current_category_id
                  )?.category_name
              })`
          : ''
      });
      buttonPurpose = t('remove');
    } else if (pressedIcon === ADD_ICON) {
      dialogTitle = isTraining
        ? t('assignParticipantToTraining')
        : t('assignParticipant');
      onClick = (evt) => {
        isTraining
          ? this.setParticipantToTraining(evt)
          : this.validateMultipleCategoriesAssignment(() => {
              this.saveParticipant(evt);
            });
      };
      dialogContent = !isTraining && (
        <span
          className={clsx(
            classes.flex,
            classes.marginLeft1,
            classes.labelCheckbox
          )}>
          <CategoryCheckbox />
        </span>
      );
      modalContent = !isTraining ? (
        <AssignCategories
          {...{ tournamentsList }}
          switchTournament={this.switchTournament}
          {...{ categoryTypes }}
          {...{ selectedCategories }}
          {...{ selectedCategoriesErrors }}
          addNewCategory={() => this.addNewCategory(initialStateCategory)}
          removeCategory={this.removeCategory}
          selectCategories={this.selectMultipleCategories}
          selectCategoryType={this.selectMultipleCategoryType}
          selectTeam={this.selectMultipleTeams}
          tournamentIdParams={this.tournamentIdParams}
          shouldDisableInput={!this.tournamentIdParams && !tournamentData?.id}
          selectedTournament={tournamentData}
        />
      ) : (
        <Assign2School
          {...{ intermidiateSchoolInfo }}
          {...{ qdanList }}
          selectOption={this.selectOption}
          selectCheckboxSchool={this.selectCheckboxSchool}
        />
      );
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      onClick = this.applyOnFilterByCategoryList;
      modalContent = modalData?.participants?.map((it, idx) => (
        <span
          className={clsx(classes.flex, classes.marginBottom05)}
          key={it.participant_id}>
          {idx + 1}. {it.participant_name}
          {'   '}(
          {[
            it.gender,
            it.age,
            it.weight && `${it.weight}kg`,
            it.qdan_name
          ].join(', ')}
          )
        </span>
      ));
      dialogTitle = t('allParticipants');
      buttonPurpose = t('proceed');
    } else if (pressedIcon === TEST_ID_PARTICIPANT) {
      modalContent = (
        <AddParticipant
          state={this.state}
          triggerTableUpdate={this.triggerTableUpdate}
          selectCheckboxSchool={this.selectCheckboxSchool}
        />
      );
      dialogTitle = modalData?.id
        ? this.tournamentIdParams
          ? t('updateRecord', { name: t('participant') })
          : t('updateRecord', { name: t('athlete') })
        : this.tournamentIdParams
        ? t('addRecord', { name: t('participant') })
        : t('addRecord', { name: t('athlete') });

      const onFormOpen = (item, nextItemIdx, parent, parentIdx) => {
        if (filters.switchedView === 2) {
          this.onClickEditRecordForView2(item, (response) => {
            this.showModalParticipant(
              null,
              response?.data?.[0],
              nextItemIdx,
              parent,
              parentIdx
            );
          });
        } else {
          this.showModalParticipant(null, item, nextItemIdx);
        }
      };

      onSwitchBetween = modalData?.id && {
        prev: () => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const parentForV2 =
              filters.switchedView === 2 &&
              filteredRecordsForView2[modalData.currentRecordParentIdxForV2];
            const item =
              filters.switchedView !== 2
                ? filteredParticipants[nextItemIdx]
                : parentForV2?.participants?.[nextItemIdx];

            onFormOpen(
              item,
              nextItemIdx,
              parentForV2,
              modalData.currentRecordParentIdxForV2
            );
          }
        },
        next: () => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const parentForV2 =
            filters.switchedView === 2 &&
            filteredRecordsForView2?.[modalData.currentRecordParentIdxForV2];
          const len =
            filters.switchedView !== 2
              ? filteredParticipants?.length - 1
              : parentForV2?.participants?.length - 1;

          if (len >= nextItemIdx) {
            const item =
              filters.switchedView !== 2
                ? filteredParticipants[nextItemIdx]
                : parentForV2?.participants?.[nextItemIdx];

            onFormOpen(
              item,
              nextItemIdx,
              parentForV2,
              modalData.currentRecordParentIdxForV2
            );
          }
        }
      };
    }

    if (pressedIcon === DELETE_ICON || pressedIcon === CLEAR_ICON) {
      onClick = () =>
        this.deleteParticipant(
          () => {
            this.triggerTableUpdate();
          },
          modalData?.current_tournament_id,
          null,
          pressedIcon === DELETE_ICON,
          pressedIcon === CLEAR_ICON && modalData?.current_category_id,
          isTraining
        );
    }

    if (
      pressedIcon === ADD_ICON ||
      pressedIcon === DELETE_ICON ||
      pressedIcon === CLEAR_ICON
    ) {
      subHeader = (
        <span className={clsx(classes.flex, classes.column)}>
          {modalData && (
            <>
              <span
                className={clsx(
                  classes.flex,
                  classes.marginBottom05,
                  classes.marginTop1
                )}>
                {[
                  MODAL_FIRST_NAME(modalData),
                  MODAL_LAST_NAME(modalData),
                  [
                    modalData.age,
                    modalData.gender,
                    `${modalData.weight}${t('kg')}`,
                    (!!+tournamentData?.height_needed ||
                      !this.tournamentIdParams) &&
                      modalData.height &&
                      `${modalData.height}${t('cm')}`,
                    (!!+tournamentData?.show_reg_qdan ||
                      !this.tournamentIdParams) &&
                      modalData.qdan_name &&
                      modalData.qdan_name
                  ]
                    .filter(Boolean)
                    .join(', ')
                ].join(' ')}
              </span>
              {modalData.current_parent_category_name && (
                <span className={clsx(classes.flex, classes.marginBottom05)}>
                  {t('category')}: {modalData.current_parent_category_name}
                </span>
              )}
              {t('coach')}:{' '}
              {[
                MODAL_COACH_FIRST_NAME(modalData),
                MODAL_COACH_LAST_NAME(modalData)
              ].join(' ')}
            </>
          )}
        </span>
      );
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      subHeader = modalData && (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          {/* <span>{[t('block', {count: modalData.block_id}), modalData.block_name].join(' ')}</span> */}
          <span>{[t('tatami'), modalData.category_tatami_name].join(' ')}</span>
          <span>{modalData.category_name}</span>
        </span>
      );
    }

    const showParticipantsCheckboxes = [
      ...(!this.tournamentIdParams
        ? [
            {
              checked: filters?.archive,
              onChange: this.selectArchive,
              label: t('archive')
            }
          ]
        : [
            ...(filters.switchedView !== 2
              ? [
                  {
                    checked: filters?.issues,
                    onChange: this.selectIssues,
                    label: t('showRed')
                  },

                  ...(role === 'master' || role === 'administrator'
                    ? [
                        {
                          checked: isInactiveParticipantsChecked,
                          onChange: this.selectInactiveParticipants,
                          label: t('showRetired')
                        }
                      ]
                    : [])
                ]
              : [
                  {
                    checked: filters?.category_issue,
                    onChange: this.selectIssuesForView2,
                    label: t('showRed')
                  }
                ])
          ])
    ];

    const filter = [
      ...(!isTraining && this.tournamentIdParams && role !== 'recorder'
        ? [
            {
              options: filters.filteredCategories ?? [],
              label: t('category'),
              item: NAME,
              value:
                selectedValue(
                  filters.categories,
                  ID,
                  filters.filteredByCategoryId,
                  true
                ) || '',
              onChange: (evt, val) =>
                this.onFilterRecordsBySelectedCategory(evt, val)
            }
          ]
        : [])
    ];

    const isAdminAndNoTourn =
      !this.tournamentIdParams && role === 'administrator';

    const header = [
      ...(role === 'administrator' ? [{ label: 'ID' }] : []),
      { num: 1, label: t('name'), noTooltip: true },
      ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
        ? [{ num: 2, label: t('number') }]
        : []),
      { num: 4, label: t('gender') },
      { num: 5, label: t('age') },
      { label: t('weight'), noTooltip: true },
      ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
        ? [{ label: t('height') }]
        : []),
      ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
        ? [
            {
              ...(this.tournamentIdParams ? { num: 12 } : {}),
              label: t('kyuDan')
            }
          ]
        : []),
      ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
        ? [{ label: t('sportsCategory') }]
        : []),
      ...(!isTraining && this.tournamentIdParams
        ? [
            {
              label: t('categories'),
              noTooltip: true
            }
          ]
        : []),
      ...(!+tournamentData?.no_country || isAdminAndNoTourn
        ? [{ num: 7, label: t('country') }]
        : []),
      ...(isAdminAndNoTourn ? [{ label: t('birthday') }] : []),

      ...(isTraining ? [{ label: t('nextKyuDan') }] : []),
      ...(isAdminAndNoTourn ? [{ label: t('club') }] : []),
      ...(isAdminAndNoTourn ? [{ label: 'History' }] : []),
      ...(isAdminAndNoTourn ? [{ label: t('city') }] : []),
      ...(isAdminAndNoTourn ? [{ label: t('region') }] : []),
      ...(isAdminAndNoTourn ? [{ icon: <TimerIcon /> }] : []),
      ...(isAdminAndNoTourn ? [{ icon: <AutorenewIcon /> }] : []),
      ...(role !== 'coach'
        ? [{ num: this.tournamentIdParams ? 3 : 2, label: t('coach') }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ label: t('admisDocs'), noTooltip: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ label: t('admisWeighs'), noTooltip: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ label: t('admisEquipment'), noTooltip: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ label: t('admisMedic'), noTooltip: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online
        ? [{ num: 10, label: t('registered') }]
        : []),
      ...(!!+tournamentData?.use_reg_online
        ? [{ label: t('declaredWeight') }]
        : [])
    ];

    const headerForView2 = [
      { label: t('name') },
      { label: `${t('age')} ${t('from').toLowerCase()}` },
      { label: `${t('age')} ${t('to').toLowerCase()}` },
      { label: t('type') },
      { label: t('gender') },
      { label: t('participants') }
    ];

    const tableBody = [
      ...(role === 'administrator'
        ? [this.tournamentIdParams ? 'participant_id' : 'id']
        : []),
      {
        firstName: FIRST_NAME,
        lastName: LAST_NAME,
        ...(!this.tournamentIdParams || !!+tournamentData?.show_reg_patronymic
          ? {
              patronymic: this.tournamentIdParams
                ? 'participant_patronymic'
                : 'patronymic'
            }
          : {}),
        photo: 'photo'
      },
      ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
        ? ['participant_number']
        : []),
      'gender',
      'age',
      'weight',
      ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
        ? ['height']
        : []),
      ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
        ? ['qdan_name']
        : []),
      ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
        ? ['sport_rank_short']
        : []),
      ...(!isTraining && this.tournamentIdParams ? ['list'] : []),
      ...(!+tournamentData?.no_country || isAdminAndNoTourn
        ? ['participant_country']
        : []),
      ...(isAdminAndNoTourn ? ['birthday'] : []),
      ...(isTraining ? ['next_qdan_id'] : []),
      ...(isAdminAndNoTourn ? ['club'] : []),
      ...(isAdminAndNoTourn ? ['history'] : []),
      ...(isAdminAndNoTourn ? ['participant_city'] : []),
      ...(isAdminAndNoTourn ? ['participant_region'] : []),
      ...(isAdminAndNoTourn ? ['active'] : []),
      ...(isAdminAndNoTourn ? ['update_date'] : []),
      ...(role !== 'coach' ? ['coach_name'] : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ param: 'has_docs', isCheckbox: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ param: 'has_weighted', isCheckbox: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ param: 'has_equipment', isCheckbox: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
        ? [{ param: 'has_medic', isCheckbox: true }]
        : []),
      ...(!!+tournamentData?.use_reg_online ? ['registered'] : []),
      ...(!!+tournamentData?.use_reg_online ? ['registered_weight'] : [])
    ];

    const tableBodyForView2 = [
      'name',
      'age_from',
      'age_to',
      { defaultN: 'category_type_name', paramN: 'type' },
      'gender',
      { dropdown: 'participants_count' }
    ];

    const condensedTableData = {
      mainName: { firstName: FIRST_NAME, lastName: LAST_NAME },
      photo: 'photo',
      list: true,
      info: [
        ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
          ? [
              {
                key: 'participant_number',
                name: t('number')
              }
            ]
          : []),
        { key: 'gender', name: t('gender') },
        { key: 'age', name: t('age') },
        { key: 'weight', name: t('weight') },
        ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
          ? [{ key: 'height', name: t('height') }]
          : []),
        ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
          ? [{ key: 'qdan_name', name: t('kyuDan') }]
          : []),
        ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
          ? [{ key: 'sport_rank_short', name: t('sportsCategory') }]
          : []),
        ...(!+tournamentData?.no_country || isAdminAndNoTourn
          ? [{ key: 'participant_country', name: t('country') }]
          : []),
        ...(isTraining ? [{ key: 'next_qdan_id', name: t('nextKyuDan') }] : []),
        { key: 'coach_name', name: [t('coach'), t('club')].join('/') },
        ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
          ? [{ key: 'has_docs', name: t('admisDocs'), isCheckbox: true }]
          : []),
        ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
          ? [{ key: 'has_weighted', name: t('admisWeighs'), isCheckbox: true }]
          : []),
        ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
          ? [
              {
                key: 'has_equipment',
                name: t('admisEquipment'),
                isCheckbox: true
              }
            ]
          : []),
        ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
          ? [{ key: 'has_medic', name: t('admisMedic'), isCheckbox: true }]
          : []),
        ...(!!+tournamentData?.use_reg_online
          ? [{ key: 'registered', name: t('registered') }]
          : []),
        ...(!!+tournamentData?.use_reg_online
          ? [{ key: 'registered_weight', name: t('declaredWeight') }]
          : [])
      ]
    };

    const condensedTableDataForView2 = {
      mainName: 'name',
      info: [
        {
          key: 'age_from',
          name: `${t('age')} ${t('from').toLowerCase()}`
        },
        {
          key: 'age_to',
          name: `${t('age')} ${t('to').toLowerCase()}`
        },
        { defaultN: 'category_type_name', paramN: 'type', name: t('type') },
        { key: 'gender', name: t('gender') },
        { dropdown: { key: 'participants_count', name: t('participants') } }
      ]
    };

    const FIRST_NAME_PART = !+tournamentData?.last_name_first
      ? 'participant_first_name'
      : 'participant_last_name';
    const LAST_NAME_PART = !+tournamentData?.last_name_first
      ? 'participant_last_name'
      : 'participant_first_name';

    const collapseTableHeaderForView2 = [
      ...(!shouldDisableEditing ? [{ isDrag: true }] : []),
      ...(role === 'administrator' ? [{ label: 'ID' }] : []),
      { num: 'name', label: t('name') },
      ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
        ? [{ label: t('number') }]
        : []),
      { num: 'gender', label: t('gender') },
      { num: 'age', label: t('age') },
      { num: 'weight', label: t('weight') },
      ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
        ? [{ label: t('height') }]
        : []),
      ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
        ? [{ num: 7, label: t('kyuDan') }]
        : []),
      ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
        ? [{ label: t('sportsCategory') }]
        : []),
      ...(!+tournamentData?.no_country || isAdminAndNoTourn
        ? [{ num: 'country', label: t('country') }]
        : []),
      { label: t('coach') },
      ...(!!+tournamentData?.use_reg_online
        ? [{ label: t('registered') }]
        : []),
      ...(!!+tournamentData?.use_reg_online
        ? [{ label: t('declaredWeight') }]
        : [])
    ];

    const collapseTableBodyForView2 = [
      ...(!shouldDisableEditing ? [{ isDrag: true, param: 'drag' }] : []),
      ...(role === 'administrator'
        ? [this.tournamentIdParams ? 'participant_id' : 'id']
        : []),
      {
        firstName: FIRST_NAME_PART,
        lastName: LAST_NAME_PART,
        ...(!this.tournamentIdParams || !!+tournamentData?.show_reg_patronymic
          ? { patronymic: 'patronymic' }
          : {}),
        photo: true
      },
      ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
        ? ['participant_number']
        : []),
      'gender',
      'age',
      'weight',
      ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
        ? ['height']
        : []),
      ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
        ? ['qdan_name']
        : []),
      ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
        ? ['sport_rank_short']
        : []),
      ...(!+tournamentData?.no_country || isAdminAndNoTourn
        ? ['country_iso3']
        : []),
      'coach_name',
      ...(!!+tournamentData?.use_reg_online ? ['registered'] : []),
      ...(!!+tournamentData?.use_reg_online ? ['registered_weight'] : [])
    ];

    const collapsedCondensedTableDataForView2 = {
      mainName: {
        firstName: FIRST_NAME_PART,
        lastName: LAST_NAME_PART,
        photo: 'photo',
        isDrag: true
      },
      info: [
        ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
          ? [{ key: 'participant_number', name: t('number') }]
          : []),
        { key: 'gender', name: t('gender') },
        { key: 'age', name: t('age') },
        { key: 'weight', name: t('weight') },
        ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
          ? [{ key: 'height', name: t('height') }]
          : []),
        ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
          ? [{ key: 'qdan_name', name: t('kyuDan') }]
          : []),
        ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
          ? [{ key: 'sport_rank_short', name: t('sportsCategory') }]
          : []),
        ...(!+tournamentData?.no_country || isAdminAndNoTourn
          ? [{ key: 'country_iso3', name: t('country') }]
          : []),
        { key: 'coach_name', name: t('coach') },
        ...(!!+tournamentData?.use_reg_online
          ? [{ key: 'registered', name: t('registered') }]
          : []),
        ...(!!+tournamentData?.use_reg_online
          ? [{ key: 'registered_weight', name: t('declaredWeight') }]
          : [])
      ]
    };

    const SEARCH_BY_OPTION = [
      {
        id: '0',
        label: this.tournamentIdParams ? t('participants') : t('athletes')
      },
      { id: '1', label: t('coaches') }
    ];

    let tableActionIcons = (
      item,
      idx,
      className,
      iconWrapper,
      marginLeft,
      marginBottom,
      parent
    ) =>
      !shouldDisableEditing &&
      (!filters?.archive ? (
        <span
          className={clsx(
            classes.flex,
            role !== 'recorder' && classes.smallViewportColumn
          )}>
          <Tooltip
            arrow
            title={t('updateRecord', {
              name: this.tournamentIdParams ? t('participant') : t('athlete')
            })}>
            <EditIcon
              className={clsx(className, iconWrapper, marginBottom)}
              onClick={(evt) => {
                if (filters.switchedView === 2) {
                  this.onClickEditRecordForView2(item, (response) =>
                    this.showModalParticipant(
                      evt,
                      response?.data?.[0],
                      idx,
                      parent
                    )
                  );
                } else {
                  this.showModalParticipant(evt, item, idx);
                }
              }}
            />
          </Tooltip>
          {role !== 'recorder' ? (
            <>
              {role !== 'coach' && (
                <Tooltip arrow title={t('assignParticipant')}>
                  <AddIcon
                    id={ADD_ICON}
                    className={clsx(
                      className,
                      iconWrapper,
                      marginLeft,
                      marginBottom
                    )}
                    onClick={(e) => {
                      if (filters.switchedView === 2) {
                        this.onClickEditRecordForView2(item, (response) => {
                          this.showModal(
                            e,
                            response?.data?.[0],
                            this.tournamentIdParams
                          );
                        });
                      } else {
                        this.showModal(e, item, this.tournamentIdParams);
                      }
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip
                arrow
                title={
                  !this.tournamentIdParams
                    ? t('deleteRecord', { name: t('athlete') })
                    : filters.switchedView !== 2
                    ? t('removeParticipantTourn')
                    : t('removeParticipantCateory')
                }>
                {!this.tournamentIdParams ? (
                  <DeleteIcon
                    id={DELETE_ICON}
                    className={clsx(className, iconWrapper, marginLeft)}
                    onClick={(evt) => this.showModal(evt, item)}
                  />
                ) : (
                  <ClearIcon
                    id={CLEAR_ICON}
                    className={clsx(className, iconWrapper, marginLeft)}
                    onClick={(evt) => {
                      const name = item?.coach_name?.split(' ');

                      this.showModal(
                        evt,
                        {
                          ...item,
                          ...(filters.switchedView === 2
                            ? {
                                coach_first_name: name[0],
                                coach_last_name: name[1],
                                current_parent_category_name: parent?.name,
                                currentParentID: parent?.id
                              }
                            : {})
                        },
                        this.tournamentIdParams,
                        filters.switchedView !== 2
                          ? item.category_id || null
                          : parent?.id
                      );
                    }}
                  />
                )}
              </Tooltip>
            </>
          ) : (
            <Tooltip
              className={clsx(classes.marginCheckbox, classes.marginLeft065)}
              arrow
              title={t(
                !!+item.registered ? 'disableRegistration' : 'register'
              )}>
              <span>
                <CheckboxBtn
                  classControlLabel={clsx(
                    classes.marginRight0,
                    classes.marginLeft0
                  )}
                  className={clsx(classes.padding0, classes.checkboxSvg)}
                  checked={!!+item.registered}
                  onChange={(evt) =>
                    this.recorderToggleRegisteredStatus(evt, item, parent)
                  }
                  disabled={shouldDisableEditing}
                />
              </span>
            </Tooltip>
          )}
        </span>
      ) : (
        <Tooltip arrow title={t('restore')}>
          <RestoreIc
            className={clsx(className, iconWrapper, marginLeft)}
            onClick={() =>
              this.restoreParticipant(item, () =>
                this.fetchData(page, rowsPerPage, searchBar)
              )
            }
          />
        </Tooltip>
      ));

    const pageHeaderStatistics = {
      info: [
        {
          label:
            filters.switchedView === 1
              ? [t('total'), t('participantsTotal').toLowerCase()].join(' ')
              : t('categoriesCount'),
          name:
            filters.switchedView === 1
              ? statistics?.participantionCount
              : statistics?.totalCount
        },
        {
          label: [t('total'), t('athleteForm').toLowerCase()].join(' '),
          name: statistics?.athleteCount
        },
        ...(filters.switchedView === 1 && !!+tournamentData?.use_reg_online
          ? [
              {
                label: t('registeredParticipation'),
                name: statistics?.registeredParticipation
              },
              {
                label: t('notRegistered'),
                name: statistics?.notRegistered
              }
            ]
          : [])
      ]
    };

    return (
      <>
        <QuestionMark src={iframeURL?.shortName} tooltip={iframeURL?.altName} />
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            {this.tournamentIdParams && (
              <HeaderTournInfo
                {...{ tournamentData, pageHeaderStatistics }}
                shouldShowBtn
              />
            )}
            <span
              className={clsx(
                classes.flex,
                classes.checkboxesWrapper,
                classes.wrap,
                classes.marginBottom1
              )}>
              {FILTERS_CHECKBOXES(
                this.tournamentIdParams && categoryTypes,
                null,
                null,
                filters.switchedView !== 2
              ).map((group, groupIdx) => (
                <span
                  key={groupIdx}
                  className={clsx(
                    classes.flex,
                    classes.wrap,
                    classes.marginRight1
                  )}>
                  {group.map((item, idx) => (
                    <Fragment key={idx}>
                      <CheckboxBtn
                        formControlClassName={classes.paper}
                        classControlLabel={classes.checkboxItem}
                        className={classes.checkboxIcon}
                        checked={selectedCheckboxes.some(
                          (it) => it.id === item.id
                        )}
                        onChange={() => this.onSelectCheckbox(item)}
                        label={item.name}
                        labelPlacement="start"
                      />
                    </Fragment>
                  ))}
                </span>
              ))}
            </span>
            <Modal
              {...{ open }}
              close={this.hideModal}
              {...{ onClick, dialogTitle }}
              {...{ dialogContent }}
              {...{ buttonPurpose, subHeader, onSwitchBetween }}
              discardOrKeepEditing={this.discardOrKeepEditing}
              {...{ shouldShowDiscardChanges }}
              isOldDesign>
              {modalContent}
            </Modal>
            {this.tournamentIdParams && (
              <div
                className={clsx(
                  classes.switchMode,
                  classes.marginBottom05,
                  classes.flex
                )}>
                {filters?.switchedView === 2 ? (
                  <>
                    <Tooltip arrow title={t('participantMode')}>
                      <KaratekaIc
                        onClick={this.onSwitchView}
                        className={clsx(classes.iconWrapper, classes.icon)}
                      />
                    </Tooltip>
                    <span className={classes.marginLeft05}>
                      {t('switchToParticipantMode')}
                    </span>
                  </>
                ) : (
                  <>
                    <Tooltip arrow title={t('categoryMode')}>
                      <CategoriesIc
                        onClick={this.onSwitchView}
                        className={clsx(classes.iconWrapper, classes.icon)}
                      />
                    </Tooltip>
                    <span className={classes.marginLeft05}>
                      {t('switchToCategoryMode')}
                    </span>
                  </>
                )}
              </div>
            )}
            <Table
              componentBefore={
                <Filter
                  value={
                    selectedValue(SEARCH_BY_OPTION, ID, searchByOption.id) || ''
                  }
                  className={classes.styleFilter}
                  options={SEARCH_BY_OPTION}
                  item="label"
                  onChange={(e, val) =>
                    this.selectOption(e, val, 'searchByOption', ID, ID)
                  }
                />
              }
              search={searchBar}
              clearSearch={() =>
                this.clearSearch(searchedValue, () => this.searchHandler())
              }
              textChange={this.handleTextChange}
              openEmptyForm={(evt) => this.showModalParticipant(evt)}
              pressEnter={this.pressEnterHandler}
              showButton={!shouldDisableEditing && role !== 'recorder'}
              onClick={(evt, item, idx) =>
                filters.switchedView !== 2
                  ? this.showModalParticipant(evt, item, idx)
                  : this.expandTableRow(evt, item)
              }
              onClickSubtableRow={(evt, item, itemIdx, parent, parentIdx) => {
                if (filters.switchedView === 2) {
                  this.onClickEditRecordForView2(item, (response) => {
                    this.showModalParticipant(
                      evt,
                      response?.data?.[0],
                      itemIdx,
                      parent,
                      parentIdx
                    );
                  });
                }
              }}
              {...{ role, filter }}
              condensedTableData={
                filters.switchedView !== 2
                  ? condensedTableData
                  : condensedTableDataForView2
              }
              data={filters.switchedView !== 2 ? tableBody : tableBodyForView2}
              body={
                filters.switchedView !== 2
                  ? role === 'coach'
                    ? filteredParticipants
                    : participants
                  : filteredRecordsForView2.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
              }
              header={filters.switchedView !== 2 ? header : headerForView2}
              collapsedCondensedTableData={
                filters.switchedView === 2 &&
                collapsedCondensedTableDataForView2
              }
              toolbarIndividualContent={
                <>
                  {this.tournamentIdParams && (
                    <Button
                      className={clsx(
                        classes.exportBtn,
                        filters.switchedView === 2 && classes.marginRight0dot5
                      )}
                      onClick={() =>
                        filters.switchedView !== 2
                          ? this.exportParticipants(
                              tournamentData.id,
                              {
                                searchedValue,
                                isInactiveParticipantsChecked,
                                filteredByCategoryId:
                                  filters.filteredByCategoryId,
                                searchByOption,
                                ...filters
                              },
                              true
                            )
                          : this.exportCategories(tournamentData.id)
                      }
                      label={t('saveList')}
                    />
                  )}

                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      classes.fitContent
                    )}>
                    {showParticipantsCheckboxes.map(
                      (checkboxData, checkboxIdx) => (
                        <CheckboxBtn
                          key={checkboxIdx}
                          className={classes.checkboxPaddingLeft}
                          checked={checkboxData?.checked ?? false}
                          onChange={checkboxData.onChange}
                          label={checkboxData.label}
                        />
                      )
                    )}
                    {this.tournamentIdParams && filters.switchedView === 2 && (
                      <FormControlLabel
                        className={clsx(classes.switch)}
                        control={
                          <Switch
                            checked={filters.collapseAll ?? false}
                            onChange={(evt, checked) => {
                              this.onSwitchToggleForView2(evt, checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={t('expandList')}
                      />
                    )}
                  </span>
                </>
              }
              selected={
                filters.switchedView !== 2
                  ? (item) => {
                      const id = item.id || item.participant_id;
                      return selectedRow === id;
                    }
                  : undefined
              }
              shouldAllowEditing={() => role !== 'recorder'}
              rowOrder={filters?.switchedView !== 2 && (page - 1) * rowsPerPage}
              actionIcons={(
                item,
                idx,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) =>
                filters.switchedView !== 2 &&
                tableActionIcons(
                  item,
                  idx,
                  className,
                  iconWrapper,
                  marginLeft,
                  marginBottom
                )
              }
              selectedSubTable={(item) => {
                return +selectedRow === +item.id;
              }}
              subTableActionIcons={(
                item,
                idx,
                className,
                iconWrapper,
                marginLeft,
                marginBottom,
                parent
              ) =>
                filters.switchedView === 2 &&
                tableActionIcons(
                  item,
                  idx,
                  className,
                  iconWrapper,
                  marginLeft,
                  marginBottom,
                  parent
                )
              }
              actionIconsList={(item, category, classes) =>
                this.tournamentIdParams && (
                  <InfoIc
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onFilterParticipantsBySelectedCategory(category);
                    }}
                    className={clsx(classes)}
                  />
                )
              }
              onSortCollapsibleTable={this.onSortCollapsibleTable}
              onSort={this.onSort}
              {...{ sortDirection }}
              paginationComponent={
                filters.switchedView !== 2
                  ? Array.isArray(participants) &&
                    participants.length > 0 && (
                      <Pagination
                        count={participantsCount}
                        onPageChange={this.onPageChange}
                        onRowsPerPageChange={(evt) =>
                          this.rowsPerPageHandler(evt, this.searchHandler)
                        }
                        {...{ page }}
                        {...{ rowsPerPage }}
                      />
                    )
                  : filteredRecordsForView2Count > 0 && (
                      <Pagination
                        count={filteredRecordsForView2Count}
                        onPageChange={this.pageChangeHandler}
                        onRowsPerPageChange={this.rowsPerPageHandler}
                        {...{ page }}
                        {...{ rowsPerPage }}
                      />
                    )
              }
              {...{ categoryTypes }}
              {...{ copyPasteData }}
              mainTableRowColor={
                filters.switchedView === 2 && classes.mainTableRowColor
              }
              onClickPasteIndicatorHeader={this.onClickPasteIndicatorHeader}
              onClickCopyIndicatorBody={this.onClickCopyIndicatorBody}
              expandTableRow={(evt, item) => this.expandTableRow(evt, item)}
              subTableDataKey={filters.switchedView === 2 && 'participants'}
              id="id"
              showMainTbClassNameIssue={(item, param) => {
                return (
                  (filters.switchedView === 2 &&
                    param === 'name' &&
                    item.issue) ||
                  (filters.switchedView === 1 &&
                    typeof param === 'object' &&
                    param.firstName &&
                    !!+item.duplicate)
                );
              }}
              collapsed={collapsedForView2}
              collapseTableHeader={collapseTableHeaderForView2}
              collapseTableBody={collapseTableBodyForView2}
              //Categories List records
              shouldShowNoncollapsibleList={this.tournamentIdParams}
              tournamentIdParams={this.tournamentIdParams}
              {...{ tournamentApplications }}
              {...{ processing }}
              toggleCollpasedList={this.itemToggle}
              listCollapsed={collapsed}
              {...{ qdanList }}
              openModal={(evt, item, tournID, catID) =>
                this.showModal(evt, item, tournID, catID)
              }
              shouldDisableEditing={role === 'recorder' || shouldDisableEditing}
              editableTableCell={role === 'recorder' && editableTableCell}
              onEditableTableCell={this.onEditableTableCell}
              onTableCellCheckbox={this.onTableCellCheckbox}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(UserList)));
