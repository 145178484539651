import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  wrap: {
    flexWrap: 'wrap'
  },

  btn: {
    width: '10em'
  },

  paddingLR1dot5: {
    padding: '0 1.5rem'
  },

  discardWrapper: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    padding: '20px',
    position: 'absolute',
    bottom: 0,
    background: '#fff',
    width: '100%',
    zIndex: 1
  },

  discardChanges: {
    fontWeight: 500,
    marginRight: '5px'
  },

  onHoldEditing: {
    backgroundColor: 'rgb(232,232,232)',
    paddingBottom: 0,
    pointerEvents: 'none'
  },

  header: {
    padding: '1.5rem',
    fontSize: '20px',
    fontWeight: 500,
    color: '#262441'
  },

  bgWhite: {
    background: '#fff'
  },

  bgGrey: {
    background: 'rgb(232,232,232)'
  },

  wrapper_header: {
    background: '#E9ECF6',
    position: 'sticky',
    top: 0,
    zIndex: 10
  },

  wrapper_footer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    paddingTop: '1rem',
    zIndex: 1,
    justifyContent: 'space-between'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  left: {
    marginLeft: 'auto'
  },

  column: {
    flexDirection: 'column'
  },

  maxWidth: {
    '@media (min-width:1024px)': {
      minWidth: 'fit-content'
    },
    '@media (max-width:1023px)': {
      minWidth: '100%'
    }
  },

  marginTop1: {
    marginTop: '1rem'
  },

  contentWrappper: {
    margin: '1.5rem 1.5rem'
  },

  contentSubheader: {
    margin: '0 1.5rem 1rem 1.5rem'
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.6'
    }
  },

  toggleIcons: {
    background: '#fff',
    width: '1.334em',
    height: '1.334em',
    borderRadius: '0.2rem',
    color: 'rgb(137, 150, 172)'
  },

  marginRight2: {
    marginRight: '2rem'
  },

  margin1dot5: { margin: '1.5rem' }
}));
