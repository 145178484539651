import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    marginBottom: '40px',
    padding: '0 20px'
  },
  flex: {
    display: 'flex'
  },
  btn: {
    width: '15em'
  },
  marginTopBottom: {
    margin: '.5em 0'
  },
  end: {
    justifyContent: 'end'
  },
  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#ff0000'
    }
  },
  marginTop: {
    marginTop: '.65em'
  },
  fullWidth: {
    width: '100%'
  },
  input: {
    '@media (min-width:901px)': {
      maxWidth: 'calc(100%/3)',
      width: '100%'
    }
  },
  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '60px'
    }
  },
  maxHeightTxtInput: {
    height: '60px'
  },
  condensedView: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },
  paddingInput: {
    '@media (min-width:901px)': {
      margin: '0 0.5rem'
    }
  }
}));
