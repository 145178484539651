import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },

  paper: {
    backgroundColor: '#fff',
    marginBottom: '40px',
    padding: '0 .5rem'
  },

  flex: {
    display: 'flex'
  },

  wrap: {
    flexWrap: 'wrap'
  },
  cancelBtn: {
    backgroundColor: '#f2f2f2'
  },

  width100: {
    width: '100%'
  },
  secretaryDescription: {
    '@media (min-width:901px)': {
      minWidth: 'calc(100%/3)'
    }
  },

  marginBottom15: {
    marginBottom: '15px'
  },

  alignCenter: {
    alignSelf: 'center'
  },
  btn: {
    width: '18em'
  },

  marginTopBottom: {
    margin: '.5em 0'
  },

  settingsName: {
    marginTop: '1rem',
    fontWeight: 500,
    marginBottom: '0.5rem'
  },

  end: {
    justifyContent: 'end'
  },
  marginRight: {
    marginRight: '.65em'
  },

  inputCondensedView: {
    '@media (max-width:900px)': {
      width: 'calc(100% + 4.5em)',
      marginLeft: '-4.5em'
    }
  },

  img: {
    '@media (max-width:900px)': {
      width: '4em',
      height: '6.7em'
    },
    marginRight: '.5em',
    '@media (min-width:901px)': {
      width: '10em',
      height: '14.1em'
    }
  },
  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#ff0000'
    }
  },

  tameshiwariInput: {
    '&.MuiFormControl-root': {
      width: '100% !important'
    }
  },

  marginTop: {
    marginTop: '.65em'
  },
  formWrapper: {
    '@media (max-width:900px)': {
      width: 'calc(100% - 4.5em)'
    },
    '@media (min-width:901px)': {
      width: 'calc(100% - 10.5em)'
    }
  },

  input: {
    '@media (min-width:901px)': {
      maxWidth: 'calc(100%/3)',
      width: '100%'
    }
  },

  overlappingLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: '40px',
    lineHeight: 'unset !important'
  },

  labelShrinked: {
    right: 'unset'
  },

  twoInputInARow: {
    '@media (min-width:901px)': {
      maxWidth: 'calc(100%/2)',
      width: '100%'
    }
  },

  settingCompartment: {
    '@media (min-width:901px)': {
      width: 'calc((100% - 2rem)/3)'
    },
    '@media (max-width:900px)': {
      width: 'calc(100% + 4.5em)',
      marginLeft: '-4.5em'
    }
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '60px'
    }
  },
  leftAuto: {
    marginLeft: 'auto'
  },

  maxHeightTxtInput: {
    height: '75px'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  tameshiwari: {
    alignItems: 'flex-start',
    width: '100%'
  },

  condensedView: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },

  marginRight0: {
    marginRight: 0
  },

  column: {
    flexDirection: 'column'
  },

  paddingInput05: {
    '@media (min-width:901px)': {
      margin: '0 1rem'
    }
  },

  paddingLeftInput05: {
    '@media (min-width:901px)': {
      marginLeft: '0.5rem'
    }
  },

  paddingRightInput05: {
    '@media (min-width:901px)': {
      marginRight: '0.5rem'
    }
  },

  marginTop05: {
    '@media (max-width:900px)': {
      marginTop: '12px'
    }
  },

  disabledBtn: {
    cursor: 'not-allowed'
  },

  disabled: {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.disabled,
      color: theme.palette.primary.contrastText
    }
  },

  width: {
    width: '5em'
  },

  addWrapper: {
    height: '3em',
    width: '3.5em',
    margin: '0.4em 0 0 0.5em'
  },

  marginTop5: {
    margin: '0.5em 0 0.5em 0'
  }

}));

export const styleFilter = {
  width:'100%'
};
