import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { changeModal, getApps, getApp, downloadFile } from '../../helpers/util';
import { changeTitle } from '../../helpers/actions';

import { AuthContext } from '../../AuthContext';

import DownloadApp from '../../components/DownloadApp/DownLoadsApp';
import SideModal from '../../components/Snackbar/SideModal';
import LoadingState from '../../components/LoadingState/LoadingState';

class Settings extends Component {
  state = {
    apps: [],
    showModal: false,
    success: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    isDownloading: false
  };

  changeModal = changeModal.bind(this);
  getApps = getApps.bind(this);
  getApp = getApp.bind(this);
  downloadFile = downloadFile.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeTitle(t('moreApps'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { role } = this.context.authState;

    this.getApps(() => {
      const { apps } = this.state;
      let copyApps = [...apps];

      if (role === 'operator') {
        copyApps = copyApps.filter((it) => +it?.app_type === 1); // TICKET #829
      }

      this.setState({ loading: false, apps: copyApps });
    });

    changeTitle(this.props.t('moreApps'));
  }

  closeModalHandler = () => this.setState({ showModal: false });

  render() {
    const {
      modalInfo,
      showModal,
      success,
      apps,
      loading,
      appId,
      isDownloading
    } = this.state;

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <DownloadApp
            {...{ apps }}
            getApp={this.getApp}
            loading={isDownloading}
            {...{ appId }}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(Settings);
