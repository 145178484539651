import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  img: {
    cursor: 'pointer',
    maxHeight: '70px',
    objectFit: 'fill'
  },
  titleImg: {
    maxWidth: '280px',
    width: '100%'
  },
  wrapper: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },
  marginTop: {
    marginTop: '20px'
  },
  centerTxt: {
    textAlign: 'center'
  },
  column: {
    flexDirection: 'column'
  },
  centerHorizontally: {
    justifyContent: 'center'
  },
  centerVertically: {
    alignItems: 'center'
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '40px'
  },
  app: {
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '20px',
    '@media (max-width:900px)': {
      fontSize: '16px'
    }
  },
  infoTxt: {
    paddingLeft: '40px'
  },
  btnWrapper: {
    marginLeft: 'auto',
    '@media (max-width:900px)': {
      margin: 'auto'
    }
  },
  card: {
    maxWidth: '570px',
    paddingRight: '20px'
  },
  maxWidth: {
    maxWidth: '300px'
  },
  fullWidth: {
    width: '100%'
  },
  spinner: {
    color: '#fff'
  },

  othersWrapper: {
    maxHeight: '8rem',
    overflow: 'auto',
    width: '100%'
  },

  othersItem: {
    padding: '0.5rem 0',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6
    }
  },

  others: {
    color: '#001D3D',
    padding: '0.5rem',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
      opacity: 0.6
    }
  }
}));
