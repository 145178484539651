import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  maxWidth: {
    width: '100%'
  },

  marginLeft: {
    marginLeft: 'auto'
  },

  condensedViewLeftAligned: {
    marginTop: '10px',
    '@media (max-width:1300px)': {
      marginLeft: 'auto'
    }
  },

  listRecord: {
    border: '1px solid #000',
    height: 'fit-content',
    padding: '2px',
    borderRadius: '4px'
  },

  breakWord: {
    whiteSpace: 'break-spaces'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  iconWrapper: {
    width: '2.2rem',
    height: '2.2rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    }
  },

  icon: {
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    cursor: 'pointer',
    '@media (min-width:901px)': {
      marginLeft: '.65em'
    },
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  }
}));

export default useStyles;
